import ApiClient from './ApiClient'
import { AppProfilesService, AppProfileType } from '../../domain/AppProfiles'

export default class ApiAppProfilesService implements AppProfilesService {
  constructor(private apiClient: ApiClient) { }

  async getAppProfiles(): Promise<AppProfileType[]> {
    const response = await this.apiClient.getAppProfiles()
    return response
  }
}
