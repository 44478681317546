import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../utils'
import { Booking, BookingService } from '../../domain/Booking'
import { AppState } from '../../apps/main/store'

export const REQUEST_FETCH_HISTORIC_BOOKINGS = 'REQUEST_FETCH_HISTORIC_BOOKINGS'
export const SUCCESS_FETCH_HISTORIC_BOOKINGS = 'SUCCESS_FETCH_HISTORIC_BOOKINGS'
export const FAILURE_FETCH_HISTORIC_BOOKINGS = 'FAILURE_FETCH_HISTORIC_BOOKINGS'
export const SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY = 'SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY'

export const FETCH_ALL_BOOKINGS = 'FETCH_ALL_BOOKINGS'
export const SUCCESS_FETCH_ALL_BOOKINGS = 'SUCCESS_FETCH_ALL_BOOKINGS'
export const FAILURE_FETCH_ALL_BOOKINGS = 'FAILURE_FETCH_ALL_BOOKINGS'

interface ShowBookingDetailsFromBookingHistoryActionType {
  type: typeof SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY;
}

interface RequestHistoricBookingsActionType {
  type: typeof REQUEST_FETCH_HISTORIC_BOOKINGS;
}

interface SuccessFetchHistoricBookingsActionType {
  type: typeof SUCCESS_FETCH_HISTORIC_BOOKINGS;
  bookingsHistory: Array<Booking>;
}

interface FailureFetchHistoricBookingsActionType {
  type: typeof FAILURE_FETCH_HISTORIC_BOOKINGS;
}

interface FetchAllBookingsAction {
  type: typeof FETCH_ALL_BOOKINGS;
}

export interface SuccessFetchAllBookingsAction {
  type: typeof SUCCESS_FETCH_ALL_BOOKINGS;
  allBookings: Array<Booking>;
}

interface FailureFetchAllBookingsAction {
  type: typeof FAILURE_FETCH_ALL_BOOKINGS;
}

export type MyBookingsTypes = RequestHistoricBookingsActionType
| SuccessFetchHistoricBookingsActionType
| FailureFetchHistoricBookingsActionType
| ShowBookingDetailsFromBookingHistoryActionType
| FetchAllBookingsAction
| SuccessFetchAllBookingsAction
| FailureFetchAllBookingsAction

export function fetchBookingHistoryAction():
  AsyncAction<{}, { bookingService: BookingService }> {
  return async (dispatch, getState, { bookingService }) => {
    dispatch({ type: REQUEST_FETCH_HISTORIC_BOOKINGS })
    try {
      const response = await bookingService.getBookingHistory()
      let bookingsHistory = []
      const { appProfiles } = getState() as AppState
      const { isDependent, activeProfile } = appProfiles
      const profileId = activeProfile?.id
      if (isDependent) {
        bookingsHistory = response.filter((item: Booking) => (item.children?.id === profileId))
      } else {
        bookingsHistory = response.filter((item: Booking) => (!item.children))
      }
      dispatch({ type: SUCCESS_FETCH_HISTORIC_BOOKINGS, bookingsHistory })
    } catch (error) {
      dispatch({ type: FAILURE_FETCH_HISTORIC_BOOKINGS })
      Sentry.captureException(new Error(`Failure to fetch historic bookings - ${JSON.stringify(error)}`))
    }
  }
}

export function getBookingsAction():
  AsyncAction<{}, { bookingService: BookingService }> {
  return async (dispatch, getState, { bookingService }) => {
    dispatch({ type: FETCH_ALL_BOOKINGS })
    try {
      const response = await bookingService.getBookings()
      let allBookings = []
      const { appProfiles } = getState() as AppState
      const { isDependent, activeProfile } = appProfiles
      const profileId = activeProfile?.id
      if (isDependent) {
        allBookings = response.filter((item: Booking) => (item.children?.id === profileId))
      } else {
        allBookings = response.filter((item: Booking) => (!item.children))
      }
      dispatch({ type: SUCCESS_FETCH_ALL_BOOKINGS, allBookings })
    } catch (error) {
      dispatch({ type: FAILURE_FETCH_ALL_BOOKINGS })
      Sentry.captureException(new Error(`Failure to fetch all bookings - ${JSON.stringify(error)}`))
    }
  }
}

export const onShowBookingDetailsFromBookingHistoryAction = ():
ShowBookingDetailsFromBookingHistoryActionType => ({
  type: SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY
})
