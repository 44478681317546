import { HighlightDesireType, UserHighlights, UserHighlightsService } from '../../domain/UserHighlights'
import ApiClient from './ApiClient'

export default class ApiUserHighlightsService implements UserHighlightsService {
  constructor(private apiClient: ApiClient) { }

  getHighlights(): Promise<UserHighlights> {
    return this.apiClient.getHighlights()
  }

  sendHighlight(highlight: string): Promise<void> {
    return this.apiClient.sendHighlight(highlight)
  }

  postNotificationConfirmation(type: HighlightDesireType): Promise<void> {
    return this.apiClient.postNotificationConfirmation(type)
  }
}
