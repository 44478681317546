import { TheiaError } from './errors/TheiaError'

export type FetchStatusType = {
  isFetch: boolean;
  isFetching: boolean;
  errorFetch: boolean;
  errorMsg?: string;
}

export type PostStatusType = {
  isPosting: boolean;
  isPost: boolean;
  errorPost: boolean;
}

export type StatusType = {
  isLoading: boolean;
  success: boolean;
  error: boolean;
  errorMessage?: TheiaError;
}

export const initialStatus: StatusType = {
  isLoading: false,
  error: false,
  success: false,
  errorMessage: undefined
}
