import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { RegularButton, Loader } from 'theia-web-ds'
import { AppDispatch } from '../../../state/utils'
import CalendarWarning from '../../../../assets/calendar_error.svg'
import {
  confirmBookingCancellationAction,
  toggleCancellationReasonAction,
  suppressScheduleCancellationAction
} from '../../../state/scheduleCancellation/actions'
import { AppState } from '../../../apps/main/store'
import { Reason } from '../../../domain/Booking'
import ReasonSelection from '../../common/ReasonSelection'
import Modal from '../../common/Modal'
import CancelButton from '../../common/buttons/CancelButton'

interface Props {
  isFetchingReasons: boolean;
  reasons: Array<Reason>;
  confirmBookingCancellation: (otherMotive: string) => void;
  toggleReason: (reason: Reason) => void;
  closeModal: (step: any) => void;
  step: any;
  isExam?: boolean;
}

function ScheduleCancellationReasonModal({
  reasons,
  confirmBookingCancellation,
  toggleReason,
  isFetchingReasons,
  closeModal,
  step,
  isExam
}: Props) {
  const [otherMotive, setOtherMotive] = useState('')
  const hasReason = useMemo(() => reasons.some((r) => r.selected), [reasons])

  function onSend() {
    confirmBookingCancellation(otherMotive)
  }

  function updateOtherMotive(value: string) {
    setOtherMotive(value)
  }

  return (
    <Modal>
      <div data-testid="schedule-cancellation-reason-modal" className="schedule-cancellation-reason-modal flex flex-col text-center">
        <div className="self-center mb-4 ml-4">
          <CalendarWarning />
        </div>
        <h1 className="modal__title">Poxa, mas por quê?</h1>
        <div>
          {isFetchingReasons
            ? (
              <div className="flex justify-center w-full pb-4">
                <div className="flex items-center">
                  <Loader />
                </div>
              </div>
            )
            : (
              <ReasonSelection
                otherMotive={otherMotive}
                reasons={reasons}
                toggleReason={toggleReason}
                updateOtherMotive={updateOtherMotive}
              />
            )}
        </div>
        <RegularButton
          label={`Cancelar ${isExam ? 'esse exame' : 'essa consulta'}`}
          onClick={onSend}
          disabled={!hasReason}
          maxWidth="100%"
          extraClass="cancellation-reason-button mb-4"
          data-testid="cancellation-reason-button"
        />
        <CancelButton onClick={() => closeModal(step)}>Manter {isExam ? 'exame' : 'consulta'}</CancelButton>

      </div>
    </Modal>
  )
}

const mapStateToProps = ({ scheduleCancellation }: AppState) => ({
  reasons: scheduleCancellation.reasons,
  isFetchingReasons: scheduleCancellation.isFetchingReasons,
  step: scheduleCancellation.step,
  isExam: scheduleCancellation.isExam
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  toggleReason: (reason: Reason) => dispatch(toggleCancellationReasonAction(reason)),
  confirmBookingCancellation: (otherMotive: string) => dispatch(
    confirmBookingCancellationAction(otherMotive)
  ),
  closeModal: (step: any) => {
    dispatch(suppressScheduleCancellationAction(step))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleCancellationReasonModal)
