/* eslint-disable no-unused-vars */
export enum HealthInsurancePlanStatus {
  NOT_INFORMED = 'NOT_INFORMED',
  HAVE_PLAN = 'HAVE_PLAN',
  DONT_HAVE_PLAN = 'DONT_HAVE_PLAN'
}

export type BabyData = {
  id?: string;
  name?: string;
  birthDate?: string;
  cpf?: string;
  companyId?: string | null;
  companyName?: string | null;
  memberId?: string | null;
  planId?: string | null;
  planName?: string | null;
  healthInsurancePlanStatus?: HealthInsurancePlanStatus;
}

export type ChildrenSummary = {
  names: string;
  birthDate: string;
  birthDateText: string;
  currentMonth: string;
  title: string;
}

export interface PediatricFlowService {
  getChildrenData(): Promise<BabyData[]>;
  getChildrenSummary(): Promise<ChildrenSummary | undefined>;
  updateChildren(babyData: BabyData[]): Promise<void>;
}
