/* eslint-disable no-unused-vars */
import { StatusType, initialStatus } from '../../domain/Status';
import { isProductionEnv } from '../../utils/helpers';
import {
  GET_REMOTE_CONFIG,
  SUCCESS_GET_REMOTE_CONFIG,
  FAILURE_GET_REMOTE_CONFIG,
  RemoteConfigTypes
} from './actions'

export type AppFlagsOptions =
  | 'redirectSignUp'
  | 'showGinecologicRoutine'
  | 'currentAcceptableDateToRegister'
  | 'showRecaptcha'

export type AppFlags = { [key in AppFlagsOptions]: any }

/**
 * Valores default para as flags caso a API falhe
*/
export const defaultProdValues: AppFlags = {
  redirectSignUp: false,
  showGinecologicRoutine: true,
  currentAcceptableDateToRegister: 1751338800000,
  showRecaptcha: true
}

const defaultStgValues: AppFlags = {
  redirectSignUp: false,
  showGinecologicRoutine: true,
  currentAcceptableDateToRegister: 1751338800000,
  showRecaptcha: false
}

interface RemoteConfigState {
  flags: AppFlags;
  getRemoteConfigStatus: StatusType;
}

const initialRemoteConfigState: RemoteConfigState = {
  flags: isProductionEnv() ? defaultProdValues : defaultStgValues,
  getRemoteConfigStatus: initialStatus
}

export function remoteConfigState(
  state = initialRemoteConfigState,
  action: RemoteConfigTypes
): RemoteConfigState {
  switch (action.type) {
    case GET_REMOTE_CONFIG: {
      return {
        ...state,
        getRemoteConfigStatus: {
          isLoading: true,
          error: false,
          success: false,
          errorMessage: undefined
        }
      }
    }
    case SUCCESS_GET_REMOTE_CONFIG: {
      return {
        ...state,
        getRemoteConfigStatus: {
          isLoading: false,
          error: false,
          success: true,
          errorMessage: undefined
        },
        /** Sometimes flags response comes empty,
        * so to keep previous flag values we set as ...action.flags */
        flags: {
          ...action.flags
        }
      }
    }
    case FAILURE_GET_REMOTE_CONFIG: {
      return {
        ...state,
        getRemoteConfigStatus: {
          isLoading: false,
          error: true,
          success: false,
          errorMessage: action.error
        }
      }
    }
    default: return state
  }
}
