import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  RegularButton,
  ListItemCard,
  AccessibleModal,
  ButtonOptions,
  Divider
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import HealthPlanAccepted from '../../../../assets/OvalPlanCard-Yes.svg'
import HealthPlanNotAccepted from '../../../../assets/Plan-no.svg'
import HealthPlanAlert from '../../../../assets/PlanCard-Alert.svg'
import HealthPlanAdd from '../../../../assets/PlanCard-Add.svg'

import './HealthPlanProfile.scss'
import { getAcceptedHealthCompaniesAction, clearPostHealthInsuranceStatusAction, postUserHealthInsuranceAction } from '../../../state/healthplanProfile/actions'
import { AppDispatch } from '../../../state/utils'
import { AppState } from '../../../apps/main/store'
import { error, textSecondary } from '../../../color'
import { eventTrack } from '../../../../eventGenerate'
import { ConsultantMoment } from '../../../domain/Profile'
import {
  isOtherMoment,
  isPostPregnant,
  isPregnant,
  isTrying,
  isValidElement
} from '../../../utils/helpers'
import { HealthInsurancePlanData, PostUserHealthPlanType } from '../../../domain/Healthplan'
import { CHANGE_HEALTH_PLAN, HEALTH_PLAN_INCLUDED, PROFILE } from '../../../routes/RoutesConstants'
import { StatusType } from '../../../domain/Status'

interface Props {
  isFetchingProfile: boolean;
  postHealthInsurancePlanStatus: StatusType;
  isSuccessDeleted: boolean;
  pregnancyMoment?: ConsultantMoment;
  acceptedHealthCompanies?: Array<string>;
  isGettingHealthPlansList: boolean;
  healthInsurancePlanData: HealthInsurancePlanData | null;
  getHealthInsurancePlanStatus: StatusType;
  onPostHealthInsurance: (
    healthPlanData: PostUserHealthPlanType,
    alreadyHasInsurancePlan: boolean
  ) => void;
  clearPostHealthInsuranceStatus: () => void;
  getAcceptedHealthCompanies: () => void;
}

function HealthPlanProfileCard({
  isFetchingProfile,
  postHealthInsurancePlanStatus,
  isSuccessDeleted,
  pregnancyMoment,
  acceptedHealthCompanies,
  isGettingHealthPlansList,
  healthInsurancePlanData,
  getHealthInsurancePlanStatus,
  onPostHealthInsurance,
  clearPostHealthInsuranceStatus,
  getAcceptedHealthCompanies,
}: Props) {
  const history = useHistory()

  const isInsuranceCovered = !!(healthInsurancePlanData?.insurancePlanCovered)
  const [isButtonGroupVisible, setIsButtonGroupVisible] = useState(false)
  const [deleteAlertCard, setDeleteAlertCard] = useState(false)
  const includedCategories = healthInsurancePlanData?.benefits?.map(
    (item: any) => item.categoryName
  ).filter((cn: any) => cn !== null).length || 0
  const healthPlanCompanyName = healthInsurancePlanData?.healthInsurancePlan?.company || ''
  const healthPlanName = isValidElement(healthInsurancePlanData?.healthInsurancePlan?.planName)
    ? `${healthPlanCompanyName} ${healthInsurancePlanData?.healthInsurancePlan?.planName}`
    : healthPlanCompanyName
  const alreadyHasHealthPlan = !!(healthInsurancePlanData?.hasHealthInsurancePlan)

  const isFetchingInfos = useMemo(() => !!(
    isFetchingProfile
    || isGettingHealthPlansList
    || getHealthInsurancePlanStatus.isLoading
  ), [isFetchingProfile, isGettingHealthPlansList, getHealthInsurancePlanStatus])

  useEffect(() => {
    const timeout: NodeJS.Timeout = setTimeout(() => {
      if (!acceptedHealthCompanies) getAcceptedHealthCompanies()
    }, 200)
    return () => {
      clearTimeout(timeout)
      clearPostHealthInsuranceStatus()
    }
  }, [])

  useEffect(() => {
    if (isSuccessDeleted) {
      setDeleteAlertCard(false)
      history.push(PROFILE, { isFromDeleteHealthPlan: true })
    }
  }, [isSuccessDeleted])

  const getHealthPlanImage = () => {
    if (!healthInsurancePlanData?.hasHealthInsurancePlan) {
      return <HealthPlanAdd />
    }
    if (!isInsuranceCovered && healthInsurancePlanData?.hasHealthInsurancePlan) {
      return <HealthPlanNotAccepted />
    }
    if (acceptedHealthCompanies?.includes(healthPlanCompanyName || '')
      && healthInsurancePlanData?.healthInsurancePlan?.planName === '') {
      return <HealthPlanAlert />
    }
    return <HealthPlanAccepted />
  }

  const getSubtitleMessage = () => {
    if (!isInsuranceCovered && healthInsurancePlanData?.hasHealthInsurancePlan) {
      return 'Ainda não aceitamos esse plano'
    }
    if (acceptedHealthCompanies?.includes(healthPlanCompanyName || '')
      && healthInsurancePlanData?.healthInsurancePlan?.planName === '') {
      return 'Atualize os dados do seu convênio'
    }
    if (isInsuranceCovered) {
      if (acceptedHealthCompanies
        && acceptedHealthCompanies.includes(healthPlanCompanyName || '')) {
        if (isPregnant(pregnancyMoment)) {
          return 'Jornada de Pré-Natal inclusa'
        }
        if (isTrying(pregnancyMoment)) {
          return 'Consulta pré-concepcional inclusa'
        }
        if (isOtherMoment(pregnancyMoment) || isPostPregnant(pregnancyMoment)) {
          return '1 especialidade inclusa'
        }
      }
      if (includedCategories === 1) {
        return `${includedCategories} especialidade incluída`
      }
      if (includedCategories > 1) {
        return `${includedCategories} especialidades disponíveis`
      }
    }
    return ''
  }

  function goToLocationUsingHistoryPush(link: string, state?: unknown) {
    eventTrack('Clicou Incluso no Plano')
    history.push(link, state)
  }

  const openButtonGroup = () => {
    eventTrack('Clicou Editar Plano')
    setIsButtonGroupVisible(true)
  }

  const closeButtonGroup = () => {
    setIsButtonGroupVisible(false)
  }

  const onDeleteHealthPlanBtnGroup = () => {
    setIsButtonGroupVisible(false)
    setDeleteAlertCard(true)
  }

  const suppressModal = () => {
    setDeleteAlertCard(false)
  }

  const deleteHealthPlan = () => {
    onPostHealthInsurance({
      hasHealthInsurancePlan: false,
      healthInsurancePlan: {
        company: null,
        planName: null,
        memberId: null,
        companyId: null,
        planId: null,
      }
    }, !!(healthInsurancePlanData?.hasHealthInsurancePlan)
    )
  }

  const deleteCard = (
    <AccessibleModal
      headerText=""
      visible={deleteAlertCard}
      showCloseButton={false}
      onClose={suppressModal}
      extraModalContainerClass="delete-plan-modal"
    >
      <p className="m-4 text-fontDefault text-textPrimary text-center">
        Tem certeza que deseja excluir seu plano {healthPlanName}?
      </p>
      <Divider />
      <div className="flex">
        <RegularButton
          label="Agora não"
          variant="text"
          extraClass="btn-from-list-card btn-left"
          onClick={suppressModal}
        />
        <span className="button-bottom" />
        <RegularButton
          label="Excluir"
          variant="text"
          extraClass="btn-from-list-card btn-right btn-delete-plan"
          onClick={deleteHealthPlan}
          isSubmitting={postHealthInsurancePlanStatus.isLoading}
        />
      </div>
    </AccessibleModal>
  )

  const buttonBarInsurancePlanCovered = (
    <>
      <ButtonOptions
        buttonToAnchor={(
          <RegularButton
            label="Editar plano"
            variant="text"
            extraClass="btn-change-plan btn-from-list-card btn-left"
            onClick={openButtonGroup}
          />
        )}
        visible={isButtonGroupVisible}
        onClose={closeButtonGroup}
        headerText="Opções de editar plano"
        extraClass="new-anchor-group"
      >
        <ListItemCard
          title="Alterar plano de saúde"
          ariaLabel="Alterar plano de saúde"
          onClick={() => goToLocationUsingHistoryPush(CHANGE_HEALTH_PLAN, [alreadyHasHealthPlan])}
          iconType="icon-EditLight"
          iconColor={textSecondary}
          extraClassNameContainer="item-from-btn-group"
          extraClassClickableContainer="first-item-border-radius"
        />
        <ListItemCard
          title="Deletar plano atual"
          ariaLabel="Deletar plano atual"
          onClick={onDeleteHealthPlanBtnGroup}
          iconType="icon-TrashLight"
          iconColor={error}
          arrowColor={textSecondary}
          extraClassNameContainer="item-from-btn-group"
          extraClassClickableContainer="last-item-border-radius"
          isLastItem
        />
      </ButtonOptions>
      <span className="button-bottom" />
      <RegularButton label="Incluso no plano" variant="text" onClick={() => goToLocationUsingHistoryPush(HEALTH_PLAN_INCLUDED)} extraClass="btn-from-list-card btn-right" />
    </>
  )

  const buttonBarInsurancePlanNotCovered = (
    <ButtonOptions
      buttonToAnchor={(
        <RegularButton label="Editar plano" variant="text" extraClass="btn-change-plan btn-from-list-card single-btn" onClick={openButtonGroup} />
        )}
      visible={isButtonGroupVisible}
      onClose={closeButtonGroup}
      headerText="Opções de editar plano"
      extraClass="new-anchor-group"
    >
      <ListItemCard
        title="Alterar plano de saúde"
        ariaLabel="Alterar plano de saúde"
        onClick={() => goToLocationUsingHistoryPush(CHANGE_HEALTH_PLAN, [alreadyHasHealthPlan])}
        iconType="icon-EditLight"
        iconColor={textSecondary}
        extraClassNameContainer="item-from-btn-group"
        extraClassClickableContainer="first-item-border-radius"
      />
      <ListItemCard
        title="Deletar plano atual"
        ariaLabel="Deletar plano atual"
        onClick={onDeleteHealthPlanBtnGroup}
        iconType="icon-TrashLight"
        iconColor={error}
        arrowColor={textSecondary}
        extraClassNameContainer="item-from-btn-group"
        extraClassClickableContainer="last-item-border-radius"
        isLastItem
      />
    </ButtonOptions>
  )

  const buttonBarNoHealthPlanAdded = (
    <RegularButton
      label="Adicionar plano de saúde"
      variant="text"
      onClick={() => goToLocationUsingHistoryPush(CHANGE_HEALTH_PLAN, [alreadyHasHealthPlan])}
      extraClass="btn-from-list-card single-btn"
      isLoadingScreen={isFetchingInfos}
    />
  )

  return (
    <>
      {deleteAlertCard && (
        deleteCard
      )}
      <ListItemCard
        extraClassNameContainer="profile-health-card list-item-card-only-btn"
        extraClassClickableContainer="list-item-container-only-btn"
        title={healthPlanName || 'Nenhum plano adicionado'}
        caption={getSubtitleMessage()}
        ariaLabel={healthPlanName || 'Nenhum plano adicionado'}
        isLastItem
        isLoading={isFetchingInfos}
        isItemFromList={false}
        picture={<span className="profile-icon">{getHealthPlanImage()}</span>}
      >
        <Divider />
        <div className="flex">
          {isInsuranceCovered && (
            buttonBarInsurancePlanCovered
          )}
          {!isInsuranceCovered && isInsuranceCovered !== null && alreadyHasHealthPlan && (
            buttonBarInsurancePlanNotCovered
          )}
          {isInsuranceCovered === null
          || (!isInsuranceCovered && isInsuranceCovered !== null && alreadyHasHealthPlan === false)
            ? (
              buttonBarNoHealthPlanAdded
            ) : ''}
        </div>
      </ListItemCard>
    </>
  )
}

const mapStateToProps = ({
  healthplan, authentication
}: AppState) => ({
  isSuccessDeleted: healthplan.isSuccessDeleted,
  postHealthInsurancePlanStatus: healthplan.postHealthInsurancePlanStatus,
  pregnancyMoment: authentication.currentUser?.pregnancyMoment,
  isGettingHealthPlansList: healthplan.acceptedHealthCompaniesStatus.isLoading,
  acceptedHealthCompanies: healthplan.acceptedHealthCompanies,
  healthInsurancePlanData: healthplan.healthInsurancePlanData,
  getHealthInsurancePlanStatus: healthplan.getHealthInsurancePlanStatus
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onPostHealthInsurance: postUserHealthInsuranceAction,
  clearPostHealthInsuranceStatus: clearPostHealthInsuranceStatusAction,
  getAcceptedHealthCompanies: getAcceptedHealthCompaniesAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthPlanProfileCard)
