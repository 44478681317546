import { Booking } from '../../domain/Booking';
import { initialStatus, StatusType } from '../../domain/Status';
import { SuccessRescheduleBookingActionType, SUCCESS_RESCHEDULE_BOOKING } from '../reschedule/actions'
import { SuccessBookingCancel, SUCCESS_BOOKING_CANCEL } from '../scheduleCancellation/actions'
import {
  FAILURE_FETCH_HISTORIC_BOOKINGS,
  MyBookingsTypes,
  REQUEST_FETCH_HISTORIC_BOOKINGS,
  SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY,
  SUCCESS_FETCH_HISTORIC_BOOKINGS,
  FETCH_ALL_BOOKINGS,
  SUCCESS_FETCH_ALL_BOOKINGS,
  FAILURE_FETCH_ALL_BOOKINGS,
} from './actions'

export interface MyBookingsState {
  bookingHistory: Array<Booking>;
  isFetchingBookingHistory: boolean;
  forceUpdateHistory: boolean;
  hasPendingNotification: boolean;
  getBookingsStatus: StatusType;
  forceUpdateAllBookings: boolean;
  allBookings: Array<Booking>;
  bookingsByMonth: Map<string, Array<Booking>>;
}

const initialWeekFlow: MyBookingsState = {
  bookingHistory: [],
  isFetchingBookingHistory: false,
  forceUpdateHistory: false,
  hasPendingNotification: false,
  getBookingsStatus: initialStatus,
  forceUpdateAllBookings: false,
  allBookings: [],
  bookingsByMonth: new Map(),
}

let bookingHistoryList: any

export function myBookingsFlow(
  state = initialWeekFlow,
  action: MyBookingsTypes | SuccessBookingCancel | SuccessRescheduleBookingActionType
): MyBookingsState {
  switch (action.type) {
    case REQUEST_FETCH_HISTORIC_BOOKINGS: {
      return {
        ...state,
        isFetchingBookingHistory: true,
        bookingHistory: [],
        hasPendingNotification: false
      }
    }
    case SUCCESS_FETCH_HISTORIC_BOOKINGS: {
      bookingHistoryList = action.bookingsHistory
      const bookingHistoryPendingPrescriptionsViewed = bookingHistoryList.filter(
        (prescription: any) => prescription.prescriptionsViewed === false
      )
      return {
        ...state,
        bookingHistory: action.bookingsHistory,
        forceUpdateHistory: false,
        isFetchingBookingHistory: false,
        hasPendingNotification: bookingHistoryPendingPrescriptionsViewed.length >= 1
      }
    }
    case FAILURE_FETCH_HISTORIC_BOOKINGS: {
      return {
        ...state,
        isFetchingBookingHistory: false,
      }
    }
    case SUCCESS_BOOKING_CANCEL: {
      const newBookings = state.allBookings.filter((b) => b.id !== action.bookingId)
      return {
        ...state,
        allBookings: newBookings,
        bookingsByMonth: newBookings.reduce((resultMap, booking) => {
          const month = booking.startDate.formatFullMonth()
          const year = booking.startDate.formatYear()
          const title = `${month} ${year}`
          if (resultMap.has(title)) {
            resultMap.get(title).push(booking)
          } else {
            resultMap.set(title, [booking])
          }
          return resultMap
        }, new Map()),
        forceUpdateHistory: true
      }
    }
    case SUCCESS_RESCHEDULE_BOOKING: {
      return {
        ...state,
        forceUpdateHistory: true,
        forceUpdateAllBookings: true,
      }
    }
    case SHOW_BOOKING_DETAILS_FROM_BOOKING_HISTORY: {
      const bookingHistoryPendingPrescriptionsViewed = bookingHistoryList.filter(
        (prescription: any) => prescription.prescriptionsViewed === false
      )
      return {
        ...state,
        hasPendingNotification: bookingHistoryPendingPrescriptionsViewed.length >= 1
      }
    }
    case FETCH_ALL_BOOKINGS:
      return {
        ...state,
        getBookingsStatus: {
          ...initialStatus,
          isLoading: true
        },
        allBookings: [],
        bookingsByMonth: new Map(),
        forceUpdateAllBookings: false,
      }
    case SUCCESS_FETCH_ALL_BOOKINGS:
      return {
        ...state,
        getBookingsStatus: {
          ...initialStatus,
          success: true
        },
        allBookings: action.allBookings,
        bookingsByMonth: action.allBookings.reduce((
          resultMap: any,
          booking: any
        ) => {
          const month = booking.startDate.formatFullMonth()
          const year = booking.startDate.formatYear()
          const title = `${month} ${year}`
          if (resultMap.has(title)) {
            resultMap.get(title).push(booking)
          } else {
            resultMap.set(title, [booking])
          }
          return resultMap
        }, new Map())
      }
    case FAILURE_FETCH_ALL_BOOKINGS:
      return {
        ...state,
        getBookingsStatus: {
          ...initialStatus,
          error: true
        }
      }
    default: return state
  }
}
