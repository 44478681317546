import * as Sentry from '@sentry/browser'
import { AsyncAppAction } from '../utils'
import { LocalAddress } from '../../utils/LocalAddress'
import { promptDefaultErrorAction } from '../defaultError/actions'
import { isValidElement } from '../../utils/helpers'
import { eventTrack } from '../../../eventGenerate'
import { TheiaError } from '../../domain/errors/TheiaError'

export const REQUEST_BOOKING_CONFIRMATION_INFO_SMS = 'REQUEST_BOOKING_CONFIRMATION_INFO_SMS'
export const SUCCESS_BOOKING_CONFIRMATION_INFO_SMS = 'SUCCESS_BOOKING_CONFIRMATION_INFO_SMS'
export const FAILURE_BOOKING_CONFIRMATION_INFO_SMS = 'FAILURE_BOOKING_CONFIRMATION_INFO_SMS'
export const REQUEST_CONFIRM_BOOKING_SMS = 'REQUEST_CONFIRM_BOOKING_SMS'
export const SUCCESS_CONFIRM_BOOKING_SMS = 'SUCCESS_CONFIRM_BOOKING_SMS'
export const FAILURE_CONFIRM_BOOKING_SMS = 'FAILURE_CONFIRM_BOOKING_SMS'
export const REQUEST_CANCEL_BOOKING_SMS = 'REQUEST_CANCEL_BOOKING_SMS'
export const SUCCESS_CANCEL_BOOKING_SMS = 'SUCCESS_CANCEL_BOOKING_SMS'
export const FAILURE_CANCEL_BOOKING_SMS = 'FAILURE_CANCEL_BOOKING_SMS'
export const PROMPT_CANCEL_BOOKING_SMS_MODAL = 'PROMPT_CANCEL_BOOKING_SMS_MODAL'
export const SUPPRESS_CANCEL_BOOKING_SMS_MODAL = 'SUPPRESS_CANCEL_BOOKING_SMS_MODAL'
export const REQUEST_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS = 'REQUEST_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS'
export const SUCCESS_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS = 'SUCCESS_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS'
export const FAILURE_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS = 'FAILURE_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS'
export const PROMPT_CONFIRM_BOOKING_SMS_MODAL = 'PROMPT_CONFIRM_BOOKING_SMS_MODAL'
export const SUPPRESS_CONFIRM_BOOKING_SMS_MODAL = 'SUPPRESS_CONFIRM_BOOKING_SMS_MODAL'

interface RequestBookingInfoSMSActionType {
  type: typeof REQUEST_BOOKING_CONFIRMATION_INFO_SMS;
  bookingId: string;
  token: string;
}

interface SuccessBookingInfoSMSActionType {
  type: typeof SUCCESS_BOOKING_CONFIRMATION_INFO_SMS;
  startDate: string;
  professionalName: string;
  address: LocalAddress;
  alreadyConfirmed: boolean;
  alreadyCancelled: boolean;
  confirmationAvailable: boolean;
}

interface FailureBookingInfoSMSActionType {
  type: typeof FAILURE_BOOKING_CONFIRMATION_INFO_SMS;
  error?: TheiaError
}

interface RequestConfirmBookingSMSActionType {
  type: typeof REQUEST_CONFIRM_BOOKING_SMS;
  participantType?: string
  bookingId: string;
}

interface SuccessConfirmBookingSMSActionType {
  type: typeof SUCCESS_CONFIRM_BOOKING_SMS;
  participantType?: string;
  bookingId: string;
}

interface FailureConfirmBookingSMSActionType {
  type: typeof FAILURE_CONFIRM_BOOKING_SMS;
  participantType?: string;
  bookingId: string;
  error: TheiaError
}

interface RequestCancelBookingSMSActionType {
  type: typeof REQUEST_CANCEL_BOOKING_SMS;
  bookingId: string;
}

interface SuccessCancelBookingSMSActionType {
  type: typeof SUCCESS_CANCEL_BOOKING_SMS;
  bookingId: string;
}

interface FailureCancelBookingSMSActionType {
  type: typeof FAILURE_CANCEL_BOOKING_SMS;
  bookingId: string;
  error: {
    message?: string;
    title?: string;
  };
}

interface PromtpCancelBookingSMSModalActionType {
  type: typeof PROMPT_CANCEL_BOOKING_SMS_MODAL;
}

interface SuppressCancelBookingSMSModalActionType {
  type: typeof SUPPRESS_CANCEL_BOOKING_SMS_MODAL;
}

interface RequestBookingProfessionalInfoSMSActionType {
  type: typeof REQUEST_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS;
  bookingId: string;
  token: string;
}

interface SuccessBookingProfessionalInfoSMSActionType {
  type: typeof SUCCESS_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS;
  startDate: string;
  professionalName: string;
  status: string;
  bookingId: string;
}

interface FailureBookingProfessionalInfoSMSActionType {
  type: typeof FAILURE_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS;
  bookingId: string;
}

interface PromtpConfirmBookingSMSModalActionType {
  type: typeof PROMPT_CONFIRM_BOOKING_SMS_MODAL;
}

interface SuppressConfirmBookingSMSModalActionType {
  type: typeof SUPPRESS_CONFIRM_BOOKING_SMS_MODAL;
}

export type ScheduleConfirmationSMSTypes =
  RequestBookingInfoSMSActionType |
  SuccessBookingInfoSMSActionType |
  FailureBookingInfoSMSActionType |
  RequestConfirmBookingSMSActionType |
  SuccessConfirmBookingSMSActionType |
  FailureConfirmBookingSMSActionType |
  RequestCancelBookingSMSActionType |
  SuccessCancelBookingSMSActionType |
  FailureCancelBookingSMSActionType |
  PromtpCancelBookingSMSModalActionType |
  SuppressCancelBookingSMSModalActionType |
  RequestBookingProfessionalInfoSMSActionType |
  SuccessBookingProfessionalInfoSMSActionType |
  FailureBookingProfessionalInfoSMSActionType |
  PromtpConfirmBookingSMSModalActionType |
  SuppressConfirmBookingSMSModalActionType

export const promtpCancelBookingSMSModalAction = (): ScheduleConfirmationSMSTypes => ({
  type: PROMPT_CANCEL_BOOKING_SMS_MODAL
})

export const suppressCancelBookingSMSModalAction = (): ScheduleConfirmationSMSTypes => ({
  type: SUPPRESS_CANCEL_BOOKING_SMS_MODAL
})

export function bookingConfirmationInfoSMSAction(
  bookingId: string,
  token: string
): AsyncAppAction {
  return async (dispatch, _getState, { bookingService }) => {
    if (!isValidElement(bookingId)) return
    eventTrack('get booking confirmation infos', {
      bookingId
    })
    dispatch({
      type: REQUEST_BOOKING_CONFIRMATION_INFO_SMS,
      bookingId,
      token
    })
    try {
      const bookingConfirmationInfo = await bookingService.bookingConfirmationInfoSMS(
        bookingId,
        token
      )
      eventTrack('success getting booking confirmation infos', {
        bookingId
      })
      dispatch({
        type: SUCCESS_BOOKING_CONFIRMATION_INFO_SMS,
        startDate: bookingConfirmationInfo.startDate,
        professionalName: bookingConfirmationInfo.professionalName,
        address: bookingConfirmationInfo.address,
        alreadyConfirmed: bookingConfirmationInfo.alreadyConfirmed,
        alreadyCancelled: bookingConfirmationInfo.alreadyCancelled,
        confirmationAvailable: bookingConfirmationInfo.confirmationAvailable
      })
    } catch (error) {
      Sentry.captureException(new Error(`Failure getting booking confirmation infos - ${JSON.stringify(error)}`))
      eventTrack('failure getting booking confirmation infos', {
        bookingId,
        erro: JSON.stringify(error)
      })
      if (error instanceof TheiaError) {
        dispatch({
          type: FAILURE_BOOKING_CONFIRMATION_INFO_SMS,
          error: {
            ...error,
            friendlyMessageTitle: error.friendlyMessageTitle || 'Ops, aconteceu um problema',
            friendlyMessage: error.friendlyMessage || 'Não foi possível carregar as informações do agendamento.'
          }
        })
      } else {
        dispatch({ type: FAILURE_BOOKING_CONFIRMATION_INFO_SMS })
      }
    }
  }
}

export function confirmBookingSMSAction(
  bookingId: string,
  token: string,
  participantType?: string,
  professionalStatus?: string
): AsyncAppAction {
  return async (dispatch, _getState, { bookingService }) => {
    if (!isValidElement(bookingId)) return
    dispatch({ type: REQUEST_CONFIRM_BOOKING_SMS, participantType, bookingId })
    eventTrack('post confirm booking', {
      bookingId,
      participantType
    })
    try {
      await bookingService.confirmBookingSMS(bookingId, token, participantType, professionalStatus)
      dispatch({ type: SUCCESS_CONFIRM_BOOKING_SMS, participantType, bookingId })
      eventTrack('Consulta confirmada', {
        bookingId,
        participantType
      })
    } catch (error) {
      eventTrack('Erro ao confirmar consulta', {
        bookingId,
        erro: JSON.stringify(error),
        participantType
      })
      Sentry.captureException(new Error(`Failure to confirm a booking - ${JSON.stringify(error)}`))
      if (error instanceof TheiaError) {
        dispatch(promptDefaultErrorAction(error))
        dispatch({
          type: FAILURE_CONFIRM_BOOKING_SMS,
          participantType,
          bookingId,
          error: {
            ...error,
            friendlyMessageTitle: error.friendlyMessageTitle || 'Ops, aconteceu um problema',
            friendlyMessage: error.friendlyMessage || 'Não foi possível confirmar o agendamento.'
          }
        })
      }
    }
  }
}

export function cancelBookingSMSAction(bookingId: string, token: string): AsyncAppAction {
  return async (dispatch, _getState, { bookingService }) => {
    if (!isValidElement(bookingId)) return
    dispatch({ type: REQUEST_CANCEL_BOOKING_SMS, bookingId })
    eventTrack('post cancel booking', {
      bookingId
    })
    try {
      await bookingService.cancelBookingSMS(bookingId, token)
      dispatch({ type: SUCCESS_CANCEL_BOOKING_SMS, bookingId })
      eventTrack('Consulta cancelada', {
        bookingId
      })
    } catch (error) {
      eventTrack('Erro ao cancelar consulta', {
        bookingId,
        erro: JSON.stringify(error)
      })
      Sentry.captureException(new Error(`Failure to cancel a booking - ${JSON.stringify(error)}`))
      if (error instanceof TheiaError) {
        dispatch(promptDefaultErrorAction(error))
        dispatch({
          type: FAILURE_CANCEL_BOOKING_SMS,
          bookingId,
          error: {
            ...error,
            message: error.friendlyMessage,
            title: error.friendlyMessageTitle || 'Ops, aconteceu um problema'
          },
        })
      }
    }
  }
}

export const promtpConfirmBookingSMSModalAction = (): ScheduleConfirmationSMSTypes => ({
  type: PROMPT_CONFIRM_BOOKING_SMS_MODAL
})

export const suppressConfirmBookingSMSModalAction = (): ScheduleConfirmationSMSTypes => ({
  type: SUPPRESS_CONFIRM_BOOKING_SMS_MODAL
})

export function bookingProfessionalConfirmationInfoSMSAction(
  bookingId: string,
  token: string
): AsyncAppAction {
  return async (dispatch, _getState, { bookingService }) => {
    if (!isValidElement(bookingId)) return
    eventTrack('post professional booking confirmation', {
      bookingId
    })
    dispatch({
      type: REQUEST_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS,
      bookingId,
      token
    })
    try {
      const response = await bookingService.bookingProfessionalConfirmationInfoSMS(
        bookingId,
        token
      )
      dispatch({
        type: SUCCESS_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS,
        startDate: response.startDate,
        professionalName: response.professionalName,
        status: response.status,
        bookingId
      })
      eventTrack('success post professional booking confirmation', {
        bookingId
      })
    } catch (error) {
      Sentry.captureException(new Error(`Failure getting booking professional confirmation infos - ${JSON.stringify(error)}`))
      dispatch({
        type: FAILURE_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS,
        bookingId
      })
      eventTrack('failure post professional booking confirmation', {
        bookingId,
        erro: JSON.stringify(error)
      })
    }
  }
}
