import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  ContentCard,
  Loader,
  ResponsiveDrawer,
  Snackbar
} from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import { PregnancyCardContent } from '../../domain/Consultant'
import { AppDispatch } from '../../state/utils'
import Book from '../../../assets/book.png'
import BookDisabled from '../../../assets/book-disabled.png'
import { getPregnancyCardsAction, suppressPregnancyCardsDrawerAction } from '../../state/consultant/actions'
import { StatusType } from '../../domain/Status'
import { textDisable, textPrimary, textSecondary } from '../../color'
import { isPregnant } from '../../utils/helpers'
import { ConsultantMoment } from '../../domain/Profile'
import './PregnancyCardsDrawer.scss'
import { eventTrack } from '../../../eventGenerate'

interface Props {
  pregnancyCards?: Array<PregnancyCardContent>
  getPregnancyCardsStatus: StatusType
  pregnancyCardsDrawerIsOpen: boolean
  pregnancyMoment?: ConsultantMoment
  suppressDrawer: () => void
  getPregnancyCards: () => void
}
function PregnancyCardsDrawer({
  pregnancyCards,
  getPregnancyCardsStatus,
  pregnancyCardsDrawerIsOpen,
  pregnancyMoment,
  getPregnancyCards,
  suppressDrawer,
}: Props) {
  const previousPregnancyCards = pregnancyCards && pregnancyCards.filter((card) => !card.isCurrent)
  const activePregnancyCard = pregnancyCards && pregnancyCards.find((card) => card.isCurrent)

  useEffect(() => {
    if (pregnancyCardsDrawerIsOpen) {
      getPregnancyCards()
      eventTrack('Visualizou modal cartão da gestante')
    }
  }, [pregnancyCardsDrawerIsOpen])

  function onClickPregnancyCard(url?: string) {
    window.open(url, '_blank')
  }

  useEffect(() => {
    if (getPregnancyCardsStatus.error) {
      eventTrack('Erro ao carregar cartões', {
        motivo: JSON.stringify(getPregnancyCardsStatus.errorMessage)
      })
    }
  }, [getPregnancyCardsStatus])

  return pregnancyCardsDrawerIsOpen ? (
    <ResponsiveDrawer
      id="pregnancy-cards-drawer"
      isDrawerVisible={pregnancyCardsDrawerIsOpen}
      onGoBack={suppressDrawer}
      onClose={suppressDrawer}
      headerTitle="Cartão da gestante"
    >
      <div className="pregnancy-card-drawer-content">
        {getPregnancyCardsStatus.isLoading && (
          <div className="preganancy-cards-drawer__loading">
            <Loader />
          </div>
        )}
        {getPregnancyCardsStatus.error && (
          <div className="pregnancy-cards-drawer__error-msg">
            <Snackbar
              text={getPregnancyCardsStatus.errorMessage?.friendlyMessage
                || 'Desculpe, no momento não foi possível carregar a listagem dos cartões.'}
              title="Falha no carregamento"
              buttonTwoProps={{
                label: 'Tentar novamente',
                onClick: getPregnancyCards
              }}
            />
          </div>
        )}
        {getPregnancyCardsStatus.success && (
          <>
            {activePregnancyCard && (
              <div className="pb-4">
                <h3 className="pregnancy-card-drawer-subtitle">
                  Gestação atual
                </h3>
                <ContentCard
                  type="outlined"
                  ilustraBefore={{ src: Book }}
                  onClick={() => {
                    onClickPregnancyCard(activePregnancyCard?.pdfUrl)
                    eventTrack('Clicou card gestação atual', {
                      'data de atualização': activePregnancyCard.date
                    })
                  }}
                  headerTitle="Cartão da gestante (atual)"
                  headerSubtitle={`Atualizado em ${activePregnancyCard?.date}`}
                />
              </div>
            )}
            {!activePregnancyCard && isPregnant(pregnancyMoment) && (
              <div className="pb-4">
                <h3 className="pregnancy-card-drawer-subtitle">
                  Gestação atual
                </h3>
                <ContentCard
                  type="outlined"
                  ilustraBefore={{ src: BookDisabled }}
                  headerTitle="Nenhum novo cartão gerado."
                  disabled
                />
              </div>
            )}
            <h3 className="pregnancy-card-drawer-subtitle mt-4">
              Gestações anteriores na Theia
            </h3>
            <div className="pregnancy-cards-drawer__previous-cards">
              {previousPregnancyCards
              && previousPregnancyCards.length > 0
                ? previousPregnancyCards?.map((card, index) => (
                  <ContentCard
                    type="outlined"
                    iconBefore={{
                      icon: 'icon-OpenBookLight',
                      size: '22px',
                      color: textPrimary
                    }}
                    onClick={() => {
                      onClickPregnancyCard(card?.pdfUrl)
                      eventTrack('Clicou card gestação anterior', {
                        'data de atualização': card.date
                      })
                    }}
                    headerTitle={`Cartão da ${index + 1}ª Gestação`}
                    iconAfter={{
                      icon: 'icon-ArrowRight2Light',
                      color: textSecondary,
                      size: '24px',
                    }}
                    extraClass="mb-2"
                  />
                )) : (
                  <ContentCard
                    type="outlined"
                    iconBefore={{
                      icon: 'icon-OpenBookLight',
                      size: '20px',
                      color: textDisable
                    }}
                    disabled
                    headerTitle="Nenhuma gestação passada"
                  />
                )}
            </div>
          </>
        )}
      </div>
    </ResponsiveDrawer>
  ) : null
}

const mapStateToProps = ({
  consultant, authentication
}: AppState) => ({
  pregnancyCards: consultant.pregnancyCards,
  getPregnancyCardsStatus: consultant.getPregnancyCardsStatus,
  pregnancyCardsDrawerIsOpen: consultant.pregnancyCardsDrawerIsOpen,
  pregnancyMoment: authentication.currentUser?.pregnancyMoment
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  suppressDrawer: suppressPregnancyCardsDrawerAction,
  getPregnancyCards: getPregnancyCardsAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PregnancyCardsDrawer)
