/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  NewListItem,
  Loader,
  Snackbar,
  IconButton,
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../../apps/main/store'
import { Prescriptions } from '../../../domain/Prescriptions'
import {
  getPrescriptionsInformationAction,
} from '../../../state/prescriptions/actions'
import { AppDispatch } from '../../../state/utils'
import EmptyView from '../../common/EmptyView'
import NoExams from '../../../../assets/NoExams.svg'
import {
  bgCanvas, textSecondary
} from '../../../color'
import DefaultPicture from '../../../../assets/face-icon.png'
import { formatToDate } from '../../../utils/helpers'
import ImageIconBadge from '../../common/molecules/ImageIconBadge'
import { onUpdateBookingSuggestionAction } from '../../../state/scheduleSuggestion/actions'
import { eventPage, eventTrack } from '../../../../eventGenerate'
import { SAUDE } from '../../../routes/RoutesConstants'
import { StatusType } from '../../../domain/Status'
import { EventProfileType } from '../../../domain/AppProfiles'

interface Props {
  prescriptions: Prescriptions[];
  getPrescriptionsStatus: StatusType;
  hasDownloadedPrescription: boolean;
  profileType?: EventProfileType
  getPrescriptionsInformation: () => void;
  onUpdatePrescriptions: (
    reminderType: string,
    bookingIds?: Array<string>,
    callback?: () => void,
    prescriptionId?: string
  ) => void;
}

function PrescriptionsView({
  prescriptions,
  getPrescriptionsStatus,
  hasDownloadedPrescription,
  profileType,
  getPrescriptionsInformation,
  onUpdatePrescriptions,
}: Props) {
  const history = useHistory()

  useEffect(() => {
    getPrescriptionsInformation()
    eventPage('saude', 'Pedidos e exames', { tipo_de_perfil: profileType })
  }, [])

  const goBackToSaude = () => {
    history.push(SAUDE)
  }

  const downloadPrescription = (
    prescription: string,
    bookingId: Array<string>,
    prescriptionId: string
  ) => {
    window.open(prescription, '_blank')
    onUpdatePrescriptions('EXAM_REQUEST', bookingId, undefined, prescriptionId)
    eventTrack('Baixou Prescrição')
  }

  useEffect(() => {
    if (hasDownloadedPrescription) {
      getPrescriptionsInformation()
    }
  }, [hasDownloadedPrescription])

  const prescriptionsOrderedByDate = prescriptions && prescriptions.length > 0
    ? prescriptions.sort((a, b) => b.created - a.created)
    : []

  const prescriptionsOrderedByDateAndUnseen = prescriptionsOrderedByDate.length > 0
    ? prescriptionsOrderedByDate.sort((a, b) => Number(a.prescriptionDownloaded)
      - Number(b.prescriptionDownloaded))
    : []

  return (
    <>
      {getPrescriptionsStatus.isLoading && (
        <div className="content-loader">
          <div className="loader">
            <Loader />
          </div>
        </div>
      )}
      {getPrescriptionsStatus.error && (
        <div className="alert-snackbar relative">
          <Snackbar
            text={getPrescriptionsStatus.errorMessage?.friendlyMessage
              || 'Desculpe, no momento não foi possível carregar a listagem de prescrições.'}
            alignTop
            iconLeft="icon-InfoSquareLight"
            showCloseButton
            variant="default"
            title={getPrescriptionsStatus.errorMessage?.friendlyMessageTitle
              || 'Falha no carregamento'}
            buttonTwoProps={{
              label: 'Tentar novamente',
              onClick: getPrescriptionsInformation
            }}
          />
        </div>
      )}
      {getPrescriptionsStatus.success && (
        prescriptions && prescriptions.length > 0 ? (
          <div className="prescription-info">
            {prescriptionsOrderedByDateAndUnseen.map((prescription) => (
              <NewListItem
                imageIconBadgeComponent={(
                  <ImageIconBadge
                    iconColor={prescription.prescriptionDownloaded ? textSecondary : bgCanvas}
                    iconType="icon-ExamLight"
                    extraBadgeContainerClass={`exam-icon-container ${prescription.prescriptionDownloaded ? 'bg-divider' : 'bg-purpleSecondary'}`}
                    iconSize="14px"
                    picture={(
                      <img
                        src={prescription.professionalPictureUrl || DefaultPicture}
                        alt="foto do profissional"
                        width={40}
                        height={40}
                      />
                    )}
                  />
                )}
                title="Pedido médico"
                ariaLabel="medical-request"
                caption={`Enviado dia ${formatToDate(prescription.created)}`}
                id={prescription.id}
                key={prescription.id}
                extraComponent={(
                  <div className="download-button-container">
                    <IconButton
                      variant="text"
                      iconType="icon-DownloadLight"
                      iconSize="24px"
                      onClick={() => downloadPrescription(
                        prescription.pdfUrl,
                        [prescription.bookingId],
                        prescription.id
                      )}
                    />
                  </div>
                )}
              />
            ))}
          </div>
        ) : (
          <div className="prescriptions-and-exams__empty-list-view">
            <EmptyView
              title="Você ainda não possui nenhum pedido médico"
              subtitle="Todos os seus pedidos preescritos por nossos especialistas vão estar aqui."
              extraActionButton={goBackToSaude}
              extraActionText="Voltar pra saúde"
              icon={<NoExams />}
            />
          </div>
        )
      )}
    </>
  )
}

const mapStateToProps = ({
  prescriptions,
  scheduleSuggestion,
  appProfiles
}: AppState) => ({
  prescriptions: prescriptions.prescriptions,
  getPrescriptionsStatus: prescriptions.getPrescriptionsStatus,
  hasDownloadedPrescription: scheduleSuggestion.hasDownloaded,
  profileType: appProfiles.profileType
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  getPrescriptionsInformation: getPrescriptionsInformationAction,
  onUpdatePrescriptions: onUpdateBookingSuggestionAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionsView)
