import { Post, defaultCategory } from '../../domain/Content'
import { initialStatus, StatusType } from '../../domain/Status';
import {
  FETCH_ALL_POSTS,
  SUCCESS_FETCH_ALL_POSTS,
  FAILURE_FETCH_ALL_POSTS,
  FETCH_POST,
  SUCCESS_FETCH_POST,
  FAILURE_FETCH_POST,
  SELECT_CONTENT_CATEGORY,
  SET_NUMBER_OF_POSTS_TO_RENDER,
  ContentTypes
} from './actions'

export interface ContentState {
  allPosts: Array<Post>;
  getAllPostsStatus: StatusType
  singlePost?: Array<Post>;
  getSinglePostStatus: StatusType
  contentCategory: string;
  numberOfVisiblePosts: number;
}

export const initialContentState: ContentState = {
  allPosts: [],
  getAllPostsStatus: initialStatus,
  getSinglePostStatus: initialStatus,
  contentCategory: defaultCategory,
  numberOfVisiblePosts: 20
}

export function content(state = initialContentState, action: ContentTypes): ContentState {
  switch (action.type) {
    case FETCH_ALL_POSTS:
      return {
        ...state,
        getAllPostsStatus: {
          ...initialStatus,
          isLoading: true
        }
      }
    case SUCCESS_FETCH_ALL_POSTS:
      return {
        ...state,
        getAllPostsStatus: {
          ...initialStatus,
          success: true
        },
        allPosts: action.allPosts,
        singlePost: []
      }
    case FAILURE_FETCH_ALL_POSTS:
      return {
        ...state,
        getAllPostsStatus: {
          ...initialStatus,
          error: true
        },
      }
    case FETCH_POST:
      return {
        ...state,
        getSinglePostStatus: {
          ...initialStatus,
          isLoading: true
        }
      }
    case SUCCESS_FETCH_POST:
      return {
        ...state,
        getSinglePostStatus: {
          ...initialStatus,
          success: true
        },
        singlePost: action.singlePost
      }
    case FAILURE_FETCH_POST:
      return {
        ...state,
        getSinglePostStatus: {
          ...initialStatus,
          error: true
        }
      }
    case SELECT_CONTENT_CATEGORY:
      return {
        ...state,
        contentCategory: action.category,
        numberOfVisiblePosts: initialContentState.numberOfVisiblePosts
      }
    case SET_NUMBER_OF_POSTS_TO_RENDER:
      return {
        ...state,
        numberOfVisiblePosts: action.numberOfPostsToRender
      }
    default: return state
  }
}
