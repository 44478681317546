/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RegularButton, Button, FormInput } from 'theia-web-ds'
import { bindActionCreators, Dispatch } from 'redux'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import '../common/Input.scss'
import './Authentication.scss'
import { resetAccountPasswordAction } from '../../state/authentication/main/actions'
import AppMainContainerOff from '../AppMainContainerOff'
import { passwordSpecialCharacters } from '../../utils/helpers'
import { AppState } from '../../apps/main/store'
import ErrorModal from '../common/ErrorModal'
import ValidatePasswordInput from '../common/ValidatePasswordInput'
import { INICIO, LOGIN, RECOVERY } from '../../routes/RoutesConstants'
import { StatusType } from '../../domain/Status'

interface PasswordRecoveryFormValues {
  password: string;
  passwordConfirm: string;
}

function renderPasswordrecoveryForm(
  {
    handleSubmit,
    isSubmitting,
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    validateField,
    setSubmitting
  }: FormikProps<PasswordRecoveryFormValues>,
  resetAccountPasswordStatus: StatusType
) {
  const history = useHistory()

  function goToLogin() {
    history.push(LOGIN)
  }

  const canSubmit = (
    !!values.password
    && !!values.passwordConfirm
    && !errors.password
    && !errors.passwordConfirm
  )

  useEffect(() => {
    if (resetAccountPasswordStatus.error) {
      setSubmitting(false)
    }
  }, [resetAccountPasswordStatus])

  return (
    <form onSubmit={handleSubmit} className="mx-auto w-full lg:max-w-x" noValidate>
      <div className="mb-4">
        <ValidatePasswordInput
          onBlur={handleBlur}
          password={values.password}
          error={errors.password}
          passwordLabel="Nova senha *"
          onChange={handleChange}
          errorMessage={touched.password && !values.password ? errors.password : ''}
          value={values.password}
          eventText="'Inseriu Nova Senha'"
          onValidateField={() => validateField('password')}
        />
      </div>
      <div className="mb-4">
        <FormInput
          label="Confirme sua nova senha *"
          placeholder="Digite a senha novamente"
          type="password"
          name="passwordConfirm"
          id="passwordConfirm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.passwordConfirm}
          errorMessage={touched.passwordConfirm ? errors.passwordConfirm : ''}
          iconSize="24px"
          isPassword
        />
      </div>

      <div className="mt-8 flex flex-auto justify-center ">
        <RegularButton
          type="submit"
          label="Trocar senha"
          disabled={isSubmitting || !canSubmit}
          isSubmitting={isSubmitting}
          maxWidth="100%"
        />
      </div>
      <div className="mt-5">
        <Button className="text-textSmaller underline text-greyColor font-light" height="auto" width="100%" onClick={goToLogin}>
          Entrar na minha conta
        </Button>
      </div>
    </form>
  );
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8)
    .matches(/[A-Z]/)
    .matches(/[a-z]/)
    .matches(/[0-9]/)
    .matches(passwordSpecialCharacters)
    .required('O campo de senha é obrigatório'),
  passwordConfirm: Yup.string()
    .required('Confirmação de senha obrigatória')
    .oneOf([Yup.ref('password')], 'As senhas devem ser iguais')
})

interface Props {
  resetAccountPassword: (password: string, token: string) => void
  resetAccountPasswordStatus: StatusType
  defaultErrorVisible: boolean;
}

function NewPasswordRecovery({
  resetAccountPassword,
  resetAccountPasswordStatus,
  defaultErrorVisible
}: Props) {
  const history = useHistory()
  const urlToken = new URLSearchParams(window.location.search)
  const token = urlToken.get('token') || undefined
  const [hasExtraButton, setHasExtraButton] = useState(false)

  const initialValues = {
    password: '',
    passwordConfirm: '',
  }

  const onClickExtraButton = () => {
    history.push(RECOVERY)
  }

  function handlePasswordRecovery(values: PasswordRecoveryFormValues) {
    resetAccountPassword(values.password, token || '')
  }

  useEffect(() => {
    if (resetAccountPasswordStatus.error) {
      if (resetAccountPasswordStatus.errorMessage?.message !== 'Um erro ocorreu durante o envio') {
        setHasExtraButton(true)
      } else {
        setHasExtraButton(false)
      }
    }
    if (resetAccountPasswordStatus.success) {
      history.push(INICIO)
    }
  }, [resetAccountPasswordStatus])

  return (
    <AppMainContainerOff screenTitle="Mudança de senha">
      <div className="buttons-content">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handlePasswordRecovery}
        >
          {(props) => renderPasswordrecoveryForm(
            props,
            resetAccountPasswordStatus
          )}
        </Formik>
        <ErrorModal
          visible={defaultErrorVisible}
          extraButton={hasExtraButton}
          extraButtonclick={onClickExtraButton}
          extraButtonText="Recuperar senha"
        />
      </div>
    </AppMainContainerOff>
  )
}

const mapStateToProps = ({
  errorWithFriendlyMessage, authentication
}: AppState) => ({
  defaultErrorVisible: errorWithFriendlyMessage.visible,
  resetAccountPasswordStatus: authentication.resetAccountPasswordStatus
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetAccountPassword: resetAccountPasswordAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPasswordRecovery)
