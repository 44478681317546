/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { AvailabilitySlot, ProfessionalListType } from './Professional';

export interface Prescriptions {
  id: string;
  pdfUrl: string;
  prescriptionDownloaded: boolean;
  professionalPictureUrl: string;
  created: number;
  bookingId: string;
}

export type UsgPrescription = {
  title: string;
  objective: string;
  limitDate: string;
  obs?: string;
  infoTitle?: string;
  infoMessage?: string;
  usgId: string;
  pictureUrlWeb: string;
  pictureUrlMobile: string;
}

export type UsgStatus = 'REQUESTED' | 'CANCELED' | 'EXPIRED' | 'DONE' | 'SCHEDULED'

export type UsgAvailabilityParams = {
  examId?: string;
  consultantId?: string;
}

export type Exam = {
  examId: string
  fileName: string
  ownerName: string
  uploadDate: number
  downloadUrl: string
}

export enum PrescriptionsTabs {
  PRESCRIPTIONS = 'prescriptions',
  EXAMS = 'exams'
}

export type UploadExamResponse = {
  examId?: string
  fileName?: string,
  ownerName?: string,
  uploadDate?: number,
  downloadUrl?: string
}

export interface PrescriptionsService {
  getPrescriptionsInformation(): Promise<Array<Prescriptions>>;
  getExams(): Promise<Array<Exam>>;
  getChildExams(childId: string): Promise<Array<Exam>>;
  deleteExam(examId: string): Promise<void>;
  uploadExam(consultantId: string, exam: FormData): Promise<UploadExamResponse>
  getExamDownloadUrl(examId: string): Promise<string>;
  getUsgPrescription(): Promise<UsgPrescription>;
  getUsgData(chronosId: string, token?: string): Promise<ProfessionalListType>;
  getUsgAvailability(
    chronosId: string, params: UsgAvailabilityParams, token?: string,
  ): Promise<Array<AvailabilitySlot>>;
  postUsgStatus(status: UsgStatus, usgId: string): Promise<void>;
}
