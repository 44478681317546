import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { RegularButton } from 'theia-web-ds'
import { AppState } from '../../../apps/main/store'
import { AppDispatch } from '../../../state/utils'
import CalendarClock from '../../../../assets/calendar_clock.svg'
import { suppressCancelBookingSMSModalAction, cancelBookingSMSAction } from '../../../state/scheduleConfirmationSMS/actions'
import Modal from '../../common/Modal'
import CancelButton from '../../common/buttons/CancelButton'
import { formatToHours } from '../../../utils/helpers'
import { eventTrack } from '../../../../eventGenerate'

interface Props {
  closeModal: () => void;
  cancelBooking: (bookingId: string, token: string) => void;
  bookingId: string;
  token: string;
  startDate?: string;
  isLoading: boolean;
}

function ScheduleCancellationSmsModal({
  cancelBooking,
  closeModal,
  bookingId,
  token,
  startDate,
  isLoading
}: Props) {
  const startDateString = startDate && moment(startDate).format('DD/MM')
  const startHourString = startDate && formatToHours(startDate)
  const textModal = `Você tem certeza que deseja cancelar a consulta do dia ${startDateString} às ${startHourString}?`

  useEffect(() => {
    eventTrack('Visualizou modal cancelamento consulta')
  }, [])

  function handleCancelBooking() {
    eventTrack('Clicou cancelar consulta', {
      bookingId,
    })
    cancelBooking(bookingId, token)
  }

  function handleCloseModal() {
    eventTrack('Clicou manter consulta', {
      bookingId,
    })
    closeModal()
  }

  return (
    <Modal>
      <div className="schedule-cancelation-confirmation-modal flex flex-col text-center" data-testid="schedule-cancelation-confirmation-modal">
        <div className="self-center ml-4">
          <CalendarClock />
        </div>
        <h1 className="text-textMedium text-primary font-medium w-8/12 mx-auto my-4">
          Cancelamento de consulta
        </h1>
        <p className="text-textSmall text-darkGreyColor font-medium">
          {textModal}
        </p>
        <p className="font-light text-darkGreyColor text-textSmaller mt-4">
          Você pode aproveitar e já remarcar a consulta para outra data.
        </p>
        <RegularButton
          label="Cancelar consulta"
          onClick={handleCancelBooking}
          disabled={isLoading}
          maxWidth="100%"
          extraClass="cancelation-confirmation-button mt-4 mb-4"
          data-testid="cancelation-confirmation-button"
        />
        <CancelButton
          onClick={handleCloseModal}
          extraClass="font-medium text-primaryButton"
        >
          Manter consulta
        </CancelButton>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ scheduleConfirmationSMS }: AppState) => ({
  startDate: scheduleConfirmationSMS.startDate,
  bookingId: scheduleConfirmationSMS.bookingId,
  token: scheduleConfirmationSMS.token,
  isLoading: scheduleConfirmationSMS.isLoading,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  closeModal: () => {
    dispatch(suppressCancelBookingSMSModalAction())
  },
  cancelBooking: (bookingId: string, token: string) => {
    dispatch(cancelBookingSMSAction(bookingId, token))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleCancellationSmsModal)
