import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  RegularButton,
  ListItemCard,
  AccessibleModal,
  ButtonOptions,
  Divider
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import HealthPlanAccepted from '../../../../assets/OvalPlanCard-Yes.svg'
import HealthPlanAdd from '../../../../assets/PlanCard-Add.svg'

import './HealthPlanProfile.scss'
import { AppDispatch } from '../../../state/utils'
import { AppState } from '../../../apps/main/store'
import { error, textSecondary } from '../../../color'
import { eventTrack } from '../../../../eventGenerate'
import {
  havePlan,
} from '../../../utils/helpers'
import { EDIT_DEPENDENT_HEALTHPLAN, PROFILE } from '../../../routes/RoutesConstants'
import { StatusType } from '../../../domain/Status'
import { ActiveProfileType } from '../../../domain/AppProfiles'
import { clearSaveDependentStatusAction, saveDependentDataAction } from '../../../state/appProfiles/actions'
import { BabyData, HealthInsurancePlanStatus } from '../../../domain/PediatricFlow'

interface Props {
  activeProfile?: ActiveProfileType
  getChildrenDataStatus: StatusType
  saveDependentDataStatus: StatusType
  onPostHealthInsurance: (
    childData: BabyData
  ) => void
  clearSaveDependentStatus: () => void
}

function DependentHealthplanCard({
  activeProfile,
  getChildrenDataStatus,
  saveDependentDataStatus,
  onPostHealthInsurance,
  clearSaveDependentStatus,
}: Props) {
  const history = useHistory()
  const [isButtonGroupVisible, setIsButtonGroupVisible] = useState(false)
  const [deleteAlertCard, setDeleteAlertCard] = useState(false)

  const healthPlanName = activeProfile?.companyName && activeProfile.planName
    ? `${activeProfile?.companyName} ${activeProfile?.planName}`
    : undefined
  const alreadyHasHealthPlan = !!(havePlan(activeProfile?.healthInsurancePlanStatus))

  const isFetchingInfos = useMemo(() => !!(getChildrenDataStatus.isLoading
    || saveDependentDataStatus.isLoading
  ), [saveDependentDataStatus, getChildrenDataStatus])

  useEffect(() => {
    if (deleteAlertCard && saveDependentDataStatus.success) {
      setDeleteAlertCard(false)
      history.push(PROFILE, { isFromDeleteHealthPlan: true })
      clearSaveDependentStatus()
    }
  }, [saveDependentDataStatus])

  const getHealthPlanImage = () => {
    if (havePlan(activeProfile?.healthInsurancePlanStatus)) {
      return <HealthPlanAccepted />
    }
    return <HealthPlanAdd />
  }

  function handleChangeHealthPlan() {
    eventTrack('Clicou Alterar Plano Dependente')
    history.push(EDIT_DEPENDENT_HEALTHPLAN)
  }

  function handleAddHealthplan() {
    eventTrack('Clicou Adicionar Plano Dependente')
    history.push(EDIT_DEPENDENT_HEALTHPLAN)
  }

  const openButtonGroup = () => {
    eventTrack('Clicou Editar Plano Dependente')
    setIsButtonGroupVisible(true)
  }

  const closeButtonGroup = () => {
    setIsButtonGroupVisible(false)
  }

  const handleDeleteHealthPlan = () => {
    eventTrack('Clicou Deletar Plano Dependente')
    closeButtonGroup()
    setDeleteAlertCard(true)
  }

  const suppressModal = () => {
    setDeleteAlertCard(false)
  }

  const deleteHealthPlan = () => {
    eventTrack('Confirmou Deletar Plano Dependente')
    onPostHealthInsurance({
      id: activeProfile?.id,
      companyId: null,
      companyName: null,
      memberId: null,
      planId: null,
      planName: null,
      healthInsurancePlanStatus: HealthInsurancePlanStatus.DONT_HAVE_PLAN
    })
  }

  const deleteCard = (
    <AccessibleModal
      headerText=""
      visible={deleteAlertCard}
      showCloseButton={false}
      onClose={suppressModal}
      extraModalContainerClass="delete-plan-modal"
    >
      <p className="m-4 text-fontDefault text-textPrimary text-center">
        Tem certeza que deseja excluir seu plano {healthPlanName}?
      </p>
      <Divider />
      <div className="flex">
        <RegularButton
          label="Agora não"
          variant="text"
          extraClass="btn-from-list-card btn-left"
          onClick={suppressModal}
        />
        <span className="button-bottom" />
        <RegularButton
          label="Excluir"
          variant="text"
          extraClass="btn-from-list-card btn-right btn-delete-plan"
          onClick={deleteHealthPlan}
          isSubmitting={saveDependentDataStatus.isLoading}
        />
      </div>
    </AccessibleModal>
  )

  const buttonBarInsurancePlanCovered = (
    <>
      <ButtonOptions
        buttonToAnchor={(
          <RegularButton
            label="Editar plano"
            variant="text"
            extraClass="btn-change-plan btn-from-list-card btn-left"
            onClick={openButtonGroup}
          />
        )}
        visible={isButtonGroupVisible}
        onClose={closeButtonGroup}
        headerText="Opções de editar plano"
        extraClass="new-anchor-group"
      >
        <ListItemCard
          title="Alterar plano de saúde"
          ariaLabel="Alterar plano de saúde"
          onClick={handleChangeHealthPlan}
          iconType="icon-EditLight"
          iconColor={textSecondary}
          extraClassNameContainer="item-from-btn-group"
          extraClassClickableContainer="first-item-border-radius"
        />
        <ListItemCard
          title="Deletar plano atual"
          ariaLabel="Deletar plano atual"
          onClick={handleDeleteHealthPlan}
          iconType="icon-TrashLight"
          iconColor={error}
          arrowColor={textSecondary}
          extraClassNameContainer="item-from-btn-group"
          extraClassClickableContainer="last-item-border-radius"
          isLastItem
        />
      </ButtonOptions>
      <span className="button-bottom" />
    </>
  )

  const buttonBarNoHealthPlanAdded = (
    <RegularButton
      label="Adicionar plano de saúde"
      variant="text"
      onClick={handleAddHealthplan}
      extraClass="btn-from-list-card single-btn"
      isLoadingScreen={isFetchingInfos}
    />
  )

  return (
    <>
      {deleteAlertCard && (
        deleteCard
      )}
      <ListItemCard
        extraClassNameContainer="profile-health-card list-item-card-only-btn"
        extraClassClickableContainer="list-item-container-only-btn"
        title={healthPlanName || 'Nenhum plano adicionado'}
        ariaLabel={healthPlanName || 'Nenhum plano adicionado'}
        isLastItem
        isLoading={isFetchingInfos}
        isItemFromList={false}
        picture={<span className="profile-icon">{getHealthPlanImage()}</span>}
      >
        <Divider />
        <div className="flex">
          {alreadyHasHealthPlan ? buttonBarInsurancePlanCovered : buttonBarNoHealthPlanAdded}
        </div>
      </ListItemCard>
    </>
  )
}

const mapStateToProps = ({
  appProfiles, pediatricFlow
}: AppState) => ({
  activeProfile: appProfiles.activeProfile,
  getChildrenDataStatus: pediatricFlow.getChildrenDataStatus,
  saveDependentDataStatus: appProfiles.saveDependentDataStatus
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onPostHealthInsurance: saveDependentDataAction,
  clearSaveDependentStatus: clearSaveDependentStatusAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DependentHealthplanCard)
