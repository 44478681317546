import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Loader,
  ContentCard,
  AccessibleModal,
  RegularButton,
  FontIcon,
  Snackbar
} from 'theia-web-ds'
import moment from 'moment'
import { findCategoryIcon, isGynecologist } from '../../utils/helpers'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import {
  getCategoriesToScheduleAction,
  selectAttendanceTypeAction,
  selectCategoryToScheduleAction,
  selectPaymentMethodAction,
  selectSpecialistAction,
  selectTimeSlotAction,
  setLoadingStepAction,
  setPreviousSelectedCategoryAction,
  setPreviousSelectedSpecialistAction
} from '../../state/scheduleFlow/actions'
import { CategoryToSchedule, SchedulePaymentType } from '../../domain/ScheduleFlow'
import { SpecialistCategories } from '../../domain/Specialist'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { TheiaError } from '../../domain/errors/TheiaError'
import { NOVO_AGENDAMENTO } from '../../routes/RoutesConstants'
import { cleanPromotionalCodeAction } from '../../state/payment/actions'
import './ChooseMedicalSpecialty.scss'
import { setFilteredSpecialistsAction } from '../../state/specialists/actions'
import { ActiveProfileType, EventProfileType } from '../../domain/AppProfiles'
import { HealthInsurancePlanStatus } from '../../domain/PediatricFlow'
import { EventCategories } from '../../utils/EventCategories'

interface Props {
  categoriesToSchedule?: CategoryToSchedule[];
  isGettingCategories: boolean;
  failureGettingCategories: boolean;
  error?: TheiaError;
  activeProfile?: ActiveProfileType;
  loadingStep: boolean;
  previousSelectedCategoryName?: SpecialistCategories;
  categoryPaymentMethod?: SchedulePaymentType;
  previousRoute?: string;
  isDependent: boolean
  profileType?: EventProfileType
  getCategoriesList: (childId?: string) => void;
  selectCategoryToSchedule: (category?: CategoryToSchedule) => void;
  setLoadingSteps: (isLoading: boolean) => void;
  clearForwardFlow: () => void;
  onChooseCategory: () => void
}

function ChooseMedicalSpecialty({
  categoriesToSchedule,
  isGettingCategories,
  failureGettingCategories,
  error,
  activeProfile,
  loadingStep,
  previousSelectedCategoryName,
  categoryPaymentMethod,
  previousRoute,
  isDependent,
  profileType,
  getCategoriesList,
  selectCategoryToSchedule,
  setLoadingSteps,
  clearForwardFlow,
  onChooseCategory
}: Props) {
  const [showBlockedCategoryModal, setShowBlockedCategoryModal] = useState(false)
  const [blockedSpecialtyMessage, setBlockedSpecialtyMessage] = useState('')

  useEffect(() => {
    eventPage('Seleção Categoria', 'Seleção Categoria', { tipo_de_perfil: profileType })
  }, [])

  useEffect(() => {
    getCategoriesList(isDependent ? activeProfile?.id : undefined)
  }, [activeProfile?.id])

  function clickOnBlockedSpecialty(category: CategoryToSchedule) {
    setShowBlockedCategoryModal(true)
    if (category.blockReason) {
      setBlockedSpecialtyMessage(category.blockReason)
    }
  }

  function handleChooseCategory(category: CategoryToSchedule) {
    selectCategoryToSchedule(category)
    eventTrack('escolheu categoria', {
      category: EventCategories.NEW_SCHEDULE_FLOW,
      expecialidade: category.category,
      incluso: category.included,
      valor: category.price,
      disponibilidade: category.attendanceAvailability,
      pagamento: categoryPaymentMethod
    })
    onChooseCategory()
  }

  function isMoreThanOneYearOld(birthDateString: any): boolean {
    const birthdate = moment(birthDateString)
    const today = moment()
    const years = today.diff(birthdate, 'years')
    return years >= 1
  }

  function handleClickGynecologistSchedule() {
    const gynocologistSpecialty = categoriesToSchedule?.find(
      (category) => isGynecologist(category.category)
    )
    if (gynocologistSpecialty) {
      selectCategoryToSchedule(gynocologistSpecialty)
      onChooseCategory()
    }
  }

  useEffect(() => {
    if (categoriesToSchedule && previousRoute !== NOVO_AGENDAMENTO) {
      const previousCategory = (categoriesToSchedule && previousSelectedCategoryName
        && categoriesToSchedule.find(
          (obj) => obj.category === previousSelectedCategoryName
        )) || undefined
      if (previousCategory) {
        if (previousCategory.attendanceAvailability !== 'none') {
          handleChooseCategory(previousCategory)
        } else {
          setLoadingSteps(false)
          clickOnBlockedSpecialty(previousCategory)
        }
        return
      }
      clearForwardFlow()
      setLoadingSteps(false)
    }
    if (categoriesToSchedule && previousRoute === NOVO_AGENDAMENTO) {
      clearForwardFlow()
      setLoadingSteps(false)
    }
  }, [categoriesToSchedule])

  if (loadingStep || isGettingCategories) {
    return (
      <div className="schedule-view-loader choose-medical-specialty-view">
        <Loader />
      </div>
    )
  }

  return (
    <div className="specialtys-list-container">
      <h3 className="specialtys-list-title">
        Qual tipo de atendimento {activeProfile?.name} precisa?
      </h3>
      {failureGettingCategories && (
        <Snackbar
          title={error?.friendlyMessageTitle || 'Falha no carregamento'}
          text={error?.friendlyMessage || 'Desculpe, no momento não foi possível carregar as especialidades disponíveis.'}
          buttonOneProps={{
            onClick: () => getCategoriesList(),
            label: 'Tentar novamente'
          }}
          iconLeft="icon-InfoSquareLight"
        />
      )}
      {!isGettingCategories
      && categoriesToSchedule
      && isDependent
      && activeProfile
      && isMoreThanOneYearOld(activeProfile.birthDate)
      && activeProfile.healthInsurancePlanStatus === HealthInsurancePlanStatus.HAVE_PLAN
      && (
        <div className="mb-4">
          <Snackbar
            title="Restrição de cobertura"
            text="Crianças acima de 1 ano possuem cobertura apenas para Pediatria. Mas você pode agendar de forma particular."
            alignTop
            iconLeft="icon-InfoSquareLight"
          />
        </div>
      )}
      {!isGettingCategories && categoriesToSchedule
        && categoriesToSchedule.length > 0
        && categoriesToSchedule.map((category: CategoryToSchedule) => (
          <ContentCard
            type="elevated"
            iconBefore={{
              icon: findCategoryIcon(category.category)
            }}
            contentTitle={category.category}
            contentSubtitle={category.description}
            extraClass="mb-4"
            key={category.categoryId}
            onClick={() => handleChooseCategory(category)}
            onClickDisabled={() => clickOnBlockedSpecialty(category)}
            chipProps={{
              id: 'specialty-tooltip-id',
              variant: category.included ? 'success' : 'primary',
              disabled: category.included && category.attendanceAvailability === 'none',
              label: category.included ? 'Incluso' : `R$ ${category.price / 100}`,
              iconAfter: category.included && category.attendanceAvailability === 'none' ? 'icon-LockBold' : '',
              fontSize: '11px',
              size: 'tiny'
            }}
          />
        ))}
      <AccessibleModal
        headerText="Consulta Bloqueada"
        showCloseButton
        maxWidthClass="md:max-w-lg"
        visible={showBlockedCategoryModal}
        onClose={() => setShowBlockedCategoryModal(false)}
        extraOuterClassName="blocked-specialty-modal-outer"
        variant="centerBottom"
      >
        <div className="blocked-specialty-modal-content">
          <h1 className="blocked-specialty-modal-title">
            Consulta Bloqueada
          </h1>
          <p className="blocked-specialty-modal-description">
            {blockedSpecialtyMessage}
          </p>
          <RegularButton
            variant="primary"
            label="Agendar com Obstetra"
            onClick={handleClickGynecologistSchedule}
          />
        </div>
        <FontIcon
          fontSize="38px"
          iconType="icon-LockBold"
          extraClass="blocked-specialty-block-icon"
        />
      </AccessibleModal>
    </div>
  )
}

const mapStateToProps = ({
  scheduleFlow, routerListener, appProfiles
}: AppState) => ({
  categoriesToSchedule: scheduleFlow.categoriesToSchedule,
  isGettingCategories: scheduleFlow.isGettingCategories,
  failureGettingCategories: scheduleFlow.failureGettingCategories,
  error: scheduleFlow.error,
  activeProfile: appProfiles.activeProfile,
  loadingStep: scheduleFlow.loadingStep,
  previousSelectedCategoryName: scheduleFlow.previousSelectedCategoryName,
  categoryPaymentMethod: scheduleFlow.selectedPaymentMethod,
  previousRoute: routerListener.previousRoute,
  isDependent: appProfiles.isDependent,
  profileType: appProfiles.profileType
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCategoriesList: (childId?: string) => {
    dispatch(getCategoriesToScheduleAction(childId))
  },
  selectCategoryToSchedule: (category?: CategoryToSchedule) => {
    dispatch(selectCategoryToScheduleAction(category))
  },
  setLoadingSteps: (isLoading: boolean) => {
    dispatch(setLoadingStepAction(isLoading))
  },
  clearForwardFlow: () => {
    dispatch(cleanPromotionalCodeAction())
    dispatch(selectTimeSlotAction(undefined))
    dispatch(selectPaymentMethodAction(
      false,
      false,
      false,
      undefined,
    ))
    dispatch(selectAttendanceTypeAction(undefined))
    dispatch(selectSpecialistAction(undefined))
    dispatch(selectCategoryToScheduleAction(undefined))
    dispatch(setPreviousSelectedSpecialistAction(undefined))
    dispatch(setFilteredSpecialistsAction(undefined))
    dispatch(setPreviousSelectedCategoryAction(undefined))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseMedicalSpecialty)
