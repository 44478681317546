/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState
} from 'react'
import { connect } from 'react-redux'
import {
  Divider,
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../../apps/main/store'
import { PrescriptionsTabs } from '../../../domain/Prescriptions'
import { AppDispatch } from '../../../state/utils'
import Header from '../../common/molecules/Header'
import { eventPage } from '../../../../eventGenerate'
import { SAUDE } from '../../../routes/RoutesConstants'
import NavItem from '../../common/navigation/NavItem'
import './PrescriptionsAndExams.scss'
import ExamsView from './ExamsView'
import PrescriptionsView from './PrescriptionsView'

interface Props {
  sawExamsList: boolean;
}

function PrescriptionsAndExams({
  sawExamsList,
}: Props) {
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState<PrescriptionsTabs>(PrescriptionsTabs.PRESCRIPTIONS)

  useEffect(() => {
    eventPage('saude', 'Pedidos e exames')
  }, [])

  const goBackToSaude = () => {
    history.push(SAUDE)
  }

  return (
    <div className="prescriptions-and-exams-view">
      <div className="prescriptions-fixed-header">
        <Header title="Pedidos e exames" goBack={goBackToSaude} />
        <div className="prescriptions-tabs">
          <ul className="prescriptions-tabs-options" role="menu" aria-label="Pedidos e exames">
            <NavItem
              handleClick={() => setSelectedTab(PrescriptionsTabs.PRESCRIPTIONS)}
              isActive={selectedTab === PrescriptionsTabs.PRESCRIPTIONS}
              text="Pedidos médicos"
              variant="tab"
            />
            <NavItem
              handleClick={() => setSelectedTab(PrescriptionsTabs.EXAMS)}
              isActive={selectedTab === PrescriptionsTabs.EXAMS}
              text="Resultados de exames"
              variant="tab"
              hasNotification={!sawExamsList}
              className="exams-nav-item"
              tabTooltipMessage={!sawExamsList ? 'Clique aqui para acessar os resultados de exames.' : undefined}
            />
          </ul>
        </div>
        <Divider className="" />
      </div>
      <div className="prescriptions-and-exams__tab-content">
        {selectedTab === PrescriptionsTabs.PRESCRIPTIONS && <PrescriptionsView />}
        {selectedTab === PrescriptionsTabs.EXAMS && <ExamsView />}
      </div>
    </div>
  )
}

const mapStateToProps = ({
  userHighlights
}: AppState) => ({
  sawExamsList: userHighlights.sawExamsList,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionsAndExams)
