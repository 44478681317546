/* eslint-disable max-len */
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { RegularButton, Divider } from 'theia-web-ds'
import { eventTrack } from '../../../../eventGenerate'
import { getLinkFromCategory } from '../../../utils/helpers'
import { FaqToggle } from '../../common/FaqToggle'
import './HealthplanFaqAndBenefits.scss'

interface Props {
    benefits?: any;
    iconColor?: string;
    iconType?: string;
    closeModal?: () => void;
    screen?: string;
}

export function HealthPlanFaqAndBenefits({
  benefits, iconColor, iconType, closeModal, screen
}: Props) {
  const history = useHistory()
  function transformToHtml(content: string) {
    return <span dangerouslySetInnerHTML={{ __html: content }} />
  }

  function goToPage(link: string) {
    window.open(link, '_blank')
  }

  function goToLocationUsingHistoryPush(link: string) {
    history.push(link)
    if (closeModal) closeModal()
  }

  const orderedContentList = benefits?.length > 0 ? benefits?.map((item: any) => (
    { title: item.title, contents: item.contents.sort((a: any, b: any) => a.order - b.order) }))
    : []

  const actionEventTrack = (param: string) => {
    if (param === '<p>Consultas pré-natal com <strong>ginecologistas-obstetras</strong> Theia</p>') {
      eventTrack('Clicou Ginecologistas/Obstetras Theia')
    }
    if (param === 'Conhecer obstetras') {
      eventTrack('Clicou Conhecer Obstetras')
    }

    if (param === '<p>Atendimento <strong>24 horas</strong></p>') {
      eventTrack('Clicou Atendimento 24h')
    }

    if (param === '<p>Consultas presenciais e on-line</p>' && screen === 'convenio-feedback') {
      eventTrack('Clicou Consultas Presen e Online')
    }

    if (param === '<p>Consultas presenciais e on-line</p>' && screen === 'health-plan-included') {
      eventTrack('Clicou Consultas presenciais e online')
    }

    if (param === '<p>Clínica Theia</p>' && screen === 'health-plan-included') {
      eventTrack('Clicou Clínica Theia')
    }

    if (param === 'Ver no mapa') {
      eventTrack('Clicou Ver no Mapa')
    }

    if (param === 'Conhecer jornada' && screen === 'convenio-feedback') {
      eventTrack('Clicou Conhecer jornada')
    }
    if (param === 'Conhecer jornada' && screen === 'health-plan-included') {
      eventTrack('Clicou Conhecer jornada na lista')
    }

    if (param === '<p>Especialistas focados em você</p>') {
      eventTrack('Clicou Especialista Focados em Voce')
    }

    if (param === '<p>Cobertura para o <strong>seu parto</strong></p>') {
      eventTrack('Clicou Cobertura para o seu parto')
    }

    if (param === '<p>Cobertura para o <strong>seu parto</strong></p>') {
      eventTrack('Clicou Cobertura para o seu parto')
    }

    if (param === '<p>Apoio de uma especialista pessoal</p>' && screen === 'convenio-feedback') {
      eventTrack('Clicou Apoio de uma EP')
    }
    if (param === '<p>Apoio de uma especialista pessoal</p>' && screen === 'health-plan-included') {
      eventTrack('Clicou Apoio de uma especialista pessoal')
    }

    if (param === '<p>Hospitais de referência</p>') {
      eventTrack('Clicou Hospitais de referencia')
    }

    if (param === '<p>Hospitais de referência</p>') {
      eventTrack('Clicou Hospitais de referencia')
    }

    if (param === '<p>App Theia</p>') {
      eventTrack('Clicou App Theia')
    }
    if (param === 'Falar com especialista') {
      eventTrack('Clicou Falar com especialista')
    }
  }

  return (
    <div className="container-benefits">
      {orderedContentList && (
        (orderedContentList as any[]).map((item: any) => (
          <Fragment key={[item.title, '*'].join()}>
            <Divider key={[item.title, '+'].join()} />
            <FaqToggle
              iconType={iconType}
              toggleTitle={transformToHtml(item.title)}
              key={[item.order, item.content].join()}
              extraDescriptionClassContainer="ml-10"
              iconColor={iconColor}
              hasDivider={false}
              extraAction={() => { actionEventTrack(item.title) }}
            >
              {item.contents.map((c: any) => {
                switch (c.type) {
                  case 'TEXT':
                    if (c.content.includes('\\n')) {
                      const newContent = c.content.split('\\n')
                      const contentRefactored = newContent.map((content: any) => {
                        let lastItem
                        if (newContent.indexOf(content) === newContent.length - 1) {
                          lastItem = content
                          return lastItem
                        }
                        return lastItem
                      })
                      newContent.pop()
                      return [newContent.map((content: any) => <p key={content} className="text-textSecondary text-fontDefault">{content}</p>), <p key={contentRefactored} className="text-textSecondary text-fontDefault pb-4">{contentRefactored}</p>]
                    }
                    return <p key={c.content} className="text-textSecondary text-fontDefault pb-4 mt-4" dangerouslySetInnerHTML={{ __html: c.content }} />
                  case 'IMAGE':
                    return <img src={c.content} alt={c.altText} key={[c.order, c.content].join()} className="rounded-xl mb-4" />
                  case 'LINK':
                    return (
                      <RegularButton
                        onClick={() => { goToPage(c.link); actionEventTrack(c.content) }}
                        variant="text"
                        label={c.content}
                        width="auto"
                        extraClass="button-faq-description mb-4"
                        key={[c.content, c.order].join()}
                      />
                    )
                  case 'LIST_ITEM':
                    return (
                      <ul key={c.order} className="text-textSecondary text-fontDefault list-item">
                        <li>{c.content}</li>
                      </ul>
                    )
                  case 'LIST_ITEM_WITH_CATEGORY':
                    return (
                      <RegularButton
                        onClick={() => { goToLocationUsingHistoryPush(getLinkFromCategory(c.category)); actionEventTrack(c.content) }}
                        variant="text"
                        label={c.content}
                        width="auto"
                        extraClass="button-faq-description mb-4"
                        key={[c.order, c.content].join()}
                      />
                    )
                  default:
                    return ''
                }
              })}
            </FaqToggle>
          </Fragment>
        ))
      )}
      <Divider />
    </div>
  )
}
