import { HealthInsuranceCompany } from '../../domain/Healthplan'
import {
  BrazilState, CityOfState, OnboardingService,
  PregnancyDueDate
} from '../../domain/Onboarding'
import ApiClient from './ApiClient'

export default class ApiOnboardingService implements OnboardingService {
  constructor(private apiClient: ApiClient) { }

  async getBrazilStates(): Promise<BrazilState> {
    const brazilStatesResponse = await this.apiClient.getBrazilStates()
    return brazilStatesResponse
  }

  async getCitiesOfState(id: string): Promise<CityOfState> {
    const citiesOfStateResponse = await this.apiClient.getCitiesOfState(id)
    return citiesOfStateResponse
  }

  async getHealthInsuranceCompanies(): Promise<HealthInsuranceCompany[]> {
    const healthInsuranceCompaniesResponse = await this.apiClient.getHealthInsuranceCompanies()
    return healthInsuranceCompaniesResponse
  }

  async getPregnancyDueDate(dum: number): Promise<PregnancyDueDate> {
    const pregnancyDueDate = await this.apiClient.getPregnancyDueDate(dum)
    return pregnancyDueDate
  }
}
