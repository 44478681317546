/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { AvailabilitySlot, ProfessionalListType } from '../../domain/Professional'
import { Exam, Prescriptions, UsgPrescription } from '../../domain/Prescriptions'
import {
  PrescriptionsTypes,
  GET_PRESCRIPTIONS_INFOS,
  SUCCESS_GET_PRESCRIPTIONS_INFOS,
  FAILURE_GET_PRESCRIPTIONS_INFOS,
  GET_USG_PRESCRIPTION,
  SUCCESS_GET_USG_PRESCRIPTION,
  FAILURE_GET_USG_PRESCRIPTION,
  GET_USG_DATA,
  SUCCESS_GET_USG_DATA,
  FAILURE_GET_USG_DATA,
  GET_USG_AVAILABILITY,
  SUCCESS_GET_USG_AVAILABILITY,
  FAILURE_GET_USG_AVAILABILITY,
  POST_USG_STATUS,
  SUCCESS_POST_USG_STATUS,
  FAILURE_POST_USG_STATUS,
  SUPPRESS_USG_MODAL,
  GET_EXAMS,
  SUCCESS_GET_EXAMS,
  FAILURE_GET_EXAMS,
  DELETE_EXAM,
  SUCCESS_DELETE_EXAM,
  FAILURE_DELETE_EXAM,
  GET_EXAM_DOWNLOAD_URL,
  SUCCESS_GET_EXAM_DOWNLOAD_URL,
  FAILURE_GET_EXAM_DOWNLOAD_URL,
  RESET_GET_URL_STATUS,
  RESET_DELETE_EXAM_STATUS,
  UPLOAD_EXAM,
  SUCCESS_UPLOAD_EXAM,
  FAILURE_UPLOAD_EXAM,
} from './actions'
import { initialStatus, StatusType } from '../../domain/Status'

export interface ExamInProgress extends StatusType {
  name: string
  size: number
}

export interface PrescriptionsState {
  sawUsgModal: boolean;
  usgPrescription?: UsgPrescription;
  isGettingUsgPrescription: boolean;
  usgData?: ProfessionalListType;
  isGettingUsgData: boolean;
  usgAvailability?: Array<AvailabilitySlot>;
  isGettingUsgAvailability: boolean;
  successPostUsgStatus: boolean;
  unseenMedicalRequests: number;
  prescriptions: Array<Prescriptions>;
  getPrescriptionsStatus: StatusType;
  isLoading: boolean;
  exams?: Array<Exam>;
  getExamsStatus: StatusType;
  deleteExamStatus: StatusType;
  uploadExamStatus: StatusType
  examsInProgress: ExamInProgress[]
  examDownloadUrl?: string;
  getExamDownloadUrlStatus: StatusType;
}

const initialPrescriptionsState: PrescriptionsState = {
  sawUsgModal: false,
  usgPrescription: undefined,
  isGettingUsgPrescription: false,
  usgData: undefined,
  isGettingUsgData: false,
  usgAvailability: undefined,
  isGettingUsgAvailability: false,
  successPostUsgStatus: false,
  unseenMedicalRequests: 0,
  prescriptions: [],
  getPrescriptionsStatus: initialStatus,
  isLoading: false,
  exams: undefined,
  getExamsStatus: initialStatus,
  deleteExamStatus: initialStatus,
  uploadExamStatus: initialStatus,
  examsInProgress: [],
  examDownloadUrl: undefined,
  getExamDownloadUrlStatus: initialStatus,
}

export function prescriptions(
  state: PrescriptionsState = initialPrescriptionsState,
  action: PrescriptionsTypes
): PrescriptionsState {
  switch (action.type) {
    case GET_PRESCRIPTIONS_INFOS: {
      return {
        ...state,
        getPrescriptionsStatus: {
          isLoading: true,
          success: false,
          error: false,
          errorMessage: undefined
        }
      }
    }
    case FAILURE_GET_PRESCRIPTIONS_INFOS: {
      return {
        ...state,
        getPrescriptionsStatus: {
          isLoading: false,
          success: false,
          error: true,
          errorMessage: action.error
        }
      }
    }
    case SUCCESS_GET_PRESCRIPTIONS_INFOS: {
      const pendingMedicalRequests = action.prescriptions.filter(
        (prescription) => prescription.prescriptionDownloaded === false
      )
      return {
        ...state,
        getPrescriptionsStatus: {
          isLoading: false,
          success: true,
          error: false,
        },
        unseenMedicalRequests: pendingMedicalRequests.length || 0,
        prescriptions: action.prescriptions
      }
    }
    case GET_EXAMS: {
      const previousExams = state.exams || []
      return {
        ...state,
        getExamsStatus: {
          isLoading: true,
          success: false,
          error: false,
          errorMessage: undefined
        },
        exams: action.isUpdate ? previousExams : undefined
      }
    }
    case SUCCESS_GET_EXAMS: {
      return {
        ...state,
        getExamsStatus: {
          isLoading: false,
          success: true,
          error: false
        },
        exams: action.exams
      }
    }
    case FAILURE_GET_EXAMS: {
      return {
        ...state,
        getExamsStatus: {
          isLoading: false,
          success: false,
          error: true,
          errorMessage: action.error
        }
      }
    }
    case DELETE_EXAM: {
      return {
        ...state,
        deleteExamStatus: {
          isLoading: true,
          success: false,
          error: false,
          errorMessage: undefined
        }
      }
    }
    case SUCCESS_DELETE_EXAM: {
      const updatedExams = state.exams ? state.exams.filter(
        (exam: Exam) => exam.examId !== action.examId
      ) : []
      return {
        ...state,
        deleteExamStatus: {
          isLoading: false,
          success: true,
          error: false
        },
        exams: updatedExams
      }
    }
    case FAILURE_DELETE_EXAM: {
      return {
        ...state,
        deleteExamStatus: {
          isLoading: false,
          success: false,
          error: true,
          errorMessage: action.error
        }
      }
    }
    case RESET_DELETE_EXAM_STATUS: {
      return {
        ...state,
        deleteExamStatus: initialStatus
      }
    }
    case UPLOAD_EXAM: {
      const newExamsInProgress = state.examsInProgress
      newExamsInProgress.push({
        ...initialStatus,
        name: action.file.name,
        size: action.file.size,
        isLoading: true
      })
      return {
        ...state,
        uploadExamStatus: {
          isLoading: true,
          success: false,
          error: false,
          errorMessage: undefined
        },
        examsInProgress: newExamsInProgress
      }
    }
    case SUCCESS_UPLOAD_EXAM: {
      const newExamsInProgress = state.examsInProgress.filter((exam) => (exam.name !== action.file.name))
      return {
        ...state,
        uploadExamStatus: {
          isLoading: false,
          success: newExamsInProgress.length === 0,
          error: false
        },
        examsInProgress: newExamsInProgress
      }
    }
    case FAILURE_UPLOAD_EXAM: {
      const newExamsInProgress = state.examsInProgress.map((exam) => (
        exam.name === action.file.name ? {
          ...exam,
          error: true
        } : { ...exam }))
      return {
        ...state,
        uploadExamStatus: {
          isLoading: false,
          success: false,
          error: newExamsInProgress.length === 1,
          errorMessage: action.error
        },
        examsInProgress: newExamsInProgress
      }
    }
    case GET_EXAM_DOWNLOAD_URL: {
      return {
        ...state,
        getExamDownloadUrlStatus: {
          isLoading: true,
          success: false,
          error: false,
          errorMessage: undefined
        },
        examDownloadUrl: undefined
      }
    }
    case SUCCESS_GET_EXAM_DOWNLOAD_URL: {
      return {
        ...state,
        getExamDownloadUrlStatus: {
          isLoading: false,
          success: true,
          error: false
        },
        examDownloadUrl: action.examDownloadUrl
      }
    }
    case FAILURE_GET_EXAM_DOWNLOAD_URL: {
      return {
        ...state,
        getExamDownloadUrlStatus: {
          isLoading: false,
          success: false,
          error: true,
          errorMessage: action.error
        }
      }
    }
    case RESET_GET_URL_STATUS: {
      return {
        ...state,
        getExamDownloadUrlStatus: initialStatus
      }
    }
    case GET_USG_PRESCRIPTION: {
      return {
        ...state,
        isGettingUsgPrescription: true,
      }
    }
    case SUCCESS_GET_USG_PRESCRIPTION: {
      return {
        ...state,
        isGettingUsgPrescription: false,
        usgPrescription: action.usgPrescription,
      }
    }
    case FAILURE_GET_USG_PRESCRIPTION: {
      return {
        ...state,
        isGettingUsgPrescription: false,
        usgPrescription: undefined
      }
    }
    case GET_USG_DATA: {
      return {
        ...state,
        isGettingUsgData: true,
      }
    }
    case SUCCESS_GET_USG_DATA: {
      return {
        ...state,
        isGettingUsgData: false,
        usgData: action.usgData,
      }
    }
    case FAILURE_GET_USG_DATA: {
      return {
        ...state,
        isGettingUsgData: false,
        usgData: undefined
      }
    }
    case GET_USG_AVAILABILITY: {
      return {
        ...state,
        isGettingUsgAvailability: true,
      }
    }
    case SUCCESS_GET_USG_AVAILABILITY: {
      return {
        ...state,
        isGettingUsgAvailability: false,
        usgAvailability: action.usgAvailability,
      }
    }
    case FAILURE_GET_USG_AVAILABILITY: {
      return {
        ...state,
        isGettingUsgAvailability: false,
        usgAvailability: undefined
      }
    }
    case POST_USG_STATUS: {
      return {
        ...state,
        isLoading: true
      }
    }
    case SUCCESS_POST_USG_STATUS: {
      return {
        ...state,
        isLoading: false,
        successPostUsgStatus: true,
      }
    }
    case FAILURE_POST_USG_STATUS: {
      return {
        ...state,
        isLoading: false,
        successPostUsgStatus: false,
      }
    }
    case SUPPRESS_USG_MODAL: {
      return {
        ...state,
        sawUsgModal: true
      }
    }
    default: return state
  }
}
