import { BookingSuggestion, WeekInfo } from '../../domain/WeekFlow'
import {
  CLOSE_SHOULD_ASK_FOR_PROFILE_DETAILS,
  FAILURE_WEEK_FETCHED,
  FETCH_WEEK_REQUESTED,
  SELECT_WEEK,
  SUCCESS_GET_ALL_WEEKS_INFOS,
  SUCCESS_WEEK_FETCHED,
  SUCCESS_WEEK_WITHOUT_ADITIONAL_INTELIGENCE_FETCHED,
  WeekFlowTypes
} from './actions'

export interface WeekFlowState {
  weeksInfo?: {
    [id: string]: WeekInfo;
  };
  weeks: string[];
  weeksByJourneyStage?: {
    [id: string]: WeekInfo[];
  };
  suggestionsById?: {
    [id: string]: BookingSuggestion;
  };
  journeyStageList: string[];
  selectedWeek?: WeekInfo;
  isFetchingWeekInfos: boolean;
  currentWeek?: WeekInfo;
  shouldAskForProfileDetail: boolean;
  alreadyAskForProfileDetail: boolean;
  pregnancyJourney: boolean;
  weeksInfoWithoutAditionalInteligence?: {
    [id: string]: WeekInfo;
  };
  weeksWithoutAditionalInteligence: string[];
  suggestionsByIdWithoutAditionalInteligence?: {
    [id: string]: BookingSuggestion;
  };
  weeksByJourneyStageWithoutAditionalInteligence?: {
    [id: string]: WeekInfo[];
  };
  journeyStageListWithoutAditionalInteligence: string[];
}

const initialWeekFlow: WeekFlowState = {
  weeksInfo: undefined,
  selectedWeek: undefined,
  currentWeek: undefined,
  weeks: [],
  isFetchingWeekInfos: false,
  shouldAskForProfileDetail: false,
  alreadyAskForProfileDetail: false,
  pregnancyJourney: false,
  suggestionsById: undefined,
  weeksByJourneyStage: undefined,
  journeyStageList: [],
  weeksInfoWithoutAditionalInteligence: undefined,
  weeksWithoutAditionalInteligence: [],
  suggestionsByIdWithoutAditionalInteligence: undefined,
  weeksByJourneyStageWithoutAditionalInteligence: undefined,
  journeyStageListWithoutAditionalInteligence: [],
}

export function weekFlow(
  state = initialWeekFlow,
  action: (
    WeekFlowTypes
  )
): WeekFlowState {
  switch (action.type) {
    case FETCH_WEEK_REQUESTED: {
      return {
        ...state,
        isFetchingWeekInfos: true,
      }
    }
    case SUCCESS_WEEK_FETCHED: {
      const weekKeys = action.weeks.map((week) => week.weekName)
      const weeksInfo = action.weeks.reduce((result, _value: WeekInfo) => ({
        ...result,
        [_value.weekName]: _value
      }), {})
      const journeyStageList: string[] = []
      const weeksByJourneyStage = action.weeks.reduce((result, _value: WeekInfo) => {
        let aux: { [id: string]: WeekInfo[]} = { ...result }
        if (!journeyStageList.includes(_value.journeyStage)) {
          journeyStageList.push(_value.journeyStage)
        }
        if (_value.journeyStage in aux) {
          aux = {
            ...aux,
            [_value.journeyStage]: [
              ...aux[_value.journeyStage],
              _value
            ]
          }
        } else {
          aux = {
            ...aux,
            [_value.journeyStage]: [
              _value
            ]
          }
        }
        return aux
      }, {})
      const suggestionsById = action.weeks.reduce((result, _value: WeekInfo) => {
        let aux = { ...result }
        _value.bookingSuggestions.forEach((bs) => {
          aux = {
            ...aux,
            [bs.id]: bs
          }
        })
        return aux
      }, {})
      const currentWeek = Object.values(action.weeks).find((week) => week.isCurrentWeek)
      return {
        ...state,
        weeksInfo,
        weeks: weekKeys,
        selectedWeek: currentWeek || undefined,
        currentWeek,
        shouldAskForProfileDetail: (
          action.shouldAskForProfileDetail && !state.alreadyAskForProfileDetail
        ),
        pregnancyJourney: action.pregnancyJourney,
        suggestionsById,
        weeksByJourneyStage,
        journeyStageList,
      }
    }
    case SUCCESS_WEEK_WITHOUT_ADITIONAL_INTELIGENCE_FETCHED: {
      const weekKeys = action.weeksWithoutAditionalInteligence.map((week) => week.weekName)
      const weeksInfo = action.weeksWithoutAditionalInteligence.reduce(
        (result, _value: WeekInfo) => ({
          ...result,
          [_value.weekName]: _value
        }), {}
      )
      const journeyStageList: string[] = []
      const weeksByJourneyStage = action.weeksWithoutAditionalInteligence.reduce(
        (result, _value: WeekInfo) => {
          let aux: { [id: string]: WeekInfo[]} = { ...result }
          if (!journeyStageList.includes(_value.journeyStage)) {
            journeyStageList.push(_value.journeyStage)
          }
          if (_value.journeyStage in aux) {
            aux = {
              ...aux,
              [_value.journeyStage]: [
                ...aux[_value.journeyStage],
                _value
              ]
            }
          } else {
            aux = {
              ...aux,
              [_value.journeyStage]: [
                _value
              ]
            }
          }
          return aux
        }, {}
      )
      const suggestionsById = action.weeksWithoutAditionalInteligence.reduce(
        (result, _value: WeekInfo) => {
          let aux = { ...result }
          _value.bookingSuggestions.forEach((bs) => {
            aux = {
              ...aux,
              [bs.id]: bs
            }
          })
          return aux
        }, {}
      )
      return {
        ...state,
        weeksInfoWithoutAditionalInteligence: weeksInfo,
        weeksWithoutAditionalInteligence: weekKeys,
        suggestionsByIdWithoutAditionalInteligence: suggestionsById,
        weeksByJourneyStageWithoutAditionalInteligence: weeksByJourneyStage,
        journeyStageListWithoutAditionalInteligence: journeyStageList,
      }
    }
    case SUCCESS_GET_ALL_WEEKS_INFOS: {
      return {
        ...state,
        isFetchingWeekInfos: false,
      }
    }
    case FAILURE_WEEK_FETCHED: {
      return {
        ...state,
        isFetchingWeekInfos: false,
      }
    }
    case SELECT_WEEK: {
      return {
        ...state,
        selectedWeek: state.weeksInfo && state.weeksInfo[action.week],
      }
    }
    case CLOSE_SHOULD_ASK_FOR_PROFILE_DETAILS: {
      return {
        ...state,
        shouldAskForProfileDetail: false,
        alreadyAskForProfileDetail: true,
      }
    }
    default: return state
  }
}
