import React, { useEffect } from 'react'
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Loader } from 'theia-web-ds'
import LogoutMobile from '../components/authentication/LogoutMobile'
import { AppState } from '../apps/main/store'
import { UnauthenticatedModalError, User } from '../domain/User'
import BookingRoom from '../components/booking/BookingRoom'
import SinglePost from '../components/content/SinglePost'
import ProfessionalInfo from '../components/professionals/ProfessionalInfo'
import HealthInfos from '../components/profile/HealthInfos'
import GestationalInfos from '../components/profile/GestationalInfos'
import PersonalInfos from '../components/profile/PersonalInfos'
import ChooseUsgDate from '../components/usgFlow/ChooseUsgDate'
import ScheduleFlow from '../components/scheduleFlow/ScheduleFlow'
import AppMainContainer from '../components/AppMainContainer'
import Profile from '../components/profile/Profile'
import EditAccessDataForm from '../components/profile/EditAccessDataForm'
import EditAddressForm from '../components/profile/EditAddressForm'
import EditPaymentInfoProfile from '../components/profile/payment/EditPaymentInfoProfile'
import HealthPlanIncluded from '../components/profile/healthplan/HealthPlanIncluded'
import ChangeHealthPlanProfile from '../components/profile/healthplan/ChangeHealthPlanProfile'
import FeedbackHealthPlanProfile from '../components/profile/healthplan/FeedbackHealthPlanProfile'
import PrescriptionsAndExams from '../components/healthData/prescriptions/PrescriptionsAndExams'
import AllPosts from '../components/content/AllPosts'
import ScheduleView from '../components/scheduleFlow/ScheduleView'
import Home from '../components/home/Home'
import QuarterDetailsView from '../components/guidedJourney/QuarterDetailsView'
import MyBookingsView from '../components/myBookingsFlow/MyBookingsView'
import HealthDataView from '../components/healthData/HealthDataView'
import ChatView from '../components/home/Chat'
import SalesOnboardingSuccess from '../components/sales-onboarding/SalesOnboardingSuccess'
import Localization from '../components/onboarding/Localization'
import HealthInsurance from '../components/onboarding/HealthInsurance'
import HealthInsuranceType from '../components/onboarding/HealthInsuranceType'
import HealthInsurancePlan from '../components/onboarding/HealthInsurancePlan'
import HealthInsuranceFeedback from '../components/onboarding/HealthInsuranceFeedback'
import { getFlutterClient, isAccessTokenValid, isRefreshTokenValid } from '../utils/helpers'
import { useNewMessageNotifier } from '../hooks/NewMessageNotifier'
import { AppDispatch } from '../state/utils'
import {
  clearForceUpdateCurrentUserAction,
  handleRefreshTokenAction,
  logoutAction,
  promptUnauthenticatedModalAction,
  updateCurrentUserInfosAction
} from '../state/authentication/main/actions'
import { getAllWeekOptionsAction } from '../state/weekFlow/actions'
import { fetchProfileAction } from '../state/profile/actions'
import {
  BOOKINGS,
  DADOS_GESTACIONAIS,
  DADOS_PESSOAIS,
  DADOS_DE_SAUDE,
  LOCALIZACAO,
  LOGOUT_MOBILE,
  OLD_SPECIALISTS,
  AGENDAMENTO_ULTRASSOM,
  NOVO_AGENDAMENTO,
  PLANO_DE_SAUDE,
  CONVENIO,
  CONVENIO_INFORMACOES,
  CONVENIO_FEEDBACK,
  SUCESSO_CADASTRO,
  INICIO,
  JORNADA_EXPLICADA,
  PROFILE,
  EDIT_PROFILE,
  EDIT_ADDRESS,
  EDIT_PAYMENT,
  HEALTH_PLAN_INCLUDED,
  CHANGE_HEALTH_PLAN,
  HEALTHPLAN_FEEDBACK,
  PRESCRIPTIONS_AND_EXAMS,
  CONTENT,
  CONSULTAS,
  SAUDE,
  AGENDAR,
  ESPECIALISTA,
  CHAT,
  BOOKING_CONFIRMATION,
  SELECT_PROFILE,
  ADD_NEW_DEPENDENT,
  EDIT_DEPENDENT,
  EDIT_DEPENDENT_HEALTHPLAN
} from './RoutesConstants'
import { identifyUser } from '../../eventGenerate'
import RedirectOldSpecialistsLinks from '../components/common/RedirectOldSpecialistsLinks'
import ScheduleCancellationModalsGroup from '../components/schedule/cancellation/ScheduleCancellationModalsGroup'
import BrowserErrorModal from '../components/booking/BrowserErrorModal'
import FeedbackModalsGroup from '../components/feedback/FeedbackModalsGroup'
import RescheduleModalsGroup from '../components/rescheduleFlow/RescheduleModalsGroup'
import DesireModalsGroup from '../components/maternalDesire/DesireModalsGroup'
import PediatricWelcomeModal from '../components/pediatricFlow/PediatricWelcomeModal'
import PhoneValidationModal from '../components/profile/accountValidation/PhoneValidationModal'
import EmailValidationModal from '../components/profile/accountValidation/EmailValidationModal'
import { getHighlightsAction } from '../state/userHighlights/actions'
import { enableHealthPlanModalAction, getHealthInsurancePlanDataAction } from '../state/healthplanProfile/actions'
import ScheduleConfirmSMS from '../components/schedule/confirmationSMS/ScheduleConfirmSMS'
import PregnancyCardsDrawer from '../components/pregnancyCard/PregnancyCardsDrawer'
import SelectProfile from '../components/profile/SelectProfile'
import { ActiveProfileType } from '../domain/AppProfiles'
import AddNewDependent from '../components/profile/AddNewDependent'
import EditDependent from '../components/profile/EditDependent'
import { getChildrenDataAction } from '../state/pediatricFlow/actions'
import EditDependentHealthplan from '../components/profile/healthplan/EditDependentHealthplan'
import { getBookingsAction } from '../state/myBookingsFlow/actions'

interface Props {
  currentUser?: User;
  forceUpdateCurrentUser: boolean;
  isFetchingUser: boolean;
  isFetchingProfile: boolean;
  activeProfile?: ActiveProfileType;
  isFetchingAllBookings: boolean
  updateCurrentUserInfos: () => void;
  getAllWeekOptions: () => void;
  clearForceUpdateCurrentUser: () => void;
  fetchProfile: () => void;
  handleRefreshToken: () => void;
  logout: () => void;
  promptUnauthenticatedModal: (errorType: UnauthenticatedModalError) => void;
  getHighlights: () => void;
  getHealthPlanInfos: () => void;
  enableHealthPlanModal: () => void;
  getChildrenData: () => void;
  getBookings: () => void
}

const PrivateRoutes = React.memo(({
  currentUser,
  forceUpdateCurrentUser,
  isFetchingUser,
  isFetchingProfile,
  activeProfile,
  isFetchingAllBookings,
  updateCurrentUserInfos,
  getAllWeekOptions,
  clearForceUpdateCurrentUser,
  fetchProfile,
  handleRefreshToken,
  logout,
  promptUnauthenticatedModal,
  getHighlights,
  getHealthPlanInfos,
  enableHealthPlanModal,
  getChildrenData,
  getBookings,
}: Props) => {
  const history = useHistory()
  const isFlutterClient = getFlutterClient()
  const onboardingRoutes = [
    LOCALIZACAO,
    PLANO_DE_SAUDE,
    CONVENIO,
    CONVENIO_FEEDBACK,
    CONVENIO_INFORMACOES
  ]
  const route = window.location.pathname
  useNewMessageNotifier()

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAccessTokenValid() && isRefreshTokenValid() && !isFlutterClient) {
        handleRefreshToken()
      }
      if (!isAccessTokenValid() && !isRefreshTokenValid() && !isFlutterClient) {
        logout()
        promptUnauthenticatedModal(UnauthenticatedModalError.INACTIVITY)
      }
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (currentUser
      && !activeProfile?.id
      && !onboardingRoutes.includes(route)
      && !route.includes(BOOKING_CONFIRMATION)) {
      history.push(SELECT_PROFILE)
    }
  }, [activeProfile?.id, route])

  useEffect(() => {
    if (activeProfile?.id && !isFetchingAllBookings) {
      getBookings()
    }
  }, [activeProfile?.id])

  useEffect(() => {
    getHealthPlanInfos()
    getAllWeekOptions()
    enableHealthPlanModal()
    getHighlights()
    getChildrenData()
  }, [])

  useEffect(() => {
    if (currentUser) {
      identifyUser(currentUser)
    }
  }, [currentUser])

  useEffect(() => {
    if (forceUpdateCurrentUser && !isFetchingUser && !isFetchingProfile) {
      fetchProfile()
      updateCurrentUserInfos()
      clearForceUpdateCurrentUser()
    }
  }, [forceUpdateCurrentUser, isFetchingUser, isFetchingProfile])

  const renderBooking = ({ match }: RouteComponentProps<any>) => (
    <BookingRoom userType="consultant" uuid={match.params.uuid} />
  )

  const renderSingleContent = ({ match }: RouteComponentProps<any>) => (
    <SinglePost id={match.params.id} />
  )

  const renderProfessionalInfo = ({ match }: RouteComponentProps<any>) => (
    <ProfessionalInfo id={match.params.id} />
  )

  const renderScheduleConfirmSMS = ({ match, location }: RouteComponentProps<any>) => (
    <ScheduleConfirmSMS
      id={match.params.id}
      token={location.search.replace('?token=', '')}
    />
  )

  if (!currentUser || isFetchingUser) {
    return (
      <div id="appLoader" className="app-loader-container" data-testid="appLoader">
        <div className="app-loader">
          <Loader size="35px" />
        </div>
      </div>
    )
  }

  const routesWithoutMenu = [
    LOGOUT_MOBILE,
    `${BOOKINGS}/:uuid`,
    DADOS_DE_SAUDE,
    DADOS_GESTACIONAIS,
    DADOS_PESSOAIS,
    AGENDAMENTO_ULTRASSOM,
    NOVO_AGENDAMENTO,
    LOCALIZACAO,
    PLANO_DE_SAUDE,
    CONVENIO,
    CONVENIO_INFORMACOES,
    CONVENIO_FEEDBACK,
    SUCESSO_CADASTRO,
    SELECT_PROFILE
  ]

  return (
    <AppMainContainer showMenu={!routesWithoutMenu.includes(route)}>
      <div id="app" className="app" data-testid="app">
        <ScheduleCancellationModalsGroup />
        <BrowserErrorModal />
        <FeedbackModalsGroup />
        <RescheduleModalsGroup />
        <DesireModalsGroup />
        <PediatricWelcomeModal />
        <EmailValidationModal />
        <PhoneValidationModal />
        <PregnancyCardsDrawer />
        <Switch>
          <Redirect exact from="/" to={SELECT_PROFILE} />
          <Redirect exact from="/index.html" to={SELECT_PROFILE} />
          {/* Rotas sem menus - tela cheia */}
          <Route exact path={SELECT_PROFILE} component={SelectProfile} />
          <Route exact path={LOGOUT_MOBILE} component={LogoutMobile} />
          <Route exact path={`${BOOKINGS}/:uuid`} render={renderBooking} />
          <Route exact path={DADOS_DE_SAUDE} component={HealthInfos} />
          <Route exact path={DADOS_GESTACIONAIS} component={GestationalInfos} />
          <Route exact path={DADOS_PESSOAIS} component={PersonalInfos} />
          <Route exact path={AGENDAMENTO_ULTRASSOM} component={ChooseUsgDate} />
          <Route exact path={NOVO_AGENDAMENTO} component={ScheduleFlow} />
          <Route exact path={LOCALIZACAO} component={Localization} />
          <Route exact path={PLANO_DE_SAUDE} component={HealthInsurance} />
          <Route exact path={CONVENIO} component={HealthInsuranceType} />
          <Route exact path={CONVENIO_INFORMACOES} component={HealthInsurancePlan} />
          <Route exact path={CONVENIO_FEEDBACK} component={HealthInsuranceFeedback} />
          <Route exact path={SUCESSO_CADASTRO} component={SalesOnboardingSuccess} />
          <Route path={`${BOOKING_CONFIRMATION}/:id`} render={renderScheduleConfirmSMS} />
          {/* Rotas com menus */}
          <Route exact path={INICIO} component={Home} />
          <Route exact path={AGENDAR} component={ScheduleView} />
          <Route exact path={CHAT} component={ChatView} />
          <Route exact path={CONSULTAS} component={MyBookingsView} />
          <Route exact path={SAUDE} component={HealthDataView} />
          <Route exact path={CONTENT} component={AllPosts} />
          <Route path={PROFILE} component={Profile} />
          <Route path={ADD_NEW_DEPENDENT} component={AddNewDependent} />
          <Route path={EDIT_DEPENDENT} component={EditDependent} />
          <Route path={EDIT_DEPENDENT_HEALTHPLAN} component={EditDependentHealthplan} />
          <Route path={HEALTH_PLAN_INCLUDED} component={HealthPlanIncluded} />
          <Route path={CHANGE_HEALTH_PLAN} component={ChangeHealthPlanProfile} />
          <Route path={HEALTHPLAN_FEEDBACK} component={FeedbackHealthPlanProfile} />
          <Route path={EDIT_ADDRESS} component={EditAddressForm} />
          <Route path={EDIT_PROFILE} component={EditAccessDataForm} />
          <Route path={EDIT_PAYMENT} component={EditPaymentInfoProfile} />
          <Route path={PRESCRIPTIONS_AND_EXAMS} component={PrescriptionsAndExams} />
          <Route exact path={JORNADA_EXPLICADA} component={QuarterDetailsView} />
          <Route path={`${CONTENT}/:title/:id`} render={renderSingleContent} />
          <Route path={`${ESPECIALISTA}/:id`} render={renderProfessionalInfo} />
          <Route path={OLD_SPECIALISTS} component={RedirectOldSpecialistsLinks} />
          <Redirect to={SELECT_PROFILE} />
        </Switch>
      </div>
    </AppMainContainer>
  )
})

const mapStateToProps = ({
  authentication,
  profile,
  appProfiles,
  myBookingsFlow
}: AppState) => ({
  currentUser: authentication?.currentUser,
  forceUpdateCurrentUser: authentication.forceUpdateCurrentUser,
  isFetchingUser: authentication.isFetchingUser,
  isFetchingProfile: profile.isFetchingProfile,
  postRefreshTokenStatus: authentication.postRefreshTokenStatus,
  newRefreshToken: authentication.currentRefreshToken,
  activeProfile: appProfiles.activeProfile,
  isFetchingAllBookings: myBookingsFlow.getBookingsStatus.isLoading,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  getAllWeekOptions: getAllWeekOptionsAction,
  updateCurrentUserInfos: updateCurrentUserInfosAction,
  clearForceUpdateCurrentUser: clearForceUpdateCurrentUserAction,
  fetchProfile: fetchProfileAction,
  handleRefreshToken: handleRefreshTokenAction,
  logout: logoutAction,
  promptUnauthenticatedModal: promptUnauthenticatedModalAction,
  getHighlights: getHighlightsAction,
  getHealthPlanInfos: getHealthInsurancePlanDataAction,
  enableHealthPlanModal: enableHealthPlanModalAction,
  getChildrenData: getChildrenDataAction,
  getBookings: getBookingsAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoutes)
