import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { RegularButton, Loader } from 'theia-web-ds';
import { AppState } from '../../../apps/main/store'

import './ScheduleConfirmSMS.scss'
import LogoHorizontal from '../../../../assets/horizontal-logo.svg'
import MainIllustration from '../../../../public/images/confirm-ilustra.png'
import CalendarError from '../../../../assets/calendar_error.svg'
import {
  bookingConfirmationInfoSMSAction,
  confirmBookingSMSAction,
  promtpCancelBookingSMSModalAction
} from '../../../state/scheduleConfirmationSMS/actions'
import ScheduleCancellationSmsModal from './ScheduleCancellationSmsModal'
import { LocalAddress } from '../../../utils/LocalAddress'
import { formatToHours } from '../../../utils/helpers'
import { AGENDAR } from '../../../routes/RoutesConstants'
import { eventTrack } from '../../../../eventGenerate'
import { TheiaError } from '../../../domain/errors/TheiaError'

interface Props {
  step: string;
  bookingConfirmationInfoSMS: (
    bookingId: string, token: string
  ) => void;
  confirmBookingSMS: (bookingId: string, token: string) => void;
  promtpCancelBookingSMSModal: () => void;
  id: string;
  token: string;
  startDate?: string;
  professionalName: string;
  address?: LocalAddress;
  alreadyConfirmed: boolean;
  alreadyCancelled: boolean;
  isModalOpened: boolean;
  confirmationAvailable: boolean;
  isLoading: boolean;
  bookingConfirmInfosError?: TheiaError
}

function ScheduleConfirmSMS({
  step,
  bookingConfirmationInfoSMS,
  confirmBookingSMS,
  promtpCancelBookingSMSModal,
  id,
  token,
  startDate,
  professionalName,
  address,
  alreadyConfirmed,
  alreadyCancelled,
  isModalOpened,
  confirmationAvailable,
  isLoading,
  bookingConfirmInfosError
}: Props) {
  const history = useHistory();
  const [stepConfirmation, setStepConfirmation] = useState('')
  const startDateString = startDate && moment(startDate).format('DD/MM')
  const startHourString = startDate && formatToHours(startDate)

  useEffect(() => {
    eventTrack('Visualizou confirmacao agendamento', {
      bookingId: id
    })
    bookingConfirmationInfoSMS(id, token)
  }, [])

  useEffect(() => {
    if (stepConfirmation) {
      eventTrack('Step confirmacao agendamento', {
        stepConfirmation
      })
    }
  }, [stepConfirmation])

  useEffect(() => {
    if (step) {
      setStepConfirmation(step)
    }
  }, [step])

  useEffect(() => {
    if (!confirmationAvailable) {
      setStepConfirmation('confirmation-not-available')
    }
  }, [confirmationAvailable])

  useEffect(() => {
    if (alreadyConfirmed) {
      setStepConfirmation('already-confirmed')
    } else if (alreadyCancelled) {
      setStepConfirmation('already-canceled')
    }
  }, [alreadyConfirmed, alreadyCancelled])

  const hasAddress = () => {
    if (address && address.name !== '') {
      return `no endereço ${address.name}, ${address.number}`
    }
    return ''
  }

  function getConfirmMainText() {
    switch (stepConfirmation) {
      case 'initial':
        return `Você confirma presença na consulta do dia ${startDateString} às ${startHourString} ${hasAddress()} com ${professionalName}?`
      case 'just-confirmed':
        return `Sua presença na consulta do dia ${startDateString} às ${startHourString} foi confirmada!`
      case 'already-confirmed':
        return `Sua presença na consulta do dia ${startDateString} às ${startHourString} já está confirmada!`
      case 'just-canceled':
        return `A consulta do dia ${startDateString} às ${startHourString} foi cancelada!`
      case 'already-canceled':
        return `A consulta do dia ${startDateString} às ${startHourString} já foi cancelada!`
      case 'confirmation-not-available':
        return `A consulta do dia ${startDateString} às ${startHourString} não está disponível para confirmação!`
      default:
        return `Você confirma presença na consulta do dia ${startDateString} às ${startHourString} no endereço ${address} com ${professionalName}?`
    }
  }

  function getConfirmSubText() {
    switch (stepConfirmation) {
      case 'initial':
        return 'Em caso negativo, iremos cancelar a consulta e você poderá remarcá-la para outro horário.'
      case 'just-confirmed':
        return 'Em caso de dúvidas, é só entrar em contato com nossa equipe através do chat no aplicativo.'
      case 'already-confirmed':
        return 'Em caso de dúvidas, é só entrar em contato com nossa equipe através do chat no aplicativo.'
      case 'just-canceled':
        return 'Mas esperamos você na plataforma para agendar novamente, viu?!'
      case 'already-canceled':
        return 'Mas não tem problema, podemos agendar novamente, viu?!'
      case 'confirmation-not-available':
        return 'Aproveite para agendar sua próxima consulta com a Theia!'
      default:
        return 'Em caso negativo, iremos cancelar a consulta e você poderá remarcá-la para outro horário.'
    }
  }

  function getConfirmButtonText() {
    switch (stepConfirmation) {
      case 'initial':
        return 'Confirmo presença'
      case 'just-canceled':
        return 'Agendar novo horário'
      case 'already-canceled':
        return 'Agendar novo horário'
      case 'confirmation-not-available':
        return 'Agendar nova consulta'
      default:
        return 'Confirmo presença'
    }
  }

  function cancelBooking() {
    eventTrack('Clicou nao poderei comparecer', {
      bookingId: id
    })
    promtpCancelBookingSMSModal()
  }

  const onConfirmSchedule = () => {
    eventTrack(`Clicou ${getConfirmButtonText()}`, {
      bookingId: id
    })
    confirmBookingSMS(id, token)
  }

  const onCancelledSchedule = () => {
    eventTrack(`Clicou ${getConfirmButtonText()}`, {
      bookingId: id
    })
    history.push(AGENDAR)
  }

  const getButtonAction = () => {
    if (stepConfirmation === 'initial') {
      onConfirmSchedule()
    } else if (stepConfirmation === 'just-canceled'
      || stepConfirmation === 'already-canceled'
      || stepConfirmation === 'confirmation-not-available') {
      onCancelledSchedule()
    }
  }

  return (
    <>
      {isModalOpened && <ScheduleCancellationSmsModal />}
      <div className="ml-4 mt-6 lg:ml-20">
        <LogoHorizontal className="logo-schedule-confirm h-fit" />
      </div>
      {isLoading && (
        <div className="flex justify-center w-full h-full mt-20">
          <div className="flex items-center">
            <Loader />
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="schedule-confirm">
          {bookingConfirmInfosError ? (
            <>
              <div className="icon-welcome">
                <CalendarError className="error-icon" />
              </div>
              <p className="schedule-confirm__title mt-12">
                {bookingConfirmInfosError.friendlyMessageTitle}
              </p>
              <p className="text-confirm">
                {bookingConfirmInfosError.friendlyMessage}
              </p>
            </>
          ) : (
            <>
              <div className="icon-welcome">
                <img src={MainIllustration} alt="ilustração Theia" />
              </div>
              <p className="schedule-confirm__title mt-12">
                {getConfirmMainText()}
              </p>
              <p className="text-confirm">
                {getConfirmSubText()}
              </p>
              <div className={`${stepConfirmation === 'initial' ? 'full-container' : 'half-container'} confirm-button-container flex flex-col lg:flex-row items-center mt-8`}>
                {stepConfirmation !== 'just-confirmed' && stepConfirmation !== 'already-confirmed' && (
                  <RegularButton
                    type="button"
                    label={getConfirmButtonText()}
                    onClick={getButtonAction}
                    maxWidth="100%"
                  />
                )}
                {stepConfirmation === 'initial' && (
                  <RegularButton
                    type="button"
                    label="Não poderei comparecer"
                    onClick={cancelBooking}
                    variant="secondary"
                    maxWidth="100%"
                    extraClass="font-medium md:ml-4 my-6 md:my-0"
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ scheduleConfirmationSMS }: AppState) => ({
  step: scheduleConfirmationSMS.step,
  startDate: scheduleConfirmationSMS.startDate,
  professionalName: scheduleConfirmationSMS.professionalName,
  address: scheduleConfirmationSMS.address,
  alreadyConfirmed: scheduleConfirmationSMS.alreadyConfirmed,
  alreadyCancelled: scheduleConfirmationSMS.alreadyCancelled,
  isLoading: scheduleConfirmationSMS.isLoading,
  isModalOpened: scheduleConfirmationSMS.isModalOpened,
  confirmationAvailable: scheduleConfirmationSMS.confirmationAvailable,
  bookingConfirmInfosError: scheduleConfirmationSMS.bookingConfirmInfosError
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  bookingConfirmationInfoSMS: bookingConfirmationInfoSMSAction,
  confirmBookingSMS: confirmBookingSMSAction,
  promtpCancelBookingSMSModal: promtpCancelBookingSMSModalAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleConfirmSMS)
