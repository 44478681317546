import React from 'react'
import ProfilePicture, { PicturePlaceholder } from '../common/molecules/ProfilePicture'
import './UserInfo.scss'

interface Props {
  profileName: string;
  profileEmail: string;
  profileImage?: string | null;
  isLoading: boolean;
  isFetchingProfile?: boolean;
  picturePlaceholder?: PicturePlaceholder
  rightComponent?: React.ReactNode
}

function UserInfo({
  profileName,
  profileEmail,
  profileImage,
  isLoading,
  isFetchingProfile,
  picturePlaceholder,
  rightComponent
}: Props) {
  return (
    isFetchingProfile ? (
      <div className="user-name-loading" />
    ) : (
      <div className="w-full left-0 right-0">
        <div className="flex items-center mx-auto lg:max-w-xl pt-1">
          <div className="flex profile-picture">
            <ProfilePicture
              pictureUrl={profileImage}
              pictureSize={40}
              isLoading={isLoading}
              picturePlaceholder={picturePlaceholder}
            />
          </div>
          <div className="profile-name-email ml-4">
            <h1 className="text-fontDefault font-medium">{profileName || ''}</h1>
            <span className="text-greyColor text-fontExtraSmall">{profileEmail || ''}</span>
          </div>
          {rightComponent && <div className="place-items-end flex-1">{rightComponent}</div>}
        </div>
      </div>
    )
  )
}

export default UserInfo
