import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import Clipboard from '../../../assets/feedback-clipboard.svg'
import {
  givePositiveFeedbackAction,
  giveNegativeFeedbackAction,
  suppressFeedbackAction
} from '../../state/feedback/actions'
import { AppState } from '../../apps/main/store'
import Modal from '../common/Modal'
import CancelButton from '../common/buttons/CancelButton'

interface Props {
  givePositiveFeedback: () => void;
  giveNegativeFeedback: () => void;
  closeFeedback: () => void;
  isLoading: boolean;
}

function FeedbackModal({
  givePositiveFeedback, giveNegativeFeedback, isLoading, closeFeedback
}: Props) {
  return (
    <Modal>
      <div className="flex flex-col text-center feedback-modal">
        <div className="self-center mb-4 ml-4">
          <Clipboard />
        </div>
        <h1 className="modal__title">Sua sessão virtual acabou</h1>

        {isLoading
          ? <Loader />
          : (
            <p className="modal__info pt-2">
              A experiência atendeu às suas expectativas?
            </p>
          )}

        <div className="flex flex-row justify-center pt-4 pb-2">
          <button disabled={isLoading} onClick={givePositiveFeedback} type="button" className="feedback__button focus:outline-none">Sim</button>
          <button disabled={isLoading} onClick={giveNegativeFeedback} type="button" className="feedback__button focus:outline-none">Não</button>
        </div>
        <CancelButton onClick={closeFeedback}>Fechar</CancelButton>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ feedback }: AppState) => ({
  isLoading: feedback.isFetchingReasons,
})
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  givePositiveFeedback: () => { dispatch(givePositiveFeedbackAction()) },
  giveNegativeFeedback: () => { dispatch(giveNegativeFeedbackAction()) },
  closeFeedback: () => { dispatch(suppressFeedbackAction()) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackModal)
