import React, {
  ReactElement,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  AccessibleModal,
  Loader,
  RegularButton,
  Snackbar
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Exam } from '../../../domain/Prescriptions'
import EmptyView from '../../common/EmptyView'
import NoExams from '../../../../assets/NoExams.svg'
import { eventTrack } from '../../../../eventGenerate'
import { StatusType } from '../../../domain/Status'
import { AppState } from '../../../apps/main/store'
import { AppDispatch } from '../../../state/utils'
import {
  deleteExamAction,
  getExamDownloadUrlAction,
  getExamsAction,
  resetDeleteExamStatusAction,
  resetGetUrlStatusAction
} from '../../../state/prescriptions/actions'
import { ActiveProfileType } from '../../../domain/AppProfiles'
import DeleteExam from '../../../../assets/delete_exam.svg'
import { setSawExamsListAction } from '../../../state/userHighlights/actions'
import ExamUpload from './ExamUpload'
import './ExamsView.scss'
import ExamCard from './ExamCard'
import { ExamInProgress } from '../../../state/prescriptions/reducers'

interface Props {
  exams?: Array<Exam>;
  deleteExamStatus: StatusType;
  getExamDownloadUrlStatus: StatusType;
  examDownloadUrl?: string;
  getExamsStatus: StatusType;
  isDependent: boolean;
  activeProfile?: ActiveProfileType
  sawExamsList: boolean
  setSawExamsList: () => void;
  getExams: (childId?: string, isUpdate?: boolean) => void;
  resetGetUrlStatus: () => void;
  deleteExam: (examId: string) => void;
  getExamDownloadUrl: (examId: string) => void
  resetDeleteExamStatus: () => void;
}

function ExamsView({
  exams,
  deleteExamStatus,
  getExamDownloadUrlStatus,
  examDownloadUrl,
  getExamsStatus,
  isDependent,
  activeProfile,
  sawExamsList,
  setSawExamsList,
  getExams,
  resetGetUrlStatus,
  deleteExam,
  getExamDownloadUrl,
  resetDeleteExamStatus,
}: Props) {
  const [examToDelete, setExamToDelete] = useState<Exam | undefined>()
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [acceptedFiles, setAcceptedFiles] = useState<Array<any>>()
  const [progress, setProgress] = useState(0)
  const [shouldCancelUpload, setShouldCancelUpload] = useState(false)
  const [messageToRender, setMessageToRender] = useState<ReactElement | undefined>()
  const examsRef = useRef<HTMLDivElement | null>(null)
  const intervalRef: any = useRef()

  function getProfileExams(isUpdate?: boolean) {
    getExams(isDependent ? activeProfile?.id : undefined, isUpdate)
  }

  useEffect(() => {
    getProfileExams()
    if (!sawExamsList) {
      setSawExamsList()
    }
  }, [isDependent])

  useEffect(() => {
    if (shouldCancelUpload) {
      setShouldCancelUpload(false)
    }
  }, [shouldCancelUpload])

  function handleClickExamOptions(showOptions: boolean, exam?: Exam) {
    if (exam && showOptions) {
      eventTrack('clicou opcoes exame', {
        examId: exam.examId
      })
    }
  }

  function handleClickDownloadExam(exam: Exam) {
    getExamDownloadUrl(exam.examId)
    eventTrack('clicou download exame', {
      examId: exam.examId
    })
  }

  function handleClickDeleteExam(exam: Exam) {
    setExamToDelete(exam)
    setShowConfirmDeleteModal(true)
    eventTrack('clicou excluir exame', {
      examId: exam.examId
    })
  }

  function confirmDeleteExam() {
    if (examToDelete) {
      deleteExam(examToDelete.examId)
      eventTrack('confirmou exclusao exame', {
        examId: examToDelete.examId
      })
    }
  }

  function handleCancelDeleteExam() {
    setShowConfirmDeleteModal(false)
    eventTrack('cancelou exclusao exame', {
      examId: examToDelete?.examId
    })
  }

  useEffect(() => {
    if (deleteExamStatus.success) {
      setShowConfirmDeleteModal(false)
      eventTrack('exame excluido', {
        examId: examToDelete?.examId
      })
      resetDeleteExamStatus()
    }
    if (deleteExamStatus.error) {
      setShowConfirmDeleteModal(false)
      eventTrack('erro ao excluir exame', {
        motivo: JSON.stringify(deleteExamStatus.errorMessage)
      })
    }
  }, [deleteExamStatus])

  useEffect(() => {
    if (getExamDownloadUrlStatus.success && examDownloadUrl) {
      window.open(examDownloadUrl, '_blank')
      eventTrack('baixou exame')
      resetGetUrlStatus()
    }
    if (getExamDownloadUrlStatus.error) {
      eventTrack('erro download exame', {
        motivo: getExamDownloadUrlStatus.errorMessage?.toString()
      })
    }
  }, [getExamDownloadUrlStatus, examDownloadUrl])

  function startProgress() {
    intervalRef.current = (setInterval(() => {
      setProgress((prevProgress) => {
        if (progress > 0 && prevProgress < 100) {
          return prevProgress + 1
        }
        clearInterval(intervalRef.current)
        return prevProgress
      })
    }, 100))

    return () => clearInterval(intervalRef.current)
  }

  const resetProgress = () => {
    clearInterval(intervalRef?.current)
    setProgress(0)
  }

  function getAcceptedExams(files?: Array<ExamInProgress>) {
    if (files) {
      setAcceptedFiles(files)
      setProgress(1)
      startProgress()
    }
  }

  return (
    <>
      <ExamUpload
        onFinishAllUploads={() => {
          getProfileExams(true)
          setAcceptedFiles(undefined)
          resetProgress()
        }}
        getAcceptedExams={(accepted) => {
          getAcceptedExams(accepted)
        }}
        onRenderMessage={(message?: ReactElement) => setMessageToRender(message)}
        scrollTopOnSubmit={() => {
          examsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }}
      />
      {getExamsStatus.error && (
        <div className="alert-snackbar relative">
          <Snackbar
            text={getExamsStatus.errorMessage?.friendlyMessage
              || 'Desculpe, no momento não foi possível carregar a listagem de exames.'}
            alignTop
            iconLeft="icon-InfoSquareLight"
            showCloseButton
            variant="default"
            title={getExamsStatus.errorMessage?.friendlyMessageTitle
              || 'Falha no carregamento'}
            buttonTwoProps={{
              label: 'Tentar novamente',
              onClick: getProfileExams
            }}
          />
        </div>
      )}
      {deleteExamStatus.error && (
        <div className="alert-snackbar fixed bottom-16">
          <Snackbar
            text={deleteExamStatus.errorMessage?.friendlyMessage
              || 'Desculpe, houve um erro ao tentar excluir, por favor tente novamente.'}
            alignTop
            iconLeft="icon-CloseCircleLight"
            showCloseButton
            variant="alert"
            title={deleteExamStatus.errorMessage?.friendlyMessageTitle
              || 'Falha ao excluir'}
            buttonTwoProps={{
              label: 'Tentar novamente',
              onClick: confirmDeleteExam
            }}
          />
        </div>
      )}
      {getExamsStatus.isLoading && !exams?.length && (
        <div className="content-loader">
          <div className="loader">
            <Loader />
          </div>
        </div>
      )}
      <div className="prescription-info" ref={examsRef}>
        {acceptedFiles ? (
          acceptedFiles?.map((file: ExamInProgress, index: number) => (
            <div className="flex gap-4 my-3 w-full" key={`${file.name}-${file.size}-${index}`}>
              <ExamCard
                fileName={file.name}
                fileSize={file.size}
                uploadProgress={progress}
                onCancelUpload={() => setShouldCancelUpload(true)}
              />
            </div>
          ))
        ) : null}
        {exams?.length ? (
          <ul className="exams-list">
            {getExamsStatus.isLoading ? (
              <div className="flex justify-center p-4">
                <Loader />
              </div>
            ) : null}
            {exams.map((item: Exam) => (
              <div className="flex gap-4 my-3 w-full" key={`${item.fileName}-${item.examId}`}>
                <ExamCard
                  fileName={item.fileName}
                  ownerName={item.ownerName}
                  uploadDate={item.uploadDate}
                  onClickExamOptions={(showOptions) => handleClickExamOptions(showOptions, item)}
                  onClickDeleteExam={() => handleClickDeleteExam(item)}
                  onClickDownloadExam={() => handleClickDownloadExam(item)}
                />
              </div>
            ))}
          </ul>
        ) : null}
        {(!exams?.length && !getExamsStatus.isLoading && !acceptedFiles) ? (
          <div className="prescriptions-and-exams__empty-list-view">
            <EmptyView
              title="Nenhum exame"
              subtitle={(
                <>
                  Arquivo suportado:
                  <br />
                  Apenas JPG, PNG, TIF, JPEG, HEIC, HEIF ou PDF com tamanho máximo de 70 mb
                </>
              )}
              icon={<NoExams />}
            />
          </div>
        ) : null}
        {messageToRender}
      </div>
      <AccessibleModal
        showCloseButton
        headerText="confirm-delete-exam"
        visible={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        variant="centerCenter"
        extraOuterClassName="confirm-delete-exam-modal__outer"
        extraModalContainerClass="confirm-delete-exam-modal__inner"
      >
        <div className="delete-exam-modal__img">
          <DeleteExam />
        </div>
        <h3 className="delete-exam-modal__title">
          Deseja realmente excluir esse exame?
        </h3>
        <p className="delete-exam-modal__message">
          O item {`"${examToDelete?.fileName}"`} será excluído definitivamente.
        </p>
        <RegularButton
          label="Excluir"
          onClick={confirmDeleteExam}
          isSubmitting={deleteExamStatus.isLoading}
          extraClass="mb-4"
        />
        <RegularButton
          label="Cancelar"
          onClick={handleCancelDeleteExam}
          variant="text"
        />
      </AccessibleModal>
    </>
  )
}

const mapStateToProps = ({
  prescriptions,
  userHighlights,
  appProfiles
}: AppState) => ({
  exams: prescriptions.exams,
  deleteExamStatus: prescriptions.deleteExamStatus,
  getExamsStatus: prescriptions.getExamsStatus,
  getExamDownloadUrlStatus: prescriptions.getExamDownloadUrlStatus,
  examDownloadUrl: prescriptions.examDownloadUrl,
  sawExamsList: userHighlights.sawExamsList,
  isDependent: appProfiles.isDependent,
  activeProfile: appProfiles.activeProfile
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  deleteExam: deleteExamAction,
  getExamDownloadUrl: getExamDownloadUrlAction,
  resetDeleteExamStatus: resetDeleteExamStatusAction,
  getExams: getExamsAction,
  resetGetUrlStatus: resetGetUrlStatusAction,
  setSawExamsList: setSawExamsListAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamsView)
