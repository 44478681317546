import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  DateInput,
  ErrorMessage,
  FormInput,
  Loader,
  MaskedFormInput,
  NewChipButton,
  RegularButton,
  Snackbar
} from 'theia-web-ds'
import Header from '../common/molecules/Header'
import { BabyData, HealthInsurancePlanStatus } from '../../domain/PediatricFlow'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { saveDependentDataAction, clearSaveDependentStatusAction, removeActiveProfileAction } from '../../state/appProfiles/actions'
import { StatusType } from '../../domain/Status'
import './AddNewDependent.scss'
import {
  cpfMask,
  cpfValidation,
  dontHavePlan,
  havePlan,
  onlyDigits,
  specialCharacterRegex,
  validationFullNameRegex
} from '../../utils/helpers'
import { success, warning } from '../../color'
import HealthPlanRegistrationForm, { HealthInsurancePlanForm } from '../common/HealthPlanRegistrationForm'
import { INICIO, SELECT_PROFILE } from '../../routes/RoutesConstants'
import { showNotificationAction } from '../../state/notifications/actions'
import { ToastInfos, ToastType } from '../../domain/Notifications'
import { getChildrenDataAction } from '../../state/pediatricFlow/actions'
import { eventTrack } from '../../../eventGenerate'
import { getHighlightsAction } from '../../state/userHighlights/actions'
import { EventCategories } from '../../utils/EventCategories'

function renderAddDependentForm(
  {
    values,
    errors,
    handleSubmit,
    touched,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue
  }: FormikProps<BabyData>,
  saveDependentDataStatus: StatusType
) {
  return (
    <form onSubmit={handleSubmit} className="save-dependent-form">
      <div className="save-dependent-form-content">
        <FormInput
          label="Nome completo"
          name="name"
          type="text"
          placeholder="Igual ao documento oficial"
          id="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={touched.name ? errors.name : ''}
          extraClassContainer="mb-4"
        />
        <DateInput
          id="birthDate"
          name="birthDate"
          onChange={(value) => {
            if (value) setFieldValue('birthDate', value.getTime())
            else setFieldValue('birthDate', '')
          }}
          onBlur={() => setFieldTouched('birthDate')}
          blockFutureDates
          placeholder="DD/MM/AA"
          label="Data de nascimento"
          value={values.birthDate}
          extraClassName="mb-4"
          errorText={touched.birthDate ? errors.birthDate : ''}
          hasError={!!(touched.birthDate && errors.birthDate)}
          shouldCloseOnSelect
        />
        <MaskedFormInput
          label="CPF"
          errorMessage={touched.cpf ? errors.cpf : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          id="cpf"
          value={values.cpf}
          name="cpf"
          type="text"
          placeholder="000.000.000-00"
          mask={cpfMask}
          extraContainerClassName="mb-4"
        />
        <label className="chips-label" htmlFor="hasHealthplan">
          Possui plano de saúde?
        </label>
        <div className="chips-container">
          <NewChipButton
            key="Sim"
            label="Sim"
            isSelected={havePlan(values.healthInsurancePlanStatus)}
            onClick={() => setFieldValue(
              'healthInsurancePlanStatus',
              HealthInsurancePlanStatus.HAVE_PLAN
            )}
            height="32px"
            width="fit-content"
          />
          <NewChipButton
            key="Não"
            label="Não"
            isSelected={dontHavePlan(values.healthInsurancePlanStatus)}
            onClick={() => setFieldValue(
              'healthInsurancePlanStatus',
              HealthInsurancePlanStatus.DONT_HAVE_PLAN
            )}
            height="32px"
            width="fit-content"
          />
        </div>
        {errors.healthInsurancePlanStatus
        && touched.cpf
        && touched.birthDate
        && touched.name
        && (
          <ErrorMessage error={errors.healthInsurancePlanStatus} />
        )}
      </div>
      <div className="save-dependent-form-btn">
        <RegularButton
          label={havePlan(values.healthInsurancePlanStatus) ? 'Continuar' : 'Adicionar'}
          type="submit"
          variant="primary"
          isSubmitting={saveDependentDataStatus.isLoading}
        />
      </div>
    </form>
  )
}

interface Props {
  saveDependentDataStatus: StatusType
  previousRoute?: string
  childrenData?: BabyData[]
  consultantDocument?: string
  getChildrenDataStatus: StatusType
  needToCreateBaby: boolean
  getHighlights: () => void
  removeActiveProfile: () => void
  saveDependentData: (payload: BabyData) => void
  showNotification: (toatInfos: ToastInfos) => void
  clearSaveDependentStatus: () => void
  getChildrenData: () => void
}

function AddNewDependent({
  saveDependentDataStatus,
  previousRoute,
  childrenData,
  consultantDocument,
  getChildrenDataStatus,
  needToCreateBaby,
  getHighlights,
  removeActiveProfile,
  saveDependentData,
  showNotification,
  clearSaveDependentStatus,
  getChildrenData
}: Props) {
  const history = useHistory()
  const [submittedValues, setSubmittedValues] = useState<BabyData | undefined>()
  const [step, setStep] = useState(1)

  const goBack = () => {
    if (step === 2) setStep(1)
    else if (previousRoute === SELECT_PROFILE) history.push(INICIO)
    else history.goBack()
  }

  const alreadyRegisteredDocuments = useMemo(() => childrenData?.map((child) => child.cpf)
    .filter((cpf) => cpf !== undefined && cpf !== null) || [], [childrenData])

  const initialValues: BabyData = submittedValues || {
    name: '',
    birthDate: '',
    cpf: '',
    healthInsurancePlanStatus: HealthInsurancePlanStatus.NOT_INFORMED
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Campo obrigatório')
      .matches(validationFullNameRegex, 'Informe o nome e sobrenome para continuar')
      .matches(specialCharacterRegex, 'Inválido caracter especial'),
    birthDate: Yup.string().required('Campo obrigatório'),
    cpf: Yup.string()
      .required('Campo obrigatório')
      .transform((value) => value.replace(/[^\d]/g, ''))
      .test(
        'test-document',
        'CPF inválido',
        (value) => cpfValidation(value)
      )
      .test(
        'test-docs',
        'CPF já cadastrado. Por favor, verifique se o CPF inserido é do bebê.',
        (value) => !alreadyRegisteredDocuments.includes(onlyDigits(value))
      )
      .test(
        'test-cpf',
        'Coloque um CPF diferente do seu',
        (value) => onlyDigits(value) !== consultantDocument
      ),
    healthInsurancePlanStatus: Yup.string().required('Campo obrigatório')
      .oneOf([
        HealthInsurancePlanStatus.DONT_HAVE_PLAN,
        HealthInsurancePlanStatus.HAVE_PLAN], 'Obrigatório')
  })

  const submitValues = (values?: BabyData) => {
    const formattedValues = {
      ...values,
      cpf: onlyDigits(values?.cpf || '')
    }
    setSubmittedValues(formattedValues)
    if (values?.healthInsurancePlanStatus === HealthInsurancePlanStatus.HAVE_PLAN) {
      setStep(2)
    } else saveDependentData(formattedValues)
  }

  const handlePostDependentHealthInsurance = (values: HealthInsurancePlanForm) => {
    saveDependentData({
      ...submittedValues,
      ...values
    })
  }

  useEffect(() => {
    if (saveDependentDataStatus.success) {
      showNotification({
        message: 'Dependente adicionado com sucesso!',
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_SAVE_NEW_DEPENDENT',
        type: ToastType.SUCCESS,
      })
      if (needToCreateBaby) getHighlights()
      removeActiveProfile()
    }
    if (saveDependentDataStatus.error) {
      eventTrack('erro ao cadastrar dependente', {
        category: EventCategories.PROFILE_SEPARATION,
        erro: JSON.stringify(saveDependentDataStatus.errorMessage)
      })
    }
  }, [saveDependentDataStatus])

  useEffect(() => {
    getChildrenData()
    return () => clearSaveDependentStatus()
  }, [])

  return (
    <div className="save-dependent-data-view">
      <div className="save-dependent-data-header">
        <Header title="Adicionar dependente" goBack={goBack} />
      </div>
      {getChildrenDataStatus.isLoading ? (
        <div className="save-dependent-data-loader">
          <Loader />
        </div>
      ) : (
        <>
          {step === 1 && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitValues}
            >
              {(props) => renderAddDependentForm(
                props,
                saveDependentDataStatus
              )}
            </Formik>
          )}
          {step === 2 && (
            <div className="save-dependent-form pb-4">
              <HealthPlanRegistrationForm
                onPostHealthInsurance={handlePostDependentHealthInsurance}
                btnLabel="Cadastrar"
                showOnlyCovered
                isChildHealthPlan
                failureToSave={saveDependentDataStatus.error}
              />
            </div>
          )}
        </>
      )}
      {saveDependentDataStatus.error && (
        <div className="save-dependent-snackbar-error">
          <Snackbar
            title={saveDependentDataStatus.errorMessage?.friendlyMessageTitle
              || 'Erro ao salvar'}
            text={saveDependentDataStatus.errorMessage?.friendlyMessage
              || 'Desculpe, houve um erro ao adicionar dependente, por favor tente novamente'}
            buttonTwoProps={{
              label: 'Tentar novamente',
              onClick: () => submitValues(submittedValues)
            }}
            variant="alert"
            iconLeft="icon-CloseCircleLight"
            customIconColor={warning}
            showCloseButton
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  appProfiles, routerListener, pediatricFlow, authentication, userHighlights
}: AppState) => ({
  saveDependentDataStatus: appProfiles.saveDependentDataStatus,
  previousRoute: routerListener.previousRoute,
  childrenData: pediatricFlow.childrenData,
  consultantDocument: authentication.currentUser?.document,
  getChildrenDataStatus: pediatricFlow.getChildrenDataStatus,
  needToCreateBaby: userHighlights.needToCreateBaby
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  saveDependentData: saveDependentDataAction,
  showNotification: showNotificationAction,
  clearSaveDependentStatus: clearSaveDependentStatusAction,
  getChildrenData: getChildrenDataAction,
  removeActiveProfile: removeActiveProfileAction,
  getHighlights: getHighlightsAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewDependent)
