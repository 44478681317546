/* eslint-disable no-unused-vars */
import { SpecialistCategories } from './Specialist'

export enum JourneyType {
  PEDIATRICIAN = 'PEDIATRICIAN',
  PREGNANCY = 'PREGNANCY'
}

export type Quarter = {
  id: string;
  title: string;
  description: string;
  imgUrl: string;
  steps: number;
  checkedSteps: number;
  currentQuarter: boolean;
  journeyType: JourneyType;
}

export type GuidedJourney = {
  isFirstTime?: boolean;
  firstTimeMessage?: string;
  firstTimeJourneyMessage?: string;
  list?: Array<Quarter>;
}

export type AppointmentType = 'Presencial' | 'Online';

export type ChipProps = {
  text: string;
  color: string;
}

export type QuarterConsultation = {
  id: string;
  imgUrl: string;
  done: boolean;
  title: string;
  type: AppointmentType;
  typeInfo: string | null;
  weeks?: string;
  description: string;
  toConsultant: boolean;
  chipList: ChipProps[];
  category: SpecialistCategories;
}

export type ConsultationInfos = {
  quarter: string;
  title: string;
  objective: string;
  recommendedWeek: string;
  obs: string;
  type: string;
  attendanceAdress: string;
  id: string;
  chipList: ChipProps[];
  toConsultant: boolean;
}

export type ResponseQuarterDetails = {
  journeyConsultants: QuarterConsultation[];
}

export type UpdateCardResponse = {
  id: string;
  done: boolean;
  chipList: ChipProps[];
}

export interface GuidedJourneyService {
  getConsultantJourney(): Promise<GuidedJourney>;
  getChildJourney(childId: string): Promise<GuidedJourney>;
  getQuarterDetails(id: string): Promise<Array<QuarterConsultation>>;
  getConsultationInfos(id: string): Promise<ConsultationInfos>;
  setViewedGuidedJourney(): Promise<void>;
  checkJourneyStep(id: string): Promise<UpdateCardResponse>;
  uncheckJourneyStep(id: string): Promise<UpdateCardResponse>;
}
