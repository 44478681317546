import { DesireContent, PregnancyCardContent } from '../../domain/Consultant';
import { initialStatus, StatusType } from '../../domain/Status'
import {
  ConsultantTypes,
  POST_MATERNAL_DESIRE,
  SUCCESS_POST_MATERNAL_DESIRE,
  FAILURE_POST_MATERNAL_DESIRE,
  SUPPRESS_FIRST_MATERNAL_DESIRE_MODAL,
  SUPPRESS_MATERNAL_DESIRE_ERROR,
  GET_DESIRE_CONTENT,
  SUCCESS_GET_DESIRE_CONTENT,
  FAILURE_GET_DESIRE_CONTENT,
  GET_PREGNANCY_CARDS,
  SUCCESS_GET_PREGNANCY_CARDS,
  FAILURE_GET_PREGNANCY_CARDS,
  PROMPT_PREGNANCY_CARDS_DRAWER,
  SUPPRESS_PREGNANCY_CARDS_DRAWER
} from './actions'

export interface ConsultantState {
  isPostingDesire: boolean;
  failurePostDesire: boolean;
  sawFirstMaternalDesireModal: boolean;
  desireContent?: DesireContent;
  isGettingDesireContent: boolean;
  getPregnancyCardsStatus: StatusType;
  pregnancyCards?: Array<PregnancyCardContent>;
  pregnancyCardsDrawerIsOpen: boolean;
}

const initialConsultantState: ConsultantState = {
  isPostingDesire: false,
  failurePostDesire: false,
  sawFirstMaternalDesireModal: false,
  desireContent: undefined,
  isGettingDesireContent: false,
  getPregnancyCardsStatus: initialStatus,
  pregnancyCards: undefined,
  pregnancyCardsDrawerIsOpen: false
}

export function consultant(
  state: ConsultantState = initialConsultantState,
  action: ConsultantTypes
): ConsultantState {
  switch (action.type) {
    case POST_MATERNAL_DESIRE: {
      return {
        ...state,
        isPostingDesire: true,
      }
    }
    case SUCCESS_POST_MATERNAL_DESIRE: {
      return {
        ...state,
        isPostingDesire: false,
        sawFirstMaternalDesireModal: true,
        failurePostDesire: false
      }
    }
    case FAILURE_POST_MATERNAL_DESIRE: {
      return {
        ...state,
        isPostingDesire: false,
        failurePostDesire: true,
      }
    }
    case GET_DESIRE_CONTENT: {
      return {
        ...state,
        isGettingDesireContent: true,
        desireContent: undefined
      }
    }
    case SUCCESS_GET_DESIRE_CONTENT: {
      return {
        ...state,
        isGettingDesireContent: false,
        desireContent: action.desireContent
      }
    }
    case FAILURE_GET_DESIRE_CONTENT: {
      return {
        ...state,
        isGettingDesireContent: false,
      }
    }
    case SUPPRESS_FIRST_MATERNAL_DESIRE_MODAL: {
      return {
        ...state,
        sawFirstMaternalDesireModal: true
      }
    }
    case SUPPRESS_MATERNAL_DESIRE_ERROR: {
      return {
        ...state,
        failurePostDesire: false
      }
    }
    case GET_PREGNANCY_CARDS: {
      return {
        ...state,
        getPregnancyCardsStatus: {
          ...initialStatus,
          isLoading: true
        }
      }
    }
    case SUCCESS_GET_PREGNANCY_CARDS: {
      return {
        ...state,
        getPregnancyCardsStatus: {
          ...initialStatus,
          success: true
        },
        pregnancyCards: action.pregnancyCards
      }
    }
    case FAILURE_GET_PREGNANCY_CARDS: {
      return {
        ...state,
        getPregnancyCardsStatus: {
          ...initialStatus,
          error: true,
          errorMessage: action.error
        }
      }
    }
    case PROMPT_PREGNANCY_CARDS_DRAWER: {
      return {
        ...state,
        pregnancyCardsDrawerIsOpen: true
      }
    }
    case SUPPRESS_PREGNANCY_CARDS_DRAWER: {
      return {
        ...state,
        pregnancyCardsDrawerIsOpen: false
      }
    }
    default: return state
  }
}
