import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { isNotInformed } from '../../utils/helpers'
import ChooseMedicalSpecialty from './ChooseMedicalSpecialty'
import {
  resetScheduleFlowAction,
  setLoadingStepAction,
  setScheduleStepAction
} from '../../state/scheduleFlow/actions'
import { ScheduleFlowSteps } from '../../domain/ScheduleFlow'
import RegisterChildHealthPlan from '../pediatricFlow/RegisterChildHealthPlan'
import { cleanPromotionalCodeAction } from '../../state/payment/actions'
import { SpecialistCategories } from '../../domain/Specialist'
import AppHeader from '../common/AppHeader'
import { ActiveProfileType } from '../../domain/AppProfiles'
import { NOVO_AGENDAMENTO } from '../../routes/RoutesConstants'
import './ScheduleView.scss'

interface Props {
  activeScheduleStep?: ScheduleFlowSteps;
  previousSelectedCategoryName?: SpecialistCategories;
  activeProfile?: ActiveProfileType
  isDependent: boolean
  resetScheduleFlow: () => void;
  cleanPromotionalCode: () => void;
  setScheduleStep: (step: ScheduleFlowSteps) => void;
  setLoadingSteps: (isLoading: boolean) => void;
}

type PreScheduleViewsType = {
  name: ScheduleFlowSteps;
  renderStep: boolean;
  actionsOnGoBack?: Array<() => void>;
}

function ScheduleView({
  activeScheduleStep,
  previousSelectedCategoryName,
  activeProfile,
  isDependent,
  resetScheduleFlow,
  cleanPromotionalCode,
  setScheduleStep,
  setLoadingSteps,
}: Props) {
  const history = useHistory()
  const [activeView, setActiveView] = useState(0)
  const showChildHealthplanView = isDependent
    && isNotInformed(activeProfile?.healthInsurancePlanStatus)
  const canGoBack = activeView > 0

  const scheduleViews: PreScheduleViewsType[] = [
    {
      name: ScheduleFlowSteps.ChooseMedicalSpecialty,
      renderStep: true,
    },
    {
      name: ScheduleFlowSteps.ChildHealthPlan,
      renderStep: showChildHealthplanView,
      actionsOnGoBack: [resetScheduleFlow]
    },
  ]

  useEffect(() => {
    if (activeView > (scheduleViews.length - 1)) {
      history.push(NOVO_AGENDAMENTO)
    }
    if (scheduleViews[activeView]?.renderStep === true) {
      setScheduleStep(scheduleViews[activeView].name)
    } else {
      setActiveView(activeView + 1)
    }
  }, [
    activeView,
    showChildHealthplanView
  ])

  const prevProfileIdRef = useRef<string | undefined>()

  useEffect(() => {
    if (prevProfileIdRef.current !== activeProfile?.id) {
      setActiveView(0)
    }
    prevProfileIdRef.current = activeProfile?.id
  }, [activeProfile?.id])

  function handleNextStep() {
    setLoadingSteps(true)
    setActiveView(activeView + 1)
  }

  function onGoBack() {
    setLoadingSteps(true)
    scheduleViews[activeView].actionsOnGoBack?.map((action) => action())
    if (activeView > 0) {
      for (let i = activeView - 1; i >= 0; i -= 1) {
        if (scheduleViews[i].renderStep === true) {
          setActiveView(i)
          return
        }
      }
    }
    resetScheduleFlow()
    cleanPromotionalCode()
    setActiveView(0)
  }

  return (
    <div className="schedule-view-container">
      {!previousSelectedCategoryName && (
        <AppHeader title="Agendar" onGoBack={canGoBack ? onGoBack : undefined} />
      )}
      <div className="schedule-view-body">
        <div className="schedule-view-content">
          {activeScheduleStep === ScheduleFlowSteps.ChooseMedicalSpecialty && (
            <ChooseMedicalSpecialty onChooseCategory={handleNextStep} />
          )}
          {activeScheduleStep === ScheduleFlowSteps.ChildHealthPlan && (
            <RegisterChildHealthPlan
              actionOnMount={() => setLoadingSteps(false)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  scheduleFlow,
  appProfiles
}: AppState) => ({
  activeScheduleStep: scheduleFlow.activeScheduleStep,
  previousSelectedCategoryName: scheduleFlow.previousSelectedCategoryName,
  activeProfile: appProfiles.activeProfile,
  isDependent: appProfiles.isDependent
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  resetScheduleFlow: resetScheduleFlowAction,
  cleanPromotionalCode: cleanPromotionalCodeAction,
  setScheduleStep: setScheduleStepAction,
  setLoadingSteps: setLoadingStepAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleView)
