import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RegularButton } from 'theia-web-ds'
import ExclamationError from '../../../assets/exclamation-error.svg'
import CardError from '../../../assets/card-error.svg'
import { AppState } from '../../apps/main/store'
import { suppressErrorAction, ErrorWithFriendlyMessage } from '../../state/errorWithFriendlyMessage/actions'
import Modal from './Modal'
import HeaderModal from './HeaderModal'

interface Props {
  visible: boolean;
  closeModal: () => void;
  error?: ErrorWithFriendlyMessage;
  buttonText?: string;
  extraButtonAction?: () => void;
  extraButton?: boolean;
  extraButtonText?: string;
  extraButtonclick?: () => void;
  hasObs?: boolean;
  isSubmitting?: boolean;
}

function ErrorModal({
  visible,
  closeModal,
  error,
  buttonText,
  extraButtonAction = () => {},
  extraButton,
  extraButtonText,
  extraButtonclick,
  hasObs,
  isSubmitting
}: Props) {
  if (!visible) return null

  function handleOnClick() {
    closeModal()
    extraButtonAction()
  }

  const icon = (error?.message === 'Payment Method Failed' || error?.statusCode === 'PAYMENT-METHOD-FAILED')
    ? <CardError className="self-center" />
    : <ExclamationError className="self-center" />

  return (
    <Modal>
      <div className="default-error-modal flex flex-col text-center" data-testid="default-error-modal">
        {icon}
        <HeaderModal className="mt-4 mb-4">
          {error?.friendlyMessageTitle || 'Ops, aconteceu um problema'}
        </HeaderModal>
        <p className="break-words text-textSecondary font-normal mb-2">
          {error?.friendlyMessage || 'Não foi possível concluir sua solicitação.'}
        </p>
        {hasObs && (
          <p className="text-textSecondary font-normal mb-2">
            {error && error.message}
          </p>
        )}
        <div>
          {extraButton && (
            <RegularButton
              label={extraButtonText}
              onClick={extraButtonclick}
              isSubmitting={isSubmitting}
              extraClass="mt-2 mb-2"
            />
          )}
          <RegularButton
            label={buttonText || 'Fechar'}
            onClick={handleOnClick}
            variant={extraButton ? 'secondary-text' : 'primary'}
            extraClass="mt-2"
          />
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ errorWithFriendlyMessage }: AppState) => ({
  error: errorWithFriendlyMessage.error
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => { dispatch(suppressErrorAction()) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorModal)
