import ApiClient from './ApiClient'
import {
  BookingService,
  PromotionalCodeView,
  Reason,
  BookingConfirmationInfo,
  BookingProfessionalConfirmationInfo,
  BookingSuggestionResponse,
  Booking
} from '../../domain/Booking'
import { LocalDate } from '../../utils/LocalDate'
import { LocalAddress } from '../../utils/LocalAddress'

export default class ApiBookingService implements BookingService {
  constructor(private apiClient: ApiClient) { }

  getPromotionalCode(
    codeCoupon?: string,
    productId?: string
  ): Promise<PromotionalCodeView> {
    return this.apiClient.getPromotionalCode(codeCoupon, productId)
  }

  getBookingsCancellationReasons(): Promise<Array<Reason>> {
    return this.apiClient.getBookingsCancellationReasons()
  }

  getFeedbackReasons(): Promise<Array<Reason>> {
    return this.apiClient.getFeedbackReasons()
  }

  sendFeedback(
    bookingId: string,
    reasonsId: number[],
    otherMotive: string,
    meetExpectation: boolean
  ): Promise<void> {
    return this.apiClient.postFeedback(bookingId, reasonsId, otherMotive, meetExpectation)
  }

  cancelBooking(bookingId: string, reasonsId: number[], otherMotive: string): Promise<void> {
    return this.apiClient.cancelBooking(bookingId, reasonsId, otherMotive)
  }

  bookingConfirmationInfoSMS(
    bookingId: string, token: string
  ): Promise<BookingConfirmationInfo> {
    return this.apiClient.bookingConfirmationInfoSMS(bookingId, token)
  }

  confirmBookingSMS(
    bookingId: string, token: string, participantType?: string, professionalStatus?: string
  ): Promise<void> {
    return this.apiClient.confirmBookingSMS(bookingId, token, participantType, professionalStatus)
  }

  cancelBookingSMS(bookingId: string, token: string): Promise<void> {
    return this.apiClient.cancelBookingSMS(bookingId, token)
  }

  bookingProfessionalConfirmationInfoSMS(
    bookingId: string, token: string
  ): Promise<BookingProfessionalConfirmationInfo> {
    return this.apiClient.bookingProfessionalConfirmationInfoSMS(bookingId, token)
  }

  async getBookingSuggestion(): Promise<BookingSuggestionResponse> {
    const scheduleSuggestionResponse = await this.apiClient.getBookingSuggestion()
    return scheduleSuggestionResponse
  }

  updateBookingSuggestion(
    reminderType: string,
    bookingIds?: string[],
    prescriptionId?: string
  ): Promise<void> {
    return this.apiClient.updateBookingSuggestion(
      reminderType,
      bookingIds,
      prescriptionId
    )
  }

  async getBookings(): Promise<Array<Booking>> {
    const bookings: Array<Booking> = await this.apiClient.getBookings()
    return bookings.map(this.parseBooking)
  }

  async getBookingHistory(): Promise<Array<Booking>> {
    const bookings: Array<Booking> = await this.apiClient.getBookingHistory()
    return bookings.map(this.parseBooking)
  }

  private parseBooking = (booking: any): Booking => ({
    ...booking,
    startDate: new LocalDate(booking.startDate),
    endDate: new LocalDate(booking.endDate),
    attendanceAddress: booking.attendanceAddress && new LocalAddress(booking.attendanceAddress),
  })
}
