/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../utils'
import { TheiaError } from '../../domain/errors/TheiaError'
import { urlEnvironment } from '../../utils/helpers'
import { AppFlags } from './reducers'

export const GET_REMOTE_CONFIG = 'GET_REMOTE_CONFIG'
export const SUCCESS_GET_REMOTE_CONFIG = 'SUCCESS_GET_REMOTE_CONFIG'
export const FAILURE_GET_REMOTE_CONFIG = 'FAILURE_GET_REMOTE_CONFIG'

interface GetRemoteConfigActionType {
  type: typeof GET_REMOTE_CONFIG;
}

interface SuccessGetRemoteConfigActionType {
  type: typeof SUCCESS_GET_REMOTE_CONFIG;
  flags: AppFlags;
}

interface FailureGetRemoteConfigActionType {
  type: typeof FAILURE_GET_REMOTE_CONFIG;
  error: TheiaError;
}

export type RemoteConfigTypes =
GetRemoteConfigActionType |
SuccessGetRemoteConfigActionType |
FailureGetRemoteConfigActionType

export function getRemoteConfigAction(): AsyncAction<{}, {}> {
  return async (dispatch) => {
    dispatch({ type: GET_REMOTE_CONFIG })
    await fetch(`${process.env.API_FRONT_URL}/app-lists?listName=appFlags&environment=${urlEnvironment}`)
      .then(async (response) => {
        const data = await response.json()
        if (response.ok || response.status === 200) {
          dispatch({ type: SUCCESS_GET_REMOTE_CONFIG, flags: data })
        } else {
          dispatch({ type: FAILURE_GET_REMOTE_CONFIG, error: { message: response } })
          Sentry.captureException(new Error(`Failure to fetch remote config: ${response}`))
        }
      })
      .catch((error) => {
        dispatch({ type: FAILURE_GET_REMOTE_CONFIG, error: { message: error.message } })
        Sentry.captureException(new Error(`Failure to fetch remote config: ${error.message}`))
      })
  }
}
