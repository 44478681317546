/* eslint-disable max-len */
import React, {
  useMemo
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppDispatch } from '../../../state/utils'
import { AppState } from '../../../apps/main/store'
import {
  onSelectHealthInsuranceAction,
  postUserHealthInsuranceAction,
} from '../../../state/healthplanProfile/actions'
import CardFaq from '../../../../assets/card-faq.png'
import HealthPlanRegistrationForm, { HealthInsurancePlanForm } from '../../common/HealthPlanRegistrationForm'
import { HealthInsurancePlanData, SelectedHealthPlan, PostUserHealthPlanType } from '../../../domain/Healthplan'
import { FaqToggle } from '../../common/FaqToggle'
import { HEALTHPLAN_FEEDBACK } from '../../../routes/RoutesConstants'
import Header from '../../common/molecules/Header'

interface LocationProps {
  isStillLegacyPlan: boolean;
}

interface Props {
  onPostHealthInsurance: (
    healthPlanData: PostUserHealthPlanType,
    alreadyHasInsurancePlan: boolean
  ) => void;
  onSelectHealthInsurancePlan: (selectedHealthPlan: SelectedHealthPlan) => void;
  hasInsurancePlan: boolean;
  healthInsurancePlanData?: HealthInsurancePlanData | null;
}

function HealthPlanProfile({
  onPostHealthInsurance,
  onSelectHealthInsurancePlan,
  hasInsurancePlan,
  healthInsurancePlanData,
}: Props) {
  const history = useHistory()
  const location = useLocation().state as LocationProps
  const isStillLegacyPlan = location?.isStillLegacyPlan

  const healthInsuranceFormMessage = useMemo(() => {
    let message = 'Trocar plano de saúde'
    if ((hasInsurancePlan === false) || (hasInsurancePlan && isStillLegacyPlan)) {
      message = 'Adicionar plano de saúde'
    }
    return message
  }, [hasInsurancePlan])

  function handleSubmit(values: HealthInsurancePlanForm) {
    onSelectHealthInsurancePlan({
      companyName: values.otherCompany || values.companyName,
      companyId: values.companyId,
      planName: values.planName,
      planId: values.planId,
      memberId: values.memberId,
      hasPreviousInsurancePlan: hasInsurancePlan,
      isCovered: values.isCovered,
    })
    if (values.isCovered) {
      onPostHealthInsurance({
        hasHealthInsurancePlan: values.hasHealthInsurancePlan,
        healthInsurancePlan: {
          company: values.otherCompany || values.companyName,
          planName: values.planName,
          memberId: values.memberId,
          companyId: values.companyId,
          planId: values.planId,
        }
      }, !!(healthInsurancePlanData?.hasHealthInsurancePlan))
    }
    history.push(HEALTHPLAN_FEEDBACK)
  }

  return (
    <div className="flex flex-1 relative">
      <div className="mx-auto w-full h-full">
        <Header
          title={healthInsuranceFormMessage}
          goBack={() => history.goBack()}
        />
        <div className="lg:max-w-xl mx-auto w-full mb-14">

          <div className="lg:max-w-xl px-4 mb-12  pt-4">
            <HealthPlanRegistrationForm
              onPostHealthInsurance={(values: HealthInsurancePlanForm) => handleSubmit(values)}
              previousHealthInsurancePlan={healthInsurancePlanData?.healthInsurancePlan}
            />
          </div>
          <FaqToggle
            toggleTitle="Onde encontro esses dados na carteirinha?"
            iconType="icon-ChatHelpLight"
          >
            <div className="flex flex-col items-center mb-20">
              <img src={CardFaq} alt="" height={40} />
              <p className="mt-4 text-textPrimary text-fontDefault">
                Você encontra os dados do plano na carteirinha virtual.
              </p>
              <div className="flex items-center mt-6">
                <span className="number-icon text-secondary font-medium">1</span>
                <p className="flex-1 text-textSecondary text-fontDefault ml-4 text-faq-line">
                  O seguro ou número da carteirinha fica localizado logo abaixo do nome,
                  como mostra na figura acima.
                </p>
              </div>
              <div className="flex items-center mt-4">
                <span className="number-icon text-secondary font-medium">2</span>
                <p className="flex-1 text-textSecondary text-fontDefault ml-4">
                  E o nome do plano está ao lado direito do número da carteirinha.
                </p>
              </div>
            </div>
          </FaqToggle>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  healthplan
}: AppState) => ({
  hasInsurancePlan: !!healthplan.healthInsurancePlanData?.hasHealthInsurancePlan,
  healthInsurancePlanData: healthplan.healthInsurancePlanData,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onPostHealthInsurance: postUserHealthInsuranceAction,
  onSelectHealthInsurancePlan: onSelectHealthInsuranceAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthPlanProfile)
