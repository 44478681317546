import * as Sentry from '@sentry/browser'
import { HighlightDesireType, UserHighlights, UserHighlightsService } from '../../domain/UserHighlights'
import { AsyncAction } from '../utils'
import { AppState } from '../../apps/main/store'

export const GET_HIGHLIGHTS = 'GET_HIGHLIGHTS'
export const SUCCESS_GET_HIGHLIGHTS = 'SUCCESS_GET_HIGHLIGHTS'
export const FAILURE_GET_HIGHLIGHTS = 'FAILURE_GET_HIGHLIGHTS'
export const RESET_HIGHLIGHTS_STATUS = 'RESET_HIGHLIGHTS_STATUS'

export const POST_PRESCRIPTIONS_HIGHLIGHTS = 'POST_PRESCRIPTIONS_HIGHLIGHTS'
export const SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS = 'SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS'
export const FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS = 'FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS'

export const POST_NOTIFICATION_CONFIRMATION = 'POST_NOTIFICATION_CONFIRMATION'
export const SUCCESS_POST_NOTIFICATION_CONFIRMATION = 'SUCCESS_POST_NOTIFICATION_CONFIRMATION'
export const FAILURE_POST_NOTIFICATION_CONFIRMATION = 'FAILURE_POST_NOTIFICATION_CONFIRMATION'

export const SET_SAW_EXAMS_LIST = 'SET_SAW_EXAMS_LIST'
export const SET_SAW_EXAMS_UPLOAD = 'SET_SAW_EXAMS_UPLOAD'

interface GetHighlightsActionType {
  type: typeof GET_HIGHLIGHTS;
}

interface SuccessGetHighlightsActionType {
  type: typeof SUCCESS_GET_HIGHLIGHTS;
  highlights: UserHighlights;
}

interface FailureGetHighlightsActionType {
  type: typeof FAILURE_GET_HIGHLIGHTS;
}

interface ResetHighlightsStatusActionType {
  type: typeof RESET_HIGHLIGHTS_STATUS
}

interface PostPrescriptionsHighlightsActionType {
  type: typeof POST_PRESCRIPTIONS_HIGHLIGHTS;
}

interface SuccessPostPrescriptionsHighlightsActionType {
  type: typeof SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS;
  highlights: UserHighlights;
}

interface FailurePostPrescriptionsHighlightsActionType {
  type: typeof FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS;
}

interface PostNotificationConfirmationActionType {
  type: typeof POST_NOTIFICATION_CONFIRMATION;
}

interface SuccessPostNotificationConfirmationActionType {
  type: typeof SUCCESS_POST_NOTIFICATION_CONFIRMATION;
  highlights: UserHighlights;
}

interface FailurePostNotificationConfirmationActionType {
  type: typeof FAILURE_POST_NOTIFICATION_CONFIRMATION;
}

interface SetSawExamsListActionType {
  type: typeof SET_SAW_EXAMS_LIST;
}

interface SetSawExamsUploadActionType {
  type: typeof SET_SAW_EXAMS_UPLOAD;
}

export type UserHighlightsType = GetHighlightsActionType
| SuccessGetHighlightsActionType
| FailureGetHighlightsActionType
| ResetHighlightsStatusActionType
| PostPrescriptionsHighlightsActionType
| SuccessPostPrescriptionsHighlightsActionType
| FailurePostPrescriptionsHighlightsActionType
| PostNotificationConfirmationActionType
| SuccessPostNotificationConfirmationActionType
| FailurePostNotificationConfirmationActionType
| SetSawExamsListActionType
| SetSawExamsUploadActionType

export function getHighlightsAction():
  AsyncAction<AppState, { userHighlightsService: UserHighlightsService }> {
  return async (dispatch, getState, { userHighlightsService }) => {
    const { userHighlights } = getState()
    if (userHighlights.getUserHighlightsStatus.isLoading) return
    dispatch({ type: GET_HIGHLIGHTS })
    try {
      const highlights = await userHighlightsService.getHighlights()
      dispatch({ type: SUCCESS_GET_HIGHLIGHTS, highlights })
    } catch (error) {
      dispatch({ type: FAILURE_GET_HIGHLIGHTS })
      Sentry.captureException(new Error(`Failure to fetch user highlights - ${error.message}`))
    }
  }
}

export const resetHightlightsStatusAction = (): ResetHighlightsStatusActionType => ({
  type: RESET_HIGHLIGHTS_STATUS
})

export function onSendHighlightAction(highlight: string):
  AsyncAction<AppState, { userHighlightsService: UserHighlightsService }> {
  return async (dispatch, _getState, { userHighlightsService }) => {
    dispatch({ type: POST_PRESCRIPTIONS_HIGHLIGHTS })
    try {
      await userHighlightsService.sendHighlight(highlight)
      dispatch({ type: SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS })
      dispatch(getHighlightsAction())
    } catch (error) {
      dispatch({ type: FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS })
      Sentry.captureException(new Error(`Unexpected error to send highlights infos - ${error.message}`))
    }
  }
}

export function postNotificationConfirmationAction(type: HighlightDesireType):
  AsyncAction<{}, { userHighlightsService: UserHighlightsService }> {
  return async (dispatch, _getState, { userHighlightsService }) => {
    dispatch({ type: POST_NOTIFICATION_CONFIRMATION })
    try {
      await userHighlightsService.postNotificationConfirmation(type)
      dispatch({ type: SUCCESS_POST_NOTIFICATION_CONFIRMATION })
    } catch (error) {
      dispatch({ type: FAILURE_POST_NOTIFICATION_CONFIRMATION })
      Sentry.captureException(new Error(`Failure to post notification confirmation to ${type} - ${error.message}`))
    }
  }
}

export const setSawExamsListAction = (): SetSawExamsListActionType => ({
  type: SET_SAW_EXAMS_LIST
})

export const setSawExamsUploadAction = (): SetSawExamsUploadActionType => ({
  type: SET_SAW_EXAMS_UPLOAD
})
