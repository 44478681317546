import * as rudderanalytics from 'rudder-sdk-js'
import { User } from './src/domain/User'

type rudderUser = {
  user?: User;
  id?: string;
  email?: string;
  healthInsurancePlan?: string;
  hasHealthInsurancePlan?: boolean;
  moment?: string;
  cityName?: string;
}

export function rudderInitializeStg() {
  rudderanalytics.load(`${process.env.RUDDER_KEY_STG || ''}`, 'https://rudder.oithea.com.br')
}

export function rudderInitializeProd() {
  rudderanalytics.load(`${process.env.RUDDER_KEY_PRD || ''}`, 'https://rudder.theia.com.br')
}

export function eventTrack(
  event: string,
  properties?: rudderanalytics.apiObject,
  options?: rudderanalytics.apiOptions,
  callback?: rudderanalytics.apiCallback,
  modifyContext?: boolean
) {
  const newOptions = modifyContext ? { ...options, context: { traits: { email: '' } } } : options
  rudderanalytics.track(
    event,
    {
      ...properties,
      plataforma: 'web'
    },
    newOptions,
    callback
  )
}

export function identifyUser(user: rudderUser) {
  rudderanalytics.identify(
    user.id,
    {
      email: user.email,
      healthInsurance: user.healthInsurancePlan,
      hasHealthInsurancePlan: user.hasHealthInsurancePlan,
      moment: user.moment,
      cityName: user.cityName,
    }
  )
}

export function eventPage(
  category: string,
  name: string,
  properties?: rudderanalytics.apiObject,
  options?: rudderanalytics.apiOptions,
  callback?: rudderanalytics.apiCallback,
) {
  rudderanalytics.page(
    category,
    name,
    {
      ...properties,
      plataforma: 'web'
    },
    options,
    callback
  )
}

export function resetUser() {
  rudderanalytics.reset()
}
