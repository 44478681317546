/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import SpecialistIcon from '../../../../assets/specialist-icon.png'
import UserIcon from '../../../../assets/face-icon.png'
import UsgIcon from '../../../../assets/ultrassom.png'
import BabyIcon from '../../../../assets/BabyFace.png'
import './ProfilePicture.scss'

export enum PicturePlaceholder {
  SPECIALIST = 'specialist',
  USG = 'usg',
  BABY = 'baby',
  USER = 'user'
}
interface ProfilePictureProps {
  pictureUrl?: string | null;
  pictureSize?: number;
  picturePlaceholder?: PicturePlaceholder;
  className?: string;
  isLoading?: boolean;
  extraClass?: string;
}

export default function ProfilePicture({
  pictureUrl,
  pictureSize = 24,
  picturePlaceholder,
  className,
  isLoading,
  extraClass,
}: ProfilePictureProps) {
  const [error, setError] = useState(false)

  if (isLoading) {
    return (
      <div
        className={`profile-picture-loading ${extraClass}`}
        style={{
          width: pictureSize,
          height: pictureSize
        }}
        data-testid="profile-picture-loader"
      />
    )
  }

  function getPicturePlaceholder() {
    switch (picturePlaceholder) {
      case PicturePlaceholder.SPECIALIST:
        return SpecialistIcon
      case PicturePlaceholder.BABY:
        return BabyIcon
      case PicturePlaceholder.USG:
        return UsgIcon
      default:
        return UserIcon
    }
  }

  return (
    <div>
      {(error || !pictureUrl) ? (
        <div className={`profile-picture-container mr-2 ${className}`}>
          {picturePlaceholder ? (
            <img
              src={getPicturePlaceholder()}
              width={pictureSize}
              height={pictureSize}
              alt="placeholder"
              data-testid="placeholder-testid"
            />
          ) : (
            <div
              style={{ width: pictureSize, height: pictureSize }}
              className="rounded-full bg-bgDisable"
            />
          )}
        </div>
      ) : (
        <img
          width={pictureSize}
          height={pictureSize}
          alt="profile"
          src={pictureUrl || getPicturePlaceholder()}
          onError={() => setError(true)}
          className={`profile-picture-container mr-2 ${className}`}
          style={{
            backgroundImage: `url(${pictureUrl})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: pictureSize,
            height: pictureSize,
          }}
        />
      )}
    </div>
  )
}
