/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { RegularButton } from 'theia-web-ds'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import checkHealthInsurance from '../../../animations/checking_card.json'
import { AppState } from '../../../apps/main/store'
import NegativeFeedback from '../../../../assets/Plan-no.svg'
import { AppDispatch } from '../../../state/utils'
import BackButton from '../../common/buttons/BackButton'
import { SelectedHealthPlan, PostUserHealthPlanType } from '../../../domain/Healthplan'
import './FeedbackHealthPlanProfile.scss'
import { HEALTH_PLAN_INCLUDED, PROFILE } from '../../../routes/RoutesConstants'
import { postUserHealthInsuranceAction } from '../../../state/healthplanProfile/actions'
import { StatusType } from '../../../domain/Status'

interface Props {
  onPostHealthInsurance: (
    healthPlanData: PostUserHealthPlanType,
    alreadyHasInsurancePlan: boolean
  ) => void;
  postHealthInsurancePlanStatus: StatusType;
  selectedHealthplan?: SelectedHealthPlan;
  alreadyHasInsurancePlan: boolean;
}

function FeedbackHealthPlanProfile({
  onPostHealthInsurance,
  selectedHealthplan,
  postHealthInsurancePlanStatus,
  alreadyHasInsurancePlan,
}: Props) {
  const history = useHistory()
  const companyName = selectedHealthplan?.companyName || ''
  const companyId = selectedHealthplan?.companyId || ''
  const planName = selectedHealthplan?.planName || ''
  const planId = selectedHealthplan?.planId || ''
  const memberId = selectedHealthplan?.memberId || ''
  const hasPreviousInsurancePlan = selectedHealthplan?.hasPreviousInsurancePlan
  const isHealthPlanCovered = selectedHealthplan?.isCovered
  const [isSubmittingHealthPlan, setIsSubmittingHealthplan] = useState(false)
  const [animationDelay, setAnimationDelay] = useState(false)
  const [showNegativeFeedback, setShowNegativeFeedback] = useState(false)
  const isSuccessFeedback = isHealthPlanCovered && postHealthInsurancePlanStatus.success
  const isNegativeFeedback = !isHealthPlanCovered && postHealthInsurancePlanStatus.success
  const lottieCheckingHealthInsurance = {
    loop: true,
    autoplay: true,
    animationData: checkHealthInsurance,
  }

  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => {
      setAnimationDelay(true)
    }, 6000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    if (postHealthInsurancePlanStatus.isLoading) {
      setIsSubmittingHealthplan(true)
    }
    if (postHealthInsurancePlanStatus.error) {
      setIsSubmittingHealthplan(false)
    }
  }, [postHealthInsurancePlanStatus])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isSuccessFeedback) {
      timer = setTimeout(() => {
        history.push(HEALTH_PLAN_INCLUDED, {
          isFromFeedbackChangePlan: true,
          hasPreviousInsurancePlan
        })
      }, 5000)
      return
    }
    if (isNegativeFeedback && hasPreviousInsurancePlan) {
      timer = setTimeout(() => {
        history.push(PROFILE, {
          isFromFeedbackChangePlan: true,
          hasPreviousInsurancePlan
        })
      }, 2000)
      return
    }
    timer = setTimeout(() => {
      setShowNegativeFeedback(true)
    }, 5000)
    return () => clearTimeout(timer)
  }, [postHealthInsurancePlanStatus, hasPreviousInsurancePlan])

  const onSubmit = () => {
    onPostHealthInsurance({
      hasHealthInsurancePlan: true,
      healthInsurancePlan: {
        company: companyName,
        planName,
        memberId,
        companyId,
        planId,
      }
    }, alreadyHasInsurancePlan)
  }

  const goToProfile = () => {
    history.push(PROFILE)
  }

  const negativeFeedback = (
    <div className="flex flex-col items-center health-plan-negative-profile">
      <div className={animationDelay ? 'go-up' : 'not-accepted-plan-icon'}>
        <NegativeFeedback />
      </div>
      <div className="show-content-not-accepted">
        <div className="mx-4">
          <p className="text-textMedium text-center font-normal justify-center">
            {planName && (`Ahh! Ainda não aceitamos o plano ${planName} da ${companyName}`)}
            {!planName && (`Ahh! Ainda não aceitamos o plano ${companyName}`)}
          </p>
          <p className="text-fontDefault text-textSecondary text-center mt-4">
            Mas você pode agendar no particular e pedir reembolso após a consulta.
          </p>
          {hasPreviousInsurancePlan && (
            <>
              <RegularButton
                label="Confirmar alteração"
                onClick={onSubmit}
                isSubmitting={isSubmittingHealthPlan}
                extraClass="my-4"
              />
              <RegularButton
                variant="text"
                label="Manter o atual"
                onClick={goToProfile}
              />
            </>
          )}
          {!hasPreviousInsurancePlan && (
            <RegularButton
              label="Ok, entendi"
              onClick={goToProfile}
              extraClass="my-4"
            />
          )}
        </div>
      </div>
    </div>
  )

  const checkingHealthInsurance = (
    <div className="flex flex-col items-center justify-center checking-health-insurance">
      <Lottie
        options={lottieCheckingHealthInsurance}
        isClickToPauseDisabled
        ariaRole=""
        height={192}
        width={192}
      />
      <p className="text-textPrimary text-textMedium w-4/5 text-center">
        Falando com a {companyName}...
      </p>
    </div>
  )

  return (
    <div className="flex flex-1 relative">
      <div className="mx-auto w-full">
        <div className="sticky flex items-center bg-bgCanvas top-0 z-10 h-20">
          <BackButton text="" to="/profile" extraClass="back-button-svg" />
        </div>
        <div className="lg:max-w-xl mx-auto w-full feedback-container">
          {showNegativeFeedback ? negativeFeedback : checkingHealthInsurance}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  healthplan
}: AppState) => ({
  postHealthInsurancePlanStatus: healthplan.postHealthInsurancePlanStatus,
  selectedHealthplan: healthplan.selectedHealthplan,
  alreadyHasInsurancePlan: !!(healthplan.healthInsurancePlanData?.hasHealthInsurancePlan)
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onPostHealthInsurance: postUserHealthInsuranceAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackHealthPlanProfile)
