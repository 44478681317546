import React, { useState, useEffect } from 'react'
import { Formik, FormikProps } from 'formik'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { RegularButton, FormInput } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import AppMainContainerOff from '../AppMainContainerOff'
import { FaqToggle } from '../common/FaqToggle'
import {
} from '../../state/onboarding/actions'
import { HealthInsuranceCompany, SelectedHealthPlan, PostUserHealthPlanType } from '../../domain/Healthplan'
import { notUndefined } from '../../utils/helpers'
import SelectInputSearch from '../common/SelectInputSearch'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { ConvenioFeedbackParams } from './HealthInsuranceFeedback'
import { ConvenioInformacoesParams } from './HealthInsurancePlan'
import { CONVENIO_FEEDBACK, CONVENIO_INFORMACOES } from '../../routes/RoutesConstants'
import { getHealthInsuranceCompaniesAction, onSelectHealthInsuranceAction, postUserHealthInsuranceAction } from '../../state/healthplanProfile/actions'
import { StatusType } from '../../domain/Status'

interface Props {
  companies: any;
  fetchCompaniesStatus: StatusType;
  hasHealthInsurancePlan?: boolean | null;
  onPostHealthInsurance: (
    healthPlanData: PostUserHealthPlanType,
    alreadyHasInsurancePlan: boolean
  ) => void;
  getHealthInsuranceCompanies: () => void;
  onSelectHealthInsurance: (selectedHealthPlan: SelectedHealthPlan) => void;
}

interface HealthInsurancePlanForm {
  hasHealthInsurancePlan: boolean;
  companyName: string;
  planName: string;
  memberId: string;
  companyId: string;
  planId: string;
  otherCompany: string;
}

function renderHealthInsuranceTypeForm(
  {
    handleSubmit,
    isSubmitting,
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    errors,
    touched
  }: FormikProps<HealthInsurancePlanForm>,
  getHealthInsuranceCompanies: () => void,
  companies: Array<HealthInsuranceCompany>,
  onSelectHealthInsurance: (selectedHealthPlan: SelectedHealthPlan) => void,
  fetchCompaniesStatus: StatusType,
  hasHealthInsurancePlan?: boolean | null
) {
  const history = useHistory()
  const [hasOtherCompany, setHasOtherCompany] = useState(false)
  const [companiesOptions, setCompaniesOptions] = useState<any>()
  const [plans, setPlans] = useState<any>()
  const [companyName, setCompanyName] = useState('')

  useEffect(() => {
    eventPage('onboarding', 'Convenio')
    getHealthInsuranceCompanies()
  }, [])

  useEffect(() => {
    if (values.companyName === 'Outro') {
      setHasOtherCompany(true)
      setCompanyName(values.otherCompany)
    } else {
      setHasOtherCompany(false)
      setFieldValue('otherCompany', '')
    }
  }, [values.companyName, values.otherCompany])

  const disabledButton = !values.companyName || values.companyName === ''
    || (values.companyName === 'Outro' && (!values.otherCompany || values.otherCompany === ''))

  useEffect(() => {
    if (companies) {
      const allCompanies = companies.map((item) => item.company)
      setCompaniesOptions(allCompanies)
    }
  }, [companies])

  const onSubmit = () => {
    eventTrack('Clicou Continuar Convenio')
    if (plans.length === 0) {
      handleSubmit()
      history.push(CONVENIO_FEEDBACK, {
        companyName,
      } as ConvenioFeedbackParams)
    } else {
      history.push(CONVENIO_INFORMACOES, {
        plans,
        companyName,
        companyId: values.companyId
      } as ConvenioInformacoesParams)
    }
  }

  useEffect(() => {
    if (values.companyName !== '') {
      const company = companies.find(
        (item) => item.company === values.companyName
      )
      setCompanyName(values.companyName)
      onSelectHealthInsurance({
        companyName: values.companyName,
        hasPreviousInsurancePlan: !!(hasHealthInsurancePlan),
        isCovered: !!(company?.plans && company?.plans.length > 0)
      })
      if (company) {
        setFieldValue('companyId', company.companyId)
        setPlans(company.plans)
      }
    } else {
      setFieldValue('companyId', '')
    }
  }, [values.companyName])

  return (
    <AppMainContainerOff hasNewLayout hasBackButton>
      <div className="onboarding-container">
        <h3 className="text-textPrimary text-textMedium text-center mb-6">
          Qual é o seu plano de saúde?
        </h3>
        <div className="mb-4">
          <SelectInputSearch
            name="companyName"
            options={companiesOptions}
            placeholder="Selecione seu convênio"
            disabled={fetchCompaniesStatus.isLoading}
            searchInputPlaceholder="Digite seu convênio"
            extraOnBlur={() => eventTrack('Selecionou Convenio')}
          />
        </div>
        {hasOtherCompany && (
          <div className="mb-4">
            <FormInput
              label="Nome do plano de saúde"
              type="text"
              name="otherCompany"
              placeholder="Digite o nome do seu plano"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.otherCompany}
              id="otherCompany"
              errorMessage={touched.otherCompany ? errors.otherCompany : ''}
            />
          </div>
        )}
        <div className="mb-6">
          <RegularButton
            onClick={onSubmit}
            label="Continuar"
            isSubmitting={isSubmitting}
            disabled={disabledButton || isSubmitting}
          />
        </div>
      </div>
      <FaqToggle
        toggleTitle="Por que essa pergunta?"
        toggleDescription="Essa pergunta nos ajuda a saber quais de nossos especialistas estão cobertos pelo seu plano de saúde."
        iconType="icon-ChatHelpLight"
      />
    </AppMainContainerOff>
  )
}
const initialValues = {
  hasHealthInsurancePlan: true,
  companyName: '',
  planName: '',
  memberId: '',
  companyId: '',
  planId: '',
  otherCompany: ''
}

function HealthInsuranceType({
  companies,
  fetchCompaniesStatus,
  hasHealthInsurancePlan,
  onPostHealthInsurance,
  getHealthInsuranceCompanies,
  onSelectHealthInsurance,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({
        hasHealthInsurancePlan,
        companyName,
        planName,
        memberId,
        companyId,
        planId,
        otherCompany
      }) => {
        const company = otherCompany || companyName
        onPostHealthInsurance({
          hasHealthInsurancePlan,
          healthInsurancePlan: {
            company,
            planName,
            memberId,
            companyId,
            planId,
          }
        }, false)
      }}
    >
      {(props) => renderHealthInsuranceTypeForm(
        props,
        getHealthInsuranceCompanies,
        companies,
        onSelectHealthInsurance,
        fetchCompaniesStatus,
        hasHealthInsurancePlan
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  healthplan
}: AppState) => {
  const allCompanies = Object.values(healthplan.byCompanyName)
    .map((p) => p.company)
    .filter(notUndefined)
  return {
    fetchCompaniesStatus: healthplan.fetchCompaniesStatus,
    companies: allCompanies,
    hasHealthInsurancePlan: healthplan.healthInsurancePlanData?.hasHealthInsurancePlan,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onPostHealthInsurance: postUserHealthInsuranceAction,
  getHealthInsuranceCompanies: getHealthInsuranceCompaniesAction,
  onSelectHealthInsurance: onSelectHealthInsuranceAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthInsuranceType)
