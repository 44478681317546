import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  AccessibleModal,
  ContentCard,
  FontIcon,
  IconButton,
  Loader,
  Snackbar
} from 'theia-web-ds'
import { useHistory } from 'react-router-dom'
import Logo from '../../../assets/horizontal-logo.svg'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import {
  ActiveProfileType,
  AppProfileType,
  EventProfileType,
  ProfilesTypes
} from '../../domain/AppProfiles'
import ProfilePicture, { PicturePlaceholder } from './molecules/ProfilePicture'
import {
  getAppProfilesAction,
  promptChooseProfileModalAction,
  selectProfileAction,
  suppressChooseProfileModalAction
} from '../../state/appProfiles/actions'
import FaceIcon from '../../../assets/face-icon.png'
import BabyFace from '../../../assets/BabyFace.png'
import Plus from '../../../assets/Plus.png'
import { ADD_NEW_DEPENDENT, PROFILE } from '../../routes/RoutesConstants'
import './AppHeader.scss'
import { StatusType } from '../../domain/Status'
import { resetHightlightsStatusAction } from '../../state/userHighlights/actions'
import { formatHeaderProfileName } from '../../utils/helpers'
import { primary, textPrimary } from '../../color'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { EventCategories } from '../../utils/EventCategories'

interface Props {
  activeProfile?: ActiveProfileType
  chooseProfileModalIsVisible: boolean
  appProfiles?: AppProfileType[]
  getUserHighlightsStatus: StatusType
  getChildrenDataStatus: StatusType
  isDependent: boolean
  title?: string
  getAppProfilesStatus: StatusType
  getBookingsStatus: StatusType
  promptChooseProfileModal: () => void
  suppressChooseProfileModal: () => void
  selectProfile: (profile: AppProfileType) => void
  resetHightlightsStatus: () => void
  getAppProfiles: () => void
  onGoBack?: () => void
}

function AppHeader({
  activeProfile,
  chooseProfileModalIsVisible,
  appProfiles,
  getUserHighlightsStatus,
  getChildrenDataStatus,
  isDependent,
  title,
  getAppProfilesStatus,
  getBookingsStatus,
  onGoBack,
  promptChooseProfileModal,
  suppressChooseProfileModal,
  selectProfile,
  resetHightlightsStatus,
  getAppProfiles
}: Props) {
  const history = useHistory()
  const [isMountingProfile, setIsMountingProfile] = useState(false)
  const dependents = appProfiles?.filter((profile) => profile.type === ProfilesTypes.CHILD) || []

  const handleClickProfileBtn = () => {
    promptChooseProfileModal()
  }

  const goToProfile = () => {
    suppressChooseProfileModal()
    history.push(PROFILE)
  }

  const handleSelectProfile = (profile: AppProfileType) => {
    eventTrack('trocou de perfil', {
      tipo_de_perfil: EventProfileType[profile.type],
      category: EventCategories.PROFILE_SEPARATION
    })
    setIsMountingProfile(true)
    selectProfile(profile)
  }

  const handleAddProfile = () => {
    eventTrack('clicou adicionar dependente', {
      category: EventCategories.PROFILE_SEPARATION,
      dependentes: !!(dependents.length > 0),
      source: 'modal'
    })
    suppressChooseProfileModal()
    history.push(ADD_NEW_DEPENDENT)
  }

  useEffect(() => {
    if (chooseProfileModalIsVisible) {
      if (appProfiles && getAppProfilesStatus.success) {
        eventPage(EventCategories.PROFILE_SEPARATION, 'modal troca de perfil', {
          dependentes: !!(dependents.length > 0),
          category: EventCategories.PROFILE_SEPARATION
        })
        return
      }
      if (getAppProfilesStatus.error) {
        eventPage(EventCategories.PROFILE_SEPARATION, 'modal troca de perfil', {
          dependentes: undefined,
          category: EventCategories.PROFILE_SEPARATION
        })
        eventTrack('erro ao carregar perfis', {
          category: EventCategories.PROFILE_SEPARATION,
          erro: JSON.stringify(getAppProfilesStatus.errorMessage),
          localizacao: 'modal'
        })
      }
    }
  }, [appProfiles, getAppProfilesStatus, chooseProfileModalIsVisible])

  useEffect(() => {
    if (!getUserHighlightsStatus.isLoading
      && !getChildrenDataStatus.isLoading
      && !getBookingsStatus.isLoading) {
      setIsMountingProfile(false)
    } else {
      setIsMountingProfile(true)
    }
  }, [
    getUserHighlightsStatus.isLoading,
    getChildrenDataStatus.isLoading,
    getBookingsStatus.isLoading
  ])

  useEffect(() => {
    if (!isMountingProfile && activeProfile?.id) {
      suppressChooseProfileModal()
      resetHightlightsStatus()
    }
  }, [isMountingProfile, activeProfile?.id])

  useEffect(() => {
    if (chooseProfileModalIsVisible && !appProfiles?.length) getAppProfiles()
  }, [chooseProfileModalIsVisible])

  return (
    <div className="app-header">
      {onGoBack && (
        <IconButton
          iconType="icon-ArrowLeft2Light"
          variant="form-icon"
          iconSize="24px"
          iconColor={textPrimary}
          className="goBack-btn"
          onClick={onGoBack}
        />
      )}
      {title ? (
        <h3 className="app-header__title">
          {title}
        </h3>
      ) : (
        <Logo className="app-header-logo" alt="" />
      )}
      <button
        tabIndex={0}
        className="select-profile-btn"
        onClick={handleClickProfileBtn}
        type="button"
        disabled={isMountingProfile}
      >
        <p className="active-profile-name">
          {formatHeaderProfileName(activeProfile?.name)}
        </p>
        <ProfilePicture
          pictureUrl={activeProfile?.imageUrl}
          pictureSize={40}
          picturePlaceholder={isDependent
            ? PicturePlaceholder.BABY
            : PicturePlaceholder.USER}
        />
        <FontIcon iconType="icon-ArrowDown2Light" fontSize="14px" />
      </button>
      <AccessibleModal
        headerText="profiles"
        visible={chooseProfileModalIsVisible}
        onClose={suppressChooseProfileModal}
        variant="centerBottom"
        extraModalContainerClass="choose-profile-modal"
        showCloseButton
        titleText="Trocar perfil"
      >
        <div className="choose-profile-modal-content w-full">
          {getAppProfilesStatus.isLoading ? (
            <div className="flex items-center justify-center p-10">
              <Loader />
            </div>
          ) : (
            <>
              {(isMountingProfile) && (
                <div className="choose-profile-modal-loader">
                  <Loader size="74px" />
                </div>
              )}
              <div className="profiles-list-container">
                <div className={`profiles-list ${isMountingProfile ? 'loading' : ''}`}>
                  {getAppProfilesStatus.error ? (
                    <Snackbar
                      title="Erro ao carregar perfis"
                      text="Desculpe, houve um erro ao carregar os perfis, por favor tente novamente."
                      buttonTwoProps={{
                        label: 'Tentar novamente',
                        onClick: getAppProfiles
                      }}
                      iconLeft="icon-DangerTriangleLight"
                      customIconColor={primary}
                    />
                  ) : (
                    <>
                      {appProfiles && appProfiles.map((profile) => (
                        <ContentCard
                          type={profile.id === activeProfile?.id ? 'filled' : 'outlined'}
                          headerTitle={profile.name}
                          key={profile.id}
                          headerSubtitle={profile.type === ProfilesTypes.CHILD ? 'Dependente' : 'Titular'}
                          chipProps={profile.id === activeProfile?.id ? {
                            label: 'Ver perfil',
                            onClick: goToProfile,
                            extraClass: 'chip-profile-card',
                            variant: 'primary'
                          } : undefined}
                          avatarBefore={{
                            src: profile.imageUrl || (
                              profile.type === ProfilesTypes.CHILD ? BabyFace : FaceIcon),
                            height: '40px',
                            width: '40px'
                          }}
                          extraClass="mb-2"
                          onClick={() => handleSelectProfile(profile)}
                        />
                      ))}
                      <ContentCard
                        type="outlined"
                        headerTitle="Adicionar dependente"
                        key="add-profile-card"
                        avatarBefore={{
                          src: Plus,
                          height: '40px',
                          width: '40px'
                        }}
                        onClick={handleAddProfile}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </AccessibleModal>
    </div>
  )
}

const mapStateToProps = ({
  appProfiles, userHighlights, pediatricFlow, myBookingsFlow
}: AppState) => ({
  activeProfile: appProfiles.activeProfile,
  chooseProfileModalIsVisible: appProfiles.chooseProfileModalIsVisible,
  appProfiles: appProfiles.appProfiles,
  getUserHighlightsStatus: userHighlights.getUserHighlightsStatus,
  getChildrenDataStatus: pediatricFlow.getChildrenDataStatus,
  isDependent: appProfiles.isDependent,
  getAppProfilesStatus: appProfiles.getAppProfilesStatus,
  getBookingsStatus: myBookingsFlow.getBookingsStatus
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  promptChooseProfileModal: promptChooseProfileModalAction,
  suppressChooseProfileModal: suppressChooseProfileModalAction,
  selectProfile: selectProfileAction,
  resetHightlightsStatus: resetHightlightsStatusAction,
  getAppProfiles: getAppProfilesAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader)
