/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, FormikErrors } from 'formik'
import { Loader, RegularButton, FormInput } from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import * as Yup from 'yup'
import './SubmitDocuments.scss'
import {
  cpfMask,
  cpfValidation,
  dateValidationRegex,
  epochToString,
  formatHeaderProfileName,
  isNotFutureDate,
  isValidElement,
  onlyDigits,
} from '../../utils/helpers'
import { updateProfileAction } from '../../state/profile/actions'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { eventPage, eventTrack } from '../../../eventGenerate'
import InputGroup from '../common/molecules/inputGroup'
import { BabyData } from '../../domain/PediatricFlow'
import { InfosToUpdateProfile } from '../../domain/Profile'
import { ActiveProfileType, EventProfileType } from '../../domain/AppProfiles'
import { StatusType } from '../../domain/Status'
import { updateChildrenAction } from '../../state/pediatricFlow/actions'
import { EventCategories } from '../../utils/EventCategories'

interface Props {
  updateProfile: (profileInfos: InfosToUpdateProfile, showNotification: boolean) => void;
  onUpdateDocuments?: () => void;
  updateChildren: (babiesData: BabyData[]) => void;
  document?: string;
  name?: string;
  email?: string;
  phone?: string;
  birthDate?: string;
  nationalIdentityCard?: string;
  isUpdatingProfile: boolean;
  isFetchingProfile: boolean;
  activeProfile?: ActiveProfileType;
  childrenData?: BabyData[];
  successUpdateProfileInfos: boolean;
  getChildrenDataStatus: StatusType;
  updateChildrenStatus: StatusType;
  isDependent: boolean
  profileType?: EventProfileType
}

interface DocumentFormValues {
  document: string;
  birthDate: string;
  nationalIdentityCard: string;
  childDocument: string;
}

function SubmitDocuments({
  updateProfile,
  updateChildren,
  onUpdateDocuments,
  document,
  name,
  email,
  phone,
  birthDate,
  nationalIdentityCard,
  isUpdatingProfile,
  isFetchingProfile,
  activeProfile,
  childrenData,
  successUpdateProfileInfos,
  getChildrenDataStatus,
  updateChildrenStatus,
  isDependent,
  profileType
}: Props) {
  const [profileUpdated, setProfileUpdated] = useState(false)
  const [childUpdated, setChildUpdated] = useState(false)
  const [childDocumentDigits, setChildDocumentDigits] = useState<string>()

  const initialValues = {
    document: document || '',
    birthDate: epochToString(Number(birthDate)) || '',
    nationalIdentityCard: nationalIdentityCard || '',
    childDocument: activeProfile?.document || ''
  }

  const showChildDocumentInput = isDependent && !isValidElement(activeProfile?.document)
  const hasDocument = isValidElement(document)
  const hasBirthDate = isValidElement(birthDate)
  const hasRG = isValidElement(nationalIdentityCard)
  const birthDateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

  useEffect(() => {
    eventPage('checkout', 'Inserir CPF, DN e RG', { tipo_de_perfil: profileType })
  }, [])

  const childrenDocuments = childrenData?.map((child) => onlyDigits(child.cpf))
    .filter((cpf) => cpf !== undefined && cpf !== null && cpf !== '') || []

  function canSendInfos(
    values: DocumentFormValues,
    errors: FormikErrors<DocumentFormValues>
  ) {
    let canSendBabyInfos = true
    if (showChildDocumentInput) {
      canSendBabyInfos = !!(values.childDocument && !errors.childDocument)
    }
    return values.document
      && !errors.document
      && values.birthDate
      && !errors.birthDate
      && values.nationalIdentityCard
      && !errors.nationalIdentityCard
      && canSendBabyInfos
  }

  async function saveUserData(
    documentDigits: string,
    formatedBirthDate: number,
    rg: string
  ) {
    if (name && email && phone && (
      (!document && documentDigits)
      || (!birthDate && formatedBirthDate)
      || (!nationalIdentityCard && rg)
    )) {
      updateProfile({
        name,
        email,
        phone,
        birthDate: formatedBirthDate.toString(),
        document: documentDigits,
        nationalIdentityCard: rg
      }, true)
    } else {
      setProfileUpdated(true)
    }
  }

  async function saveBabyDocument() {
    if (showChildDocumentInput) {
      updateChildren([{
        id: activeProfile?.id,
        cpf: childDocumentDigits
      }])
    } else {
      setChildUpdated(true)
    }
  }

  function saveData(values: DocumentFormValues) {
    const documentDigits = onlyDigits(values.document)
    const rg = values.nationalIdentityCard
    const formatedBirthDate = moment(values.birthDate, 'DD/MM/YYYY').valueOf()
    setChildDocumentDigits(onlyDigits(values?.childDocument))
    saveUserData(documentDigits, formatedBirthDate, rg)
  }

  useEffect(() => {
    if (profileUpdated || successUpdateProfileInfos) {
      saveBabyDocument()
    }
  }, [profileUpdated, successUpdateProfileInfos])

  useEffect(() => {
    if ((profileUpdated || successUpdateProfileInfos)
      && (childUpdated || updateChildrenStatus.success)
      && onUpdateDocuments
    ) {
      onUpdateDocuments()
    }
  }, [
    profileUpdated,
    successUpdateProfileInfos,
    childUpdated,
    updateChildrenStatus.success
  ])

  const validationSchema = Yup.object().shape({
    document: !hasDocument ? Yup.string()
      .required('CPF obrigatório')
      .transform((value) => value.replace(/[^\d]/g, ''))
      .test(
        'test-document',
        'CPF inválido',
        (value) => cpfValidation(value)
      )
      .test(
        'test-repeat-document',
        'CPF já cadastrado. Por favor, verifique se o CPF inserido é o seu.',
        (value) => !childrenDocuments.includes(onlyDigits(value))
      ) : Yup.string().notRequired(),
    birthDate: Yup.string()
      .required('Data de nascimento é obrigatório')
      .matches(dateValidationRegex, 'Data inválida')
      .test(
        'test-birthDate',
        'Data de nascimento não pode ser uma data futura',
        (value) => isNotFutureDate(value)
      ),
    nationalIdentityCard: Yup.string()
      .required('RG é obrigatorio'),
    childDocument: showChildDocumentInput ? Yup.string()
      .required('CPF do bebê é obrigatório')
      .test(
        'test-document',
        'CPF inválido',
        (value) => cpfValidation(value)
      )
      .transform((value: any) => value.replace(/[^\d]/g, ''))
      .test(
        'test-document',
        `CPF já cadastrado. Por favor, verifique se o CPF inserido é de ${formatHeaderProfileName(activeProfile?.name)}`,
        (value) => !childrenDocuments?.includes(onlyDigits(value))
      )
      .when(['document'], {
        is: (doc) => doc !== '' && doc !== undefined && doc !== null,
        then: Yup.string()
          .notOneOf(
            [Yup.ref('document')],
            'Coloque um CPF diferente do seu'
          )
      })
      : Yup.string().notRequired()
  })

  if (isFetchingProfile
    || isUpdatingProfile
    || getChildrenDataStatus.isLoading
    || updateChildrenStatus.isLoading
  ) {
    return (
      <div className="submit-documents--loader">
        <Loader />
      </div>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: DocumentFormValues) => saveData(values)}
    >
      {({
        handleSubmit, values, errors, handleChange, setFieldError, handleBlur, touched
      }) => (
        <div className="submit-documents--form input-group">
          <h1 className="submit-documents--title">
            Precisamos de algumas informações para continuar
          </h1>
          {!hasDocument && (
            <InputGroup
              name="document"
              type="text"
              mask={cpfMask}
              placeholder="000.000.000-00"
              length={11}
              value={values.document}
              label="Qual o seu CPF?"
              error={errors.document}
              clearError={() => setFieldError('document', '')}
              handleOnChange={(value) => {
                handleChange('document')(value)
                eventTrack('Digitou CPF')
              }}
            />
          )}
          {!hasBirthDate && (
            <InputGroup
              name="birthDate"
              type="text"
              mask={birthDateMask}
              placeholder="00/00/0000"
              length={8}
              value={values.birthDate.toString()}
              label="Qual a sua data de nascimento?"
              error={errors.birthDate}
              clearError={() => setFieldError('birthDate', '')}
              handleOnChange={(value) => {
                handleChange('birthDate')(value)
                eventTrack('Digitou data nascimento')
              }}
            />
          )}
          {!hasRG && (
            <FormInput
              label="Qual o seu RG?"
              id="nationalIdentityCard"
              value={values.nationalIdentityCard}
              onChange={handleChange}
              type="text"
              name="nationalIdentityCard"
              extraClassContainer="mt-8"
              onBlur={(value) => {
                handleBlur(value)
                eventTrack('Digitou rg')
              }}
              errorMessage={touched.nationalIdentityCard ? errors.nationalIdentityCard : ''}
              placeholder="Digite o RG"
            />
          )}
          {showChildDocumentInput && (
            <InputGroup
              name="childDocument"
              type="text"
              mask={cpfMask}
              placeholder="Digite o CPF"
              length={11}
              value={values.childDocument}
              label={`Qual o CPF de ${activeProfile?.name}?`}
              error={errors.childDocument}
              clearError={() => setFieldError('childDocument', '')}
              handleOnChange={(value) => {
                handleChange('childDocument')(value)
                eventTrack('Digitou CPF Criança', { category: EventCategories.PEDIATRIC_SCHEDULE })
              }}
            />
          )}
          <div className="submit-documents--button">
            <RegularButton
              type="submit"
              onClick={() => handleSubmit()}
              label="Continuar"
              disabled={!canSendInfos(values, errors)}
              isSubmitting={isUpdatingProfile || updateChildrenStatus.isLoading}
              maxWidth="100%"
              height="49px"
              extraClass="submit-btn"
            />
          </div>
        </div>
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  authentication,
  profile,
  pediatricFlow,
  appProfiles
}: AppState) => ({
  document: authentication.currentUser?.document,
  name: authentication.currentUser?.name,
  email: authentication.currentUser?.email,
  phone: authentication.currentUser?.phone,
  nationalIdentityCard: authentication.currentUser?.nationalIdentityCard,
  birthDate: authentication.currentUser?.birthDate || profile.profile?.birthDate,
  isFetchingProfile: profile.isFetchingProfile,
  isUpdatingProfile: profile.isUpdatingProfile,
  getChildrenDataStatus: pediatricFlow.getChildrenDataStatus,
  updateChildrenStatus: pediatricFlow.updateChildrenStatus,
  childrenData: pediatricFlow.childrenData,
  successUpdateProfileInfos: profile.successUpdateProfileInfos,
  activeProfile: appProfiles.activeProfile,
  isDependent: appProfiles.isDependent,
  profileType: appProfiles.profileType
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  updateProfile: updateProfileAction,
  updateChildren: updateChildrenAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitDocuments)
