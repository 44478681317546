import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Lottie from 'react-lottie'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Loader, RegularButton } from 'theia-web-ds'
import positiveFeedback from '../../animations/positive_feedback.json'
import { success } from '../../color'
import './PositiveHealthInsurance.scss'
import IconWhatsapp from '../../../assets/icon-whatsapp.png'
import { AppState } from '../../apps/main/store'
import { closeModalAction } from '../../state/onboarding/actions'
import { AppDispatch } from '../../state/utils'
import { HealthPlanFaqAndBenefits } from '../profile/healthplan/HealthplanFaqAndBenefits'
import { eventPage, eventTrack, identifyUser } from '../../../eventGenerate'
import { User } from '../../domain/User'
import { AGENDAR, INICIO } from '../../routes/RoutesConstants'
import { HealthInsurancePlanData } from '../../domain/Healthplan'
import { StatusType } from '../../domain/Status'
import { EventProfileType } from '../../domain/AppProfiles'

interface Props {
  currentUser?: User;
  healthInsurancePlanData: HealthInsurancePlanData | null;
  getHealthInsurancePlanStatus: StatusType;
  profileType?: EventProfileType
  closeModal: () => void;
}

function PositiveHealthInsurance({
  currentUser,
  healthInsurancePlanData,
  getHealthInsurancePlanStatus,
  profileType,
  closeModal,
}: Props) {
  const history = useHistory()
  const benefitsSubtitle = healthInsurancePlanData?.benefitsSubtitle
  const benefitsTitle = healthInsurancePlanData?.benefitsTitle
  const benefits = healthInsurancePlanData?.benefits
  const lottiePositiveFeedback = {
    loop: false,
    autoplay: true,
    animationData: positiveFeedback,
  }

  useEffect(() => {
    eventPage('Incluso no Plano', 'Incluso no Plano', { tipo_de_perfil: profileType })
  }, [])

  function goToPage(link: string) {
    eventTrack('Clicou Falar com a gente')
    window.open(link, '_blank')
  }

  function goTo(link: string) {
    if (link === AGENDAR) {
      eventTrack('Clicou Agendar Agora')
    } else {
      eventTrack('Clicou Agendar Depois')
    }
    history.push(link)
    closeModal()
  }

  const infosToRudder = {
    id: currentUser?.id,
    email: currentUser?.email,
    healthInsurance: healthInsurancePlanData?.healthInsurancePlan?.company || undefined,
    hasHealthInsurancePlan: healthInsurancePlanData?.hasHealthInsurancePlan || undefined,
    moment: currentUser?.pregnancyMoment,
    cityName: currentUser?.address.city
  }
  identifyUser(infosToRudder)

  if (healthInsurancePlanData && !getHealthInsurancePlanStatus.isLoading) {
    return (
      <div className="flex flex-col items-center health-plan-check mt-4">
        <div className="animation-up">
          <Lottie
            options={lottiePositiveFeedback}
            isClickToPauseDisabled
            ariaRole=""
            height={210}
            width={210}
          />
        </div>
        <div className="show-content">
          <div className="mx-4">
            <p className="text-textMedium text-center font-normal justify-center">
              {benefitsTitle || 'Legal! A gente aceita seu plano'}
            </p>
            <p className="text-fontDefault text-textSecondary text-center mt-4">
              {benefitsSubtitle || 'Você pode agendar consultas com as nossas ginecologistas-obstetras sem custo adicional.'}
            </p>
            <RegularButton
              label="Agendar agora"
              extraClass="mt-6 mx-auto button-healthplan-feedback"
              onClick={() => goTo(AGENDAR)}
            />
          </div>
          <div className="mt-10 w-full">
            <p className="text-fontDefault font-medium mb-4 text-left mx-4">
              Está incluso no seu plano
            </p>
            <div className="accordion-wrapper">
              <HealthPlanFaqAndBenefits
                benefits={benefits}
                iconColor={success}
                iconType="icon-TickRoundLight"
                closeModal={closeModal}
                screen="convenio-feedback"
              />
              <div className="mx-4">
                <img src={IconWhatsapp} alt="Whatsapp" className="mb-4 mt-6 mx-auto rounded-full" />
                <p className="text-textMedium text-center font-normal justify-center mt-4 mb-2">
                  Ainda em dúvida?
                </p>
                <p className="text-fontDefault text-textSecondary text-center">
                  Nosso time está pronto para te atender <span role="img" aria-label="carinha mandando um abraço">🤗</span>
                </p>
                <RegularButton
                  onClick={() => goToPage('https://atendimento.theia.com.br/whatsapp')}
                  variant="subtle"
                  label="Falar com a gente"
                  extraClass="my-6 button-healthplan-feedback mx-auto"
                />
                <RegularButton
                  onClick={() => goTo(INICIO)}
                  variant="text"
                  label="Agendar depois"
                  extraClass="mb-6 button-healthplan-feedback mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-1 relative pt-20">
      <div className="mx-auto w-full flex items-center justify-center">
        <Loader />
      </div>
    </div>
  )
}

const mapStateToProps = ({
  authentication, healthplan, appProfiles
}: AppState) => ({
  currentUser: authentication.currentUser,
  healthInsurancePlanData: healthplan.healthInsurancePlanData,
  getHealthInsurancePlanStatus: healthplan.getHealthInsurancePlanStatus,
  profileType: appProfiles.profileType
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  closeModal: closeModalAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PositiveHealthInsurance)
