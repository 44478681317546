/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  ListItemCard,
  BadgeNotification,
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { error, primary } from '../../color'
import {
  getCosultantMomentOptionLabel,
} from '../../utils/helpers'
import { fetchProfileAction } from '../../state/profile/actions'
import HealthStatus from '../../../assets/health-status.png'
import Exams from '../../../assets/exams.png'
import Drawer from '../common/drawer/Drawer'
import { promptDrawerAction, suppressDrawerAction } from '../../state/drawer/actions'
import MomentDataEdit from './MomentDataEdit'
import { getPrescriptionsInformationAction } from '../../state/prescriptions/actions'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { ConsultantMoment } from '../../domain/Profile'
import './HealthDataView.scss'
import { PRESCRIPTIONS_AND_EXAMS } from '../../routes/RoutesConstants'
import { onSendHighlightAction } from '../../state/userHighlights/actions'
import { StatusType } from '../../domain/Status'
import AppHeader from '../common/AppHeader'
import ProfilePictureEdit from '../common/molecules/ProfilePictureEdit'
import { EventProfileType } from '../../domain/AppProfiles'

interface Props {
  isFetchingProfile: boolean;
  hasProfile: boolean;
  pregnancyMomentText?: string;
  sawExamsList: boolean;
  pregnancyMoment?: ConsultantMoment;
  unseenMedicalRequests?: number;
  getPrescriptionsStatus: StatusType;
  showNewPrescriptionBadge?: boolean;
  isDependent: boolean
  getUserHighlightsStatus: StatusType
  getChildrenDataStatus: StatusType
  profileType?: EventProfileType
  fetchProfile: () => void;
  promptDrawer: () => void;
  suppressDrawer: () => void;
  getPrescriptionsInformation: () => void;
  onSendHightlight: (hightlight: string) => void;
}

function HealthDataView({
  isFetchingProfile,
  hasProfile,
  pregnancyMomentText,
  sawExamsList,
  pregnancyMoment,
  unseenMedicalRequests,
  getPrescriptionsStatus,
  showNewPrescriptionBadge,
  isDependent,
  getUserHighlightsStatus,
  getChildrenDataStatus,
  profileType,
  fetchProfile,
  promptDrawer,
  suppressDrawer,
  getPrescriptionsInformation,
  onSendHightlight,
}: Props) {
  const [medicalRequestsMsg, setMedicalRequestsMsg] = useState('')
  const [showMomentDrawer, setShowMomentDrawer] = useState(false)
  const hasNewPrescription = !!(unseenMedicalRequests && unseenMedicalRequests > 0)
  const isMountingProfile = getUserHighlightsStatus.isLoading
    || getChildrenDataStatus.isLoading || isFetchingProfile

  useEffect(() => {
    if (showNewPrescriptionBadge) {
      onSendHightlight('highlight-health-menu-item')
    }
  }, [showNewPrescriptionBadge])

  useEffect(() => {
    if (unseenMedicalRequests === 0 || unseenMedicalRequests === undefined) {
      setMedicalRequestsMsg('')
    } else if (unseenMedicalRequests === 1) {
      setMedicalRequestsMsg(`Você tem ${unseenMedicalRequests} novo pedido`)
    } else {
      setMedicalRequestsMsg(`Você tem ${unseenMedicalRequests} novos pedidos`)
    }
  }, [unseenMedicalRequests])

  const history = useHistory()

  useEffect(() => {
    getPrescriptionsInformation()
    eventPage('saude', 'Saúde', { tipo_de_perfil: profileType })
  }, [])

  useEffect(() => {
    if (!hasProfile && !isFetchingProfile && !isDependent) {
      fetchProfile()
    }
  }, [])

  function onShowMomentEditModal() {
    promptDrawer()
    setShowMomentDrawer(true)
    eventTrack('Clicou Status Atual', {
      status_atual: getCosultantMomentOptionLabel(pregnancyMoment)
    })
  }

  function onCancel() {
    suppressDrawer()
    setShowMomentDrawer(false)
  }

  const goToMedicalRequests = () => {
    history.push(PRESCRIPTIONS_AND_EXAMS)
  }

  return (
    <div className="health-view">
      <AppHeader title="Saúde" />
      <div className="health-view-grid-container">
        <div className="health-view-item-a">
          <ListItemCard
            extraClassNameContainer="health-card relative first-card"
            onClick={goToMedicalRequests}
            picture={(
              <img
                src={Exams}
                width={40}
                height={40}
                className="health-icon"
                alt=""
              />
            )}
            iconColor={primary}
            caption={medicalRequestsMsg}
            title="Pedidos e exames"
            ariaLabel="Pedidos e exames"
            alertMessage=""
            isLastItem
            isLoading={getPrescriptionsStatus.isLoading}
            isItemFromList={false}
            key="prescriptions-and-exams-health"
          >
            {(hasNewPrescription && !getPrescriptionsStatus.isLoading) && (
              <BadgeNotification
                color={error}
                width={20}
                height={20}
                borderRadius="50%"
                extraClass="absolute new-prescriptions-badge"
              >
                <span className="number-inside-badge-container">{unseenMedicalRequests}</span>
              </BadgeNotification>
            )}
            {!hasNewPrescription && !sawExamsList && (
              <BadgeNotification
                color={error}
                width={10}
                height={10}
                borderRadius="50%"
                extraClass="absolute exams-badge"
              />
            )}
          </ListItemCard>
          {!isDependent && (
            <ListItemCard
              extraClassNameContainer="health-card"
              onClick={onShowMomentEditModal}
              showCaptionFirst
              picture={(
                <img
                  src={HealthStatus}
                  width={40}
                  height={40}
                  className="health-icon"
                  alt=""
                />
              )}
              iconColor={primary}
              caption={pregnancyMomentText ? 'Momento atual' : undefined}
              title={pregnancyMomentText || 'Momento atual'}
              ariaLabel="Momento atual"
              alertMessage={!pregnancyMomentText ? 'Completar dados' : undefined}
              isLastItem
              isLoading={isMountingProfile}
              isItemFromList={false}
              key="status-moment-health"
            />
          )}
        </div>
        <div className="health-view-item-b">
          <ProfilePictureEdit />
        </div>
      </div>
      {showMomentDrawer && (
        <Drawer
          variant="rightDrawer"
          id="health-data-modal"
          text="Status atual"
        >
          <MomentDataEdit onGoBack={onCancel} />
        </Drawer>
      )}
    </div>
  )
}

const mapStateToProps = ({
  profile, prescriptions, userHighlights, appProfiles, pediatricFlow
}: AppState) => ({
  isFetchingProfile: profile.isFetchingProfile,
  hasProfile: !!profile.profile,
  pregnancyMomentText: profile.profile?.pregnancyMomentText,
  pregnancyMoment: profile.profile?.pregnancyMoment,
  sawExamsList: userHighlights.sawExamsList,
  unseenMedicalRequests: prescriptions.unseenMedicalRequests,
  getPrescriptionsStatus: prescriptions.getPrescriptionsStatus,
  showNewPrescriptionBadge: userHighlights.highlightHealthMenuItem,
  isDependent: appProfiles.isDependent,
  getUserHighlightsStatus: userHighlights.getUserHighlightsStatus,
  getChildrenDataStatus: pediatricFlow.getChildrenDataStatus,
  profileType: appProfiles.profileType
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  fetchProfile: fetchProfileAction,
  promptDrawer: promptDrawerAction,
  suppressDrawer: suppressDrawerAction,
  getPrescriptionsInformation: getPrescriptionsInformationAction,
  onSendHightlight: onSendHighlightAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthDataView)
