import React from 'react'
import { ToolTip } from 'theia-web-ds'
import './SpecialistChip.scss'
import ProfilePicture, { PicturePlaceholder } from './ProfilePicture'
import { bgBox, success } from '../../../color'

interface Props {
  professionalPictureUrl?: string;
  professionalName?: string;
  tag?: string;
  caption?: string;
  professionalValue?: number;
  showFirstCaption?: boolean;
  onClickSpecialistChip?: () => void;
}

export default function SpecialistChip({
  professionalPictureUrl,
  tag,
  professionalName,
  caption,
  professionalValue,
  showFirstCaption = false,
  onClickSpecialistChip
}: Props) {
  const isClickable = !!(onClickSpecialistChip)

  const tooltip = (
    <ToolTip
      label="Incluso"
      backgroundColor={success}
      borderRadius="16px"
      textColor={bgBox}
    />
  )

  const finalPrice = professionalValue === 0
    ? tooltip
    : `R$ ${professionalValue}`

  const cardContent = (
    <>
      <ProfilePicture
        pictureUrl={professionalPictureUrl}
        pictureSize={64}
        className="specialist-chip-picture"
        picturePlaceholder={PicturePlaceholder.SPECIALIST}
      />
      <div className="specialist-chip-professional-info">
        <div className="specialist-chip-right-container-header">
          {showFirstCaption ? (
            <p className="specialist-chip-professional-caption">
              {caption}
            </p>
          ) : (
            <>
              <p className="specialist-chip-professional-tag">
                {tag}
              </p>
              {professionalValue !== undefined && (
                <div className="specialist-chip-professional-value">
                  {finalPrice}
                </div>
              )}
            </>
          )}
        </div>
        <p className="specialist-chip-professional-name">
          {professionalName}
        </p>
        {caption && !showFirstCaption && (
          <p className="specialist-chip-professional-caption extra-caption-style">
            {caption}
          </p>
        )}
      </div>
    </>
  )

  return isClickable ? (
    <button
      className="specialist-chip-container button"
      onClick={onClickSpecialistChip}
      type="button"
    >
      {cardContent}
    </button>
  ) : (
    <div className="specialist-chip-container">
      {cardContent}
    </div>
  )
}
