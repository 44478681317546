import {
  ProfileForm,
} from '../../domain/Profile'
import {
  ProfileTypes,
  SUCCESS_UPDATE_PROFILE,
  FETCH_PROFILE_REQUESTED,
  SUCCESS_FETCH_PROFILE,
  FAILURE_FETCH_PROFILE,
  UPDATE_PROFILE_REQUESTED,
  FAILURE_UPDATE_PROFILE,
  CLOSE_SUCCESS_UPDATE_MODAL,
  SUCCESS_UPLOAD_PICTURE_PROFILE,
  UPLOAD_PICTURE_PROFILE_REQUESTED,
  FAILURE_UPLOAD_PICTURE_PROFILE,
  FORCE_FETCH_PROFILE,
  SUCCESS_UPDATE_HEALTH_INFOS_IN_PROFILE,
  SUCCESS_UPDATE_GESTATIONAL_INFOS_IN_PROFILE,
  SUCCESS_UPDATE_PERSONAL_INFOS_IN_PROFILE,
  SUCCESS_UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE,
  SUCCESS_UPDATE_HEALTH_WEIGHT_DATA_IN_PROFILE,
  SUCCESS_UPDATE_HEALTH_ROUTINE_AND_FOOD_DATA_IN_PROFILE,
  SUCCESS_UPDATE_HEALTH_MEDICINE_DATA_IN_PROFILE,
  SUCCESS_UPDATE_HEALTH_MOMENT_DATA_IN_PROFILE,
  SUCCESS_UPDATE_HEALTH_HISTORY_DATA_IN_PROFILE,
  UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE_REQUESTED,
  UPDATE_HEALTH_WEIGHT_IN_PROFILE_REQUESTED,
  UPDATE_HEALTH_ROUTINE_AND_FOOD_IN_PROFILE_REQUESTED,
  UPDATE_HEALTH_MEDICATION_IN_PROFILE_REQUESTED,
  UPDATE_HEALTH_MOMENT_IN_PROFILE_REQUESTED,
  UPDATE_HEALTH_HISTORY_IN_PROFILE_REQUESTED,
  SUCCESS_DELETE_PICTURE_PROFILE,
  DELETE_PICTURE_PROFILE_REQUESTED,
  FAILURE_DELETE_PICTURE_PROFILE,
  SUCCESS_UPDATE_ADDRESS_PROFILE,
  UPDATE_ADDRESS_PROFILE_REQUESTED,
  BACK_TO_PROFILE_FROM_EDIT_ADDRESS_PROFILE,
  SUCCESS_UPDATE_PASSWORD,
  UPDATE_PASSWORD_REQUESTED,
  FAILURE_UPDATE_PASSWORD,
  CONFIRM_EDIT_PROFILE,
  FAILURE_UPDATE_ADDRESS_PROFILE,
  FINISH_UPLOAD_PICTURE_PROFILE,
  FINISH_DELETE_PICTURE_PROFILE,
  UPDATE_PROFILE_INFOS,
  SUCCESS_UPDATE_PROFILE_INFOS,
  FAILURE_UPDATE_PROFILE_INFOS,
  VALIDATE_INFORMATION,
  SUCCESS_VALIDATE_INFORMATION,
  FAILURE_VALIDATE_INFORMATION,
  CLEAR_VALIDATE_INFORMATION_ERRORS,
  PROMPT_VALIDATE_EMAIL_MODAL,
  SUPPRESS_VALIDATE_EMAIL_MODAL,
  CLEAR_VALIDATE_PHONE_ERRORS,
  PROMPT_VALIDATE_PHONE_MODAL,
  SUPPRESS_VALIDATE_PHONE_MODAL,
  CLEAR_UPDATE_PROFILE_STATUS
} from './actions'
import { StatusType } from '../../domain/Status'

export interface PictureChildType { number: File }

export interface ProfileState {
  profile?: ProfileForm;
  isFetchingProfile: boolean;
  isUpdatingProfile: boolean;
  isUploadingPictureProfile: boolean;
  errorUploadingPictureProfile: boolean;
  errorDeletingPictureProfile: boolean;
  hasNoName?: boolean;
  forceFetchProfile: boolean;
  pregnancyMomentUpdateInfoText: string;
  isProfilePictureDeleted: boolean;
  isDeletingPictureProfile: boolean;
  isAddressUpdated: boolean;
  isPasswordUpdated: boolean;
  isProfileUpdated: boolean;
  isPictureProfileUploaded: boolean;
  successUpdateProfileInfos: boolean;
  validateInformationStatus: StatusType;
  isFromProfileEdit: boolean;
  showValidateEmailModal: boolean;
  showValidatePhoneModal: boolean;
}

export const initialProfileState: ProfileState = {
  profile: undefined,
  isFetchingProfile: false,
  isUpdatingProfile: false,
  isUploadingPictureProfile: false,
  errorUploadingPictureProfile: false,
  errorDeletingPictureProfile: false,
  hasNoName: false,
  forceFetchProfile: false,
  pregnancyMomentUpdateInfoText: '',
  isProfilePictureDeleted: false,
  isDeletingPictureProfile: false,
  isAddressUpdated: false,
  isPasswordUpdated: false,
  isProfileUpdated: false,
  isPictureProfileUploaded: false,
  successUpdateProfileInfos: false,
  validateInformationStatus: {
    isLoading: false,
    success: false,
    error: false,
    errorMessage: undefined
  },
  isFromProfileEdit: false,
  showValidateEmailModal: false,
  showValidatePhoneModal: false
}

export function profile(
  state = initialProfileState,
  action: ProfileTypes
): ProfileState {
  switch (action.type) {
    case FETCH_PROFILE_REQUESTED:
      return {
        ...state,
        isFetchingProfile: true,
        forceFetchProfile: false,
      }
    case SUCCESS_FETCH_PROFILE:
      return {
        ...state,
        isFetchingProfile: false,
        profile: action.profile.profile,
        forceFetchProfile: false,
      }
    case FAILURE_FETCH_PROFILE:
      return {
        ...state,
        isFetchingProfile: false
      }
    case UPDATE_HEALTH_WEIGHT_IN_PROFILE_REQUESTED:
    case UPDATE_HEALTH_ROUTINE_AND_FOOD_IN_PROFILE_REQUESTED:
    case UPDATE_HEALTH_MEDICATION_IN_PROFILE_REQUESTED:
    case UPDATE_HEALTH_MOMENT_IN_PROFILE_REQUESTED:
    case UPDATE_HEALTH_HISTORY_IN_PROFILE_REQUESTED:
    case UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE_REQUESTED:
    case UPDATE_PROFILE_REQUESTED:
      return {
        ...state,
        isUpdatingProfile: true,
      }
    case SUCCESS_UPDATE_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        isProfileUpdated: true,
        profile: {
          ...state.profile,
          ...action.profile,
        },
      }
    }
    case UPDATE_ADDRESS_PROFILE_REQUESTED:
      return {
        ...state,
        isUpdatingProfile: true,
        isAddressUpdated: false
      }
    case SUCCESS_UPDATE_ADDRESS_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        isAddressUpdated: true
      }
    }
    case FAILURE_UPDATE_ADDRESS_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        isAddressUpdated: false
      }
    }
    case UPDATE_PASSWORD_REQUESTED:
      return {
        ...state,
        isUpdatingProfile: true,
        isPasswordUpdated: false
      }
    case SUCCESS_UPDATE_PASSWORD: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        isPasswordUpdated: true
      }
    }
    case FAILURE_UPDATE_PASSWORD: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        isPasswordUpdated: false
      }
    }
    case BACK_TO_PROFILE_FROM_EDIT_ADDRESS_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isAddressUpdated: false
      }
    }
    case CONFIRM_EDIT_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isPasswordUpdated: false,
        isProfileUpdated: false
      }
    }
    case SUCCESS_UPDATE_HEALTH_INFOS_IN_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        profile: {
          ...state.profile,
          ...action.healthInfos
        },
      }
    }
    case SUCCESS_UPDATE_HEALTH_PERSONAL_DATA_IN_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        profile: {
          ...state.profile,
          birthDate: action.birthDate,
          hasAllergy: action.hasAllergy,
          allergies: action.allergies,
          height: action.height,
          bloodType: action.bloodType
        },
      }
    }
    case SUCCESS_UPDATE_HEALTH_WEIGHT_DATA_IN_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        profile: {
          ...state.profile,
          weight: action.weight,
          beforePregnancyWeight: action.beforePregnancyWeight
        },
      }
    }
    case SUCCESS_UPDATE_HEALTH_ROUTINE_AND_FOOD_DATA_IN_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        profile: {
          ...state.profile,
          workType: action.workType,
          workTypeOthers: action.workTypeOthers,
          hasFoodRestriction: action.hasFoodRestriction,
          foodRestriction: action.foodRestriction,
          hasSomeHealthCondition: action.hasSomeHealthCondition,
          someHealthCondition: action.someHealthCondition
        },
      }
    }
    case SUCCESS_UPDATE_HEALTH_MEDICINE_DATA_IN_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        profile: {
          ...state.profile,
          hasUseSomeMedicine: action.hasUseSomeMedicine,
          useSomeMedicine: action.useSomeMedicine,
        },
      }
    }
    case SUCCESS_UPDATE_HEALTH_MOMENT_DATA_IN_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        forceFetchProfile: true,
        pregnancyMomentUpdateInfoText: action.pregnancyMomentUpdateInfoText
      }
    }
    case SUCCESS_UPDATE_HEALTH_HISTORY_DATA_IN_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        profile: {
          ...state.profile,
          ...action.historyInfos
        },
      }
    }
    case SUCCESS_UPDATE_GESTATIONAL_INFOS_IN_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        profile: {
          ...state.profile,
          ...action.gestationalInfos
        },
      }
    }
    case SUCCESS_UPDATE_PERSONAL_INFOS_IN_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUpdatingProfile: false,
        profile: {
          ...state.profile,
          ...action.personalInfos
        },
      }
    }
    case UPLOAD_PICTURE_PROFILE_REQUESTED: {
      return {
        ...state,
        isUploadingPictureProfile: true,
        isPictureProfileUploaded: false
      }
    }
    case SUCCESS_UPLOAD_PICTURE_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isUploadingPictureProfile: false,
        errorUploadingPictureProfile: false,
        isPictureProfileUploaded: true,
        profile: {
          ...state.profile,
          pictureUrl: action.pictureUrl,
        },
      }
    }
    case FINISH_UPLOAD_PICTURE_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isPictureProfileUploaded: false
      }
    }
    case FAILURE_UPLOAD_PICTURE_PROFILE: {
      return {
        ...state,
        isUploadingPictureProfile: false,
        errorUploadingPictureProfile: true,
        isPictureProfileUploaded: false
      }
    }
    case DELETE_PICTURE_PROFILE_REQUESTED: {
      return {
        ...state,
        isDeletingPictureProfile: true,
        isProfilePictureDeleted: false
      }
    }
    case SUCCESS_DELETE_PICTURE_PROFILE: {
      if (!state.profile) return state
      return {
        ...state,
        isDeletingPictureProfile: false,
        errorDeletingPictureProfile: false,
        profile: {
          ...state.profile,
          pictureUrl: null,
        },
        isProfilePictureDeleted: true
      }
    }
    case FAILURE_DELETE_PICTURE_PROFILE: {
      return {
        ...state,
        isDeletingPictureProfile: false,
        errorDeletingPictureProfile: true,
        isProfilePictureDeleted: false
      }
    }
    case FINISH_DELETE_PICTURE_PROFILE: {
      return {
        ...state,
        isDeletingPictureProfile: false,
        errorDeletingPictureProfile: false,
        isProfilePictureDeleted: false
      }
    }
    case FAILURE_UPDATE_PROFILE:
      return {
        ...state,
        isUpdatingProfile: false,
        isProfileUpdated: false,
      }
    case CLOSE_SUCCESS_UPDATE_MODAL:
      return {
        ...state,
      }
    case FORCE_FETCH_PROFILE: {
      return {
        ...state,
        forceFetchProfile: true,
      }
    }
    case UPDATE_PROFILE_INFOS: {
      return {
        ...state,
        isUpdatingProfile: true,
        successUpdateProfileInfos: false
      }
    }
    case SUCCESS_UPDATE_PROFILE_INFOS: {
      return {
        ...state,
        isUpdatingProfile: false,
        successUpdateProfileInfos: true,
        profile: {
          ...state.profile,
          ...action.profileInfos
        }
      }
    }
    case CLEAR_UPDATE_PROFILE_STATUS: {
      return {
        ...state,
        isUpdatingProfile: false,
        successUpdateProfileInfos: false,
      }
    }
    case FAILURE_UPDATE_PROFILE_INFOS: {
      return {
        ...state,
        isUpdatingProfile: false,
        successUpdateProfileInfos: false
      }
    }
    case VALIDATE_INFORMATION: {
      return {
        ...state,
        validateInformationStatus: {
          isLoading: true,
          error: false,
          success: false,
          errorMessage: undefined
        }
      }
    }
    case SUCCESS_VALIDATE_INFORMATION: {
      return {
        ...state,
        validateInformationStatus: {
          isLoading: false,
          error: false,
          success: true,
          errorMessage: undefined
        }
      }
    }
    case FAILURE_VALIDATE_INFORMATION: {
      return {
        ...state,
        validateInformationStatus: {
          isLoading: false,
          error: true,
          success: false,
          errorMessage: action.error
        }
      }
    }
    case CLEAR_VALIDATE_INFORMATION_ERRORS: {
      return {
        ...state,
        validateInformationStatus: {
          isLoading: false,
          error: false,
          success: false,
          errorMessage: undefined
        }
      }
    }
    case PROMPT_VALIDATE_EMAIL_MODAL: {
      return {
        ...state,
        showValidateEmailModal: true,
        isFromProfileEdit: action.isFromProfileEdit
      }
    }
    case SUPPRESS_VALIDATE_EMAIL_MODAL: {
      return {
        ...state,
        showValidateEmailModal: false,
        validateInformationStatus: {
          isLoading: false,
          error: false,
          success: false,
          errorMessage: undefined
        }
      }
    }
    case CLEAR_VALIDATE_PHONE_ERRORS: {
      return {
        ...state,
        validateInformationStatus: {
          isLoading: false,
          error: false,
          success: false,
          errorMessage: undefined
        }
      }
    }
    case PROMPT_VALIDATE_PHONE_MODAL: {
      return {
        ...state,
        showValidatePhoneModal: true,
        isFromProfileEdit: action.isFromProfileEdit
      }
    }
    case SUPPRESS_VALIDATE_PHONE_MODAL: {
      return {
        ...state,
        showValidatePhoneModal: false,
        validateInformationStatus: {
          isLoading: false,
          error: false,
          success: false,
          errorMessage: undefined
        }
      }
    }
    default: return state
  }
}
