/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum EventCategories {
  NEW_SCHEDULE_FLOW = 'Novo Agendamento',
  ONBOARDING = 'Onboarding',
  EMAIL_VALIDATION = 'Validação Email',
  PEDIATRIC_SCHEDULE = 'Agendamento Consulta Pediatrica',
  PEDIATRIC_JOURNEY = 'Jornada Pediatrica',
  PEDIATRIC_REGISTER = 'Cadastro Pediatrico',
  PHONE_VALIDATION = 'Validação Celular',
  PROFILE_SEPARATION = 'separacao de perfis',
  CONTENT = 'conteudo',
}
