import React from 'react'
import { useHistory } from 'react-router-dom'
import { AccessibleModal, RegularButton } from 'theia-web-ds'
import CongratsPregnant from '../../../assets/congrats-pregnant.png'
import { isPregnant } from '../../utils/helpers'
import { ConsultantMoment } from '../../domain/Profile'
import './CongratsPregnancyModal.scss'
import { INICIO } from '../../routes/RoutesConstants'

interface Props {
  visible: boolean;
  suppressModal: () => void;
  lastIdFocused: string;
  pregnancyMomentUpdateInfoText?: string;
  pregnancyMoment?: ConsultantMoment;
}

export default function CongratsPregnancyModal({
  visible,
  suppressModal,
  lastIdFocused,
  pregnancyMomentUpdateInfoText,
  pregnancyMoment
}: Props) {
  const history = useHistory()
  function goToHome() {
    history.push(INICIO)
  }
  const endExplanation = isPregnant(pregnancyMoment) ? (
    ', confira tudo que tem pra fazer nessa semana.'
  ) : (
    ', confira todo o conteúdo e sugestões para o seu momento atual.'
  )
  const weekButtonText = isPregnant(pregnancyMoment) ? (
    'Minha semana'
  ) : (
    'Ir para tela inicial'
  )
  const explanation = `Atualizamos o seu perfil. ${pregnancyMomentUpdateInfoText}${endExplanation}`
  return (
    <AccessibleModal
      extraOuterClassName="congrats-pregnancy-modal"
      maxWidthClass="congrats-pregnancy-width-modal"
      extraModalContainerClass={visible ? 'active' : 'inactive'}
      visible={visible}
      onClose={suppressModal}
      headerText={`Parabéns, mamãe! ${explanation}`}
      lastIdFocused={lastIdFocused}
      showCloseButton
    >
      <div className="congrats-pregnancy-icon">
        <img
          src={CongratsPregnant}
          width={80}
          height={80}
          alt=""
        />
      </div>
      <p className="congrats-pregnancy-title">Parabéns, mamãe!</p>
      <p className="congrats-pregnancy-caption">
        {explanation}
      </p>

      <RegularButton
        label={weekButtonText}
        onClick={goToHome}
        extraClass="congrats-pregnancy-first-button"
      />
      <RegularButton
        variant="text"
        label="Voltar para página saúde"
        onClick={suppressModal}
      />
    </AccessibleModal>
  )
}
