import React, { useEffect, useState } from 'react'
import { ContentCard, IconButton } from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import EmailConfirmIllustra from '../../../../assets/email-confirm.png'
import { textSecondary } from '../../../color'
import { AppDispatch } from '../../../state/utils'
import { promptValidateEmailModalAction } from '../../../state/profile/actions'
import { eventTrack } from '../../../../eventGenerate'
import { AppState } from '../../../apps/main/store'
import { UserConfirmationInfos } from '../../../domain/User'
import { StatusType } from '../../../domain/Status'
import { getUserConfirmationInfosAction } from '../../../state/authentication/main/actions'
import { ValidationCardSkelleton } from './ValidationCardSkelleton'
import './ValidationCard.scss'
import { EventCategories } from '../../../utils/EventCategories'

interface Props {
  userConfirmationInfos?: UserConfirmationInfos
  isDependent: boolean
  getUserConfirmationStatus: StatusType
  promptValidateEmailModal: (isFromProfileEdit: boolean) => void
  getUserConfirmationInfos: () => void
}

function EmailValidationCard({
  userConfirmationInfos,
  isDependent,
  getUserConfirmationStatus,
  promptValidateEmailModal,
  getUserConfirmationInfos
}: Props) {
  const [visible, setVisible] = useState(true)

  function handleClickClose() {
    eventTrack('fechou o card', {
      category: EventCategories.EMAIL_VALIDATION,
    })
    setVisible(false)
  }

  function handleClickConfirmEmail() {
    eventTrack('clicou card na home', {
      category: EventCategories.EMAIL_VALIDATION,
    })
    promptValidateEmailModal(false)
  }

  useEffect(() => {
    if (visible && !isDependent && !userConfirmationInfos && !getUserConfirmationStatus.isLoading) {
      getUserConfirmationInfos()
    }
  }, [visible, getUserConfirmationStatus, userConfirmationInfos, isDependent])

  if (!visible || isDependent || !userConfirmationInfos?.email) return null

  return getUserConfirmationStatus.isLoading ? (
    <ValidationCardSkelleton />
  ) : (
    <div className="home-validation-card">
      <ContentCard
        firstButtonProps={{
          label: 'Confirmar conta',
          onClick: handleClickConfirmEmail
        }}
        type="outlined"
        contentSubtitle="Para manter seu cadastro atualizado, faça a verificação do seu e-mail e confirme sua conta"
        ilustraBefore={{ src: EmailConfirmIllustra }}
        headerTitle="Verificação de e-mail"
        buttonAfter={(
          <IconButton
            variant="text"
            onClick={handleClickClose}
            iconType="icon-CloseLight"
            iconColor={textSecondary}
            iconSize="24px"
          />
        )}
      />
    </div>
  )
}

const mapStateToProps = ({
  authentication, appProfiles
}: AppState) => ({
  userConfirmationInfos: authentication.userConfirmationInfos,
  isDependent: appProfiles.isDependent,
  getUserConfirmationStatus: authentication.getUserConfirmationStatus,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  promptValidateEmailModal: promptValidateEmailModalAction,
  getUserConfirmationInfos: getUserConfirmationInfosAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailValidationCard)
