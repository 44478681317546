import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  NewChipButton,
  RegularButton,
  ErrorMessage,
  Loader,
} from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { updateChildHealthPlanAction } from '../../state/pediatricFlow/actions'
import { BabyData, HealthInsurancePlanStatus } from '../../domain/PediatricFlow'
import HealthPlanRegistrationForm, { HealthInsurancePlanForm } from '../common/HealthPlanRegistrationForm';
import { eventTrack } from '../../../eventGenerate'
import { dontHavePlan, havePlan, isNotInformed } from '../../utils/helpers'
import './RegisterChildHealthPlan.scss'
import { StatusType } from '../../domain/Status'
import { ActiveProfileType } from '../../domain/AppProfiles'
import { EventCategories } from '../../utils/EventCategories'

interface Props {
  activeProfile?: ActiveProfileType;
  getChildrenDataStatus: StatusType;
  updateChildrenStatus: StatusType;
  loadingStep: boolean
  updateChildHealthPlan: (babiesData: BabyData) => void;
  actionOnMount?: () => void;
}

function RegisterChildHealthPlan({
  activeProfile,
  getChildrenDataStatus,
  updateChildrenStatus,
  loadingStep,
  updateChildHealthPlan,
  actionOnMount,
}: Props) {
  const [healthInsurancePlanStatus, setHealthInsurancePlanStatus] = useState(
    activeProfile?.healthInsurancePlanStatus
  )
  const [chipsError, setChipsError] = useState<string | undefined>()
  const isLoading = updateChildrenStatus.isLoading
    || getChildrenDataStatus.isLoading

  useEffect(() => {
    if (actionOnMount) actionOnMount()
  }, [])

  function handlePostHealthInsurancePlan(values?: HealthInsurancePlanForm) {
    if (isNotInformed(healthInsurancePlanStatus)) {
      return setChipsError('Selecione uma opção para continuar')
    }
    eventTrack('Clicou continuar convenio criança', {
      category: EventCategories.PEDIATRIC_SCHEDULE
    })
    return updateChildHealthPlan({
      id: activeProfile?.id,
      healthInsurancePlanStatus,
      companyId: values?.companyId,
      planId: values?.planId,
      memberId: values?.memberId,
      planName: values?.planName,
      companyName: values?.companyName
    })
  }

  if (loadingStep) {
    return (
      <div className="schedule-view-loader register-child-healthplan-view">
        <Loader />
      </div>
    )
  }

  return (
    <div className="child-healthplan-view">
      <div className="healthplan-body">
        <h1 className="title">
          Seu bebê possui plano de saúde?
        </h1>
        <div className="chips-container">
          <NewChipButton
            key="Sim"
            label="Sim"
            isSelected={havePlan(healthInsurancePlanStatus)}
            onClick={() => {
              setHealthInsurancePlanStatus(HealthInsurancePlanStatus.HAVE_PLAN)
              setChipsError(undefined)
              eventTrack('Clicou criança tem plano de saúde', {
                category: EventCategories.PEDIATRIC_SCHEDULE
              })
            }}
            disabled={isLoading}
            height="48px"
            width="100%"
          />
          <NewChipButton
            key="Não"
            label="Não"
            isSelected={dontHavePlan(healthInsurancePlanStatus)}
            onClick={() => {
              setHealthInsurancePlanStatus(HealthInsurancePlanStatus.DONT_HAVE_PLAN)
              setChipsError(undefined)
              eventTrack('Clicou criança não tem plano de saúde', {
                category: EventCategories.PEDIATRIC_SCHEDULE
              })
            }}
            disabled={isLoading}
            height="48px"
            width="100%"
          />
        </div>
        {chipsError && (
          <ErrorMessage error={chipsError} />
        )}
        {havePlan(healthInsurancePlanStatus) && (
          <div className="form-container">
            <HealthPlanRegistrationForm
              onPostHealthInsurance={handlePostHealthInsurancePlan}
              showOnlyCovered
              isChildHealthPlan
              failureToSave={updateChildrenStatus.error}
            />
          </div>
        )}
      </div>
      {!havePlan(healthInsurancePlanStatus) && (
        <div className="continue-btn">
          <RegularButton
            label="Continuar"
            onClick={() => handlePostHealthInsurancePlan()}
            isSubmitting={isLoading}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  pediatricFlow, appProfiles, scheduleFlow
}: AppState) => ({
  getChildrenDataStatus: pediatricFlow.getChildrenDataStatus,
  updateChildrenStatus: pediatricFlow.updateChildrenStatus,
  activeProfile: appProfiles.activeProfile,
  loadingStep: scheduleFlow.loadingStep,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateChildHealthPlan: (babiesData: BabyData) => {
    dispatch(updateChildHealthPlanAction(babiesData))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterChildHealthPlan)
