import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ExclamationError from '../../../assets/exclamation-error.svg'
import { suppressBrowserErrorAction } from '../../state/booking/actions'
import Helpers from '../../utils/Browsers'
import Modal from '../common/Modal'
import HeaderModal from '../common/HeaderModal'
import ConfirmButton from '../common/buttons/ConfirmButton'
import CancelButton from '../common/buttons/CancelButton'
import { AppState } from '../../apps/main/store'

interface Props {
  browserErrorVisible: boolean;
  closeModal: () => void;
}

function BrowserErrorModal({ browserErrorVisible, closeModal }: Props) {
  if (!browserErrorVisible) return null

  function redirectToStore() {
    window.location.href = Helpers.getStoreURL()
  }

  const isIOS = Helpers.isIOS()
  const isMobile = isIOS || Helpers.isAndroid()
  const title = isIOS ? 'Navegador não suportado no iPhone' : 'Navegador não suportado'
  const defaultDescription = 'Para acessar a consulta, utilize o Chrome, Firefox ou Safari a partir da versão 11.'
  const iosDescription = 'Para acessar a consulta, utilize o navegador Safari (a partir da versão 11) ou então acesse pelo desktop.'
  const storeName = isIOS ? 'a App Store' : 'o Google Play'

  return (
    <Modal>
      <div className="flex flex-col text-center">
        <ExclamationError className="self-center" />
        <HeaderModal className="mt-4 mb-4 mr-2 ml-2">{title}</HeaderModal>
        <p className="text-textSecondary font-bold mb-2 mr-4 ml-4">
          {isIOS ? iosDescription : defaultDescription}
          {isMobile && ` Você também pode baixar nosso aplicativo n${storeName}.`}
        </p>
        {isMobile && (
          <ConfirmButton onClick={redirectToStore}>
            Ir para {storeName}
          </ConfirmButton>
        )}

        <CancelButton onClick={closeModal}>Fechar</CancelButton>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({
  browserError,
}: AppState) => ({
  browserErrorVisible: browserError.visible,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => { dispatch(suppressBrowserErrorAction()) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrowserErrorModal)
