import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Loader,
  Snackbar
} from 'theia-web-ds'
import '../AddNewDependent.scss'
import { StatusType } from '../../../domain/Status'
import { BabyData, HealthInsurancePlanStatus } from '../../../domain/PediatricFlow'
import HealthPlanRegistrationForm, { HealthInsurancePlanForm } from '../../common/HealthPlanRegistrationForm'
import Header from '../../common/molecules/Header'
import { warning } from '../../../color'
import { AppState } from '../../../apps/main/store'
import { AppDispatch } from '../../../state/utils'
import { clearSaveDependentStatusAction, saveDependentDataAction } from '../../../state/appProfiles/actions'
import { getChildrenDataAction } from '../../../state/pediatricFlow/actions'
import { ActiveProfileType } from '../../../domain/AppProfiles'
import { havePlan } from '../../../utils/helpers'
import { PROFILE } from '../../../routes/RoutesConstants'
import { ProfileLocationProps } from '../Profile'

interface Props {
  saveDependentDataStatus: StatusType
  getChildrenDataStatus: StatusType
  activeProfile?: ActiveProfileType
  saveDependentData: (payload: BabyData) => void
  clearSaveDependentStatus: () => void
  getChildrenData: () => void
}

function EditDependentHealthplan({
  saveDependentDataStatus,
  getChildrenDataStatus,
  activeProfile,
  saveDependentData,
  clearSaveDependentStatus,
  getChildrenData
}: Props) {
  const history = useHistory()
  const [submittedValues, setSubmittedValues] = useState<BabyData | undefined>()
  const hasPreviousInsurancePlan = havePlan(activeProfile?.healthInsurancePlanStatus)

  const goBack = () => {
    history.goBack()
  }

  const handleTryAgain = () => {
    if (submittedValues) saveDependentData(submittedValues)
  }

  const handlePostDependentHealthInsurance = (values: HealthInsurancePlanForm) => {
    const formattedValues:BabyData = {
      companyName: values.companyName,
      companyId: values.companyId,
      planName: values.planName,
      planId: values.planId,
      memberId: values.memberId,
      id: activeProfile?.id,
      healthInsurancePlanStatus: HealthInsurancePlanStatus.HAVE_PLAN
    }
    setSubmittedValues(formattedValues)
    saveDependentData(formattedValues)
  }

  useEffect(() => {
    if (saveDependentDataStatus.success) {
      history.push(PROFILE, {
        hasPreviousInsurancePlan,
        isFromFeedbackChangePlan: true,
      } as ProfileLocationProps)
    }
  }, [saveDependentDataStatus])

  useEffect(() => {
    getChildrenData()
    return () => clearSaveDependentStatus()
  }, [])

  return (
    <div className="save-dependent-data-view">
      <div className="save-dependent-data-header">
        <Header title={hasPreviousInsurancePlan ? 'Editar plano' : 'Adicionar plano'} goBack={goBack} />
      </div>
      {getChildrenDataStatus.isLoading ? (
        <div className="save-dependent-data-loader">
          <Loader />
        </div>
      ) : (
        <div className="save-dependent-form pb-4">
          <HealthPlanRegistrationForm
            onPostHealthInsurance={handlePostDependentHealthInsurance}
            btnLabel="Salvar"
            showOnlyCovered
            isChildHealthPlan
            failureToSave={saveDependentDataStatus.error}
            previousHealthInsurancePlan={{
              company: activeProfile?.companyName || null,
              companyId: activeProfile?.companyId || null,
              planName: activeProfile?.planName || null,
              planId: activeProfile?.planId || null,
              memberId: activeProfile?.memberId || null,
              journeyType: null
            }}
          />
        </div>
      )}
      {saveDependentDataStatus.error && (
        <div className="save-dependent-snackbar-error">
          <Snackbar
            title={saveDependentDataStatus.errorMessage?.friendlyMessageTitle
              || 'Erro ao salvar'}
            text={saveDependentDataStatus.errorMessage?.friendlyMessage
              || 'Desculpe, houve um erro ao salvar o plano, por favor tente novamente'}
            buttonTwoProps={{
              label: 'Tentar novamente',
              onClick: handleTryAgain
            }}
            variant="alert"
            iconLeft="icon-CloseCircleLight"
            customIconColor={warning}
            showCloseButton
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  appProfiles, routerListener, pediatricFlow, authentication
}: AppState) => ({
  saveDependentDataStatus: appProfiles.saveDependentDataStatus,
  previousRoute: routerListener.previousRoute,
  childrenData: pediatricFlow.childrenData,
  consultantDocument: authentication.currentUser?.document,
  getChildrenDataStatus: pediatricFlow.getChildrenDataStatus,
  activeProfile: appProfiles.activeProfile
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  saveDependentData: saveDependentDataAction,
  clearSaveDependentStatus: clearSaveDependentStatusAction,
  getChildrenData: getChildrenDataAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDependentHealthplan)
