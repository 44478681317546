import React, { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import {
  RegularButton, NewListItem, FormInput, DateInput
} from 'theia-web-ds'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import {
  fetchProfileAction,
  onUpdateHealthMomentDataInProfileAction
} from '../../state/profile/actions'
import RadioGroup, { RadioItem } from '../common/molecules/inputs/RadioGroup'
import { MomentInfosForm, ConsultantMoment } from '../../domain/Profile'
import CongratsPregnancyModal from './CongratsPregnancyModal'
import {
  getCosultantMomentOptionLabel,
  isOtherMoment,
  isPostPregnant,
  isPregnant,
  isTrying,
  isValidElement
} from '../../utils/helpers'
import './HealthDataView.scss'
import DocActive from '../../../assets/doc-active.png'
import ChangeMomentConfirmationModal from './ChangeMomentConfirmationModal'
import { eventTrack } from '../../../eventGenerate'

function renderMomentInfosForm(
  {
    values,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    handleBlur,
    handleChange,
    errors,
    touched
  }: FormikProps<MomentInfosForm>,
  onGoBack: () => void,
  isSubmitting : boolean,
  previousValues?: MomentInfosForm,
  expectedBabyBirthDateOrigin?: string
) {
  const isNotAppOrigin = !!(isValidElement(expectedBabyBirthDateOrigin) && expectedBabyBirthDateOrigin !== 'APP')
  const radioGroupEntries: RadioItem[] = [
    {
      id: ConsultantMoment.TRYING,
      value: getCosultantMomentOptionLabel(ConsultantMoment.TRYING),
      checked: isTrying(values.pregnancyMoment)
    },
    {
      id: ConsultantMoment.PREGNANT,
      value: getCosultantMomentOptionLabel(ConsultantMoment.PREGNANT),
      checked: isPregnant(values.pregnancyMoment)
    },
    {
      id: ConsultantMoment.PUERPERIUM,
      value: getCosultantMomentOptionLabel(ConsultantMoment.PUERPERIUM),
      checked: isPostPregnant(values.pregnancyMoment)
    },
    {
      id: ConsultantMoment.OTHER_MOMENT,
      value: getCosultantMomentOptionLabel(ConsultantMoment.OTHER_MOMENT),
      checked: isOtherMoment(values.pregnancyMoment)
    },
  ]

  const hasChanged = values.pregnancyMoment !== previousValues?.pregnancyMoment
  || values.pregnancyDueDate !== previousValues?.pregnancyDueDate
  || values.pregnancyEffectiveDueDate !== previousValues.pregnancyEffectiveDueDate
  || values.firstDayLastPeriod !== previousValues.firstDayLastPeriod

  const isButtonDisabled = (
    (isPregnant(values.pregnancyMoment) && !values.pregnancyDueDate && !values.firstDayLastPeriod)
    || (isPregnant(values.pregnancyMoment) && isNotAppOrigin)
    || (isPostPregnant(values.pregnancyMoment) && !values.pregnancyEffectiveDueDate)
    || !hasChanged)

  function onChangePregnancyDueDate(date: Date) {
    if (!date) {
      setFieldValue('pregnancyDueDate', '')
      setFieldTouched('pregnancyDueDate', true)
    } else {
      setFieldValue('pregnancyDueDate', date.getTime())
      setFieldTouched('pregnancyDueDate', true)
    }
  }

  function onChangeFirstDayLastPeriod(date: Date) {
    if (!date) {
      setFieldValue('firstDayLastPeriod', '')
      setFieldTouched('firstDayLastPeriod', true)
    } else {
      setFieldValue('firstDayLastPeriod', date.getTime())
      setFieldTouched('firstDayLastPeriod', true)
    }
  }

  function onChangePregnancyEffectiveDueDate(date: Date) {
    if (!date) {
      setFieldValue('pregnancyEffectiveDueDate', '')
      setFieldTouched('pregnancyEffectiveDueDate', true)
    } else {
      setFieldValue('pregnancyEffectiveDueDate', date.getTime())
      setFieldTouched('pregnancyEffectiveDueDate', true)
    }
  }

  const extraComponentsByIndex = {
    1: (
      <div className="extra-inputs-container">
        {isNotAppOrigin && (
          <NewListItem
            isLastItem
            isNoHover
            className="bg-bgBoxSecondary rounded-2xl mb-4"
            title="Essas informações são atualizadas pelo seu time de saúde Theia"
            picture={(
              <img
                src={DocActive}
                width={40}
                height={40}
                className="mr-3 ml-6"
                alt=""
              />
                )}
            ariaLabel="Banner infos atualizadas"
            titleSize="text-fontDefault"
            extraTitleClass="p-1 mr-3"
          />
        )}
        <DateInput
          required
          id="pregnancyDueDate"
          name="pregnancyDueDate"
          onChange={onChangePregnancyDueDate}
          blockPastDates
          placeholder="Selecione uma data"
          label="Data provável do parto"
          disabled={isNotAppOrigin}
          maxDate={moment().add(9, 'M').toDate()}
          value={values.pregnancyDueDate}
          hasError={(
            isPregnant(values.pregnancyMoment)
            && !values.pregnancyDueDate && !values.firstDayLastPeriod
          )}
          errorText="Para continuar precisamos saber a data provável do parto ou a data da sua última menstruação."
        />
        <div className="health-view-individual-container">
          <DateInput
            required
            id="firstDayLastPeriod"
            name="firstDayLastPeriod"
            disabled={isNotAppOrigin}
            onChange={onChangeFirstDayLastPeriod}
            blockFutureDates
            placeholder="Selecione uma data"
            label="Data da última menstruação"
            minDate={moment().subtract(9, 'M').toDate()}
            value={values.firstDayLastPeriod}
            hasError={(isPregnant(values.pregnancyMoment)
              && !values.pregnancyDueDate && !values.firstDayLastPeriod
            )}
          />
        </div>
      </div>
    ),
    2: (
      <div className="extra-inputs-container">
        <DateInput
          required
          id="pregnancyEffectiveDueDate"
          name="pregnancyEffectiveDueDate"
          onChange={onChangePregnancyEffectiveDueDate}
          blockFutureDates
          placeholder="Selecione uma data"
          label="Data efetiva do parto"
          value={values.pregnancyEffectiveDueDate}
          hasError={(isPostPregnant(values.pregnancyMoment)
            && !values.pregnancyEffectiveDueDate
          )}
          errorText="Para continuar precisamos saber a data efetiva do parto."
        />
      </div>
    ),
    3: (
      <div className="extra-inputs-container">
        <FormInput
          name="pregnancyMomentOther"
          type="text"
          id="pregnancyMomentOther"
          value={values.pregnancyMomentOther}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={touched.pregnancyMomentOther ? errors.pregnancyMomentOther : ''}
          placeholder="Qual?"
        />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} className="health-view-drawer-form" noValidate>
      <div className="health-view-drawer-inner-form">
        <div className="health-view-individual-container">
          <RadioGroup
            label="Qual o seu momento atual?"
            name="pregnancyMoment"
            choosenValue={values.pregnancyMoment}
            list={radioGroupEntries}
            extraComponents={extraComponentsByIndex}
            withDivider
            hideLabel
          />
        </div>
      </div>
      <div className="health-view-drawer-footer">
        <div className="health-view-drawer-button">
          <RegularButton
            onClick={onGoBack}
            label="Agora não"
            variant="subtle"
            extraClass="booking-details-cancel-btn"
          />
          <RegularButton
            onClick={() => handleSubmit()}
            label="Salvar"
            type="submit"
            isSubmitting={isSubmitting}
            disabled={isButtonDisabled}
            id="moment-save-button"
          />
        </div>
      </div>
    </form>
  )
}

interface Props {
  isSubmitting: boolean;
  pregnancyMoment?: ConsultantMoment;
  pregnancyMomentOther?: string;
  onGoBack: () => void;
  onUpdateHealthMomentDataInProfile: (
    name: string,
    email: string,
    phone: string,
    momentInfos: MomentInfosForm
  ) => void;
  name?: string;
  email?: string;
  phone?: string;
  fetchProfile: () => void;
  forceFetchProfile: boolean;
  pregnancyDueDate?: string;
  firstDayLastPeriod?: string;
  pregnancyMomentUpdateInfoText?: string;
  expectedBabyBirthDateOrigin?: string;
  pregnancyEffectiveDueDate?: string;
  isFetchingProfile: boolean
}

function MomentDataEdit({
  onUpdateHealthMomentDataInProfile,
  isSubmitting,
  pregnancyMoment,
  pregnancyMomentOther,
  onGoBack,
  expectedBabyBirthDateOrigin,
  name,
  email,
  phone,
  fetchProfile,
  forceFetchProfile,
  pregnancyDueDate,
  firstDayLastPeriod,
  pregnancyMomentUpdateInfoText,
  pregnancyEffectiveDueDate,
  isFetchingProfile
}: Props) {
  const [alreadySendNewInfos, setAlreadySendNewInfos] = useState(false)
  const [needsShowCongratsModal, setNeedsShowCongratsModal] = useState(false)
  const [showCongratsModal, setShowCongratsModal] = useState(false)
  const [showMomentAlertModal, setShowMomentAlertModal] = useState(false)
  const [valuesSaved, setValuesSaved] = useState<MomentInfosForm>()

  const initialValues: MomentInfosForm = {
    pregnancyMoment: pregnancyMoment || ConsultantMoment.NONE,
    pregnancyMomentOther: pregnancyMomentOther || '',
    pregnancyDueDate: pregnancyDueDate || undefined,
    firstDayLastPeriod: firstDayLastPeriod || undefined,
    pregnancyEffectiveDueDate: pregnancyEffectiveDueDate || undefined
  }

  useEffect(() => {
    if (alreadySendNewInfos) {
      setShowMomentAlertModal(false)
      if (!isFetchingProfile) fetchProfile()
      setAlreadySendNewInfos(false)
      if (needsShowCongratsModal && pregnancyMomentUpdateInfoText) {
        setShowCongratsModal(true)
        setNeedsShowCongratsModal(false)
      } else {
        onGoBack()
      }
    }
  }, [forceFetchProfile])

  function onSuppressCongratsModal() {
    setShowCongratsModal(false)
    onGoBack()
  }

  function handleSaveInfos(pregnancyInfos: MomentInfosForm) {
    if (name && email && phone) {
      onUpdateHealthMomentDataInProfile(
        name,
        email,
        phone,
        { ...pregnancyInfos }
      )
    }
  }

  function handleConfirmMomentChange() {
    eventTrack('Clicou Confirmar Troca de Status')
    if (name && email && phone && valuesSaved) {
      handleSaveInfos(valuesSaved)
    }
  }

  function handleSubmit(_value: MomentInfosForm) {
    eventTrack('Clicou Salvar Troca de Status', {
      status_atual: getCosultantMomentOptionLabel(pregnancyMoment),
      novo_status: getCosultantMomentOptionLabel(_value.pregnancyMoment)
    })
    if (name && email && phone) {
      setAlreadySendNewInfos(true)
      setValuesSaved({ ..._value })
      if (isPregnant(_value.pregnancyMoment) && _value.pregnancyMoment !== pregnancyMoment) {
        setNeedsShowCongratsModal(true)
      }
      if (pregnancyMoment === ConsultantMoment.PREGNANT
        && _value.pregnancyMoment !== pregnancyMoment) {
        setShowMomentAlertModal(true)
        return
      }
      handleSaveInfos(_value)
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(_value: MomentInfosForm) => handleSubmit(_value)}
      >
        {(props) => renderMomentInfosForm(
          props,
          onGoBack,
          isSubmitting,
          initialValues,
          expectedBabyBirthDateOrigin,
        )}
      </Formik>
      <CongratsPregnancyModal
        visible={showCongratsModal}
        suppressModal={onSuppressCongratsModal}
        pregnancyMomentUpdateInfoText={pregnancyMomentUpdateInfoText}
        pregnancyMoment={valuesSaved?.pregnancyMoment}
        lastIdFocused="moment-save-button"
      />
      {showMomentAlertModal && (
        <ChangeMomentConfirmationModal
          visible={showMomentAlertModal}
          lastIdFocused="moment-save-button"
          newMoment={valuesSaved?.pregnancyMoment}
          previousMoment={pregnancyMoment}
          suppressModal={() => setShowMomentAlertModal(false)}
          onConfirmChange={handleConfirmMomentChange}
          isSubmitting={isSubmitting}
          onCancel={() => {
            eventTrack('Cancelou Troca de Status', {
              status_atual: getCosultantMomentOptionLabel(pregnancyMoment),
              tentativa_status: getCosultantMomentOptionLabel(valuesSaved?.pregnancyMoment)
            })
          }}
        />
      )}
    </>
  )
}

const mapStateToProps = ({
  profile, authentication
}: AppState) => ({
  pregnancyMoment: profile.profile?.pregnancyMoment,
  name: authentication.currentUser?.name,
  email: authentication.currentUser?.email,
  phone: authentication.currentUser?.phone,
  isSubmitting: profile.isUpdatingProfile,
  pregnancyMomentOther: profile.profile?.pregnancyMomentOther,
  forceFetchProfile: profile.forceFetchProfile,
  pregnancyDueDate: profile.profile?.pregnancyDueDate || profile.profile?.expectedBabyBirthDate,
  firstDayLastPeriod: profile.profile?.firstDayLastPeriod || profile.profile?.lastPeriodStartDate,
  pregnancyMomentUpdateInfoText: profile.pregnancyMomentUpdateInfoText,
  pregnancyEffectiveDueDate: profile.profile?.pregnancyEffectiveDueDate,
  expectedBabyBirthDateOrigin: profile.profile?.expectedBabyBirthDateOrigin,
  isFetchingProfile: profile.isFetchingProfile
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onUpdateHealthMomentDataInProfile: onUpdateHealthMomentDataInProfileAction,
  fetchProfile: fetchProfileAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MomentDataEdit)
