/* eslint-disable max-len */
import React from 'react'
import {
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom'
import {
  BOAS_VINDAS,
  BOOKING_CONFIRMATION,
  CADASTRO_BLOQUEADO_DPP,
  DESBLOQUEIO_LOGIN,
  LISTA_DE_INTERESSE,
  LOCALIZACAO,
  LOGIN,
  LOGIN_MOBILE,
  PASSWORDRECOVERY,
  PROFESSIONAL_CONFIRMATION,
  RECOVERY,
} from './RoutesConstants'
import SalesOnboardingWelcome from '../components/sales-onboarding/SalesOnboardingWelcome'
import NewPasswordRecovery from '../components/authentication/NewPasswordRecovery'
import NewRecovery from '../components/authentication/NewRecovery'
import RedirectForm from '../components/sales-onboarding/RedirectForm'
import LoginForm from '../components/authentication/LoginForm'
import ScheduleConfirmSMS from '../components/schedule/confirmationSMS/ScheduleConfirmSMS'
import ScheduleProfessionalConfirmSMS from '../components/schedule/confirmationSMS/ScheduleProfessionalConfirmSMS'
import LoginUnlock from '../components/authentication/LoginUnlock'
import LoginMobile from '../components/authentication/LoginMobile'
import Localization from '../components/onboarding/Localization'
import SalesOnboardingBlockedDPP from '../components/sales-onboarding/SalesOnboardingBlockedDPP'

function PublicRoutes() {
  const renderScheduleConfirmSMS = ({ match, location }: RouteComponentProps<any>) => (
    <ScheduleConfirmSMS
      id={match.params.id}
      token={location.search.replace('?token=', '')}
    />
  )

  const renderScheduleProfessionalConfirmSMS = ({ match, location }: RouteComponentProps<any>) => (
    <ScheduleProfessionalConfirmSMS
      id={match.params.id}
      token={location.search.replace('?token=', '')}
    />
  )

  const renderLoginUnlock = ({ location }: RouteComponentProps<any>) => (
    <LoginUnlock
      token={location.search.replace('?token=', '')}
    />
  )

  return (
    <Switch>
      <Redirect exact from="/" to={BOAS_VINDAS} />
      <Redirect exact from="/index.html" to={BOAS_VINDAS} />
      <Route path={LOGIN} component={LoginForm} />
      <Route path={BOAS_VINDAS} component={SalesOnboardingWelcome} />
      <Route path={LISTA_DE_INTERESSE} component={RedirectForm} />
      <Route path={CADASTRO_BLOQUEADO_DPP} component={SalesOnboardingBlockedDPP} />
      <Route path={RECOVERY} component={NewRecovery} />
      <Route path={PASSWORDRECOVERY} component={NewPasswordRecovery} />
      <Route path={DESBLOQUEIO_LOGIN} render={renderLoginUnlock} />
      <Route path={`${BOOKING_CONFIRMATION}/:id`} render={renderScheduleConfirmSMS} />
      <Route path={`${PROFESSIONAL_CONFIRMATION}/:id`} render={renderScheduleProfessionalConfirmSMS} />
      <Route exact path={LOGIN_MOBILE} component={LoginMobile} />
      <Route exact path={LOCALIZACAO} component={Localization} />
      <Redirect to={BOAS_VINDAS} />
    </Switch>
  )
}

export default PublicRoutes
