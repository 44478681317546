import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RegularButton, Loader, CardPost } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import {
  fetchAllPostsAction,
  fetchPostAction,
  setNumberOfPostsToRenderAction,
  selectContentCategoryAction
} from '../../state/content/actions'
import { Post, defaultCategory } from '../../domain/Content'
import { notUndefined } from '../../utils/helpers'
import { eventPage, eventTrack } from '../../../eventGenerate'
import SelectOptions from '../common/SelectOptions'
import { hasTagOrSlug, normalizeStringToSlug } from '../home/helpers'
import './AllPosts.scss'
import AppHeader from '../common/AppHeader'
import { CONTENT } from '../../routes/RoutesConstants'
import { StatusType } from '../../domain/Status'
import { EventProfileType } from '../../domain/AppProfiles'
import { EventCategories } from '../../utils/EventCategories'

interface Props {
  getAllPostsStatus: StatusType
  posts: Array<Post>;
  contentCategory: string;
  numberOfVisiblePosts: number;
  profileType?: EventProfileType
  selectContentCategory: (category: string) => void;
  fetchAllPosts: () => void;
  fetchPost: (id: string) => void;
  setNumberOfPostsToRender: (numberOfPostsToRender: number) => void;
}

function AllPosts({
  getAllPostsStatus,
  posts,
  contentCategory,
  numberOfVisiblePosts,
  profileType,
  selectContentCategory,
  fetchAllPosts,
  fetchPost,
  setNumberOfPostsToRender
}: Props) {
  const history = useHistory()

  useEffect(() => {
    fetchAllPosts()
    eventPage('conteudo', 'pagina de conteudos', {
      tipo_de_perfil: profileType
    })
  }, [])

  function filterAllCategories() {
    const categories = posts.map((post) => (post.primary_tag.name))
    if (categories.length > 0) {
      const orderedCategories = categories.sort()
      // eslint-disable-next-line prefer-arrow-callback
      const unique = orderedCategories.filter(function filterCategories(elem, index, self) {
        return index === self.indexOf(elem)
      })
      unique.unshift(defaultCategory)
      return unique
    }
    return categories
  }

  const postsToShow = useMemo(() => {
    if (posts.length > 0 && contentCategory !== defaultCategory) {
      const slug = normalizeStringToSlug(contentCategory)
      const postsFilteredBytag = posts.filter(
        (post) => hasTagOrSlug(post.tags, slug)
        || normalizeStringToSlug(post.primary_tag.name) === slug
        || post.primary_tag.slug === slug
      )
      const featuredPosts = postsFilteredBytag.filter((p) => p.featured)
      let newPosts;
      if (featuredPosts.length > 0) {
        newPosts = featuredPosts.concat(postsFilteredBytag.filter((p) => !p.featured))
      } else {
        newPosts = postsFilteredBytag
      }
      return newPosts
    }
    return posts.slice(0, numberOfVisiblePosts)
  }, [contentCategory, posts, numberOfVisiblePosts])

  function onChangeCategory(e: React.ChangeEvent<HTMLSelectElement>) {
    selectContentCategory(e.target.value)
  }

  function getPostById(post: Post, title?: string, category?: string) {
    fetchPost(post.id)
    history.push(`${CONTENT}/${post.slug}/${post.id}`, { title, category })
  }

  function handleClickSeeMorePosts() {
    setNumberOfPostsToRender(numberOfVisiblePosts + 10)
  }

  function renderPost(post: Post) {
    return (
      <li key={post.id} className="post-list">
        <button
          onClick={() => {
            const categoryName = post.primary_tag.name || post.tags[0].name
            const title = post.meta_title || post.title
            eventTrack('clicou em conteudo', {
              titulo_conteudo: title,
              categoria_conteudo: categoryName,
              tipo_de_perfil: profileType,
              localizacao: 'pagina de conteudos',
              category: EventCategories.CONTENT
            })
            getPostById(post, title, categoryName)
          }}
          type="button"
        >
          <CardPost
            featureImage={post.feature_image}
            category={post.primary_tag.name}
            title={post.meta_title || post.title}
            description={post.meta_description || post.excerpt}
          />
        </button>
      </li>
    )
  }

  if (getAllPostsStatus.isLoading) {
    return (
      <div className="all-posts__loader-container">
        <div className="all-posts__loader">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="all-posts__view">
      <AppHeader title="Conteúdo" />
      <div className="all-posts__content">
        <div className="all-posts__content-list">
          <SelectOptions
            value={contentCategory}
            id="content-category-filter"
            options={filterAllCategories()}
            onChange={onChangeCategory}
          />
          <ul>
            {postsToShow.map((post) => renderPost(post))}
          </ul>
          {contentCategory === defaultCategory && (
            <RegularButton
              label="Mostrar mais +"
              variant="text"
              onClick={handleClickSeeMorePosts}
              extraClass="mt-4"
              aria-label="Mostrar mais"
              aria-labelledby="Mostrar mais"
            />
          )}
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = ({ content, appProfiles }: AppState) => {
  const allPosts = content.allPosts.map((post: any) => post)
    .filter(notUndefined)
  return {
    posts: allPosts,
    getAllPostsStatus: content.getAllPostsStatus,
    contentCategory: content.contentCategory,
    numberOfVisiblePosts: content.numberOfVisiblePosts,
    profileType: appProfiles.profileType
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchAllPosts: () => { dispatch(fetchAllPostsAction()) },
  fetchPost: (id: string) => { dispatch(fetchPostAction(id)) },
  selectContentCategory: (category: string) => {
    dispatch(selectContentCategoryAction(category))
  },
  setNumberOfPostsToRender: (numberOfPostsToRender: number) => {
    dispatch(setNumberOfPostsToRenderAction(numberOfPostsToRender))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllPosts)
