/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, FormikProps } from 'formik'
import { RegularButton, FontIcon, FormInput } from 'theia-web-ds'
import * as Yup from 'yup'
import {
  useElements,
  useStripe,
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { useHistory } from 'react-router-dom'

import { AppDispatch } from '../../../state/utils'
import { AppState } from '../../../apps/main/store'
import { User } from '../../../domain/User'
import '../../payment/PaymentForm.scss'
import {
  PaymentFormValuesProfile,
  paymentFormStyle as style,
  paymentFormStyle,
  errorMessages,
} from '../../payment/utils/helpers'
import { promptDefaultErrorAction } from '../../../state/defaultError/actions'
import { onGoBackToProfileAfterUpdateCardPaymentInfoAction, onUpdateCardInfoAction } from '../../../state/payment/actions'
import { warning } from '../../../color'
import './PaymentForm.scss'
import { FaqToggle } from '../../common/FaqToggle'
import { setForceUpdateCurrentUserAction } from '../../../state/authentication/main/actions'
import VisaImg from '../../../../assets/Visa.svg';
import MasterImg from '../../../../assets/Master.svg';
import { PROFILE } from '../../../routes/RoutesConstants'

interface Props {
  currentUser?: User;
  stripeLoadError: boolean;
  extraSubmitAction?: () => void;
  promptDefaultError: () => void;
  onUpdateCardInfo: (id: string) => void;
  isSubmittingPaymentInfo: boolean;
  hasSubmittedPaymentInfo: boolean;
  forceUpdateCurrentUser: () => void;
  onGoBackToProfileAfterUpdateCardPaymentInfo: () => void;
}

function renderPaymentForm({
  handleSubmit,
  isSubmitting,
  errors,
  values,
  handleBlur,
  handleChange,
  touched,
}: FormikProps<PaymentFormValuesProfile>,
stripeLoadError: boolean,
forceUpdateCurrentUser: () => void,
onGoBackToProfileAfterUpdateCardPaymentInfo: () => void,
isSubmittingPaymentInfo?: boolean,
hasSubmittedPaymentInfo?: boolean,
currentUser?: User) {
  const [hasError, setHasError] = useState(false)
  const [alreadyHasCardAdded, setAlreadyHasCardAdded] = useState(false)

  useEffect(() => {
    if (currentUser?.plan.paymentMethodAdded) setAlreadyHasCardAdded(true)
    else setAlreadyHasCardAdded(false)
  }, [])

  const history = useHistory()

  useEffect(() => {
    if (hasSubmittedPaymentInfo) {
      forceUpdateCurrentUser()
      onGoBackToProfileAfterUpdateCardPaymentInfo()
      history.push(PROFILE, { isFromUpdateCardPayment: true, alreadyHasCardAdded })
    }
  }, [hasSubmittedPaymentInfo])

  const handleChangeCvc = (event: any) => {
    const cardErrors = document.getElementById('card-cvc-errors')
    const elementErrors = document.getElementById('errors-cvc-container')
    if (elementErrors !== null) {
      if (event?.error) {
        elementErrors.className = 'errors-container show-errors-element'
      } else {
        elementErrors.className = 'errors-container hide-errors-element'
      }
    }
    if (cardErrors != null) {
      if (event?.error) {
        const eventCode = event.error.code
        cardErrors.textContent = eventCode ? errorMessages[eventCode] : event.error.message
      } else {
        cardErrors.textContent = ''
      }
    }
  }
  const handleChangeNumber = (event: any) => {
    const cardErrors = document.getElementById('card-number-errors')
    const elementErrors = document.getElementById('errors-number-container')
    if (elementErrors !== null) {
      if (event?.error) {
        elementErrors.className = 'errors-container show-errors-element'
        setHasError(true)
      } else {
        elementErrors.className = 'errors-container hide-errors-element'
        setHasError(false)
      }
    }
    if (cardErrors != null) {
      if (event?.error) {
        const eventCode = event.error.code
        cardErrors.textContent = eventCode ? errorMessages[eventCode] : event.error.message
        setHasError(true)
      } else {
        cardErrors.textContent = ''
        setHasError(false)
      }
    }
  }
  const handleChangeExpiry = (event: any) => {
    const cardErrors = document.getElementById('card-expiry-errors')
    const elementErrors = document.getElementById('errors-expiry-container')
    if (elementErrors !== null) {
      if (event?.error) {
        setHasError(true)
        elementErrors.className = 'errors-container show-errors-element'
      } else {
        elementErrors.className = 'errors-container hide-errors-element'
        setHasError(false)
      }
    }
    if (cardErrors != null) {
      if (event?.error) {
        setHasError(true)
        const eventCode = event?.error.code
        cardErrors.textContent = eventCode ? errorMessages[eventCode] : event.error.message
      } else {
        cardErrors.textContent = ''
        setHasError(false)
      }
    }
  }

  const onSubmit = () => {
    handleSubmit()
  }

  const isDisabled = isSubmitting || Object.keys(errors).length !== 0 || hasError || isSubmittingPaymentInfo

  return (
    <div className="form-cep-input-container input-group mb-4">
      <FaqToggle
        toggleTitle="Como funciona o pagamento de consultas ou exames no cartão?"
        toggleDescription="Todo o pagamento feito no cartão só é descontado após a consulta ou exame ser realizado."
        iconType="icon-ChatHelpLight"
        extraDescriptionClass="py-4"
      />
      <div className="px-4 pt-3 lg:max-w-lg w-full mx-auto">
        {stripeLoadError
          ? (
            <p className="stripe-error-text">
              Ops! Não foi possível carregar o formulário de pagamento.
              Por favor, tente novamente atualizando a página ou entre
              em contato conosco para resolvermos juntos(as)!
            </p>
          ) : (
            <>
              <div className="mb-4">
                <FormInput
                  id="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={touched.name ? errors.name : ''}
                  value={values.name}
                  placeholder="Digite aqui o seu nome"
                  type="text"
                  name="name"
                  label="Nome no cartão"
                />
              </div>
              <div id="card-element">
                <label htmlFor="cardNumber" className="payment-form-card-label">Número do cartão</label>
                <CardNumberElement
                  id="cardNumber"
                  options={{
                    style: paymentFormStyle,
                    showIcon: true
                  }}
                  onChange={handleChangeNumber}
                />
                <div id="errors-number-container" className="errors-container">
                  <FontIcon iconType="icon-DangerBold" fontSize="11px" color={warning} />
                  <div id="card-number-errors" className="element-errors" />
                </div>
                <div className="flex items-center text-textSecondary mt-2 mb-1 text-sm">
                  Bandeiras aceitas:
                  <VisaImg heigh="30px" width="46px" className="ml-4 mr-2" />
                  <MasterImg heigh="30px" width="46px" />
                </div>
                <div className="payment-form-card-infos">
                  <div className="payment-form-card-expiry my-4">
                    <label htmlFor="cardNumber" className="payment-form-card-label">Data de vencimento</label>
                    <CardExpiryElement
                      id="cardExpiry"
                      options={{
                        style: paymentFormStyle
                      }}
                      onChange={handleChangeExpiry}
                    />
                    <div id="errors-expiry-container" className="errors-container">
                      <FontIcon iconType="icon-DangerBold" fontSize="11px" color={warning} />
                      <div id="card-expiry-errors" className="element-errors" />
                    </div>
                  </div>
                  <div className="payment-form-card-cvc">
                    <label htmlFor="cardNumber" className="payment-form-card-label">Código de segurança</label>
                    <CardCvcElement
                      id="cardCvc"
                      options={{
                        style: paymentFormStyle
                      }}
                      onChange={handleChangeCvc}
                    />
                    <div id="errors-cvc-container" className="errors-container">
                      <FontIcon iconType="icon-DangerBold" fontSize="11px" color={warning} />
                      <div id="card-cvc-errors" className="element-errors" />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        <div className="button-confirm w-full">
          <RegularButton
            disabled={isDisabled}
            isSubmitting={isSubmittingPaymentInfo || isSubmitting}
            label={currentUser?.plan.paymentMethodAdded ? 'Alterar cartão' : 'Adicionar cartão'}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  )
}

const initialValues = {
  name: '',
}
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
})

function PaymentForm({
  currentUser,
  stripeLoadError,
  extraSubmitAction,
  promptDefaultError,
  onUpdateCardInfo,
  isSubmittingPaymentInfo,
  hasSubmittedPaymentInfo,
  forceUpdateCurrentUser,
  onGoBackToProfileAfterUpdateCardPaymentInfo
}: Props) {
  const stripe = useStripe()
  const elements = useElements()

  return (
    <div className="flex flex-1 relative mt-0">
      <div className="mx-auto w-full">
        <div className="flex flex-col">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (
              {
                name
              },
              { setSubmitting }
            ) => {
              let values = {
                id: ''
              }
              if (!stripe || !elements) {
                promptDefaultError()
                setSubmitting(false)
                return
              }
              const cardElement = elements.getElement(CardNumberElement) || elements.create('cardNumber', { style })
              const result = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                  name: name,
                  email: currentUser?.email,
                  address: {
                    postal_code: currentUser?.address.postalCode,
                  }
                },
              })
              if (result.error) {
                setSubmitting(false)
              } else if (result.paymentMethod) {
                values = result.paymentMethod
                onUpdateCardInfo(values.id)
                if (extraSubmitAction) {
                  extraSubmitAction()
                }
              }
            }}
          >
            {(props) => renderPaymentForm(
              props,
              stripeLoadError,
              forceUpdateCurrentUser,
              onGoBackToProfileAfterUpdateCardPaymentInfo,
              isSubmittingPaymentInfo,
              hasSubmittedPaymentInfo,
              currentUser
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  authentication,
  payment
}: AppState) => ({
  currentUser: authentication.currentUser,
  isSubmittingPaymentInfo: payment.isSubmittingPaymentInfo,
  hasSubmittedPaymentInfo: payment.hasSubmittedPaymentInfo
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  promptDefaultError: () => {
    dispatch(promptDefaultErrorAction())
  },
  onUpdateCardInfo: (id: string) => {
    dispatch(onUpdateCardInfoAction(id))
  },
  forceUpdateCurrentUser: () => {
    dispatch(setForceUpdateCurrentUserAction())
  },
  onGoBackToProfileAfterUpdateCardPaymentInfo: () => {
    dispatch(onGoBackToProfileAfterUpdateCardPaymentInfoAction())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentForm)
