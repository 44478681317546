import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './CookiesBanner.scss'
import { AppDispatch } from '../state/utils';
import { AppState } from '../apps/main/store';
import { onCloseCookieInfoAction, promptCookiesModalAction } from '../state/authentication/main/actions';
import {
  getMobileSignupOrLoginClient,
  getFlutterClient,
  isInAppFlutter,
} from '../utils/helpers';
import CookiesModal from './authentication/CookiesModal';

interface Props {
  onCloseCookieInfo: () => void;
  promptCookiesModal: () => void;
  showCookieInfo: boolean;
}

function CookiesBanner({
  onCloseCookieInfo,
  promptCookiesModal,
  showCookieInfo
}: Props) {
  const isIosSpecialistSummary = window.location.href.includes('especialista/summary')
  const mobileSignupOrLoginClient = getMobileSignupOrLoginClient()
  const flutterClient = getFlutterClient()
  const isInApp = isInAppFlutter()

  useEffect(() => {
    if (window.location.search.includes('CookiesInfoWasShown')) {
      onCloseCookieInfo()
    }
  }, [])

  if (
    !showCookieInfo
    || mobileSignupOrLoginClient
    || flutterClient
    || isIosSpecialistSummary
    || isInApp
  ) return null

  return (
    <div className="cookies-info-container">
      <div className="cookies-info">
        <p className="block">
          Nós usamos cookies para melhorar sua experiência de navegação.
          Ao continuar usando nosso site, você aceita nossa&nbsp;
          <button type="button" onClick={promptCookiesModal} onKeyPress={promptCookiesModal} className="button-text-inline">
            <span className="link-cookies-banner">política de cookies</span>.
          </button>
        </p>
        <button
          type="button"
          className="cookies-info-close-button"
          onClick={onCloseCookieInfo}
        >
          Entendi
        </button>
      </div>
      <CookiesModal />
    </div>
  );
}

const mapStateToProps = ({
  authentication,
}: AppState) => ({
  showCookieInfo: authentication.showCookieInfo,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onCloseCookieInfo: onCloseCookieInfoAction,
  promptCookiesModal: promptCookiesModalAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CookiesBanner)
