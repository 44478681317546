import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RegularButton } from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { isPregnant, isTrying } from '../../utils/helpers'
import { AGENDAR, CONSULTAS } from '../../routes/RoutesConstants'
import { User } from '../../domain/User'
import BookingCard from '../myBookingsFlow/BookingCard'
import { getBookingsAction } from '../../state/myBookingsFlow/actions'
import EmptyBookingsDisableImg from '../../../assets/empty-my-bookings-disable.png'
import { Booking } from '../../domain/Booking'
import { SourceTypes } from '../../domain/ScheduleFlow'
import { setScheduleSourceAction } from '../../state/scheduleFlow/actions'
import { TabType } from '../myBookingsFlow/MyBookingsView'

interface Props {
  currentUser?: User
  isFetchingAllBookings: boolean
  bookings: Array<Booking>;
  fetchAllBookings: () => void
  setScheduleSource: (source: SourceTypes) => void;
}

function BookingsComponent({
  currentUser,
  isFetchingAllBookings,
  bookings,
  fetchAllBookings,
  setScheduleSource,
}: Props) {
  const history = useHistory()
  const bookingsToShow: Booking[] = bookings.length > 2
    ? [bookings[0], bookings[1]]
    : bookings

  useEffect(() => {
    if (!isFetchingAllBookings && !bookings.length) {
      fetchAllBookings()
    }
  }, [])

  const goToSchedule = () => {
    setScheduleSource(SourceTypes.HOME)
    history.push(AGENDAR)
  }

  const goToAllBookings = () => {
    history.push(CONSULTAS, { initialTab: TabType.BOOKING })
  }

  return (
    <>
      <div className="bookings-header">
        <p className="bookings-title">Agendamentos</p>
        {bookingsToShow.length !== 0 && (
          <RegularButton
            onClick={goToAllBookings}
            label="Mostrar tudo"
            variant="text"
            btnSize="small"
          />
        )}
      </div>
      {isFetchingAllBookings && bookingsToShow.length === 0 && (
        <BookingCard />
      )}
      {
        bookingsToShow.map((booking) => (
          <BookingCard
            booking={booking}
            key={booking.id}
            showTagStatus={false}
          />
        ))
      }
      {!isFetchingAllBookings && bookingsToShow.length === 0 && (
        <div className="bg-white rounded-2xl pt-8 pb-8 ml-4 lg:ml-0 mb-10 mr-4 lg:mr-6 text-center">
          <img src={EmptyBookingsDisableImg} className="mx-auto w-auto booking-disable-img" alt="" />
          <p className="text-center mt-5 px-2">Você ainda não possui nenhum agendamento</p>
          <div className="mx-5 mt-2">
            <p className="text-textSecondary">
              {isPregnant(currentUser?.pregnancyMoment) && (
                'Primeiro passo é agendar uma consulta presencial com uma de nossas obstetras.'
              )}
              {isTrying(currentUser?.pregnancyMoment) && (
                'Agende uma consulta pré-concepcional presencial com uma de nossas obstetras.'
              )}
            </p>
          </div>
          <RegularButton
            onClick={goToSchedule}
            label="Agendar consulta"
            maxWidth="205px"
            extraClass="m-auto mt-4"
          />
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({
  myBookingsFlow
}: AppState) => ({
  isFetchingAllBookings: myBookingsFlow.getBookingsStatus.isLoading,
  bookings: myBookingsFlow.allBookings,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  fetchAllBookings: getBookingsAction,
  setScheduleSource: setScheduleSourceAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingsComponent)
