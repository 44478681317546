import React, { ChangeEvent } from 'react'
import { NewestCheckbox } from 'theia-web-ds'
import { QuarterConsultation } from '../../domain/GuidedJourney'
import InfographicCard from './InfographicCard'

interface Props {
  cardInfo: QuarterConsultation;
  isLastItem: boolean;
  isLoading?: boolean;
  hideCheckbox?: boolean
  onClickArrowDesktopAction: () => void;
  onClickInfosAction: () => void;
  onClickScheduleAction: () => void;
  onClickCheckbox: (checked: boolean) => void;
}

function StackedCard({
  cardInfo,
  isLastItem,
  isLoading,
  hideCheckbox,
  onClickArrowDesktopAction,
  onClickInfosAction,
  onClickScheduleAction,
  onClickCheckbox,
}: Props) {
  return (
    <div className="flex">
      {!hideCheckbox && (
        <NewestCheckbox
          name={cardInfo.title}
          value={cardInfo.id}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onClickCheckbox(e.target.checked)
          }}
          hasDivider={false}
          defaultChecked={cardInfo.done}
          hasVerticalStepper={!isLastItem}
          isLoading={isLoading}
          status="success"
        />
      )}
      <InfographicCard
        cardInfo={cardInfo}
        key={`infographicCard-${cardInfo.id}-${cardInfo.title}`}
        onClickArrowDesktopAction={onClickArrowDesktopAction}
        onClickInfosAction={onClickInfosAction}
        onClickScheduleAction={onClickScheduleAction}
      />
    </div>
  )
}

export default StackedCard
