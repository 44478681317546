import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  NewListItem,
  Divider,
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  primary,
} from '../../color'
import { GuidedJourney, Quarter } from '../../domain/GuidedJourney'
import { eventTrack } from '../../../eventGenerate'
import { isPediatricJourney } from '../../utils/helpers'
import { JORNADA_EXPLICADA } from '../../routes/RoutesConstants'
import { AppState } from '../../apps/main/store'
import { getChildJourneyAction, getConsultantJourneyAction, selectJourneyQuarterAction } from '../../state/guidedJourney/actions'
import { AppDispatch } from '../../state/utils'
import { ActiveProfileType, ProfilesTypes } from '../../domain/AppProfiles'
import { StatusType } from '../../domain/Status'
import { User } from '../../domain/User'
import ProfilePicture from '../common/molecules/ProfilePicture'
import './GuidedJourneyComponent.scss'

interface Props {
  guidedJourney?: GuidedJourney;
  getGuidedJourneyStatus: StatusType;
  activeProfile?: ActiveProfileType;
  currentUser?: User
  onSelectQuarter: (quarter: Quarter) => void;
  getChildJourney: (childId: string) => void;
  getConsultantJourney: () => void;
}

function GuidedJourneyComponent({
  guidedJourney,
  getGuidedJourneyStatus,
  activeProfile,
  currentUser,
  onSelectQuarter,
  getChildJourney,
  getConsultantJourney
}: Props) {
  const history = useHistory()
  const pediatricJourney = !!(guidedJourney?.list
    && isPediatricJourney(guidedJourney?.list[0]?.journeyType))

  function handleClickItem(quarter: Quarter) {
    eventTrack(`Clicou ${quarter.title} - jornada explicada`, {
      tipo_de_perfil: activeProfile?.type,
      momento: currentUser?.pregnancyMoment
    })
    onSelectQuarter(quarter)
    history.push(JORNADA_EXPLICADA)
  }

  useEffect(() => {
    if (activeProfile?.id && activeProfile?.type === ProfilesTypes.CHILD) {
      getChildJourney(activeProfile.id)
    }
    if (activeProfile && activeProfile.type === ProfilesTypes.CONSULTANT) {
      getConsultantJourney()
    }
  }, [activeProfile?.id, currentUser?.pregnancyMoment])

  const journeySkelleton = (
    <div className="guided-journey__loader-container">
      <div className="loader__title-skelleton skeleton" />
      {[...Array(3)].map((_, index) => (
        <div className="loader__quarter-skelleton" key={`skelleton-quarter-${index}`}>
          <div className="quarter-image-skelleton skeleton" />
          <div className="quarter-text">
            <div className="quarter-title-skelleton skeleton" />
            <div className="quarter-week-skelleton skeleton" />
          </div>
          <div className="tooltip-skelleton skeleton" />
          <div className="icon-skelleton skeleton" />
        </div>
      ))}
    </div>
  )

  return (
    <>
      {getGuidedJourneyStatus.isLoading && journeySkelleton}
      {!getGuidedJourneyStatus.isLoading && guidedJourney?.list?.length ? (
        <div className="guided-journey">
          <div className="relative">
            <p className="journey-title">
              {pediatricJourney
                ? 'Consultas para o primeiro ano do bebê'
                : 'Consultas para realizar no seu Pré-natal'}
            </p>
          </div>
          <div>
            {guidedJourney && guidedJourney.list?.map((quarter: Quarter) => (
              <div className="relative quarter-btn" key={quarter.id}>
                <NewListItem
                  onClick={() => handleClickItem(quarter)}
                  iconColor={primary}
                  title={quarter.title}
                  titleSize="text-xl"
                  caption={quarter.description}
                  captionColor={quarter.currentQuarter ? 'text-primary' : ''}
                  ariaLabel={quarter.title}
                  id={quarter.id}
                  isLastItem
                  picture={(
                    <div className="relative mx-4">
                      <ProfilePicture
                        pictureUrl={quarter.imgUrl}
                        pictureSize={40}
                      />
                    </div>
                  )}
                />
                <Divider />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  )
}

const mapStateToProps = ({
  guidedJourney,
  appProfiles,
  authentication
}: AppState) => ({
  guidedJourney: guidedJourney.guidedJourney,
  getGuidedJourneyStatus: guidedJourney.getGuidedJourneyStatus,
  activeProfile: appProfiles.activeProfile,
  currentUser: authentication?.currentUser
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  getConsultantJourney: getConsultantJourneyAction,
  getChildJourney: getChildJourneyAction,
  onSelectQuarter: selectJourneyQuarterAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuidedJourneyComponent)
