import { Formik, FormikProps } from 'formik'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Divider, RegularButton, FormInput, DateInput
} from 'theia-web-ds'
import { useHistory } from 'react-router-dom'
import { bgDisable, success } from '../../color'
import Emoji from '../common/atoms/Emoji'
import Header from '../common/molecules/Header'
import StepperBar from '../common/StepperBar'
import BooleanInput from '../common/molecules/inputs/BooleanInput'
import FormInputList from '../common/molecules/inputs/FormInputList'
import FormMultiSelect from '../common/molecules/inputs/FormMultiSelect'
import BannerUserDataInfo from './BannerUserDataInfo'
import {
  HealthInfosForm,
  ConsultantMoment,
  SubstanceUseOptions,
  WorkTypeOptions
} from '../../domain/Profile'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { fetchProfileAction, onUpdateHealthInfosInProfileAction } from '../../state/profile/actions'
import FormInputPlaceholder from '../common/molecules/placeholders/FormInputPlaceholder'
import ButtonPlaceholder from '../common/molecules/placeholders/ButtonPlaceholder'
import { formCheckHasOneElementsEmpty, isPregnant, isTrying } from '../../utils/helpers'
import './HealthGestationalAndPersonalInfos.scss'
import { DADOS_GESTACIONAIS, DADOS_PESSOAIS } from '../../routes/RoutesConstants'

function renderHealthInfosForm(
  {
    values,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur
  }: FormikProps<HealthInfosForm>,
  isLoading: boolean,
  isSubmitting: boolean,
  pregnancyMoment?: ConsultantMoment
) {
  const [allergiesError, setAllergiesError] = useState<{[index: number]: string}>()
  const [useSomeMedicineError, setUseSomeMedicineError] = useState<{[index: number]: string}>()
  const [foodRestrictionError, setFoodRestrictionError] = useState<{[index: number]: string}>()
  const [
    someHealthConditionError,
    setSomeHealthConditionError
  ] = useState<{[index: number]: string}>()
  const [workTypeOthersError, setWorkTypeOthersError] = useState<{[index: number]: string}>()
  const [
    substanceUseOthersError,
    setSubstanceUseOthersError
  ] = useState<{[index: number]: string}>()
  const disabledSubmit = (
    !!allergiesError
    || !!useSomeMedicineError
    || !!foodRestrictionError
    || !!someHealthConditionError
    || !!workTypeOthersError
    || !!substanceUseOthersError
  )

  useEffect(() => {
    setAllergiesError(formCheckHasOneElementsEmpty(values.allergies))
  }, [values.allergies])

  useEffect(() => {
    setUseSomeMedicineError(formCheckHasOneElementsEmpty(values.useSomeMedicine))
  }, [values.useSomeMedicine])

  useEffect(() => {
    setFoodRestrictionError(formCheckHasOneElementsEmpty(values.foodRestriction))
  }, [values.foodRestriction])

  useEffect(() => {
    setSomeHealthConditionError(formCheckHasOneElementsEmpty(values.someHealthCondition))
  }, [values.someHealthCondition])

  useEffect(() => {
    setWorkTypeOthersError(formCheckHasOneElementsEmpty(values.workTypeOthers))
  }, [values.workTypeOthers])

  useEffect(() => {
    setSubstanceUseOthersError(formCheckHasOneElementsEmpty(values.substanceUseOthers))
  }, [values.substanceUseOthers])

  function onChangeLastPeriodDate(date: Date) {
    if (!date) return
    setFieldValue('lastPeriodStartDate', date.getTime());
    setFieldTouched('lastPeriodStartDate', true);
  }

  function onChangeHasUseSomeMedicine(newValue: boolean) {
    setFieldValue('hasUseSomeMedicine', `${newValue}`);
    setFieldTouched('hasUseSomeMedicine', true);
    if (newValue) {
      setFieldValue('useSomeMedicine', ['']);
      setFieldTouched('useSomeMedicine', true);
    } else {
      setFieldValue('useSomeMedicine', []);
      setFieldTouched('useSomeMedicine', true);
    }
  }

  function onChangeUseSomeMedicine(newList: string[]) {
    setFieldValue('useSomeMedicine', newList);
    setFieldTouched('useSomeMedicine', true);
  }

  function onChangeHasSomeHealthCondition(newValue: boolean) {
    setFieldValue('hasSomeHealthCondition', `${newValue}`);
    setFieldTouched('hasSomeHealthCondition', true);
    if (newValue) {
      setFieldValue('someHealthCondition', ['']);
      setFieldTouched('someHealthCondition', true);
    } else {
      setFieldValue('someHealthCondition', []);
      setFieldTouched('someHealthCondition', true);
    }
  }

  function onChangeSomeHealthCondition(newList: string[]) {
    setFieldValue('someHealthCondition', newList);
    setFieldTouched('someHealthCondition', true);
  }

  function onChangeHasFoodRestriction(newValue: boolean) {
    setFieldValue('hasFoodRestriction', `${newValue}`);
    setFieldTouched('hasFoodRestriction', true);
    if (newValue) {
      setFieldValue('foodRestriction', ['']);
      setFieldTouched('foodRestriction', true);
    } else {
      setFieldValue('foodRestriction', []);
      setFieldTouched('foodRestriction', true);
    }
  }

  function onChangeFoodRestriction(newList: string[]) {
    setFieldValue('foodRestriction', newList);
    setFieldTouched('foodRestriction', true);
  }

  function onChangeHasAllergy(newValue: boolean) {
    setFieldValue('hasAllergy', `${newValue}`);
    setFieldTouched('hasAllergy', true);
    if (newValue) {
      setFieldValue('allergies', ['']);
      setFieldTouched('allergies', true);
    } else {
      setFieldValue('allergies', []);
      setFieldTouched('allergies', true);
    }
  }

  function onChangeAllergies(newList: string[]) {
    setFieldValue('allergies', newList);
    setFieldTouched('allergies', true);
  }

  function onChangeWorkType(newList: string[]) {
    setFieldValue('workType', newList);
    setFieldTouched('workType', true);
    if (newList.includes(WorkTypeOptions.OTHER) && values.workTypeOthers.length === 0) {
      setFieldValue('workTypeOthers', ['']);
      setFieldTouched('workTypeOthers', true);
    }
    if (!newList.includes(WorkTypeOptions.OTHER) && values.workTypeOthers.length > 0) {
      setFieldValue('workTypeOthers', []);
      setFieldTouched('workTypeOthers', true);
    }
  }

  function onChangeWorkTypeOthers(newList: string[]) {
    setFieldValue('workTypeOthers', newList);
    setFieldTouched('workTypeOthers', true);
  }

  function onChangeSubstanceUse(newList: string[]) {
    setFieldValue('substanceUse', newList);
    setFieldTouched('substanceUse', true);
    if (newList.includes(SubstanceUseOptions.OTHER) && values.substanceUseOthers.length === 0) {
      setFieldValue('substanceUseOthers', ['']);
      setFieldTouched('substanceUseOthers', true);
    }
    if (!newList.includes(SubstanceUseOptions.OTHER) && values.substanceUseOthers.length > 0) {
      setFieldValue('substanceUseOthers', []);
      setFieldTouched('substanceUseOthers', true);
    }
  }

  function onChangeSubstanceUseOthers(newList: string[]) {
    setFieldValue('substanceUseOthers', newList);
    setFieldTouched('substanceUseOthers', true);
  }

  const datesComponents = (
    <DateInput
      required
      id="lastPeriodStartDate"
      name="lastPeriodStartDate"
      onChange={onChangeLastPeriodDate}
      blockFutureDates
      placeholder="Selecione uma data"
      label="Data da última menstruação"
      value={values.lastPeriodStartDate}
      extraClassName="last-period-container"
    />
  )
  const realDatesComponent = isLoading ? <FormInputPlaceholder /> : datesComponents

  return (
    <form onSubmit={handleSubmit} className="user-profile-infos-form" noValidate>
      <div className="shared-container">
        {(isPregnant(pregnancyMoment)) && realDatesComponent}
        <div className="weigth-container">
          <FormInput
            value={String(values.weight)}
            onChange={handleChange}
            errorMessage={touched.weight ? errors.weight : ''}
            id="weight"
            onBlur={handleBlur}
            label="Peso atual (kg)"
            name="weight"
            type="number"
            placeholder="0.00 (kg)"
            isLoading={isLoading}
            required={false}
          />
        </div>
      </div>
      <div className="individual-container">
        <BooleanInput
          label="Faz uso de alguma medicação?"
          name="hasUseSomeMedicine"
          value={values.hasUseSomeMedicine === '' ? undefined : values.hasUseSomeMedicine === 'true'}
          onChange={onChangeHasUseSomeMedicine}
          isLoading={isLoading}
        />
        {values.hasUseSomeMedicine === 'true' && (
          <FormInputList
            placeholder="* Digite qual medicação"
            ariaLabel="Digite qual medicação"
            list={values.useSomeMedicine}
            errors={useSomeMedicineError}
            onChange={onChangeUseSomeMedicine}
            required
          />
        )}
      </div>
      <div className="individual-container">
        <BooleanInput
          label="Tem alguma condição de saúde?"
          name="hasSomeHealthCondition"
          value={values.hasSomeHealthCondition === '' ? undefined : values.hasSomeHealthCondition === 'true'}
          onChange={onChangeHasSomeHealthCondition}
          isLoading={isLoading}
        />
        {values.hasSomeHealthCondition === 'true' && (
          <FormInputList
            placeholder="* Digite qual condição"
            ariaLabel="Digite qual condição"
            list={values.someHealthCondition}
            errors={someHealthConditionError}
            onChange={onChangeSomeHealthCondition}
            required
          />
        )}
      </div>
      <div className="individual-container">
        <BooleanInput
          label="Possui alguma restrição alimentar?"
          name="hasFoodRestriction"
          value={values.hasFoodRestriction === '' ? undefined : values.hasFoodRestriction === 'true'}
          onChange={onChangeHasFoodRestriction}
          isLoading={isLoading}
        />
        {values.hasFoodRestriction === 'true' && (
          <FormInputList
            placeholder="* Digite qual restrição"
            ariaLabel="Digite qual restrição"
            list={values.foodRestriction}
            errors={foodRestrictionError}
            onChange={onChangeFoodRestriction}
            required
          />
        )}
      </div>
      <div className="individual-container">
        <BooleanInput
          label="Possui alguma alergia (medicação, materiais)?"
          name="hasAllergy"
          value={values.hasAllergy === '' ? undefined : values.hasAllergy === 'true'}
          onChange={onChangeHasAllergy}
          isLoading={isLoading}
        />
        {values.hasAllergy === 'true' && (
          <FormInputList
            placeholder="* Digite qual alergia"
            ariaLabel="Digite qual alergia"
            list={values.allergies}
            errors={allergiesError}
            onChange={onChangeAllergies}
            required
          />
        )}
      </div>
      {(!pregnancyMoment || !isPregnant(pregnancyMoment)) && (
        <div className="individual-container">
          <FormMultiSelect
            id="substanceUse"
            label="Faz uso de alguma dessas substâncias?"
            options={Object.values(SubstanceUseOptions)}
            onChange={onChangeSubstanceUse}
            isLoading={isLoading}
            initialValues={values.substanceUse}
          />
          {values.substanceUse.includes(SubstanceUseOptions.OTHER) && (
            <FormInputList
              placeholder="* Digite qual substância"
              ariaLabel="Digite qual substância"
              list={values.substanceUseOthers}
              errors={substanceUseOthersError}
              onChange={onChangeSubstanceUseOthers}
              required
            />
          )}
        </div>
      )}
      <div className="individual-container">
        <FormMultiSelect
          id="workType"
          label="Como são seus dias?"
          options={Object.values(WorkTypeOptions).filter((e) => e !== WorkTypeOptions.NONE)}
          onChange={onChangeWorkType}
          isLoading={isLoading}
          initialValues={values.workType}
        />
        {values.workType.includes(WorkTypeOptions.OTHER) && (
          <FormInputList
            placeholder="* Digite qual atividade"
            ariaLabel="Digite qual atividade"
            list={values.workTypeOthers}
            errors={workTypeOthersError}
            onChange={onChangeWorkTypeOthers}
            required
          />
        )}
      </div>
      <BannerUserDataInfo extraClassName="banner-small-screen" isLoading={isLoading} />
      <div className="button-container">
        {isLoading ? (
          <ButtonPlaceholder />
        ) : (
          <RegularButton
            onClick={() => handleSubmit()}
            label="Ir para o passo 2"
            type="submit"
            disabled={disabledSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      </div>
    </form>
  )
}

const emptyList: string[] = []

const initialValues: HealthInfosForm = {
  weight: undefined,
  lastPeriodStartDate: '',
  hasUseSomeMedicine: '',
  useSomeMedicine: emptyList,
  hasSomeHealthCondition: '',
  someHealthCondition: emptyList,
  hasFoodRestriction: '',
  foodRestriction: emptyList,
  hasAllergy: '',
  allergies: emptyList,
  workType: emptyList,
  workTypeOthers: emptyList,
  substanceUse: emptyList,
  substanceUseOthers: emptyList,
}

interface Props {
  isFetchingProfile: boolean;
  fetchProfile: () => void;
  hasProfile: boolean;
  pregnancyMoment?: ConsultantMoment;
  weight?: number;
  lastPeriodStartDate?: string;
  hasUseSomeMedicine?: string;
  useSomeMedicine?: string[];
  hasSomeHealthCondition?: string;
  someHealthCondition?: string[];
  hasFoodRestriction?: string;
  foodRestriction?: string[];
  hasAllergy?: string;
  allergies?: string[];
  workType?: string[];
  workTypeOthers?: string[];
  substanceUse?: string[];
  substanceUseOthers?: string[];
  name?: string;
  email?: string;
  phone?: string;
  onUpdateHealthInfosInProfile: (
    name: string,
    email: string,
    phone: string,
    healthInfos: HealthInfosForm
  ) => void;
  forceUpdateCurrentUser: boolean;
  isFetchingUser: boolean;
  isUpdatingProfile: boolean;
  hasKids?: string;
}

function HealthInfos({
  isFetchingProfile,
  fetchProfile,
  hasProfile,
  pregnancyMoment,
  weight,
  lastPeriodStartDate,
  hasUseSomeMedicine,
  useSomeMedicine,
  hasSomeHealthCondition,
  someHealthCondition,
  hasFoodRestriction,
  foodRestriction,
  hasAllergy,
  allergies,
  workType,
  workTypeOthers,
  substanceUse,
  substanceUseOthers,
  name,
  email,
  phone,
  onUpdateHealthInfosInProfile,
  forceUpdateCurrentUser,
  isFetchingUser,
  isUpdatingProfile,
  hasKids
}: Props) {
  const history = useHistory()
  const [updateInfos, setUpdateInfos] = useState(false)
  const isLoading = !hasProfile || isFetchingProfile

  useEffect(() => {
    if (!hasProfile && !isFetchingProfile) {
      fetchProfile()
    }
  }, [])

  useEffect(() => {
    if (forceUpdateCurrentUser && updateInfos && !isFetchingUser) {
      setUpdateInfos(false)
      if (
        isPregnant(pregnancyMoment)
        || isTrying(pregnancyMoment)
        || hasKids === 'true'
      ) {
        history.push(DADOS_GESTACIONAIS)
      } else {
        history.push(DADOS_PESSOAIS, { has3Steps: false })
      }
    }
  }, [forceUpdateCurrentUser])

  initialValues.weight = (
    weight && weight !== null
      ? weight
      : initialValues.weight
  )
  initialValues.substanceUse = (
    substanceUse && substanceUse !== null
      ? substanceUse
      : initialValues.substanceUse
  )
  initialValues.substanceUseOthers = (
    substanceUseOthers && substanceUseOthers !== null
      ? substanceUseOthers
      : initialValues.substanceUseOthers
  )
  initialValues.hasAllergy = hasAllergy
    || initialValues.hasAllergy
  initialValues.allergies = (
    allergies && allergies !== null
      ? allergies
      : initialValues.allergies
  )
  initialValues.lastPeriodStartDate = (lastPeriodStartDate || initialValues.lastPeriodStartDate)
  initialValues.hasUseSomeMedicine = hasUseSomeMedicine
      || initialValues.hasUseSomeMedicine
  initialValues.useSomeMedicine = useSomeMedicine || initialValues.useSomeMedicine
  initialValues.hasSomeHealthCondition = hasSomeHealthCondition
    || initialValues.hasSomeHealthCondition
  initialValues.someHealthCondition = (someHealthCondition || initialValues.someHealthCondition)
  initialValues.hasFoodRestriction = hasFoodRestriction
    || initialValues.hasFoodRestriction
  initialValues.foodRestriction = (foodRestriction || initialValues.foodRestriction)
  initialValues.workType = workType || initialValues.workType
  initialValues.workTypeOthers = workTypeOthers || initialValues.workTypeOthers
  return (
    <div className="user-profile-infos-container">
      <div className="header-container">
        <Header
          title="Dados de saúde"
          isLoading={isLoading}
        />
        <Divider />
      </div>
      <div className="user-profile-infos-body-outer-container">
        <div className="user-profile-infos-body-inner-container">
          <StepperBar
            totalSteps={3}
            currentStep={1}
            showInfosBottom
            showNumbers={false}
            currentStepColor={success}
            totalStepsColor={bgDisable}
            isLoading={isLoading}
            lineHeight="8px"
            lineRadius="40px"
            explanation={(
              <div className="stepper-explanation-container">
                <Emoji symbol="👩🏽‍⚕️" />
                <p className="stepper-explanation-text">Sua equipe médica precisa saber</p>
              </div>
            )}
          />
          <Divider />
        </div>
        <div className="user-profile-infos-form-and-explanation-container">
          <div className="user-profile-infos-form-container">
            <Formik
              initialValues={initialValues}
              onSubmit={(_value: HealthInfosForm) => {
                if (name && email && phone) {
                  setUpdateInfos(true)
                  onUpdateHealthInfosInProfile(name, email, phone, _value)
                }
              }}
            >
              {(props) => renderHealthInfosForm(
                props,
                isLoading,
                isUpdatingProfile,
                pregnancyMoment
              )}
            </Formik>
          </div>
          <BannerUserDataInfo extraClassName="banner-large-screen" isLoading={isLoading} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  profile,
  authentication
}: AppState) => ({
  hasProfile: !!profile.profile,
  pregnancyMoment: profile.profile?.pregnancyMoment,
  isFetchingProfile: profile.isFetchingProfile,
  weight: profile.profile?.weight,
  lastPeriodStartDate: profile.profile?.lastPeriodStartDate,
  hasUseSomeMedicine: profile.profile?.hasUseSomeMedicine,
  useSomeMedicine: profile.profile?.useSomeMedicine,
  hasSomeHealthCondition: profile.profile?.hasSomeHealthCondition,
  someHealthCondition: profile.profile?.someHealthCondition,
  hasFoodRestriction: profile.profile?.hasFoodRestriction,
  foodRestriction: profile.profile?.foodRestriction,
  hasAllergy: profile.profile?.hasAllergy,
  allergies: profile.profile?.allergies,
  workType: profile.profile?.workType,
  workTypeOthers: profile.profile?.workTypeOthers,
  substanceUse: profile.profile?.substanceUse,
  substanceUseOthers: profile.profile?.substanceUseOthers,
  name: authentication.currentUser?.name,
  email: authentication.currentUser?.email,
  phone: authentication.currentUser?.phone,
  forceUpdateCurrentUser: authentication.forceUpdateCurrentUser,
  isFetchingUser: authentication.isFetchingUser,
  isUpdatingProfile: profile.isUpdatingProfile,
  hasKids: profile.profile?.hasKids,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchProfile: () => { dispatch(fetchProfileAction()) },
  onUpdateHealthInfosInProfile: (
    name: string,
    email: string,
    phone: string,
    healthInfos: HealthInfosForm
  ) => {
    dispatch(onUpdateHealthInfosInProfileAction(name, email, phone, healthInfos))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthInfos)
