import {
  REQUEST_BOOKING_CONFIRMATION_INFO_SMS,
  SUCCESS_BOOKING_CONFIRMATION_INFO_SMS,
  FAILURE_BOOKING_CONFIRMATION_INFO_SMS,
  REQUEST_CONFIRM_BOOKING_SMS,
  SUCCESS_CONFIRM_BOOKING_SMS,
  FAILURE_CONFIRM_BOOKING_SMS,
  REQUEST_CANCEL_BOOKING_SMS,
  SUCCESS_CANCEL_BOOKING_SMS,
  FAILURE_CANCEL_BOOKING_SMS,
  ScheduleConfirmationSMSTypes,
  PROMPT_CANCEL_BOOKING_SMS_MODAL,
  SUPPRESS_CANCEL_BOOKING_SMS_MODAL,
  REQUEST_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS,
  SUCCESS_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS,
  FAILURE_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS,
  PROMPT_CONFIRM_BOOKING_SMS_MODAL,
  SUPPRESS_CONFIRM_BOOKING_SMS_MODAL
} from './actions'
import { LocalAddress } from '../../utils/LocalAddress'
import { TheiaError } from '../../domain/errors/TheiaError';

export type bookingConfirmationStepTypes = 'initial' | 'just-confirmed' | 'just-canceled';

export interface ScheduleConfirmationState {
  step: bookingConfirmationStepTypes;
  bookingId: string;
  token: string;
  error?: {
    message?: string;
    title?: string;
  };
  isLoading: boolean;
  isModalOpened: boolean;
  startDate?: string;
  professionalName: string;
  address?: LocalAddress;
  alreadyConfirmed: boolean;
  alreadyCancelled: boolean;
  confirmationAvailable: boolean;
  isProfessionalModalOpened: boolean;
  status?: string;
  participantType: string;
  bookingConfirmInfosError?: TheiaError
}

export const initialState: ScheduleConfirmationState = {
  step: 'initial',
  bookingId: '',
  token: '',
  error: {
    message: '',
    title: ''
  },
  isLoading: false,
  isModalOpened: false,
  startDate: undefined,
  professionalName: '',
  alreadyConfirmed: false,
  alreadyCancelled: false,
  confirmationAvailable: true,
  isProfessionalModalOpened: false,
  participantType: '',
  bookingConfirmInfosError: undefined
}

export function scheduleConfirmationSMS(
  state = initialState,
  action: ScheduleConfirmationSMSTypes
): ScheduleConfirmationState {
  switch (action.type) {
    case REQUEST_BOOKING_CONFIRMATION_INFO_SMS:
      return {
        ...state,
        isLoading: true,
        bookingId: action.bookingId,
        token: action.token,
        bookingConfirmInfosError: undefined
      }
    case SUCCESS_BOOKING_CONFIRMATION_INFO_SMS:
      return {
        ...state,
        isLoading: false,
        startDate: action.startDate,
        professionalName: action.professionalName,
        address: action.address,
        alreadyConfirmed: action.alreadyConfirmed,
        alreadyCancelled: action.alreadyCancelled,
        confirmationAvailable: action.confirmationAvailable
      }
    case FAILURE_BOOKING_CONFIRMATION_INFO_SMS:
      return {
        ...state,
        isLoading: false,
        bookingConfirmInfosError: action.error
      }
    case REQUEST_CONFIRM_BOOKING_SMS:
      return {
        ...state,
        isLoading: true,
        isModalOpened: false
      }
    case SUCCESS_CONFIRM_BOOKING_SMS:
      return {
        ...state,
        isLoading: false,
        step: 'just-confirmed',
        isModalOpened: false
      }
    case FAILURE_CONFIRM_BOOKING_SMS:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isModalOpened: false
      }
    case REQUEST_CANCEL_BOOKING_SMS:
      return {
        ...state,
        isLoading: true,
        isModalOpened: false
      }
    case SUCCESS_CANCEL_BOOKING_SMS:
      return {
        ...state,
        isLoading: false,
        step: 'just-canceled',
        isModalOpened: false
      }
    case FAILURE_CANCEL_BOOKING_SMS:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isModalOpened: false
      }
    case PROMPT_CANCEL_BOOKING_SMS_MODAL:
      return {
        ...state,
        isModalOpened: true,
        isLoading: false
      }
    case SUPPRESS_CANCEL_BOOKING_SMS_MODAL:
      return {
        ...state,
        isModalOpened: false
      }
    case REQUEST_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS:
      return {
        ...state,
        isLoading: true,
        bookingId: action.bookingId,
        token: action.token
      }
    case SUCCESS_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS:
      return {
        ...state,
        isLoading: false,
        startDate: action.startDate,
        professionalName: action.professionalName,
        status: action.status
      }
    case FAILURE_BOOKING_PROFESSIONAL_CONFIRMATION_INFO_SMS:
      return {
        ...state,
        isLoading: false,
        step: 'initial'
      }
    case PROMPT_CONFIRM_BOOKING_SMS_MODAL:
      return {
        ...state,
        isModalOpened: true,
        isLoading: false
      }
    case SUPPRESS_CONFIRM_BOOKING_SMS_MODAL:
      return {
        ...state,
        isModalOpened: false
      }
    default: return state
  }
}
