import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  AccessibleModal,
  NewestCheckbox,
  RegularButton,
  Snackbar
} from 'theia-web-ds'
import ExclamationError from '../../../assets/exclamation-error.svg'
import './ChangeMomentConfirmationModal.scss'
import { ConsultantMoment } from '../../domain/Profile'
import { eventTrack } from '../../../eventGenerate'

interface Props {
  visible: boolean
  lastIdFocused: string
  newMoment?: ConsultantMoment
  isSubmitting: boolean;
  previousMoment?: ConsultantMoment
  onConfirmChange: () => void
  suppressModal: () => void
  onCancel?: () => void
}

export default function ChangeMomentConfirmationModal({
  visible,
  lastIdFocused,
  newMoment,
  isSubmitting,
  previousMoment,
  onConfirmChange,
  suppressModal,
  onCancel,
}: Props) {
  const [confirmed, setConfirmed] = useState(false)

  useEffect(() => {
    if (visible) {
      eventTrack('Visualizou modal confirmação mudança de momento', {
        'novo momento': newMoment
      })
    }
  }, [visible])

  function onClickCheckbox(value: boolean) {
    setConfirmed(value)
    if (value) {
      eventTrack('Marcou checkbox li e confirmo alteração', {
        'novo momento': newMoment
      })
    }
  }

  function handleConfirmMomentChange() {
    onConfirmChange()
  }

  function handleCancel() {
    if (onCancel) onCancel()
    suppressModal()
  }

  return (
    <AccessibleModal
      extraOuterClassName="moment-alert-modal-outer"
      extraModalContainerClass="moment-alert-modal"
      visible={visible}
      onClose={suppressModal}
      headerText=""
      lastIdFocused={lastIdFocused}
      showCloseButton
      variant="centerBottom"
    >
      <div className="moment-alert-body-content">
        <div className="moment-alert-icon">
          <ExclamationError />
        </div>
        <p className="moment-alert-text">Ao mudar seu status de {previousMoment} para {newMoment}, você indicará que encerrou sua fase gestacional</p>
        <Snackbar
          variant="error"
          text="Seu cartão de gestante será arquivado para consulta, mas não poderá ser editado ou atualizado"
          title="Atenção! Essa mudança é permanente."
        />
        <NewestCheckbox
          name="confirm"
          value={`${confirmed}`}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onClickCheckbox(e.target.checked)
          }}
          defaultChecked={confirmed}
          label="Li e confirmo a alteração."
        />
      </div>
      <div className="moment-alert-modal-footer">
        <RegularButton
          label="Cancelar"
          onClick={handleCancel}
          variant="text"
        />
        <RegularButton
          label="Confirmar"
          onClick={handleConfirmMomentChange}
          disabled={!confirmed}
          isSubmitting={isSubmitting}
        />
      </div>
    </AccessibleModal>
  )
}
