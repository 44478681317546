import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  DateInput,
  FormInput,
  Loader,
  MaskedFormInput,
  RegularButton,
  Snackbar
} from 'theia-web-ds'
import Header from '../common/molecules/Header'
import { BabyData } from '../../domain/PediatricFlow'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { saveDependentDataAction, clearSaveDependentStatusAction } from '../../state/appProfiles/actions'
import { StatusType } from '../../domain/Status'
import {
  cpfMask,
  cpfValidation,
  onlyDigits,
  specialCharacterRegex,
  validationFullNameRegex
} from '../../utils/helpers'
import { success, warning } from '../../color'
import { showNotificationAction } from '../../state/notifications/actions'
import { ToastInfos, ToastType } from '../../domain/Notifications'
import { getChildrenDataAction } from '../../state/pediatricFlow/actions'
import { ActiveProfileType } from '../../domain/AppProfiles'
import './AddNewDependent.scss'

function renderEditDependentForm(
  {
    values,
    errors,
    handleSubmit,
    touched,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue
  }: FormikProps<BabyData>,
  saveDependentDataStatus: StatusType,
  dependentData?: BabyData
) {
  const hasChangedValues = dependentData?.cpf !== onlyDigits(values.cpf)
      || dependentData?.name !== values.name
      || dependentData?.birthDate !== values.birthDate

  return (
    <form onSubmit={handleSubmit} className="save-dependent-form">
      <div className="save-dependent-form-content">
        <FormInput
          label="Nome completo"
          name="name"
          type="text"
          placeholder="Igual ao documento oficial"
          id="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={touched.name ? errors.name : ''}
          extraClassContainer="mb-4"
        />
        <DateInput
          id="birthDate"
          name="birthDate"
          onChange={(value) => {
            setFieldValue('birthDate', value.getTime())
          }}
          onBlur={() => setFieldTouched('birthDate')}
          blockFutureDates
          placeholder="DD/MM/AA"
          label="Data de nascimento"
          value={values.birthDate}
          extraClassName="mb-4"
          errorText={touched.birthDate ? errors.birthDate : ''}
          hasError={!!(touched.birthDate && errors.birthDate)}
          shouldCloseOnSelect
        />
        <MaskedFormInput
          label="CPF"
          errorMessage={touched.cpf ? errors.cpf : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          id="cpf"
          value={values.cpf}
          name="cpf"
          type="text"
          placeholder="000.000.000-00"
          mask={cpfMask}
          extraContainerClassName="mb-4"
        />
      </div>
      <div className="save-dependent-form-btn">
        <RegularButton
          label="Salvar"
          type="submit"
          variant="primary"
          disabled={!hasChangedValues}
          isSubmitting={saveDependentDataStatus.isLoading}
        />
      </div>
    </form>
  )
}

interface Props {
  saveDependentDataStatus: StatusType
  childrenData?: BabyData[]
  consultantDocument?: string
  activeProfile?: ActiveProfileType
  getChildrenDataStatus: StatusType
  saveDependentData: (payload: BabyData) => void
  showNotification: (toatInfos: ToastInfos) => void
  clearSaveDependentStatus: () => void
  getChildrenData: () => void
}

function EditDependent({
  saveDependentDataStatus,
  childrenData,
  consultantDocument,
  activeProfile,
  getChildrenDataStatus,
  saveDependentData,
  showNotification,
  clearSaveDependentStatus,
  getChildrenData
}: Props) {
  const history = useHistory()
  const [submittedValues, setSubmittedValues] = useState<BabyData | undefined>()

  const goBack = () => {
    history.goBack()
  }

  const dependentData = useMemo(() => childrenData?.find(
    (child) => child.id === activeProfile?.id
  ), [activeProfile, childrenData])

  const alreadyRegisteredDocuments = useMemo(() => childrenData?.map((child) => child.cpf)
    .filter(
      (cpf) => cpf !== undefined && cpf !== null && cpf !== dependentData?.cpf
    ) || [], [childrenData])

  const initialValues: BabyData = submittedValues || {
    name: dependentData?.name || '',
    birthDate: dependentData?.birthDate || '',
    cpf: dependentData?.cpf || '',
    id: dependentData?.id
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Campo obrigatório')
      .matches(validationFullNameRegex, 'Informe o nome e sobrenome para continuar')
      .matches(specialCharacterRegex, 'Inválido caracter especial'),
    birthDate: Yup.string().required('Campo obrigatório'),
    cpf: Yup.string()
      .required('Campo obrigatório')
      .transform((value) => value.replace(/[^\d]/g, ''))
      .test(
        'test-document',
        'CPF inválido',
        (value) => cpfValidation(value)
      )
      .test(
        'test-docs',
        'CPF já cadastrado. Por favor, verifique se o CPF inserido é do bebê.',
        (value) => !alreadyRegisteredDocuments.includes(onlyDigits(value))
      )
      .test(
        'test-cpf',
        'Coloque um CPF diferente do seu',
        (value) => onlyDigits(value) !== consultantDocument
      )
  })

  const submitValues = (values?: BabyData) => {
    const formattedValues = {
      ...values,
      cpf: onlyDigits(values?.cpf || '')
    }
    setSubmittedValues(formattedValues)
    saveDependentData(formattedValues)
  }

  useEffect(() => {
    if (saveDependentDataStatus.success) {
      showNotification({
        message: 'Dados alterados com sucesso!',
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_SAVE_NEW_DEPENDENT',
        type: ToastType.SUCCESS,
      })
      goBack()
      clearSaveDependentStatus()
    }
  }, [saveDependentDataStatus])

  useEffect(() => {
    getChildrenData()
    return () => clearSaveDependentStatus()
  }, [])

  return (
    <div className="save-dependent-data-view">
      <div className="save-dependent-data-header">
        <Header title="Editar dados" goBack={goBack} />
      </div>
      {getChildrenDataStatus.isLoading ? (
        <div className="save-dependent-data-loader">
          <Loader />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitValues}
        >
          {(props) => renderEditDependentForm(
            props,
            saveDependentDataStatus,
            dependentData
          )}
        </Formik>
      )}
      {saveDependentDataStatus.error && (
        <div className="save-dependent-snackbar-error">
          <Snackbar
            title={saveDependentDataStatus.errorMessage?.friendlyMessageTitle
              || 'Erro ao salvar'}
            text={saveDependentDataStatus.errorMessage?.friendlyMessage
              || 'Desculpe, houve um erro inesperado. Por favor tente novamente'}
            buttonTwoProps={{
              label: 'Tentar novamente',
              onClick: () => submitValues(submittedValues)
            }}
            variant="alert"
            iconLeft="icon-CloseCircleLight"
            customIconColor={warning}
            showCloseButton
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  appProfiles, pediatricFlow, authentication
}: AppState) => ({
  saveDependentDataStatus: appProfiles.saveDependentDataStatus,
  childrenData: pediatricFlow.childrenData,
  consultantDocument: authentication.currentUser?.document,
  activeProfile: appProfiles.activeProfile,
  getChildrenDataStatus: pediatricFlow.getChildrenDataStatus
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  saveDependentData: saveDependentDataAction,
  showNotification: showNotificationAction,
  clearSaveDependentStatus: clearSaveDependentStatusAction,
  getChildrenData: getChildrenDataAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDependent)
