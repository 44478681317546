import * as Sentry from '@sentry/browser';

import {
  Professional,
  ProfessionalProvider,
  AvailabilitySlot,
} from '../../domain/Professional'
import { AsyncAction } from '../utils'

export const FETCH_PROFESSIONAL_SUMMARY = 'FETCH_PROFESSIONAL_SUMMARY'
export const SUCCESS_FETCH_PROFESSIONAL_SUMMARY = 'SUCCESS_FETCH_PROFESSIONAL_SUMMARY'
export const FAILURE_FETCH_PROFESSIONAL_SUMMARY = 'FAILURE_FETCH_PROFESSIONAL_SUMMARY'

export const FETCH_PROFESSIONAL_AVAILABILITY = 'FETCH_PROFESSIONAL_AVAILABILITY'
export const SUCCESS_FETCH_PROFESSIONAL_AVAILABILITY = 'SUCCESS_FETCH_PROFESSIONAL_AVAILABILITY'
export const FAILURE_FETCH_PROFESSIONAL_AVAILABILITY = 'FAILURE_FETCH_PROFESSIONAL_AVAILABILITY'

interface FetchProfessionalSummaryActionType {
  type: typeof FETCH_PROFESSIONAL_SUMMARY;
}

interface SuccessFetchProfessionalSummaryActionType {
  type: typeof SUCCESS_FETCH_PROFESSIONAL_SUMMARY;
  professionalSummary: Professional;
}

interface FailureFetchProfessionalSummaryActionType {
  type: typeof FAILURE_FETCH_PROFESSIONAL_SUMMARY;
}

interface FetchProfessionalAvailabilityActionType {
  type: typeof FETCH_PROFESSIONAL_AVAILABILITY;
}

interface SuccessFetchProfessionalAvailabilityActionType {
  type: typeof SUCCESS_FETCH_PROFESSIONAL_AVAILABILITY;
  availability: Array<AvailabilitySlot>;
}

interface FailureFetchProfessionalAvailabilityActionType {
  type: typeof FAILURE_FETCH_PROFESSIONAL_AVAILABILITY;
}

export type ProfessionalTypes =
  FetchProfessionalSummaryActionType |
  SuccessFetchProfessionalSummaryActionType |
  FailureFetchProfessionalSummaryActionType |
  FetchProfessionalAvailabilityActionType |
  SuccessFetchProfessionalAvailabilityActionType |
  FailureFetchProfessionalAvailabilityActionType

export function fetchProfessionalSummaryAction(id: string, token?: string):
  AsyncAction<{}, { professionalProvider: ProfessionalProvider }> {
  return async (dispatch, _getState, { professionalProvider }) => {
    dispatch({ type: FETCH_PROFESSIONAL_SUMMARY })
    try {
      const professionalSummary = await professionalProvider.getProfessionalSummary(id, token)
      dispatch({ type: SUCCESS_FETCH_PROFESSIONAL_SUMMARY, professionalSummary })
    } catch (error) {
      dispatch({ type: FAILURE_FETCH_PROFESSIONAL_SUMMARY })
      Sentry.captureException(new Error(`Failure to fetch professional - ${error.message}`))
    }
  }
}

export function fetchProfessionalAvailabilityAction(id: string, chronosId: string, token?: string):
AsyncAction<{}, { professionalProvider: ProfessionalProvider }> {
  return async (dispatch, _getState, { professionalProvider }) => {
    dispatch({ type: FETCH_PROFESSIONAL_AVAILABILITY })
    try {
      const availability = await professionalProvider.getProfessionalAvailability(chronosId, token)
      dispatch({ type: SUCCESS_FETCH_PROFESSIONAL_AVAILABILITY, availability })
    } catch (error) {
      dispatch({ type: FAILURE_FETCH_PROFESSIONAL_AVAILABILITY })
      Sentry.captureException(new Error(`Failure to fetch professional availability - ${error.message}`))
    }
  }
}
