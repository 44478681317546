import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppDispatch } from '../state/utils'
import { getRemoteConfigAction } from '../state/remoteConfig/actions'

interface Props {
  getRemoteConfig: () => void;
}

function RemoteConfig({
  getRemoteConfig,
}: Props) {
  useEffect(() => {
    getRemoteConfig()
    /** Aqui podemos também implementar um timer para atualizar as flags
     * automaticamente em x minutos
     */
  }, [])

  return null
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  getRemoteConfig: getRemoteConfigAction,
}, dispatch)

export default connect(
  undefined,
  mapDispatchToProps
)(RemoteConfig)
