/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { promptRescheduleAction, setShowRescheduleModalAction } from '../../state/reschedule/actions'
import { getBookingsAction } from '../../state/myBookingsFlow/actions'
import { Booking } from '../../domain/Booking'
import { PromptRescheduleInfosType } from '../../domain/Reschedule'
import ChooseDayAndTime from '../scheduleFlow/ChooseDayAndTime'
import { AttendanceAvailabilityType } from '../../domain/ScheduleFlow'
import './RescheduleView.scss'
import { LocalAddress } from '../../utils/LocalAddress'
import ChooseSpecialist from '../scheduleFlow/ChooseSpecialist'
import { AvailabilityTimeSlot, SlotSpecialist } from '../../domain/Availabilities'

export interface Props {
  booking: Booking;
  forceUpdateAllBookings: boolean;
  isFetchingAllBookings: boolean;
  attendanceAddress?: LocalAddress;
  promptReschedule: (rescheduleInfos: PromptRescheduleInfosType) => void;
  fetchAllBookings: () => void;
  onAfterReSchedule: () => void;
  showRescheduleModal: () => void;
}

function ReScheduleView({
  booking,
  forceUpdateAllBookings,
  isFetchingAllBookings,
  attendanceAddress,
  promptReschedule,
  fetchAllBookings,
  onAfterReSchedule,
  showRescheduleModal,
}: Props) {
  const [currentView, setCurrentView] = useState<'chooseDay' | 'chooseSpecialist'>('chooseDay')
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<AvailabilityTimeSlot>()

  const attendanceType = booking.type === 'InPerson'
    ? AttendanceAvailabilityType.PRESENTIAL
    : AttendanceAvailabilityType.VIRTUAL

  useEffect(() => {
    if (forceUpdateAllBookings && !isFetchingAllBookings) {
      fetchAllBookings()
      onAfterReSchedule()
    }
  }, [forceUpdateAllBookings])

  function onReschedule(specialist: SlotSpecialist, slot: AvailabilityTimeSlot) {
    if (slot) {
      promptReschedule({
        categoryName: booking.professionalCategory,
        professionalName: specialist.name,
        startDate: slot.startTime,
        availabilityId: specialist.slotId,
        attendanceAddress,
        scheduleType: booking.type,
        oldBookingId: booking.id,
        isExam: booking.isExam,
      })
      showRescheduleModal()
    }
  }

  function handleChooseTimeSlot(slot: AvailabilityTimeSlot) {
    if (!booking.isExam && slot.specialists.length > 1) {
      setSelectedTimeSlot(slot)
      setCurrentView('chooseSpecialist')
    } else {
      onReschedule(slot.specialists[0], slot)
    }
  }

  return (
    <div className="reschedule-view">
      {currentView === 'chooseDay' && (
        <ChooseDayAndTime
          onChooseTimeSlot={handleChooseTimeSlot}
          childId={booking.children?.id}
          selectedAttendanceType={attendanceType}
          categoryId={booking.categoryId}
          chronosId={booking.chronosId}
          specialistName={booking.professionalName}
          isExam={booking.isExam}
          isReschedule
          isChildReschedule={(!!booking.children?.id)}
        />
      )}
      {currentView === 'chooseSpecialist' && (
        <div className="p-4">
          <ChooseSpecialist
            categoryName={booking.professionalCategory}
            selectedTimeSlot={selectedTimeSlot}
            onChooseSpecialist={(specialist: SlotSpecialist) => {
              if (selectedTimeSlot) onReschedule(specialist, selectedTimeSlot)
            }}
          />
        </div>

      )}
    </div>
  )
}

const mapStateToProps = ({
  myBookingsFlow, availabilities
}: AppState) => ({
  isFetchingAllBookings: myBookingsFlow.getBookingsStatus.isLoading,
  forceUpdateAllBookings: myBookingsFlow.forceUpdateAllBookings,
  attendanceAddress: availabilities.attendanceAddress
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  promptReschedule: (rescheduleInfos: PromptRescheduleInfosType) => {
    dispatch(promptRescheduleAction(rescheduleInfos))
  },
  fetchAllBookings: () => { dispatch(getBookingsAction()) },
  showRescheduleModal: () => { dispatch(setShowRescheduleModalAction()) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReScheduleView)
