import {
  PediatricFlowTypes,
  UPDATE_CHILDREN,
  SUCCESS_UPDATE_CHILDREN,
  FAILURE_UPDATE_CHILDREN,
  SUPPRESS_PEDIATRIC_WELCOME_MODAL,
  GET_CHILDREN_DATA,
  SUCCESS_GET_CHILDREN_DATA,
  FAILURE_GET_CHILDREN_DATA,
  CLEAR_UPDATE_CHILDREN_STATUS,
  GET_CHILDREN_SUMMARY,
  SUCCESS_GET_CHILDREN_SUMMARY,
  FAILURE_GET_CHILDREN_SUMMARY,
  SHOW_PEDIATRIC_WELCOME_MODAL,
  SUCCESS_UPDATE_CHILD_HEALTHPLAN,
  FAILURE_UPDATE_CHILD_HEALTHPLAN,
  UPDATE_CHILD_HEALTHPLAN
} from './actions'
import {
  BabyData,
  ChildrenSummary
} from '../../domain/PediatricFlow'
import { havePlan } from '../../utils/helpers';
import { NotificationMessage } from '../../domain/Notifications'
import { StatusType, initialStatus } from '../../domain/Status';

export interface PediatricFlowState {
  isGettingPediatricJourney: boolean;
  showPediatricWelcomeModal: boolean;
  childrenData?: BabyData[];
  getChildrenDataStatus: StatusType;
  updateChildrenStatus: StatusType;
  childrenSummary?: ChildrenSummary;
  getChildrenSummaryStatus: StatusType;
  successMessage?: string;
}

const initialPediatricFlowState: PediatricFlowState = {
  isGettingPediatricJourney: false,
  showPediatricWelcomeModal: false,
  childrenData: undefined,
  getChildrenDataStatus: initialStatus,
  updateChildrenStatus: initialStatus,
  childrenSummary: undefined,
  getChildrenSummaryStatus: initialStatus,
  successMessage: undefined,
}

export function pediatricFlow(
  state = initialPediatricFlowState,
  action: PediatricFlowTypes
): PediatricFlowState {
  switch (action.type) {
    case UPDATE_CHILDREN: {
      return {
        ...state,
        updateChildrenStatus: {
          error: false,
          isLoading: true,
          success: false,
          errorMessage: undefined
        },
      }
    }
    case SUCCESS_UPDATE_CHILDREN: {
      return {
        ...state,
        updateChildrenStatus: {
          error: false,
          isLoading: false,
          success: true
        },
        successMessage: NotificationMessage.DATA_UPDATED_SUCCESSFULLY
      }
    }
    case FAILURE_UPDATE_CHILDREN: {
      return {
        ...state,
        updateChildrenStatus: {
          error: true,
          isLoading: false,
          success: false,
          errorMessage: action.error
        },
      }
    }
    case GET_CHILDREN_DATA: {
      return {
        ...state,
        getChildrenDataStatus: {
          isLoading: true,
          error: false,
          success: false,
          errorMessage: undefined
        },
      }
    }
    case SUCCESS_GET_CHILDREN_DATA: {
      return {
        ...state,
        getChildrenDataStatus: {
          isLoading: false,
          error: false,
          success: true,
          errorMessage: undefined
        },
        childrenData: action.childrenData,
      }
    }
    case FAILURE_GET_CHILDREN_DATA: {
      return {
        ...state,
        getChildrenDataStatus: {
          isLoading: false,
          error: true,
          success: false,
          errorMessage: action.error
        },
      }
    }
    case GET_CHILDREN_SUMMARY: {
      return {
        ...state,
        getChildrenSummaryStatus: {
          isLoading: true,
          error: false,
          success: false,
          errorMessage: undefined
        }
      }
    }
    case SUCCESS_GET_CHILDREN_SUMMARY: {
      return {
        ...state,
        getChildrenSummaryStatus: {
          isLoading: false,
          error: false,
          success: true,
          errorMessage: undefined
        },
        childrenSummary: action.childrenSummary
      }
    }
    case FAILURE_GET_CHILDREN_SUMMARY: {
      return {
        ...state,
        getChildrenSummaryStatus: {
          isLoading: false,
          error: true,
          success: false
        },
      }
    }
    case SUPPRESS_PEDIATRIC_WELCOME_MODAL: {
      return {
        ...state,
        showPediatricWelcomeModal: false
      }
    }
    case SHOW_PEDIATRIC_WELCOME_MODAL: {
      return {
        ...state,
        showPediatricWelcomeModal: true
      }
    }
    case CLEAR_UPDATE_CHILDREN_STATUS: {
      return {
        ...state,
        updateChildrenStatus: {
          error: false,
          isLoading: false,
          success: false,
          errorMessage: undefined
        },
        successMessage: undefined,
      }
    }
    case UPDATE_CHILD_HEALTHPLAN: {
      return {
        ...state,
        updateChildrenStatus: {
          error: false,
          isLoading: true,
          success: false,
          errorMessage: undefined
        },
        successMessage: undefined
      }
    }
    case SUCCESS_UPDATE_CHILD_HEALTHPLAN: {
      if (!state.childrenData) return state
      const updatedBabies = state.childrenData?.map(
        (baby: BabyData) => (baby.id === action.childData.id ? {
          ...baby,
          ...action.childData
        } : {
          ...baby
        })
      )
      return {
        ...state,
        updateChildrenStatus: {
          error: false,
          isLoading: false,
          success: true,
          errorMessage: undefined
        },
        childrenData: updatedBabies,
        successMessage: havePlan(action.healthInsurancePlanStatus)
          ? NotificationMessage.HEALTHPLAN_REGISTERED_SUCCESSFULLY
          : NotificationMessage.DATA_UPDATED_SUCCESSFULLY
      }
    }
    case FAILURE_UPDATE_CHILD_HEALTHPLAN: {
      return {
        ...state,
        updateChildrenStatus: {
          error: true,
          isLoading: false,
          success: false,
          errorMessage: action.error
        },
      }
    }
    default:
      return state;
  }
}
