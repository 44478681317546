/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Alert, NewListItem } from 'theia-web-ds'
import NavItem from './NavItem'
import { primary, textPrimary, textSecondary } from '../../../color'
import Drawer from '../drawer/Drawer'
import { promptDrawerAction, suppressDrawerAction } from '../../../state/drawer/actions'
import { AppDispatch } from '../../../state/utils'
import { getFlutterClient } from '../../../utils/helpers'
import { AppState } from '../../../apps/main/store'
import { AppointedSpecialists } from '../../../domain/User'
import './Navigation.scss'
import {
  AGENDAR,
  CHANGE_HEALTH_PLAN,
  CHAT,
  CONSULTAS,
  CONTENT,
  EDIT_ADDRESS,
  EDIT_DEPENDENT,
  EDIT_DEPENDENT_HEALTHPLAN,
  EDIT_PAYMENT,
  EDIT_PROFILE,
  INICIO,
  JORNADA_EXPLICADA,
  PRESCRIPTIONS_AND_EXAMS,
  PROFILE,
  SAUDE
} from '../../../routes/RoutesConstants'
import { SourceTypes } from '../../../domain/ScheduleFlow'
import { setScheduleSourceAction } from '../../../state/scheduleFlow/actions'
import { onSendHighlightAction } from '../../../state/userHighlights/actions'
import { eventTrack } from '../../../../eventGenerate'
import { promptPregnancyCardsDrawerAction } from '../../../state/consultant/actions'

type MenuProps = {
  route: string;
  className?: string;
  promptDrawer: () => void;
  suppressDrawer: () => void;
  firstPregnancyCard: boolean;
  hasPregnancyCard: boolean;
  hasNewPrescription: boolean;
  hasSpecialist?: Array<AppointedSpecialists> | [];
  insurancePlanCovered?: boolean | null;
  sawExamsList: boolean;
  isDependent: boolean
  sawExamsUpload: boolean
  setScheduleSource: (source: SourceTypes) => void;
  promptPregnancyCardDrawer: () => void;
  onSendHightlight: (hightlight: string) => void;
}

const Bottombar = React.memo(({
  route,
  className,
  promptDrawer,
  suppressDrawer,
  firstPregnancyCard,
  hasPregnancyCard,
  hasNewPrescription,
  hasSpecialist,
  insurancePlanCovered,
  sawExamsList,
  isDependent,
  sawExamsUpload,
  setScheduleSource,
  promptPregnancyCardDrawer,
  onSendHightlight,
}: MenuProps) => {
  const history = useHistory()
  const [showMoreModal, setShowMoreModal] = useState(false)
  const [showTooltip, setShowTooltip] = useState(hasPregnancyCard && firstPregnancyCard)

  const isActiveMenuItem = (pages: Array<string>) => useMemo(() => pages
    .some((p) => window.location.pathname.includes(p)), [route])

  const initialIsActiveHome = isActiveMenuItem([INICIO, JORNADA_EXPLICADA])
  const initialIsActiveMyBookingsView = isActiveMenuItem([CONSULTAS])
  const initialIsActiveSchedule = isActiveMenuItem([AGENDAR])
  const initialIsActiveMore = isActiveMenuItem([CONTENT, PROFILE, SAUDE])
  const initialIsActiveContent = isActiveMenuItem([CONTENT, `${CONTENT}/:title/:id`])
  const initialIsActiveChat = isActiveMenuItem([CHAT])
  const initialIsActiveProfile = isActiveMenuItem([
    PROFILE,
    EDIT_PROFILE,
    EDIT_ADDRESS,
    EDIT_PAYMENT,
    EDIT_DEPENDENT,
    EDIT_DEPENDENT_HEALTHPLAN,
    CHANGE_HEALTH_PLAN
  ])
  const initialIsActiveHealth = isActiveMenuItem([SAUDE, PRESCRIPTIONS_AND_EXAMS])

  useEffect(() => {
    if (hasPregnancyCard && firstPregnancyCard) setShowTooltip(true)
  }, [hasPregnancyCard, firstPregnancyCard])

  const handleClick = (newRoute: string) => {
    localStorage.removeItem('bookingSuggestion')
    localStorage.removeItem('scheduleSuggestion')
    history.push(newRoute)
    setShowMoreModal(false)
  }

  function extraActionOnSuppressDrawer() {
    setShowMoreModal(false)
  }

  const handleClickHealth = () => {
    handleClick(SAUDE)
  }

  const handleClickPregnancyCard = () => {
    eventTrack('Clicou Cartão da Gestante')
    promptPregnancyCardDrawer()
    if (firstPregnancyCard) onSendHightlight('firstPregnancyCard')
  }

  const handleClickChat = () => {
    handleClick(CHAT)
  }

  const handleClickHome = () => {
    handleClick(INICIO)
  }

  const handleClickMyBookingsView = () => {
    handleClick(CONSULTAS)
  }

  const handleClickSchedule = () => {
    setScheduleSource(SourceTypes.MENU)
    handleClick(AGENDAR)
  }

  const handleClickContent = () => {
    handleClick(CONTENT)
    suppressDrawer()
  }

  const handleClickProfile = () => {
    handleClick(PROFILE)
    suppressDrawer()
  }

  const handleClickMore = () => {
    setShowMoreModal(true)
    promptDrawer()
    setShowTooltip(false)
  }
  if (getFlutterClient()) return null

  return (
    <div className={`bottombar-content ${className}`}>
      {showTooltip && !isDependent ? (
        <div className="relative">
          <Alert
            message="Novidade! Agora você tem acesso ao seu Cartão da Gestante online."
            extraClass="pregnancy-card-popup"
            variant="flowting"
            indicator="right-bottom"
            timer={5000}
          />
        </div>
      ) : null}
      <ul role="menu" aria-label="Menu">
        <NavItem
          handleClick={handleClickHome}
          isActive={initialIsActiveHome}
          text="Início"
          activeIconType="icon-WeekBold"
          iconType="icon-WeekLight"
          variant="bottombar"
        />
        <NavItem
          handleClick={handleClickSchedule}
          isActive={initialIsActiveSchedule}
          text="Agendar"
          activeIconType="icon-CalendarBold"
          iconType="icon-CalendarLight"
          variant="bottombar"
        />
        {((hasSpecialist !== undefined && hasSpecialist.length > 0)
          || (insurancePlanCovered !== null && insurancePlanCovered))
          && (
            <NavItem
              handleClick={handleClickChat}
              isActive={initialIsActiveChat}
              text="Chat"
              activeIconType="icon-TheiaLight"
              iconType="icon-TheiaLight"
              variant="bottombar"
            />
          )}
        <NavItem
          handleClick={handleClickMyBookingsView}
          isActive={initialIsActiveMyBookingsView}
          text="Consultas"
          activeIconType="icon-PhonendoscopeBold"
          iconType="icon-PhonendoscopeLight"
          variant="bottombar"
        />
        <NavItem
          handleClick={handleClickMore}
          isActive={initialIsActiveMore}
          text="Mais"
          activeIconType="icon-MoreSquareBold"
          iconType="icon-MoreSquareLight"
          variant="bottombar"
          hasNotificationOnIcon={hasPregnancyCard && firstPregnancyCard}
        />
        {!sawExamsUpload ? (
          <Alert
            message="Novidade! Agora você pode enviar os seus exames no menu saúde."
            extraClass="upload-exams-popup"
            variant="flowting"
            indicator="right-bottom"
            timer={5000}
          />
        ) : null}
        {showMoreModal && (
          <Drawer variant="bottomDrawer" extraAction={extraActionOnSuppressDrawer}>
            <div className="bottom-bar-more-modal">
              <div className="divider-top" />
              {hasPregnancyCard && !isDependent ? (
                <NewListItem
                  onClick={handleClickPregnancyCard}
                  iconType="icon-IdLight"
                  iconColor={textPrimary}
                  arrowColor={textSecondary}
                  title="Cartão da gestante"
                  ariaLabel="Cartão da gestante"
                  id="Cartão da gestante"
                  key="Cartão da gestante"
                  isLastItem={false}
                  hasIconBadge={firstPregnancyCard}
                />
              ) : null}
              <NewListItem
                onClick={handleClickHealth}
                iconType={initialIsActiveHealth ? 'icon-MedicalRecordBold' : 'icon-MedicalRecordLight'}
                iconColor={initialIsActiveHealth ? primary : textPrimary}
                arrowColor={textSecondary}
                title="Saúde"
                ariaLabel="Saúde"
                id="Saúde"
                key="Saúde"
                isLastItem={false}
                hasIconBadge={hasNewPrescription || !sawExamsList}
              />
              <NewListItem
                onClick={handleClickContent}
                iconType={initialIsActiveContent ? 'icon-PaperBold' : 'icon-PaperLight'}
                iconColor={initialIsActiveContent ? primary : textPrimary}
                arrowColor={textSecondary}
                title="Conteúdo"
                ariaLabel="Conteúdo"
                id="Conteúdo"
                key="Conteúdo"
                isLastItem={false}
              />
              <NewListItem
                onClick={handleClickProfile}
                iconType={initialIsActiveProfile ? 'icon-ProfileBold' : 'icon-ProfileLight'}
                iconColor={initialIsActiveProfile ? primary : textPrimary}
                arrowColor={textSecondary}
                title="Perfil"
                ariaLabel="Perfil"
                id="Perfil"
                key="Perfil"
                isLastItem
              />
            </div>
          </Drawer>
        )}
      </ul>
    </div>
  )
})

const mapStateToProps = ({
  authentication, userHighlights, healthplan, appProfiles
}: AppState) => ({
  firstPregnancyCard: userHighlights.firstPregnancyCard,
  hasPregnancyCard: userHighlights.hasPregnancyCard,
  hasNewPrescription: userHighlights.highlightHealthMenuItem,
  hasSpecialist: authentication.currentUser?.appointedSpecialists,
  insurancePlanCovered: healthplan.healthInsurancePlanData?.insurancePlanCovered,
  sawExamsList: userHighlights.sawExamsList,
  sawExamsUpload: userHighlights.sawExamsUpload,
  isDependent: appProfiles.isDependent,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  promptDrawer: promptDrawerAction,
  suppressDrawer: suppressDrawerAction,
  setScheduleSource: setScheduleSourceAction,
  promptPregnancyCardDrawer: promptPregnancyCardsDrawerAction,
  onSendHightlight: onSendHighlightAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bottombar)
