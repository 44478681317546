import React, { useEffect, useState } from 'react'
import {
  ListItemCard,
  RegularButton,
  NewListItem
} from 'theia-web-ds'
import { ScheduleTypeBR } from '../../domain/Booking'
import OldCard from '../../../assets/old-card.png'
import { primary, textSecondary } from '../../color'
import SlipOption from '../../../assets/slip-option.svg'
import PixOption from '../../../assets/pix-option.svg'
import CardYes from '../../../assets/health-insurance-card-yes.png'
import {
  capitalize,
  getBookingNameToShow,
  getYourBookingName,
  isCreditCard,
  isPix,
  isSlip
} from '../../utils/helpers'
import { LocalDate } from '../../domain/utils/Date'
import { LocalAddress } from '../../utils/LocalAddress'
import { SchedulePaymentType } from '../../domain/ScheduleFlow'
import ProfessionalInfoModal from '../professionals/ProfessionalInfoModal'
import { TheiaError } from '../../domain/errors/TheiaError'
import './ScheduleReviewAndConfirm.scss'
import { eventTrack } from '../../../eventGenerate'
import ProfilePicture, { PicturePlaceholder } from '../common/molecules/ProfilePicture'
import { SpecialistDetails } from '../../domain/Specialist'
import { EventCategories } from '../../utils/EventCategories'

interface Props {
  isPresential: boolean;
  selectedPaymentMethod?: SchedulePaymentType;
  isCovered: boolean;
  startDate?: LocalDate;
  paymentSlipDueDate?: LocalDate;
  pixDueDate?: LocalDate;
  attendanceAddress?: LocalAddress;
  healthInsuranceCompanyName?: string;
  isExam?: boolean;
  cardBrand?: string;
  cardLastDigits?: string;
  professionalPicture?: string;
  professionalName?: string;
  selectedCategory?: string;
  isScheduling: boolean;
  isGettingSpecialistDetails?: boolean;
  specialistDetails?: SpecialistDetails;
  patientName?: string;
  professionalInfoError?: TheiaError;
  onClickEditPayment: () => void;
  onConfirmSchedule: () => void;
  onClickSpecialistInfos?: () => void;
}

function ScheduleReviewAndConfirm({
  isPresential,
  selectedPaymentMethod,
  isCovered,
  startDate,
  paymentSlipDueDate,
  pixDueDate,
  attendanceAddress,
  healthInsuranceCompanyName,
  isExam,
  cardBrand,
  cardLastDigits,
  professionalPicture,
  professionalName,
  selectedCategory,
  isScheduling,
  isGettingSpecialistDetails,
  specialistDetails,
  patientName,
  professionalInfoError,
  onClickEditPayment,
  onConfirmSchedule,
  onClickSpecialistInfos,
}: Props) {
  const [showSpecialistDetail, setShowSpecialistDetail] = useState(false)
  const weekDay = startDate && capitalize(startDate.formatDateToWeekday())
  const dayTwoDigits = startDate && startDate.formatDayTwoDigits()
  const bookingDateInfo = startDate ? (
    `${weekDay}, ${dayTwoDigits} de ${startDate.formatFullMonth()} às ${startDate.formatDateToHours()}`
  ) : null
  const dueDateFormatted = paymentSlipDueDate && paymentSlipDueDate.formatDayAndMonthAndYear()
  const pixDueDateFormatted = pixDueDate && pixDueDate.formatDayAndMonthAndYear()
  const iconType = isPresential ? 'icon-BuildingLight' : 'icon-VideoLight'
  const attendanceAddressToShow = attendanceAddress ? (
    `${attendanceAddress?.formatToNameNumberAndComplement()}, ${attendanceAddress?.formatToDistrictAndCity()}`
  ) : 'Clínica Theia'
  const showPaymentMethod = !!(isCovered || isCreditCard(selectedPaymentMethod)
    || isSlip(selectedPaymentMethod) || isPix(selectedPaymentMethod))

  useEffect(() => {
    eventTrack('visualizou revisao agendamento', {
      categoria: EventCategories.NEW_SCHEDULE_FLOW
    })
  }, [])

  function handleShowSpecialistDetails() {
    if (onClickSpecialistInfos) onClickSpecialistInfos()
    setShowSpecialistDetail(true)
  }

  function closeSpecialistDetail() {
    setShowSpecialistDetail(false)
  }

  const cardButton = isCreditCard(selectedPaymentMethod) && !isCovered && (
    <ListItemCard
      extraClassNameContainer="schedule-confirm-payment-card mb-4"
      onClick={onClickEditPayment}
      picture={(
        <img
          src={OldCard}
          width={40}
          height={40}
          className="mx-4"
          alt=""
        />
      )}
      iconColor={primary}
      caption={`O valor só será cobrado após ${getBookingNameToShow(isExam)}`}
      title={`${cardBrand?.toUpperCase()} final ${cardLastDigits}`}
      ariaLabel={`Pagar com o cartão ${cardBrand} final ${cardLastDigits}`}
      isLastItem
      isItemFromList={false}
      key="old-card-id"
      id="old-card-id"
      anotherIcon="icon-EditLight"
    />
  )

  const slipButton = isSlip(selectedPaymentMethod) && (
    <ListItemCard
      extraClassNameContainer="schedule-confirm-payment-card mb-4"
      onClick={onClickEditPayment}
      picture={(
        <SlipOption
          width={40}
          height={40}
          className="mx-4"
          alt=""
        />
      )}
      iconColor={primary}
      caption={`Pague até o dia ${dueDateFormatted} para garantir ${getYourBookingName(isExam)}`}
      title="Boleto"
      ariaLabel="Boleto"
      isLastItem
      isItemFromList={false}
      key="slip-id"
      id="slip-id"
      anotherIcon="icon-EditLight"
    />
  )

  const pixButton = isPix(selectedPaymentMethod) && (
    <ListItemCard
      extraClassNameContainer="schedule-confirm-payment-card mb-4"
      onClick={onClickEditPayment}
      picture={(
        <PixOption
          width={40}
          height={40}
          className="mx-4"
          alt=""
        />
      )}
      iconColor={primary}
      caption={`Pague até o dia ${pixDueDateFormatted} para garantir ${getYourBookingName(isExam)}`}
      title="PIX"
      ariaLabel="Pix"
      isLastItem
      isItemFromList={false}
      key="pix-id"
      id="pix-id"
      anotherIcon="icon-EditLight"
    />
  )

  const healthInsuranceButton = isCovered && (
    <ListItemCard
      extraClassNameContainer="schedule-confirm-payment-card mb-4"
      picture={(
        <div className="px-4">
          <img
            src={CardYes}
            width={40}
            height={40}
            alt=""
          />
        </div>
      )}
      iconColor={primary}
      caption={healthInsuranceCompanyName}
      title="Coberto pelo plano de saúde"
      ariaLabel="Coberto pelo plano de saúde"
      isLastItem
      isItemFromList={false}
      key="health-ins-id"
      id="health-ins-id"
    />
  )

  return (
    <div className="schedule-review-and-confirm-container">
      <div className={`schedule-review-and-confirm-content ${!isCovered ? 'mb-32 md:mb-0' : ''}`}>
        <h1 className="schedule-review-title">
          Revise e confirme o agendamento
        </h1>
        <p className="schedule-review-subtitle">
          Detalhes {isExam ? 'do exame' : 'da consulta'}
        </p>
        <div className="schedule-review-summary">
          <NewListItem
            picture={(
              <ProfilePicture
                pictureUrl={!isExam ? professionalPicture : undefined}
                pictureSize={48}
                className="schedule-review-professional-picture"
                picturePlaceholder={isExam
                  ? PicturePlaceholder.USG
                  : PicturePlaceholder.SPECIALIST}
              />
            )}
            showCaptionFirst={!isExam}
            caption={isExam ? selectedCategory : 'Especialista'}
            ariaLabel={isExam ? selectedCategory || 'Exame realizado na Theia' : 'Especialista'}
            title={professionalName}
            isLastItem
            key="professional-infos"
            id="professional-infos"
            onClick={handleShowSpecialistDetails}
            anotherIcon="icon-InfoSquareLight"
            iconColor={primary}
          />
          {patientName && (
            <NewListItem
              iconType="icon-ProfileLight"
              iconColor={textSecondary}
              iconSize="24px"
              showCaptionFirst
              caption="Paciente"
              ariaLabel={`Paciente ${patientName}`}
              title={patientName}
              isNoHover
              key="patient-infos"
              id="patient-infos"
            />
          )}
          {!isExam && (
            <NewListItem
              iconType={iconType}
              iconColor={textSecondary}
              iconSize="24px"
              showCaptionFirst
              caption={`Consulta ${isPresential ? ScheduleTypeBR.PRESENTIAL : ScheduleTypeBR.ONLINE}`}
              ariaLabel={`Consulta ${isPresential ? ScheduleTypeBR.PRESENTIAL : ScheduleTypeBR.ONLINE} - ${selectedCategory}`}
              title={selectedCategory}
              isNoHover
              key="category-infos"
              id="category-infos"
            />
          )}
          {(isPresential || isExam) && (
            <NewListItem
              iconType="icon-LocationLight"
              iconColor={textSecondary}
              iconSize="24px"
              showCaptionFirst
              caption="Endereço"
              ariaLabel={`Endereço - ${attendanceAddressToShow}`}
              title={attendanceAddressToShow}
              isNoHover
              key="address-infos"
              id="address-infos"
            />
          )}
          <NewListItem
            iconType="icon-CalendarLight"
            iconColor={textSecondary}
            iconSize="24px"
            showCaptionFirst
            caption="Data e hora"
            ariaLabel={`Data e hora - ${bookingDateInfo}`}
            title={bookingDateInfo || ''}
            isNoHover
            isLastItem
            key="date-infos"
            id="date-infos"
          />
        </div>
        {showPaymentMethod && (
          <p className="schedule-review-subtitle mt-8">
            Detalhes do pagamento
          </p>
        )}
        {cardButton}
        {slipButton}
        {pixButton}
        {healthInsuranceButton}
      </div>
      {isCovered && (
        <div className="schedule-review-and-confirm-button">
          <RegularButton
            onClick={onConfirmSchedule}
            label="Confirmar agendamento"
            maxWidth="100%"
            disabled={isScheduling}
            isSubmitting={isScheduling}
            extraClass="mb-4"
          />
        </div>
      )}
      <ProfessionalInfoModal
        isLoading={isGettingSpecialistDetails}
        isVisible={showSpecialistDetail}
        specialistDetails={specialistDetails}
        onChooseSpecialist={closeSpecialistDetail}
        onClose={closeSpecialistDetail}
        error={professionalInfoError}
        onTryAgain={handleShowSpecialistDetails}
      />
    </div>
  )
}

export default ScheduleReviewAndConfirm
