/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import moment from 'moment'
import { useEffect, useRef } from 'react'
import { Booking, ScheduleType, ScheduleTypeBR } from '../domain/Booking'
import {
  LiveInSpOptions,
  ConsultantMoment,
  ConsultantMomentOptionLabel
} from '../domain/Profile'
import { HealthInsurancePlanStatus } from '../domain/PediatricFlow'
import {
  AttendanceAvailabilityToShow,
  AttendanceAvailabilityType,
  BookingTextToShow,
  CategoryPaymentType,
  SchedulePaymentType
} from '../domain/ScheduleFlow'
import { JourneyType } from '../domain/GuidedJourney'
import { SpecialistCategories } from '../domain/Specialist'
import { TOKEN_ACCESS, TOKEN_REFRESH } from '../domain/User'
import { AvailabilityByDay } from '../domain/Availabilities'

/* eslint-disable radix */
export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export function unique<T>(value: T, index: number, arr: T[]) {
  return arr.indexOf(value) === index;
}

export function first(map: Map<any, any>) {
  return Array.from(map.values())[0]
}

/*
 * This function separates an continuous array in groups inside an array of the given size.
 * Size 3: [1, 2, 3, 4, 5, 6, 7] becomes [[1, 2, 3], [4, 5, 6], [7]].
*/
export function separateInGroups<T>(arr: Array<T>, size: number): Array<Array<T>> {
  const initial: Array<Array<T>> = []
  return arr.reduce((result, _value, index, array) => {
    if (index % size === 0) {
      result.push(array.slice(index, index + size));
    }
    return result;
  }, initial);
}

export function parseDigits(s: string) {
  return (s.match(/\d+/g) || []).join('')
}

export function onlyDigits(s?: string) {
  if (s) return s.replace(/[^\d]/g, '')
  return ''
}

// solution from https://stackoverflow.com/a/38552302
export function parseJwt(token: string) {
  const base64Url = token?.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    const aux = (`00${c.charCodeAt(0).toString(16)}`).slice(-2)
    return `%${aux}`
  }).join(''))

  return JSON.parse(jsonPayload)
}

export function getOtherGenders() {
  return [
    'Gênero Fluido',
    'Homem Trans',
    'Mulher Trans',
    'Não-binário',
    'Nenhum',
    'Neutro',
    'Travesti'
  ]
}

export function isNotNullOrUndefined(element: any): boolean {
  return typeof element !== 'undefined' && element !== null
}

export function isValidElement(element: any): boolean {
  return element !== undefined && element !== null && element !== ''
}

export function getPlural(count: number, word: string) {
  return count !== 1 ? `${word}s` : word
}

export function cpfValidation(value: any) {
  if (!value) return false
  const cpf = onlyDigits(value)
  let resto
  let soma
  let i
  if (cpf.length !== 11
    || cpf === '00000000000'
    || cpf === '11111111111'
    || cpf === '22222222222'
    || cpf === '33333333333'
    || cpf === '44444444444'
    || cpf === '55555555555'
    || cpf === '66666666666'
    || cpf === '77777777777'
    || cpf === '88888888888'
    || cpf === '99999999999') {
    return false
  }
  soma = 0
  for (i = 0; i < 9; i += 1) {
    soma += parseInt(cpf.charAt(i)) * (10 - i)
  }
  resto = 11 - (soma % 11)
  if (resto === 10 || resto === 11) resto = 0
  if (resto !== parseInt(cpf.charAt(9))) return false
  soma = 0
  for (i = 0; i < 10; i += 1) {
    soma += parseInt(cpf.charAt(i)) * (11 - i)
  }
  resto = 11 - (soma % 11)
  if (resto === 10 || resto === 11) {
    resto = 0
  }
  if (resto !== parseInt(cpf.charAt(10))) {
    return false
  }
  return true
}

export function isBusinessTypeB2C(value: string | undefined) {
  if (value === 'B2C') return true
  return false
}

export function getFlutterClient(): { callHandler: (handlerName: string, args: {}) => void } {
  const { flutter_inappwebview } = (window as any)
  return flutter_inappwebview
}

export function isInAppFlutter() {
  const { isInApp } = (window as any)
  const isFlutterNavigator = /FlutterTheia/i.test(window?.navigator?.userAgent)
    || navigator.userAgent.indexOf('FlutterTheia') > -1
    || (navigator.vendor && navigator.vendor.indexOf('FlutterTheia') > -1)
  return isInApp || isFlutterNavigator
}

export function getMobileSignupOrLoginClient(): { tellMobileToken: (token: string) => void } {
  const { mobileSignupClient } = (window as any)
  return mobileSignupClient
}

export function promiseTimeout(promise: Promise<any>): Promise<any> {
  // Create a promise that rejects in <ms> milliseconds
  const timeoutNumber: number = parseInt(process.env.TIMEOUT_REQUEST_VALUE || '30000')
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new Error(`Timed out in ${timeoutNumber} + ms.`))
    }, timeoutNumber)
  })

  // Returns a race between our timeout and the passed in promise
  return Promise.race([
    promise,
    timeout
  ])
}

export function getTimesToTryRequest(): number {
  return parseInt(process.env.TIMES_TO_TRY_REQUEST_VALUE || '2')
}

export function isPresentialType(type?: ScheduleType | string): boolean {
  if (!type) return false
  const lowerType = type.toLowerCase()
  return lowerType === 'inperson'
}

export function getBookingTypeBR(scheduleType: ScheduleType) {
  return (
    isPresentialType(scheduleType)
      ? ScheduleTypeBR.PRESENTIAL
      : ScheduleTypeBR.ONLINE
  )
}

export function getAttendanceTypeToShow(type?: AttendanceAvailabilityType): string {
  if (!isValidElement(type)) return ''
  if (type === AttendanceAvailabilityType.PRESENTIAL) return AttendanceAvailabilityToShow.PRESENTIAL
  if (type === AttendanceAvailabilityType.VIRTUAL) return AttendanceAvailabilityToShow.VIRTUAL
  return ''
}

export function getBookingNameToShow(isExam?: boolean): string {
  return isExam ? BookingTextToShow.DEFAULT_EXAM : BookingTextToShow.DEFAULT_CONSULTATION
}

export function getScheduledText(isExam?: boolean): string {
  return isExam ? BookingTextToShow.SCHEDULED_EXAM : BookingTextToShow.SCHEDULED_CONSULTATION
}

export function getCanceledText(isExam?: boolean): string {
  return isExam ? BookingTextToShow.CANCELED_EXAM : BookingTextToShow.CANCELED_CONSULTATION
}

export function getYourBookingName(isExam?: boolean): string {
  return isExam ? BookingTextToShow.YOURS_EXAM : BookingTextToShow.YOURS_CONSULTATION
}

export function capitalize(s: string) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function isLiveInSp(liveInSp: string) {
  return liveInSp === LiveInSpOptions.SP
}

export function getBookingPrice(
  isPresential: boolean,
  priceInCents?: number,
  priceInCentsToInPerson?: number,
  priceInCentsToExtraBooking?: number
) {
  if (isPresential && priceInCentsToInPerson) return priceInCentsToInPerson / 100
  if (priceInCents) return priceInCents / 100
  return (priceInCentsToExtraBooking || 0) / 100
}

export function centsToReal(valueInCents?: number) {
  return (valueInCents || 0) / 100
}

export function isBasicPlan(planName?: string) {
  return planName === 'Theia por uso' || planName === 'Basic'
}

export const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key]

export function getGoogleCalendarEventInfos(booking: Booking) {
  const isPresential = isPresentialType(booking.type)
  const url = isProductionEnv() ? (
    'https://app.theia.com.br/bookings/'
  ) : 'https://app.oithea.com.br/bookings/'
  const bookingAddress = booking.attendanceAddress
  const locationEventNotNull = isNotNullOrUndefined(bookingAddress)
  const descriptionAux = locationEventNotNull ? isPresential ? (
    `no endereço ${bookingAddress?.formatToNameNumberAndComplement()}, ${bookingAddress?.formatToDistrictAndCity()}`
  ) : (
    `no link ${url}${booking.roomName}`
  ) : ''
  const specialistName = booking.professionalName
  const descriptionEvent = `Consulta ${isPresential ? 'presencial ' : 'on-line '}com ${specialistName} ${descriptionAux}`
  const titleEvent = `Consulta ${isPresential ? 'presencial ' : 'on-line '}com ${specialistName}`
  const locationEvent = locationEventNotNull ? isPresential ? (
    `${bookingAddress?.formatToNameNumberAndComplement()}, ${bookingAddress?.formatToDistrictAndCity()}`
  ) : (
    `${url}${booking.roomName}`
  ) : ''

  const makeBookingOneHourLong = moment(booking.startDate.format()).add(1, 'hour')
  const newEndDateTime = moment(makeBookingOneHourLong).format()

  return {
    descriptionEvent,
    titleEvent,
    locationEvent,
    startDateTime: booking.startDate.format(),
    endDateTime: newEndDateTime
  }
}

export function creatEventOnGoogleCalendar(
  titleEvent: string,
  locationEvent: string,
  descriptionEvent: string,
  startDateTime: string,
  endDateTime: string,
) {
  const CLIENT_ID = process.env.GAPI_CLIENT_ID
  const API_KEY = process.env.GAPI_KEY
  const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
  const SCOPES = 'https://www.googleapis.com/auth/calendar.events';
  const { gapi } = (window as any)

  gapi.load('client:auth2', () => {
    gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES
    })

    gapi.client.load('calendar', 'v3')

    gapi.auth2.getAuthInstance().signIn()
      .then(() => {
        const googleEvent = {
          summary: titleEvent,
          location: locationEvent,
          description: descriptionEvent,
          start: {
            dateTime: startDateTime,
          },
          end: {
            dateTime: endDateTime,
          },
          reminders: {
            useDefault: false,
            overrides: [
              { method: 'email', minutes: 24 * 60 },
              { method: 'popup', minutes: 10 }
            ]
          }
        }

        const request = gapi.client.calendar.events.insert({
          calendarId: 'primary',
          resource: googleEvent
        })

        request.execute((event: any) => {
          window.open(event.htmlLink)
        })
      })
  })
}

export function isPreOrPosPregnancy(name: string) {
  return name.toLowerCase().startsWith('pré') || name.toLowerCase().startsWith('pós')
}

export function getAge(date: string): string {
  const parsedDate = moment(date, 'DD/MM/YYYY')
  const age = moment().diff(parsedDate, 'years')
  return `${age}`
}

export function getBooleanText(value: boolean): string {
  if (value) {
    return 'Sim'
  }
  return 'Não'
}

export function formCheckHasOneElementsEmpty(list: string[]) {
  let errors = {}
  let hasError = false
  list.forEach((element: string, index: number) => {
    if (!element) {
      errors = {
        ...errors,
        [index]: 'Campo obrigatório'
      }
      hasError = true
    }
  })
  if (hasError) return errors

  return undefined
}

export function getLinkFromCategory(category: SpecialistCategories) {
  const url = 'agendar?category='
  let newUrl
  const decodedCategory = decodeURIComponent(category)
  newUrl = url.concat(decodedCategory)
  return newUrl
}

export const UseCombinedRefs = (...refs: any[]) => {
  const targetRef = useRef(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

export const emailValidationRegex = new RegExp(/^[a-zA-Z0-9\\+\\.\\_\\%\\+\\-]{1,256}@[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}(?:\.[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25})+/)

export const dateValidationRegex = new RegExp(/^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d{2}/)

export function isNotFutureDate(date: string) {
  if (date && date !== '' && date !== undefined && date !== null) {
    const insertedDate = moment(date, 'DD/MM/YYYY').toDate()
    const today = new Date()
    if (insertedDate < today) {
      return true
    }
  }
  return false
}

export function isWithinOneYearRange(date: string) {
  if (date && date !== '' && date !== undefined && date !== null) {
    const insertedDate = moment(date, 'DD/MM/YYYY').toDate()
    const today = new Date()
    const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())
    if (insertedDate > oneYearAgo) {
      return true
    }
  }
  return false
}

export function isWithinGestationalMonth(date: string) {
  if (date && date !== '' && date !== undefined && date !== null) {
    const insertedDate = moment(date, 'DD/MM/YYYY').toDate()
    const today = new Date()
    const nineMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 9, today.getDate())
    if (insertedDate > nineMonthsAgo) {
      return true
    }
  }
  return false
}

export function epochToString(date?: string | number) {
  if (!date || !isValidElement(date)) return ''
  const newDate = new Date(date)
  const stringDate = newDate.toLocaleString()
  const formatedDate = stringDate.substring(0, 10)
  return formatedDate
}

export const pediatricJourneyCategories = [
  SpecialistCategories.PEDIATRICIAN,
  SpecialistCategories.NUTRITIONIST,
  SpecialistCategories.DENTIST
]

export function isProductionEnv() {
  return process.env.NODE_ENV === 'production'
}

export function isStagingEnv() {
  return process.env.NODE_ENV === 'staging'
}

export function isLocalEnv() {
  return process.env.NODE_ENV === 'development'
}

export const urlEnvironment = isProductionEnv() ? 'prod' : 'dev'

export const specialCharacterRegex = new RegExp(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u)
export const validationFullNameRegex = new RegExp('[\\wà-úÀ-Ú]+\\s[\\wà-úÀ-Ú]')

export const passwordSpecialCharacters = /[!@#$%^&*(),.?":{}|<>'-/\\;+_=[\]]/

export function isPregnant(pregnancyMoment?: ConsultantMoment): boolean {
  if (!pregnancyMoment) return false
  return (pregnancyMoment === ConsultantMoment.PREGNANT)
}

export function isPostPregnant(
  pregnancyMoment?: ConsultantMoment,
  pregnancyMomentText?: string
): boolean {
  if (!pregnancyMoment) return false
  if (!pregnancyMomentText) {
    return (pregnancyMoment === ConsultantMoment.PUERPERIUM
      || pregnancyMoment === ConsultantMoment.AFTER_BIRTH)
  }
  return (pregnancyMoment === ConsultantMoment.PUERPERIUM
    || pregnancyMoment === ConsultantMoment.AFTER_BIRTH)
    && pregnancyMomentText !== ConsultantMoment.OTHER_MOMENT
}

export function isStillBornPostPregnant(
  pregnancyMoment?: ConsultantMoment,
  pregnancyMomentText?: string
): boolean {
  return (pregnancyMoment === ConsultantMoment.PUERPERIUM
    || pregnancyMoment === ConsultantMoment.AFTER_BIRTH)
    && pregnancyMomentText === ConsultantMoment.OTHER_MOMENT
}

export function isOtherMoment(pregnancyMoment?: ConsultantMoment): boolean {
  if (!pregnancyMoment) return true
  return (pregnancyMoment === ConsultantMoment.OTHER_MOMENT)
}

export function isTrying(pregnancyMoment?: ConsultantMoment): boolean {
  if (!pregnancyMoment) return false
  return pregnancyMoment === ConsultantMoment.TRYING
}

export function getCosultantMomentOptionLabel(pregnancyMoment?: ConsultantMoment): ConsultantMomentOptionLabel {
  if (isPregnant(pregnancyMoment)) return ConsultantMomentOptionLabel.PREGNANT
  if (isTrying(pregnancyMoment)) return ConsultantMomentOptionLabel.TRYING
  if (isPostPregnant(pregnancyMoment)) return ConsultantMomentOptionLabel.PUERPERIUM
  return ConsultantMomentOptionLabel.OTHER
}

export function isNotInformed(healthInsurancePlanStatus?: HealthInsurancePlanStatus): boolean {
  if (!healthInsurancePlanStatus) return false
  return healthInsurancePlanStatus === HealthInsurancePlanStatus.NOT_INFORMED
}

export function dontHavePlan(healthInsurancePlanStatus?: HealthInsurancePlanStatus): boolean {
  if (!healthInsurancePlanStatus) return false
  return healthInsurancePlanStatus === HealthInsurancePlanStatus.DONT_HAVE_PLAN
}

export function havePlan(healthInsurancePlanStatus?: HealthInsurancePlanStatus): boolean {
  if (!healthInsurancePlanStatus) return false
  return healthInsurancePlanStatus === HealthInsurancePlanStatus.HAVE_PLAN
}

export function isVirtual(type?: AttendanceAvailabilityType): boolean {
  if (!type) return false
  return type === AttendanceAvailabilityType.BOTH || type === AttendanceAvailabilityType.VIRTUAL
}

export function isPresential(type?: AttendanceAvailabilityType): boolean {
  if (!type) return false
  return type === AttendanceAvailabilityType.BOTH || type === AttendanceAvailabilityType.PRESENTIAL
}

export const isObjectEmpty = (objectName: any) => Object.keys(objectName).length === 0

export function findCategoryIcon(category: SpecialistCategories): string {
  switch (category) {
    case SpecialistCategories.GINECOLOGIST:
      return 'icon-PregnantLight'
    case SpecialistCategories.OBSTETRIC_NURSE:
      return 'icon-NurseLight'
    case SpecialistCategories.PEDIATRICIAN:
      return 'icon-BabyLight'
    case SpecialistCategories.PHYSIOTHERAPIST:
      return 'icon-BallLight'
    case SpecialistCategories.NUTRITIONIST:
      return 'icon-WeightLight'
    case SpecialistCategories.PSYCHOLOGIST:
      return 'icon-BrainLight'
    case SpecialistCategories.DENTIST:
    case SpecialistCategories.PEDIATRIC_DENTIST:
      return 'icon-TeethLight'
    default:
      return ''
  }
}

export function isGynecologist(category?: SpecialistCategories): boolean {
  if (!category) return false
  return category === SpecialistCategories.GINECOLOGIST
}

export function isCreditCard(paymentType?: SchedulePaymentType): boolean {
  if (!isValidElement(paymentType)) return false
  return paymentType === SchedulePaymentType.CREDIT_CARD
}

export function isSlip(paymentType?: SchedulePaymentType): boolean {
  if (!isValidElement(paymentType)) return false
  return paymentType === SchedulePaymentType.PAYMENT_SLIP
}

export function isPix(paymentType?: SchedulePaymentType): boolean {
  if (!isValidElement(paymentType)) return false
  return paymentType === SchedulePaymentType.PIX
}

export function isHealthPlanPayment(paymentType?: SchedulePaymentType): boolean {
  if (!isValidElement(paymentType)) return false
  return paymentType === SchedulePaymentType.HEALTH_INSURANCE
}

export function isChildHealthPlanPayment(paymentType?: SchedulePaymentType | CategoryPaymentType): boolean {
  if (!isValidElement(paymentType)) return false
  return paymentType === SchedulePaymentType.HEALTH_INSURANCE_CHILDREN || paymentType === CategoryPaymentType.HEALTH_INSURANCE_CHILDREN
}

export function isPediatricJourney(journeyType?: JourneyType): boolean {
  if (!journeyType) return false
  return journeyType === JourneyType.PEDIATRICIAN
}

export const quartersNames: { [key: number]: string } = {
  0: '1º trimestre',
  1: '2º trimestre',
  2: '3º trimestre',
  3: '4º trimestre',
}

export function formatToDate(date?: string | number) {
  if (!isValidElement(date)) return ''
  return moment(date).format('DD/MM/YYYY')
}

export function formatToHours(date?: string | number) {
  if (!isValidElement(date)) return ''
  return moment(date).format('HH:mm')
}

export function formatHeaderProfileName(name?: string) {
  if (!name) return ''
  const nameParts = name.split(' ')
  if (nameParts.length === 1) return nameParts[0]
  return `${nameParts[0]} ${nameParts[nameParts.length - 1].charAt(0)}.`
}

export function mergeAvailabilitySlots(
  existingSlotsByDay: AvailabilityByDay[],
  newSlotsByDay: AvailabilityByDay[]
): AvailabilityByDay[] {
  const merged = [...existingSlotsByDay, ...newSlotsByDay]

  const reducedResponse = merged.reduce((accumulator: AvailabilityByDay[], currentItem) => {
    const existingItem = accumulator.find((item) => item.day === currentItem.day)

    if (existingItem) {
      existingItem.slots = [...existingItem.slots, ...currentItem.slots]
    } else {
      accumulator.push({ ...currentItem, slots: [...currentItem.slots] })
    }

    return accumulator
  }, [])
  return reducedResponse
}

export function abbreviateName(name: string): string {
  if (!isValidElement(name)) return ''
  const splitedName = name.split(' ')
  const formatedName = splitedName.length > 1
    ? `${splitedName[0]} ${splitedName[splitedName.length - 1]}`
    : name
  return formatedName
}

export function limitCharacters(text: string, limit: number) {
  if (text.length <= limit) {
    return text
  }
  return `${text.substring(0, limit)}...`
}

export const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]

export function phoneMask(val: string): Array<string | RegExp> {
  if (parseDigits(val).length > 10) {
    return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }

  return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
}

export const validationPhoneRegex = /\(?\d{2}\)?\s\d{4,5}-\d{4}/

export function isExamCategory(category?: SpecialistCategories): boolean {
  if (!isValidElement(category)) return false
  return category === SpecialistCategories.EXAM
}

export function isTokenValid(token?: string): boolean {
  if (!isValidElement(token)) return false
  const tokenParts = token?.split('.')
  if (token && tokenParts?.length === 3) {
    const decodedJwt = JSON.parse(atob(token.split('.')[1]))
    const fiveMinutesFromNow = Date.now() + (5 * 60 * 1000)
    if (decodedJwt.exp * 1000 < fiveMinutesFromNow) {
      return false
    }
    return true
  }
  return false
}

export function isAccessTokenValid(): boolean {
  const token = localStorage.getItem(TOKEN_ACCESS) || ''
  return isTokenValid(token)
}

export function isRefreshTokenValid(): boolean {
  const refreshToken = localStorage.getItem(TOKEN_REFRESH) || ''
  return isTokenValid(refreshToken)
}

const ODONTOLOGIST_ID_CATEGORY = '66d91bd2-7658-4877-ada5-acdb1dd3458c'
const USG_ID_CATEGORY = '7817f3e5-6fcc-4c16-b34a-00891c3cc434'
const PEDIATRIC_ODONTOLOGIST_ID_CATEGORY = 'c792c268-203e-4e4d-b5f7-133e155dd88c'

export const forbiddenCategories = [ODONTOLOGIST_ID_CATEGORY, USG_ID_CATEGORY, PEDIATRIC_ODONTOLOGIST_ID_CATEGORY]

export function cleanStringToCompare(string: string) {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim()
    .replace(/\s+/g, ' ')
}

export function transformBytes(fileSize: any) {
  const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let unitIndex = 0;
  let size = parseInt(fileSize, 10) || 0
  // eslint-disable-next-line no-plusplus
  while (size >= 1024 && ++unitIndex) {
    size /= 1024
  }
  return (`${size.toFixed(size < 10 && unitIndex > 0 ? 1 : 0)} ${units[unitIndex]}`)
}
