import React from 'react'
import { RegularButton } from 'theia-web-ds'
import './EmptyView.scss'

interface Props {
  title: string;
  subtitle: string | React.ReactNode;
  extraActionButton?: () => void;
  extraActionText?: string;
  goToSpecialist?: () => void;
  icon: React.ReactNode;
}

export default function EmptyView({
  title,
  subtitle,
  extraActionButton,
  extraActionText,
  goToSpecialist,
  icon,
}: Props) {
  return (
    <div className="empty-view-container">
      {icon}
      <p className="empty-title">{title}</p>
      <p className="empty-description">{subtitle}</p>
      {goToSpecialist && (
        <RegularButton
          onClick={goToSpecialist}
          label="Agendar consulta"
          variant="primary"
          maxWidth="197px"
        />
      )}
      {extraActionButton && extraActionText && (
        <RegularButton
          onClick={extraActionButton}
          label={extraActionText}
          variant="text"
          maxWidth="220px"
          extraClass="empty-last-button"
        />
      )}
    </div>
  )
}
