/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { Divider } from 'theia-web-ds'
import { AppDispatch } from '../../../state/utils'
import { AppState } from '../../../apps/main/store'
import { getKeyValue } from '../../../utils/helpers'
import './Drawer.scss'
import { suppressDrawerAction } from '../../../state/drawer/actions'
import ArrowLeft from '../../../../assets/arrow-left.svg'

interface Props {
  variant: any;
  children: React.ReactNode;
  suppressDrawer: () => void;
  isDrawerOpened?: boolean;
  text?: string;
  extraAction?: () => void;
  id?: string;
  canClose?: boolean;
  onGoBackAction?: () => void;
  extraClass?: string;
}

const mapVariant = {
  rightDrawer: 'rightDrawer',
  bottomDrawer: 'bottomDrawer'
}

function Drawer({
  variant = 'rightDrawer',
  children,
  suppressDrawer,
  isDrawerOpened,
  text = '',
  extraAction,
  id,
  canClose = true,
  onGoBackAction,
  extraClass,
  ...props
}: Props) {
  const [isOpened, setIsOpened] = useState(true)

  function closeDrawer() {
    setIsOpened(false)
    setTimeout(() => {
      suppressDrawer()
      if (extraAction) {
        extraAction()
      }
    }, 200)
  }

  function backDrawer() {
    if (canClose) {
      closeDrawer()
    } else if (onGoBackAction) {
      onGoBackAction()
    }
  }

  useEffect(() => {
    if (isDrawerOpened) {
      setIsOpened(true)
    }
  }, [isDrawerOpened])
  return (
    <>
      {isDrawerOpened
        && (
          <>
            <div
              aria-hidden="true"
              className={`drawer-overlay ${getKeyValue(mapVariant, variant)}`}
              onClick={closeDrawer}
            />
            <div
              role="dialog"
              id={id}
              aria-modal="true"
              className={`drawer-material ${getKeyValue(mapVariant, variant)} ${isOpened ? 'drawer-active' : 'drawer-inactive'}`}
              {...props}
            >
              {variant === 'rightDrawer'
              && (
                <>
                  <div className="flex flex-row w-full p-4">
                    <button onClick={backDrawer} className="focus:outline-none items-center" type="button">
                      <ArrowLeft />
                    </button>
                    <p className="text-headlineSmall text-textPrimary ml-4">{text}</p>
                  </div>
                  {text !== '' && <Divider width="100%" border="1px solid #EEEEEE" />}
                </>
              )}
              <div className={`contentDrawer ${extraClass}`}>
                {children}
              </div>
            </div>
          </>
        )}
    </>
  )
}

const mapStateToProps = ({
  drawer,
}: AppState) => ({
  isDrawerOpened: drawer.isDrawerOpened
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  suppressDrawer: () => { dispatch(suppressDrawerAction()) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Drawer)
