/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Loader } from 'theia-web-ds'
import Lottie from 'react-lottie'
import { useLocation } from 'react-router-dom'
import { AppState } from '../../apps/main/store'
import checkHealthInsurance from '../../animations/checking_card.json'
import AppMainContainerOff from '../AppMainContainerOff'
import PositiveHealthInsurance from './PositiveHealthInsurance'
import './HealthInsuranceFeedback.scss'
import NotAcceptedHealthInsurance from './NotAcceptedHealthInsurance'
import { StatusType } from '../../domain/Status'
import { HealthInsurancePlanData } from '../../domain/Healthplan'

interface Props {
  getHealthInsurancePlanStatus: StatusType;
  postHealthInsurancePlanStatus: StatusType;
  healthInsurancePlanData: HealthInsurancePlanData | null;
}

export interface ConvenioFeedbackParams {
  companyName: string;
}

function HealthInsuranceFeedback({
  getHealthInsurancePlanStatus,
  postHealthInsurancePlanStatus,
  healthInsurancePlanData,
}: Props) {
  const params = useLocation().state as ConvenioFeedbackParams || {}
  const companyName = params.companyName || undefined
  const [isVisibleFeedback, setIsVisibleFeedback] = useState(false)
  const isCovered = healthInsurancePlanData?.insurancePlanCovered

  const lottieCheckingHealthInsurance = {
    loop: true,
    autoplay: true,
    animationData: checkHealthInsurance,
  }

  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => {
      if (healthInsurancePlanData) setIsVisibleFeedback(true)
    }, 6000)
    return () => clearTimeout(timer)
  }, [healthInsurancePlanData])

  return (
    <AppMainContainerOff hasNewLayout={!isVisibleFeedback} extraClassOuter={isVisibleFeedback ? 'container-outer' : ''} extraClassInner="fade-in" extraClassInnerContainer="container-inner">
      {getHealthInsurancePlanStatus.isLoading || postHealthInsurancePlanStatus.isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Loader />
        </div>
      ) : (
        <div className="px-4">
          {isVisibleFeedback && isCovered && (
            <PositiveHealthInsurance />
          )}
          {isVisibleFeedback && !isCovered && (
            <NotAcceptedHealthInsurance />
          )}
          {!isVisibleFeedback && (
            <div className="flex flex-col items-center justify-center h-full">
              <Lottie
                options={lottieCheckingHealthInsurance}
                isClickToPauseDisabled
                ariaRole=""
                height={192}
                width={192}
              />
              <p className="text-textPrimary text-textMedium w-4/5 text-center">
                Falando com a {companyName}...
              </p>
            </div>
          )}
        </div>
      )}
    </AppMainContainerOff>
  )
}

const mapStateToProps = ({
  healthplan
}: AppState) => ({
  getHealthInsurancePlanStatus: healthplan.getHealthInsurancePlanStatus,
  healthInsurancePlanData: healthplan.healthInsurancePlanData,
  postHealthInsurancePlanStatus: healthplan.postHealthInsurancePlanStatus
})

export default connect(
  mapStateToProps,
  undefined
)(HealthInsuranceFeedback)
