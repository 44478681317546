import ApiClient from './ApiClient'
import {
  PrescriptionsService,
  Prescriptions,
  UsgPrescription,
  UsgStatus,
  UsgAvailabilityParams,
  Exam,
  UploadExamResponse
} from '../../domain/Prescriptions'
import { AvailabilitySlot, ProfessionalListType } from '../../domain/Professional'
import { LocalDate } from '../../utils/LocalDate'
import { LocalAddress } from '../../utils/LocalAddress'

export default class ApiPrescriptionsService implements PrescriptionsService {
  constructor(private apiClient: ApiClient) { }

  async getPrescriptionsInformation(): Promise<Array<Prescriptions>> {
    const prescriptionsInformationResponse = await this.apiClient.getPrescriptionsInformation()
    return prescriptionsInformationResponse
  }

  async getExams(): Promise<Array<Exam>> {
    const response = await this.apiClient.getExams()
    return response
  }

  async getChildExams(childId: string): Promise<Array<Exam>> {
    const response = await this.apiClient.getChildExams(childId)
    return response
  }

  async deleteExam(examId: string): Promise<void> {
    const response = await this.apiClient.deleteExam(examId)
    return response
  }

  uploadExam(consultantId: string, exam: FormData): Promise<UploadExamResponse> {
    return this.apiClient.uploadExam(consultantId, exam)
  }

  async getExamDownloadUrl(examId: string): Promise<string> {
    const response = await this.apiClient.getExamDownloadUrl(examId)
    return response
  }

  async getUsgPrescription(): Promise<UsgPrescription> {
    const response = await this.apiClient.getUsgPrescription()
    return response
  }

  async getUsgData(
    chronosId: string,
    token?: string
  ): Promise<ProfessionalListType> {
    const response = await this.apiClient.getUsgData(chronosId, token)
    return response;
  }

  async getUsgAvailability(
    chronosId: string,
    params: UsgAvailabilityParams,
    token?: string,
  ): Promise<Array<AvailabilitySlot>> {
    const availability = await this.apiClient.getUsgAvailability(
      chronosId, params, token
    )
    const formattedAvailability = availability.map((slot: any) => ({
      ...slot,
      startDate: new LocalDate(slot.startDate),
      endDate: new LocalDate(slot.endDate),
      attendanceAddress: slot.attendanceAddress && new LocalAddress(
        slot.attendanceAddress
      )
    } as AvailabilitySlot))
    return formattedAvailability
  }

  postUsgStatus(status: UsgStatus, usgId: string): Promise<void> {
    return this.apiClient.postUsgStatus(status, usgId)
  }
}
