import ApiClient from './ApiClient'
import {
  ProfileProvider,
  Profile,
  InfosToUpdateProfile,
  PersonalData,
} from '../../domain/Profile'
import {
  profileFromRemote,
  liveInSpFromRemote
} from '../../state/utils/userRemoteAdapters'

export default class ApiProfileService implements ProfileProvider {
  constructor(private apiClient: ApiClient) { }

  async getProfile(): Promise<Profile> {
    const profileRemote: any = await this.apiClient.getCurrentUser()
    const consultantWithNoAddress = { ...profileRemote.consultant }
    delete consultantWithNoAddress.address
    const profile = {
      ...profileRemote.profile,
      ...consultantWithNoAddress,
      liveInSp: liveInSpFromRemote(profileRemote.consultant.liveInSp),
    }
    const profileForm = profileFromRemote(profile)
    return {
      profile: profileForm,
      baby: profileRemote.baby,
    }
  }

  async updateProfile(payload: InfosToUpdateProfile): Promise<void> {
    const response = await this.apiClient.updateProfile(payload)
    return response
  }

  async updatePersonalData(payload: PersonalData): Promise<void> {
    const response = await this.apiClient.updatePersonalData(payload)
    return response
  }

  async deleteDocuments(consultantId: string): Promise<void> {
    const response = await this.apiClient.deleteDocuments(consultantId)
    return response
  }

  async validateInformation(
    verificationCode: string,
    email?: string,
    phone?: string
  ): Promise<void> {
    const response = await this.apiClient.validateInformation(verificationCode, email, phone)
    return response
  }
}
