import {
  FETCH_PROFESSIONAL_SUMMARY,
  FAILURE_FETCH_PROFESSIONAL_SUMMARY,
  SUCCESS_FETCH_PROFESSIONAL_SUMMARY,
  ProfessionalTypes,
  FETCH_PROFESSIONAL_AVAILABILITY,
  SUCCESS_FETCH_PROFESSIONAL_AVAILABILITY,
  FAILURE_FETCH_PROFESSIONAL_AVAILABILITY,
} from './actions'
import {
  Professional,
  AvailabilitySlot,
} from '../../domain/Professional';

export interface ProfessionalState {
  isFetchingSummary: boolean;
  professionalSummary?: Professional;
  isFetchingAvailability: boolean;
  professionalAvailability?: AvailabilitySlot[];
}

const initialState: ProfessionalState = {
  isFetchingSummary: false,
  professionalSummary: undefined,
  isFetchingAvailability: false,
  professionalAvailability: undefined
}

export function professionals(
  state: ProfessionalState = initialState,
  action: ProfessionalTypes
): ProfessionalState {
  switch (action.type) {
    case FETCH_PROFESSIONAL_SUMMARY:
      return {
        ...state,
        isFetchingSummary: true,
        professionalSummary: undefined
      }
    case SUCCESS_FETCH_PROFESSIONAL_SUMMARY:
      return {
        ...state,
        isFetchingSummary: false,
        professionalSummary: action.professionalSummary
      }
    case FAILURE_FETCH_PROFESSIONAL_SUMMARY:
      return {
        ...state,
        isFetchingSummary: false,
        professionalSummary: undefined
      }
    case FETCH_PROFESSIONAL_AVAILABILITY:
      return {
        ...state,
        isFetchingAvailability: true,
        professionalAvailability: undefined
      }
    case SUCCESS_FETCH_PROFESSIONAL_AVAILABILITY:
      return {
        ...state,
        isFetchingAvailability: false,
        professionalAvailability: action.availability
      }
    case FAILURE_FETCH_PROFESSIONAL_AVAILABILITY:
      return {
        ...state,
        isFetchingAvailability: false,
        professionalAvailability: undefined
      }
    default:
      return state
  }
}
