import { Formik, FormikProps } from 'formik'
import React, { useState, useEffect } from 'react'
import { Divider, RegularButton } from 'theia-web-ds'
import { useHistory } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'

import { bgDisable, success } from '../../color'
import Emoji from '../common/atoms/Emoji'
import Header from '../common/molecules/Header'
import StepperBar from '../common/StepperBar'
import BooleanInput from '../common/molecules/inputs/BooleanInput'
import FormInputList from '../common/molecules/inputs/FormInputList'
import FormMultiSelect from '../common/molecules/inputs/FormMultiSelect'
import BannerUserDataInfo from './BannerUserDataInfo'
import {
  GestationalInfosForm,
  RelationshipOptions
} from '../../domain/Profile'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { fetchProfileAction, onUpdateGestationalInfosInProfileAction } from '../../state/profile/actions'
import ButtonPlaceholder from '../common/molecules/placeholders/ButtonPlaceholder'
import { formCheckHasOneElementsEmpty } from '../../utils/helpers'
import './HealthGestationalAndPersonalInfos.scss'
import './GestationalInfos.scss'
import { DADOS_DE_SAUDE, DADOS_PESSOAIS } from '../../routes/RoutesConstants'

function renderGestationalInfosForm({
  values,
  setFieldValue,
  setFieldTouched,
  handleSubmit,
}: FormikProps<GestationalInfosForm>,
isLoading: boolean,
isSubmitting: boolean) {
  const [
    aboutRelationshipOthersError,
    setAboutRelationshipOthersError
  ] = useState<{[index: number]: string}>()
  const disabledSubmit = (
    !!aboutRelationshipOthersError
  )

  useEffect(() => {
    setAboutRelationshipOthersError(formCheckHasOneElementsEmpty(values.aboutRelationshipOthers))
  }, [values.aboutRelationshipOthers])

  function onChangeHasPregnancyLoss(newValue: boolean) {
    setFieldValue('hasPregnancyLoss', `${newValue}`);
    setFieldTouched('hasPregnancyLoss', true);
  }

  function onChangeHasAboutRelationship(newValue: boolean) {
    setFieldValue('hasAboutRelationship', `${newValue}`);
    setFieldTouched('hasAboutRelationship', true);
    if (!newValue) {
      setFieldValue('aboutRelationship', []);
      setFieldTouched('aboutRelationship', true);
      setFieldValue('aboutRelationshipOthers', []);
      setFieldTouched('aboutRelationshipOthers', true);
    }
  }

  function onChangeAboutRelationship(newList: string[]) {
    setFieldValue('aboutRelationship', newList);
    setFieldTouched('aboutRelationship', true);
    if (
      newList.includes(RelationshipOptions.OTHER)
      && values.aboutRelationshipOthers.length === 0
    ) {
      setFieldValue('aboutRelationshipOthers', ['']);
      setFieldTouched('aboutRelationshipOthers', true);
    }
    if (!newList.includes(RelationshipOptions.OTHER) && values.aboutRelationshipOthers.length > 0) {
      setFieldValue('aboutRelationshipOthers', []);
      setFieldTouched('aboutRelationshipOthers', true);
    }
  }

  function onChangeAboutRelationshipOthers(newList: string[]) {
    setFieldValue('aboutRelationshipOthers', newList);
    setFieldTouched('aboutRelationshipOthers', true);
  }

  return (
    <form onSubmit={handleSubmit} className="user-profile-infos-form" noValidate>
      <div className="individual-container">
        <BooleanInput
          label="Já teve alguma perda gestacional?"
          name="hasPregnancyLoss"
          value={values.hasPregnancyLoss === '' ? undefined : values.hasPregnancyLoss === 'true'}
          onChange={onChangeHasPregnancyLoss}
          isLoading={isLoading}
        />
      </div>
      <div className="individual-container">
        <BooleanInput
          label="Você tem uma rede de apoio?"
          name="hasAboutRelationship"
          value={values.hasAboutRelationship === '' ? undefined : values.hasAboutRelationship === 'true'}
          onChange={onChangeHasAboutRelationship}
          isLoading={isLoading}
        />
        {values.hasAboutRelationship === 'true' && (
          <div className="individual-sub-topic">
            <FormMultiSelect
              id="aboutRelationship"
              options={Object.values(RelationshipOptions).filter(
                (element) => element !== RelationshipOptions.NONE
              )}
              onChange={onChangeAboutRelationship}
              isLoading={isLoading}
              initialValues={values.aboutRelationship}
            />
          </div>
        )}
        {values.hasAboutRelationship === 'true' && values.aboutRelationship.includes(RelationshipOptions.OTHER) && (
          <FormInputList
            placeholder="* Digite sua rede de apoio"
            ariaLabel="Digite sua rede de apoio"
            list={values.aboutRelationshipOthers}
            errors={aboutRelationshipOthersError}
            onChange={onChangeAboutRelationshipOthers}
            required
          />
        )}
      </div>
      <BannerUserDataInfo extraClassName="banner-small-screen" isLoading={isLoading} />
      <div className="button-container">
        {isLoading ? (
          <ButtonPlaceholder />
        ) : (
          <RegularButton
            onClick={() => handleSubmit()}
            label="Ir para o passo 3"
            type="submit"
            disabled={disabledSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      </div>
    </form>
  )
}

const emptyList: string[] = []

const initialValues = {
  hasPregnancyLoss: '',
  hasAboutRelationship: '',
  aboutRelationship: emptyList,
  aboutRelationshipOthers: emptyList,
}

interface Props {
  isFetchingProfile: boolean;
  fetchProfile: () => void;
  hasProfile: boolean;
  name?: string;
  email?: string;
  phone?: string;
  onUpdateGestationalInfosInProfile: (
    name: string,
    email: string,
    phone: string,
    gestationalInfos: GestationalInfosForm
  ) => void;
  forceUpdateCurrentUser: boolean;
  isFetchingUser: boolean;
  isUpdatingProfile: boolean;
  hasPregnancyLoss?: string;
  hasAboutRelationship?: string;
  aboutRelationship?: string[];
  aboutRelationshipOthers?: string[];
}

function GestationalInfos({
  isFetchingProfile,
  fetchProfile,
  hasProfile,
  name,
  email,
  phone,
  onUpdateGestationalInfosInProfile,
  forceUpdateCurrentUser,
  isFetchingUser,
  isUpdatingProfile,
  hasPregnancyLoss,
  hasAboutRelationship,
  aboutRelationship,
  aboutRelationshipOthers
}: Props) {
  const history = useHistory()
  const [updateInfos, setUpdateInfos] = useState(false)
  const isLoading = !hasProfile || isFetchingProfile
  useEffect(() => {
    if (!hasProfile && !isFetchingProfile) {
      fetchProfile()
    }
  }, [])
  function goBack() {
    history.push(DADOS_DE_SAUDE)
  }
  useEffect(() => {
    if (forceUpdateCurrentUser && updateInfos && !isFetchingUser) {
      setUpdateInfos(false)
      history.push(DADOS_PESSOAIS, { has3Steps: true })
    }
  }, [forceUpdateCurrentUser])

  initialValues.hasPregnancyLoss = hasPregnancyLoss
    || initialValues.hasPregnancyLoss
  initialValues.hasAboutRelationship = hasAboutRelationship
    || initialValues.hasAboutRelationship
  initialValues.aboutRelationship = aboutRelationship
    || initialValues.aboutRelationship
  initialValues.aboutRelationshipOthers = aboutRelationshipOthers
    || initialValues.aboutRelationshipOthers

  return (
    <div className="user-profile-infos-container gestational-infos-container">
      <div className="header-container">
        <Header
          title="Dados gestacionais"
          isLoading={isLoading}
          goBack={goBack}
        />
        <Divider />
      </div>
      <div className="user-profile-infos-body-outer-container">
        <div className="user-profile-infos-body-inner-container">
          <StepperBar
            totalSteps={3}
            currentStep={2}
            showInfosBottom
            showNumbers={false}
            currentStepColor={success}
            totalStepsColor={bgDisable}
            isLoading={isLoading}
            lineHeight="8px"
            lineRadius="40px"
            explanation={(
              <div className="stepper-explanation-container">
                <Emoji symbol="🔍" />
                <p className="stepper-explanation-text">Pra gente te entender melhor</p>
              </div>
            )}
          />
          <Divider />
        </div>
        <div className="user-profile-infos-form-and-explanation-container">
          <div className="user-profile-infos-form-container">
            <Formik
              initialValues={initialValues}
              onSubmit={(_value: GestationalInfosForm) => {
                if (name && email && phone) {
                  setUpdateInfos(true)
                  onUpdateGestationalInfosInProfile(name, email, phone, _value)
                }
              }}
            >
              {(props) => renderGestationalInfosForm(
                props,
                isLoading,
                isUpdatingProfile
              )}
            </Formik>
          </div>
          <BannerUserDataInfo extraClassName="banner-large-screen" isLoading={isLoading} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  profile, authentication
}: AppState) => ({
  hasProfile: !!profile.profile,
  isFetchingProfile: profile.isFetchingProfile,
  name: authentication.currentUser?.name,
  email: authentication.currentUser?.email,
  phone: authentication.currentUser?.phone,
  hasPregnancyLoss: profile.profile?.hasPregnancyLoss,
  hasAboutRelationship: profile.profile?.hasAboutRelationship,
  aboutRelationship: profile.profile?.aboutRelationship,
  aboutRelationshipOthers: profile.profile?.aboutRelationshipOthers,
  forceUpdateCurrentUser: authentication.forceUpdateCurrentUser,
  isFetchingUser: authentication.isFetchingUser,
  isUpdatingProfile: profile.isUpdatingProfile
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchProfile: () => { dispatch(fetchProfileAction()) },
  onUpdateGestationalInfosInProfile: (
    name: string,
    email: string,
    phone: string,
    gestationalInfos: GestationalInfosForm
  ) => {
    dispatch(onUpdateGestationalInfosInProfileAction(name, email, phone, gestationalInfos))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestationalInfos)
