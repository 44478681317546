import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  Alert,
  IconButton,
  RegularButton,
  Snackbar
} from 'theia-web-ds'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useDropzone } from 'react-dropzone'
import { AppState } from '../../../apps/main/store'
import { AppDispatch } from '../../../state/utils'
import { setSawExamsUploadAction } from '../../../state/userHighlights/actions'
import './ExamUpload.scss'
import { eventTrack } from '../../../../eventGenerate'
import { showNotificationAction } from '../../../state/notifications/actions'
import { ToastInfos } from '../../../domain/Notifications'
import { warning } from '../../../color'
import { EventProfileType } from '../../../domain/AppProfiles'
import { uploadExamAction } from '../../../state/prescriptions/actions'
import { StatusType } from '../../../domain/Status'

interface Props {
  sawExamsUpload: boolean
  userId?: string
  consultantId?: string
  isDependent: boolean
  profileType?: EventProfileType
  uploadExamStatus: StatusType
  uploadExam: (consultantId: string, file: File, childId?: string) => void
  onFinishAllUploads: () => void
  getAcceptedExams: (acceptedFiles?: any) => void
  setSawExamsUpload: () => void
  onRenderMessage: (message?: ReactElement) => void
  scrollTopOnSubmit: () => void
  onShowNotification: (toastInfos: ToastInfos) => void
}

function ExamUpload({
  sawExamsUpload,
  userId,
  consultantId,
  isDependent,
  profileType,
  uploadExamStatus,
  uploadExam,
  onFinishAllUploads,
  getAcceptedExams,
  setSawExamsUpload,
  onRenderMessage,
  scrollTopOnSubmit,
}: Props) {
  const [accepted, setAccepted] = useState<File[] | undefined>()

  useEffect(() => {
    if (uploadExamStatus.success) onFinishAllUploads()
  }, [uploadExamStatus])

  const handleSubmit = (file: File) => {
    scrollTopOnSubmit()
    const childId = isDependent && userId ? userId : undefined
    uploadExam(consultantId || '', file, childId)
  }

  const onDrop = useCallback((acceptedFiles: any[]) => {
    acceptedFiles.forEach((file: any) => {
      handleSubmit(file)
    })
  }, [])

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: '.jpg, .png, .tiff, .pdf, .tif, .heif, .heic, .jpeg',
    noClick: true,
    noKeyboard: true,
    maxSize: 10 * 1024 * 1024, // 10 MB
  })

  useEffect(() => {
    setAccepted(acceptedFiles)
  }, [acceptedFiles])

  useEffect(() => {
    if (accepted) {
      getAcceptedExams(accepted)
    }
  }, [accepted])

  const isFileError = !!((uploadExamStatus.error && uploadExamStatus?.errorMessage?.message === 'Invalid file extension')
    || fileRejections.length)

  function postErrorMessage() {
    let message = { title: '', text: '' }
    if (uploadExamStatus.errorMessage?.message !== 'Invalid file extension') {
      message = {
        title: 'Erro ao enviar',
        text: 'Desculpe, não foi possível enviar o arquivo, por favor tente novamente.'
      }
    }
    if (uploadExamStatus.errorMessage?.message === 'Invalid file extension') {
      message = {
        title: 'Formato não suportado',
        text: 'Desculpe, o formato do arquivo não é suportado. Escolha outro formato e tente novamente.'
      }
    }
    if (fileRejections.length) {
      message = {
        title: 'Tamanho excedido',
        text: 'Desculpe, não foi possível enviar o arquivo, por favor selecione outro.'
      }
    }
    return message
  }

  useEffect(() => {
    if (uploadExamStatus.error || fileRejections.length) {
      onRenderMessage(
        <div className="alert-snackbar upload-error">
          <Snackbar
            variant="alert"
            text={postErrorMessage()?.text || ''}
            title={postErrorMessage()?.title}
            alignTop
            iconLeft="icon-CloseCircleLight"
            customIconColor={warning}
            showCloseButton
            extraOnClose={() => onRenderMessage()}
            buttonTwoProps={isFileError ? {
              label: 'Selecionar outro',
              onClick: () => {
                onRenderMessage()
              },
            } : undefined}
          />
        </div>
      )
    }
  }, [uploadExamStatus, fileRejections])

  function handleUploadExam() {
    open()
    eventTrack('clicou enviar exame', {
      tipo_de_perfil: profileType
    })
    setSawExamsUpload()
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="add-exams-desktop-btn-wrapper">
        <div className="upload-btn-desktop">
          <RegularButton
            label="+ Anexar resultado"
            onClick={handleUploadExam}
          />
          {!sawExamsUpload && (
            <Alert
              message="Clique aqui para escolher o exame que deseja enviar"
              extraClass="add-exam-tooltip-message-desktop"
              variant="flowting"
              indicator="right-top"
              timer={10000}
            />
          )}
        </div>
      </div>
      <div className="add-exams-mobile-btn-wrapper">
        <div className="upload-btn-mobile">
          <IconButton
            variant="primary"
            iconType="icon-Plus2Light"
            extraClass="download-btn-mobile"
            iconSize="32px"
            onClick={handleUploadExam}
          />
          {!sawExamsUpload && (
            <Alert
              message="Clique aqui para escolher o exame que deseja enviar"
              extraClass="add-exam-tooltip-message"
              variant="flowting"
              indicator="right-bottom"
              timer={10000}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  userHighlights, appProfiles, authentication, prescriptions
}: AppState) => ({
  sawExamsUpload: userHighlights.sawExamsUpload,
  userId: appProfiles.activeProfile?.id,
  isDependent: appProfiles.isDependent,
  consultantId: authentication.currentUser?.id,
  profileType: appProfiles.profileType,
  uploadExamStatus: prescriptions.uploadExamStatus,
  examsInProgress: prescriptions.examsInProgress
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  setSawExamsUpload: setSawExamsUploadAction,
  onShowNotification: showNotificationAction,
  uploadExam: uploadExamAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamUpload)
