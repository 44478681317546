import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Divider,
  ListItemCard,
  Loader,
  RegularButton,
  Snackbar
} from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import {
  CategoryToSchedule,
  PaymentMethods,
  SchedulePaymentType
} from '../../domain/ScheduleFlow'
import { User } from '../../domain/User'
import { getPaymentMethodsAction, selectPaymentMethodAction } from '../../state/scheduleFlow/actions'
import { primary } from '../../color'
import OldCard from '../../../assets/old-card.png'
import NewCard from '../../../assets/new-card.png'
import SlipOption from '../../../assets/slip-option.svg'
import PixOption from '../../../assets/pix-option.svg'
import CouponIcon from '../../../assets/coupon-icon.png'
import CouponForm from '../payment/CouponForm'
import { formatToDate } from '../../utils/helpers'
import { TheiaError } from '../../domain/errors/TheiaError'
import { eventPage } from '../../../eventGenerate'
import './ChoosePayment.scss'
import { AvailabilityTimeSlot } from '../../domain/Availabilities'
import { EventProfileType } from '../../domain/AppProfiles'
import { EventCategories } from '../../utils/EventCategories'

interface Props {
  selectedTimeSlot?: AvailabilityTimeSlot;
  paymentMethods?: PaymentMethods;
  isGettingPaymentMethods: boolean;
  currentUser?: User;
  productId?: string;
  promotionalPrice?: number;
  coupon?: string;
  discount?: number;
  selectedCategory?: CategoryToSchedule;
  paymentMethodsError?: TheiaError;
  profileType?: EventProfileType
  getPaymentMethods: (startTime: number) => void;
  selectPaymentMethod: (
    useOldCard: boolean,
    isFree: boolean,
    newPaymentMethodAdded: boolean,
    paymentMethod?: SchedulePaymentType,
  ) => void;
  onChoosePaymentMethod: () => void;
}

function ChoosePayment({
  selectedTimeSlot,
  paymentMethods,
  isGettingPaymentMethods,
  currentUser,
  productId,
  promotionalPrice,
  coupon,
  discount,
  selectedCategory,
  paymentMethodsError,
  profileType,
  getPaymentMethods,
  selectPaymentMethod,
  onChoosePaymentMethod,
}: Props) {
  function getAvailablePaymentMethods() {
    if (selectedTimeSlot) {
      getPaymentMethods(selectedTimeSlot.startTime.toEpochTimestamp())
    }
  }

  useEffect(() => {
    getAvailablePaymentMethods()
    eventPage(EventCategories.NEW_SCHEDULE_FLOW, 'visualizou tela de pagamento', { tipo_de_perfil: profileType })
  }, [])

  function handleChoosePaymentMethod(
    useOldCard: boolean,
    isFree: boolean,
    newPaymentMethodAdded: boolean,
    paymentMethod: SchedulePaymentType,
  ) {
    selectPaymentMethod(useOldCard, isFree, newPaymentMethodAdded, paymentMethod)
    onChoosePaymentMethod()
  }

  const oldCardButton = paymentMethods
  && paymentMethods.creditCard && (
    <ListItemCard
      extraClassNameContainer="mb-4"
      onClick={() => handleChoosePaymentMethod(true, false, false, SchedulePaymentType.CREDIT_CARD)}
      picture={(
        <img
          src={OldCard}
          width={40}
          height={40}
          className="mx-4"
          alt=""
        />
      )}
      iconColor={primary}
      caption="O valor só será cobrado após a consulta"
      title={`${paymentMethods.creditCard?.cardBrand?.toUpperCase()} final ${paymentMethods.creditCard?.cardDigits}`}
      ariaLabel={`Pagar com o cartão ${paymentMethods.creditCard?.cardBrand} final ${paymentMethods.creditCard?.cardDigits}`}
      isLastItem
      isItemFromList={false}
      key="old-card-id"
      id="old-card-id"
    />
  )

  const newCardButton = (
    <ListItemCard
      extraClassNameContainer="mb-4"
      onClick={() => handleChoosePaymentMethod(false, false, true, SchedulePaymentType.CREDIT_CARD)}
      picture={(
        <img
          src={NewCard}
          width={40}
          height={40}
          className="mx-4"
          alt=""
        />
      )}
      iconColor={primary}
      caption="O valor só será cobrado após a consulta"
      title={currentUser?.plan.paymentMethodAdded ? 'Novo cartão de crédito' : 'Cartão de crédito'}
      ariaLabel="Adicionar cartão"
      isLastItem
      isItemFromList={false}
      key="new-card-id"
      id="new-card-id"
    />
  )

  const slipButton = paymentMethods
    && paymentMethods.slip
    && paymentMethods.slip?.paymentSlipAllowed && (
    <ListItemCard
      extraClassNameContainer="mb-4"
      onClick={
        () => handleChoosePaymentMethod(false, false, false, SchedulePaymentType.PAYMENT_SLIP)
      }
      picture={(
        <SlipOption
          width={40}
          height={40}
          className="mx-4"
          alt=""
        />
      )}
      iconColor={primary}
      caption={`Pague até o dia ${formatToDate(paymentMethods.slip?.paymentSlipDueDate)} para garantir a consulta`}
      title="Boleto"
      ariaLabel="Boleto"
      isLastItem
      isItemFromList={false}
      key="slip-id"
      id="slip-id"
    />
  )

  const pixButton = paymentMethods
    && paymentMethods.pix
    && paymentMethods.pix?.paymentPixAllowed && (
    <ListItemCard
      extraClassNameContainer="mb-4"
      onClick={() => handleChoosePaymentMethod(false, false, false, SchedulePaymentType.PIX)}
      picture={(
        <PixOption
          width={40}
          height={40}
          className="mx-4"
          alt=""
        />
      )}
      iconColor={primary}
      caption={`Pague até o dia ${formatToDate(paymentMethods.pix?.paymentPixDueDate)} para garantir a consulta`}
      title="PIX"
      ariaLabel="pix"
      isLastItem
      isItemFromList={false}
      key="pix-id"
      id="pix-id"
    />
  )

  if (isGettingPaymentMethods || !selectedTimeSlot) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    )
  }

  return (
    <div className="choose-payment-container">
      <div className="discount-container">
        <label htmlFor="codeCoupon" className="">
          Possui código de desconto?
        </label>
        <CouponForm
          productId={productId || ''}
          showButton={false}
        />
        {discount && (
          <div className="discount-card">
            <img
              src={CouponIcon}
              width={40}
              height={40}
              alt=""
              className=""
            />
            <div className="discount-summary">
              <p className="coupon-name">
                Cupom {coupon}
              </p>
              <p className="coupon-values">
                {`De: R$ ${(selectedCategory?.price || 0) / 100}
                 por R$ ${(promotionalPrice || 0) / 100} a consulta`}
              </p>
            </div>
          </div>
        )}
      </div>
      <Divider />
      {promotionalPrice === 0 && (
        <RegularButton
          type="button"
          maxWidth="100%"
          onClick={() => handleChoosePaymentMethod(false, true, false, SchedulePaymentType.NONE)}
          label="Continuar"
        />
      )}
      {promotionalPrice !== 0 && paymentMethods && !paymentMethodsError && (
        <>
          <p className="text-titleLarge font-normal text-textPrimary mb-6 mt-4">
            Selecione qual meio de pagamento
          </p>
          {oldCardButton}
          {newCardButton}
          {pixButton}
          {slipButton}
        </>
      )}
      {promotionalPrice !== 0 && (!paymentMethods || paymentMethodsError) && (
      <div className="payment-methods-error-container">
        <Snackbar
          title={paymentMethodsError?.friendlyMessageTitle || 'Falha no carregamento'}
          text={paymentMethodsError?.friendlyMessage || 'Desculpe, no momento não foi possível carregar os métodos de pagamento'}
          buttonOneProps={{
            onClick: () => getAvailablePaymentMethods(),
            label: 'Tentar novamente'
          }}
          iconLeft="icon-InfoSquareLight"
        />
      </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  scheduleFlow, authentication, payment, availabilities, appProfiles
}: AppState) => ({
  selectedTimeSlot: scheduleFlow.selectedTimeSlot,
  paymentMethods: scheduleFlow.paymentMethods,
  isGettingPaymentMethods: scheduleFlow.isGettingPaymentMethods,
  currentUser: authentication.currentUser,
  productId: availabilities.productId,
  promotionalPrice: payment.promotionalPrice,
  coupon: payment.couponValueFromUser,
  discount: payment.discount,
  selectedCategory: scheduleFlow.selectedCategory,
  paymentMethodsError: scheduleFlow.paymentMethodsError,
  profileType: appProfiles.profileType
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  selectPaymentMethod: (
    useOldCard: boolean,
    isFree: boolean,
    newPaymentMethodAdded: boolean,
    paymentMethod?: SchedulePaymentType,
  ) => {
    dispatch(selectPaymentMethodAction(
      useOldCard,
      isFree,
      newPaymentMethodAdded,
      paymentMethod,
    ))
  },
  getPaymentMethods: (startTime: number) => {
    dispatch(getPaymentMethodsAction(startTime))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChoosePayment)
