import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FontIcon } from 'theia-web-ds'
import './AppMainContainerOff.scss'
import Logo from '../../assets/horizontal-logo.svg'
import Aldeia from '../../assets/aldeia.svg'
import { bgCanvas, textSecondary } from '../color'

interface Props {
  children?: React.ReactNode;
  screenTitle?: string;
  hasNewLayout?: boolean;
  hasBackButton?: boolean;
  extraClassOuter?: string;
  extraClassInnerContainer?: string;
  extraClassInner?: string;
}

const AppMainContainerOff = React.memo(({
  children,
  screenTitle,
  hasNewLayout,
  hasBackButton,
  extraClassOuter,
  extraClassInnerContainer,
  extraClassInner
}: Props) => {
  const history = useHistory()
  function goBack() {
    history.goBack()
  }

  const banner = document.querySelectorAll('.cookies-info-container')[0]
  const [hasBanner, setHasBanner] = useState(false)
  useEffect(() => {
    if (banner) {
      setHasBanner(true)
    } else {
      setHasBanner(false)
    }
  }, [banner])

  return (
    <div className="auth-grid">
      <div className="auth-item-a">
        <div className="icon-aldeia-container">
          <div className="icon-aldeia-desk">
            <Logo alt="logo" width="80%" />
          </div>
        </div>
      </div>
      <div id="item-b" className={`auth-item-b ${extraClassOuter}`} style={{ backgroundColor: bgCanvas }}>
        <div className={`flex flex-1 relative pre-login ${extraClassInner}`}>
          <div className={`w-full pre-login-inner ${hasBanner ? 'height-banner' : ''}`}>
            {hasNewLayout && (
              <div className="lg:hidden flex items-center">
                {hasBackButton && (
                  <button type="button" onClick={goBack} className="flex items-center">
                    <FontIcon extraClass="ml-4 absolute" iconType="icon-ArrowLeft2Light" color={textSecondary} fontSize="24px" />
                  </button>
                )}
                <div className="aldeia-mobile-icon">
                  <Logo alt="" />
                </div>
              </div>
            )}
            {screenTitle && (
              <div className="flex items-center justify-around screen-title-container">
                <h1 className="text-primary text-headlineMedium" style={{ maxWidth: '60%' }}>
                  {screenTitle}
                </h1>
                <div className="pre-login-icon md:invisible visible lg:invisible">
                  <Aldeia />
                </div>
              </div>
            )}
            {hasNewLayout ? (
              <div className={`onboarding-main-content ${extraClassInnerContainer}`}>
                {hasBackButton && (
                  <button type="button" onClick={goBack} className="items-center hidden lg:flex">
                    <FontIcon extraClass="ml-4 absolute" iconType="icon-ArrowLeft2Light" color={textSecondary} fontSize="24px" />
                  </button>
                )}
                {children}
              </div>
            ) : children}
          </div>
        </div>
      </div>
    </div>
  )
})

export default AppMainContainerOff
