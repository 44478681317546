import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../utils'
import {
  GuidedJourneyService,
  GuidedJourney,
  QuarterConsultation,
  ConsultationInfos,
  Quarter,
  UpdateCardResponse
} from '../../domain/GuidedJourney'
import { TheiaError } from '../../domain/errors/TheiaError'
import { AppState } from '../../apps/main/store'

export const GET_GUIDED_JOURNEY = 'GET_GUIDED_JOURNEY'
export const SUCCESS_GET_GUIDED_JOURNEY = 'SUCCESS_GET_GUIDED_JOURNEY'
export const FAILURE_GET_GUIDED_JOURNEY = 'FAILURE_GET_GUIDED_JOURNEY'

export const GET_QUARTER_DETAILS = 'GET_QUARTER_DETAILS'
export const SUCCESS_GET_QUARTER_DETAILS = 'SUCCESS_GET_QUARTER_DETAILS'
export const FAILURE_GET_QUARTER_DETAILS = 'FAILURE_GET_QUARTER_DETAILS'

export const GET_CONSULTATION_INFOS = 'GET_CONSULTATION_INFOS'
export const SUCCESS_GET_CONSULTATION_INFOS = 'SUCCESS_GET_CONSULTATION_INFOS'
export const FAILURE_GET_CONSULTATION_INFOS = 'FAILURE_GET_CONSULTATION_INFOS'

export const SET_VIEWED_GUIDED_JOURNEY = 'SET_VIEWED_GUIDED_JOURNEY'
export const SUCCESS_VIEWED_GUIDED_JOURNEY = 'SUCCESS_VIEWED_GUIDED_JOURNEY'
export const FAILURE_VIEWED_GUIDED_JOURNEY = 'FAILURE_VIEWED_GUIDED_JOURNEY'

export const CHECK_JOURNEY_STEP = 'CHECK_JOURNEY_STEP'
export const SUCCESS_CHECK_JOURNEY_STEP = 'SUCCESS_CHECK_JOURNEY_STEP'
export const FAILURE_CHECK_JOURNEY_STEP = 'FAILURE_CHECK_JOURNEY_STEP'

export const UNCHECK_JOURNEY_STEP = 'UNCHECK_JOURNEY_STEP'
export const SUCCESS_UNCHECK_JOURNEY_STEP = 'SUCCESS_UNCHECK_JOURNEY_STEP'
export const FAILURE_UNCHECK_JOURNEY_STEP = 'FAILURE_UNCHECK_JOURNEY_STEP'

export const PROMPT_CHECK_JOURNEY_STEP = 'PROMPT_CHECK_JOURNEY_STEP'
export const PROMPT_UNCHECK_JOURNEY_STEP = 'PROMPT_UNCHECK_JOURNEY_STEP'

export const SELECT_JOURNEY_QUARTER = 'SELECT_JOURNEY_QUARTER'

interface GetGuidedJourneyActionType {
  type: typeof GET_GUIDED_JOURNEY;
}

interface SuccessGetGuidedJourneyActionType {
  type: typeof SUCCESS_GET_GUIDED_JOURNEY;
  guidedJourney: GuidedJourney;
}

interface FailureGetGuidedJourneyActionType {
  type: typeof FAILURE_GET_GUIDED_JOURNEY;
  error?: TheiaError
}

interface GetQuarterDetailsActionType {
  type: typeof GET_QUARTER_DETAILS;
}

interface SuccessGetQuarterDetailsActionType {
  type: typeof SUCCESS_GET_QUARTER_DETAILS;
  quarterDetails: QuarterConsultation[];
}

interface FailureGetQuarterDetailsActionType {
  type: typeof FAILURE_GET_QUARTER_DETAILS;
}

interface GetConsultationInfosActionType {
  type: typeof GET_CONSULTATION_INFOS;
  cardInfo: QuarterConsultation;
}

interface SuccessGetConsultationInfosActionType {
  type: typeof SUCCESS_GET_CONSULTATION_INFOS;
  consultationInfos: ConsultationInfos;
  id: string;
}

interface FailureGetConsultationInfosActionType {
  type: typeof FAILURE_GET_CONSULTATION_INFOS;
}

interface SetViewedGuidedJourneyActionType {
  type: typeof SET_VIEWED_GUIDED_JOURNEY;
}

interface SuccessViewedGuidedJourneyActionType {
  type: typeof SUCCESS_VIEWED_GUIDED_JOURNEY;
}

interface FailureViewedGuidedJourneyActionType {
  type: typeof FAILURE_VIEWED_GUIDED_JOURNEY;
}

interface CheckJourneyStepActionType {
  type: typeof CHECK_JOURNEY_STEP;
  id: string;
}

interface SuccessCheckJourneyStepActionType {
  type: typeof SUCCESS_CHECK_JOURNEY_STEP;
  cardProps: UpdateCardResponse;
}

interface FailureCheckJourneyStepActionType {
  type: typeof FAILURE_CHECK_JOURNEY_STEP;
  id: string;
}

interface UncheckJourneyStepActionType {
  type: typeof UNCHECK_JOURNEY_STEP;
  id: string;
}

interface SuccessUncheckJourneyStepActionType {
  type: typeof SUCCESS_UNCHECK_JOURNEY_STEP;
  cardProps: UpdateCardResponse;
}

interface FailureUncheckJourneyStepActionType {
  type: typeof FAILURE_UNCHECK_JOURNEY_STEP;
  id: string;
}

interface PromptCheckJourneyStepActionType {
  type: typeof PROMPT_CHECK_JOURNEY_STEP;
  id: string;
}

interface PromptUncheckJourneyStepActionType {
  type: typeof PROMPT_UNCHECK_JOURNEY_STEP;
  id: string;
}

interface SelectJourneyQuarterActionType {
  type: typeof SELECT_JOURNEY_QUARTER;
  selectedQuarter: Quarter;
}

export type GuidedJourneyTypes =
| GetGuidedJourneyActionType
| SuccessGetGuidedJourneyActionType
| FailureGetGuidedJourneyActionType
| GetQuarterDetailsActionType
| SuccessGetQuarterDetailsActionType
| FailureGetQuarterDetailsActionType
| GetConsultationInfosActionType
| SuccessGetConsultationInfosActionType
| FailureGetConsultationInfosActionType
| SetViewedGuidedJourneyActionType
| SuccessViewedGuidedJourneyActionType
| FailureViewedGuidedJourneyActionType
| CheckJourneyStepActionType
| SuccessCheckJourneyStepActionType
| FailureCheckJourneyStepActionType
| UncheckJourneyStepActionType
| SuccessUncheckJourneyStepActionType
| FailureUncheckJourneyStepActionType
| PromptCheckJourneyStepActionType
| PromptUncheckJourneyStepActionType
| SelectJourneyQuarterActionType

export function getConsultantJourneyAction():
  AsyncAction<{}, { guidedJourneyService: GuidedJourneyService }> {
  return async (dispatch, _getState, { guidedJourneyService }) => {
    dispatch({ type: GET_GUIDED_JOURNEY })
    try {
      const guidedJourney: GuidedJourney = await guidedJourneyService.getConsultantJourney()
      dispatch({ type: SUCCESS_GET_GUIDED_JOURNEY, guidedJourney })
    } catch (error) {
      dispatch({ type: FAILURE_GET_GUIDED_JOURNEY, error })
      Sentry.captureException(new Error(`Failure to get guided journey quarters - ${error.message}`))
    }
  }
}

export function getChildJourneyAction(childId: string):
  AsyncAction<{}, { guidedJourneyService: GuidedJourneyService }> {
  return async (dispatch, _getState, { guidedJourneyService }) => {
    dispatch({ type: GET_GUIDED_JOURNEY })
    try {
      const guidedJourney: GuidedJourney = await guidedJourneyService.getChildJourney(childId)
      dispatch({ type: SUCCESS_GET_GUIDED_JOURNEY, guidedJourney })
    } catch (error) {
      dispatch({ type: FAILURE_GET_GUIDED_JOURNEY, error })
      Sentry.captureException(new Error(`Failure to get child journey - ${error.message}`))
    }
  }
}

export function getQuarterDetailsAction(id: string):
  AsyncAction<{}, { guidedJourneyService: GuidedJourneyService }> {
  return async (dispatch, getState, { guidedJourneyService }) => {
    dispatch({ type: GET_QUARTER_DETAILS })
    const state = getState() as AppState
    const { appProfiles } = state
    const { isDependent } = appProfiles
    try {
      const response: QuarterConsultation[] = await guidedJourneyService.getQuarterDetails(id)
      let quarterDetails = response
      if (isDependent) {
        quarterDetails = response.filter((item) => (!item.toConsultant))
      } else {
        quarterDetails = response.filter((item) => (item.toConsultant))
      }
      dispatch({ type: SUCCESS_GET_QUARTER_DETAILS, quarterDetails })
    } catch (error) {
      dispatch({ type: FAILURE_GET_QUARTER_DETAILS })
      Sentry.captureException(new Error(`Failure to get quarter details - ${JSON.stringify(error)}`))
    }
  }
}

export function getConsultationInfosAction(id: string, cardInfo: QuarterConsultation):
  AsyncAction<{}, { guidedJourneyService: GuidedJourneyService }> {
  return async (dispatch, _getState, { guidedJourneyService }) => {
    dispatch({ type: GET_CONSULTATION_INFOS, cardInfo })
    try {
      const consultationInfos = await guidedJourneyService.getConsultationInfos(id)
      dispatch({ type: SUCCESS_GET_CONSULTATION_INFOS, consultationInfos, id })
    } catch (error) {
      dispatch({ type: FAILURE_GET_CONSULTATION_INFOS })
      Sentry.captureException(new Error(`Failure to get consultation infos - ${error.message}`))
    }
  }
}

export function setViewedGuidedJourneyAction():
  AsyncAction<{}, { guidedJourneyService: GuidedJourneyService }> {
  return async (dispatch, _getState, { guidedJourneyService }) => {
    dispatch({ type: SET_VIEWED_GUIDED_JOURNEY })
    try {
      await guidedJourneyService.setViewedGuidedJourney()
      dispatch({ type: SUCCESS_VIEWED_GUIDED_JOURNEY })
    } catch (error) {
      dispatch({ type: FAILURE_VIEWED_GUIDED_JOURNEY })
      Sentry.captureException(new Error(`Failure to set guided journey viewed - ${error.message}`))
    }
  }
}

export function checkJourneyStepAction(id: string):
  AsyncAction<{}, { guidedJourneyService: GuidedJourneyService }> {
  return async (dispatch, _getState, { guidedJourneyService }) => {
    dispatch({ type: CHECK_JOURNEY_STEP, id })
    try {
      const cardProps = await guidedJourneyService.checkJourneyStep(id)
      dispatch({ type: SUCCESS_CHECK_JOURNEY_STEP, cardProps })
    } catch (error) {
      dispatch({ type: FAILURE_CHECK_JOURNEY_STEP, id })
      Sentry.captureException(new Error(`Failure to check journey step - ${error.message}`))
    }
  }
}

export function uncheckJourneyStepAction(id: string):
  AsyncAction<{}, { guidedJourneyService: GuidedJourneyService }> {
  return async (dispatch, _getState, { guidedJourneyService }) => {
    dispatch({ type: UNCHECK_JOURNEY_STEP, id })
    try {
      const cardProps = await guidedJourneyService.uncheckJourneyStep(id)
      dispatch({ type: SUCCESS_UNCHECK_JOURNEY_STEP, cardProps })
    } catch (error) {
      dispatch({ type: FAILURE_UNCHECK_JOURNEY_STEP, id })
      Sentry.captureException(new Error(`Failure to uncheck journey step - ${error.message}`))
    }
  }
}

export const promptCheckJourneyStepAction = (id: string): PromptCheckJourneyStepActionType => (
  { type: PROMPT_CHECK_JOURNEY_STEP, id }
)

export const promptUncheckJourneyStepAction = (id: string): PromptUncheckJourneyStepActionType => (
  { type: PROMPT_UNCHECK_JOURNEY_STEP, id }
)

export const selectJourneyQuarterAction = (selectedQuarter: Quarter):
SelectJourneyQuarterActionType => (
  { type: SELECT_JOURNEY_QUARTER, selectedQuarter }
)
