import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Loader, Snackbar } from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import FaceIcon from '../../../assets/face-icon.png'
import BabyFace from '../../../assets/BabyFace.png'
import Plus from '../../../assets/Plus.png'
import { ADD_NEW_DEPENDENT, AGENDAR, INICIO } from '../../routes/RoutesConstants'
import { AppDispatch } from '../../state/utils'
import { StatusType } from '../../domain/Status'
import { getAppProfilesAction, selectProfileAction } from '../../state/appProfiles/actions'
import {
  ActiveProfileType,
  AppProfileType,
  EventProfileType,
  ProfilesTypes
} from '../../domain/AppProfiles'
import './SelectProfile.scss'
import { primary } from '../../color'
import { resetHightlightsStatusAction } from '../../state/userHighlights/actions'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { EventCategories } from '../../utils/EventCategories'

interface Props {
  isAuthenticating: boolean
  appProfiles?: AppProfileType[]
  getAppProfilesStatus: StatusType
  getUserHighlightsStatus: StatusType
  activeProfile?: ActiveProfileType
  getChildrenDataStatus: StatusType
  getBookingsStatus: StatusType
  previousRoute?: string
  getAppProfiles: () => void
  selectProfile: (profile: AppProfileType) => void
  resetHightlightsStatus: () => void
}

function SelectProfile({
  isAuthenticating,
  appProfiles,
  getAppProfilesStatus,
  getUserHighlightsStatus,
  activeProfile,
  getChildrenDataStatus,
  getBookingsStatus,
  previousRoute,
  getAppProfiles,
  selectProfile,
  resetHightlightsStatus,
}: Props) {
  const history = useHistory()
  const [isMountingProfile, setIsMountingProfile] = useState(false)
  const dependents = appProfiles?.filter((profile) => profile.type === ProfilesTypes.CHILD) || []

  useEffect(() => {
    getAppProfiles()
  }, [])

  useEffect(() => {
    if (appProfiles && getAppProfilesStatus.success) {
      eventPage(EventCategories.PROFILE_SEPARATION, 'pagina selecao de perfil', {
        dependentes: !!(dependents.length > 0),
        category: EventCategories.PROFILE_SEPARATION
      })
    }
  }, [appProfiles, getAppProfilesStatus])

  function handleTryAgain() {
    getAppProfiles()
  }

  function handleSelectProfile(profile: AppProfileType) {
    eventTrack('selecionou perfil', {
      tipo_de_perfil: EventProfileType[profile.type],
      category: EventCategories.PROFILE_SEPARATION
    })
    setIsMountingProfile(true)
    selectProfile(profile)
  }

  function handleClickAddNewDependent() {
    eventTrack('clicou adicionar dependente', {
      category: EventCategories.PROFILE_SEPARATION,
      dependentes: !!(dependents.length > 0),
      source: 'pagina'
    })
    if (appProfiles && appProfiles.length > 0) {
      const motherProfileCard = appProfiles.find(
        (item: AppProfileType) => item.type === ProfilesTypes.CONSULTANT
      )
      if (motherProfileCard) {
        selectProfile(motherProfileCard)
        history.push(ADD_NEW_DEPENDENT)
      }
    }
  }

  function getPlaceholderIcon(profile: AppProfileType) {
    if (profile?.imageUrl) return profile.imageUrl
    if (profile.type === ProfilesTypes.CHILD) return BabyFace
    return FaceIcon
  }

  useEffect(() => {
    if (activeProfile?.id && (
      getUserHighlightsStatus.isLoading
      || getChildrenDataStatus.isLoading
      || getBookingsStatus.isLoading
    )) {
      setIsMountingProfile(true)
    } else {
      setIsMountingProfile(false)
    }
  }, [
    getUserHighlightsStatus.isLoading,
    getChildrenDataStatus.isLoading,
    getBookingsStatus.isLoading
  ])

  useEffect(() => {
    if (getAppProfilesStatus.error) {
      eventPage(EventCategories.PROFILE_SEPARATION, 'pagina selecao de perfil', {
        dependentes: undefined,
        category: EventCategories.PROFILE_SEPARATION
      })
      eventTrack('erro ao carregar perfis', {
        category: EventCategories.PROFILE_SEPARATION,
        erro: JSON.stringify(getAppProfilesStatus.errorMessage),
        localizacao: 'pagina de selecao'
      })
    }
  }, [getAppProfilesStatus])

  useEffect(() => {
    if (!isMountingProfile && activeProfile?.id) {
      if (previousRoute === AGENDAR) history.push(AGENDAR)
      else { history.push(INICIO) }
    }
  }, [isMountingProfile, activeProfile?.id])

  useEffect(() => () => resetHightlightsStatus(), [])

  if (isAuthenticating) {
    return (
      <div className="select-profile__view">
        <div className="select-profile__loader">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="select-profile__view">
      {(isMountingProfile) && (
        <div className="full-screen-loader">
          <Loader size="74px" />
        </div>
      )}
      <h1 className="select-profile__title">
        Selecione o perfil
      </h1>
      {getAppProfilesStatus.error && (
        <div className="select-profile__error">
          <Snackbar
            title="Erro ao carregar perfis"
            text="Desculpe, houve um erro ao carregar os perfis, por favor tente novamente."
            buttonTwoProps={{
              label: 'Tentar novamente',
              onClick: handleTryAgain
            }}
            iconLeft="icon-DangerTriangleLight"
            customIconColor={primary}
          />
        </div>
      )}
      {getAppProfilesStatus.isLoading && (
        <div className="select-profile__loader">
          <Loader />
        </div>
      )}
      {getAppProfilesStatus.success && (
        <div className="select-profile__profiles-wrapper">
          <div className="select-profile__profile-options">
            {appProfiles && appProfiles.map((profileCard, index) => (
              <button
                type="button"
                className="select-profile__profile-card-btn"
                id={`profile-card-${index}`}
                onClick={() => handleSelectProfile(profileCard)}
                key={profileCard.id}
              >
                <img
                  src={profileCard?.imageUrl || getPlaceholderIcon(profileCard)}
                  alt="profile-img"
                  width={80}
                  height={80}
                  data-testid="select-user-photo"
                  style={{
                    width: 80,
                    height: 80,
                  }}
                  className={`profile-photo ${activeProfile?.id === profileCard.id ? 'selected' : ''}`}
                />
                <p className="profile-name">{profileCard?.name}</p>
              </button>
            ))}
            <button
              type="button"
              className="select-profile__profile-card-btn pb-12"
              id="add-dependent-card"
              onClick={handleClickAddNewDependent}
            >
              <img
                src={Plus}
                alt="adicionar"
                width={80}
                height={80}
                data-testid="select-user-photo"
                style={{
                  width: 80,
                  height: 80,
                }}
                className="profile-photo"
              />
              <p className="profile-name">Adicionar dependente</p>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  authentication,
  appProfiles,
  userHighlights,
  pediatricFlow,
  myBookingsFlow,
  routerListener
}: AppState) => ({
  isAuthenticating: authentication.isAuthenticating,
  appProfiles: appProfiles.appProfiles,
  getAppProfilesStatus: appProfiles.getAppProfilesStatus,
  getUserHighlightsStatus: userHighlights.getUserHighlightsStatus,
  activeProfile: appProfiles.activeProfile,
  getChildrenDataStatus: pediatricFlow.getChildrenDataStatus,
  getBookingsStatus: myBookingsFlow.getBookingsStatus,
  previousRoute: routerListener.previousRoute
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  getAppProfiles: getAppProfilesAction,
  selectProfile: selectProfileAction,
  resetHightlightsStatus: resetHightlightsStatusAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectProfile)
