import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import {
  NewListItem,
  ButtonOptions,
  FontIcon,
  IconButton
} from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import Logo from '../../../assets/logo-rounded.svg'
import { primary } from '../../color'
import { Reminders } from '../../domain/Booking'
import { getScheduleSuggestionAction, onUpdateBookingSuggestionAction } from '../../state/scheduleSuggestion/actions'
import { setPreviousSelectedCategoryAction } from '../../state/scheduleFlow/actions'
import { SpecialistCategories } from '../../domain/Specialist'
import { AGENDAR, PRESCRIPTIONS_AND_EXAMS } from '../../routes/RoutesConstants'
import './ScheduleSuggestionBanner.scss'

interface Props {
  isGettingScheduleSuggestion: boolean;
  shouldSendReminder: boolean
  reminders: Reminders[]
  isDependent: boolean
  onUpdateScheduleSuggestion: (
    type: string, bookingIds?: Array<string>, callback?: () => void) => void;
  getScheduleSuggestion: () => void;
  setPreviousSelectedCategory: (
    category?: SpecialistCategories
  ) => void;
}

function ScheduleSugestionBanner({
  isGettingScheduleSuggestion,
  shouldSendReminder,
  reminders,
  isDependent,
  onUpdateScheduleSuggestion,
  getScheduleSuggestion,
  setPreviousSelectedCategory
}: Props) {
  const history = useHistory()
  const [optionsVisible, setOptionsVisible] = useState<number | undefined>()

  useEffect(() => {
    if (!isDependent) getScheduleSuggestion()
  }, [isDependent])

  function openOptions(index: number, event?: any) {
    event?.preventDefault()
    event?.stopPropagation()
    setOptionsVisible(index)
  }

  function closeOptions(event?: any) {
    event?.preventDefault()
    event?.stopPropagation()
    setOptionsVisible(undefined)
  }

  function updateAndGetScheduleMessage(reminder: Reminders, _callback: () => void) {
    onUpdateScheduleSuggestion(reminder.type, reminder.lastBookingIds, _callback)
  }

  function dismissSuggestion(reminder: Reminders, event?: any) {
    event?.preventDefault()
    event?.stopPropagation()
    updateAndGetScheduleMessage(reminder, getScheduleSuggestion)
    setOptionsVisible(undefined)
  }

  function doneSuggestionTypeExam(reminder: Reminders, event?: any) {
    event?.preventDefault()
    event?.stopPropagation()
    history.push(PRESCRIPTIONS_AND_EXAMS, [reminder, 'isFromReminder'])
  }

  const remindersOrdered = reminders && reminders.length > 0 ? reminders?.sort((a: any, b: any) => (
    // eslint-disable-next-line no-nested-ternary
    (a.type < b.type) ? -1 : (a.type > b.type) ? 1 : 0)) : []

  const optionsModalTypeSignUp = (reminder: Reminders) => (
    <ul>
      <NewListItem
        onClick={() => dismissSuggestion(reminder)}
        iconType="icon-TrashLight"
        iconColor={primary}
        iconSize="21px"
        title="Remover lembrete"
        ariaLabel="Remover lembrete"
        id="dismiss-suggestion"
        key="dismiss-suggestion"
        isLastItem
        hideIconButton
      />
    </ul>
  )

  const optionsModalTypeExam = (reminder: Reminders) => (
    <ul>
      <NewListItem
        onClick={() => doneSuggestionTypeExam(reminder)}
        iconType="icon-ExamLight"
        iconColor={primary}
        iconSize="21px"
        title="Pedidos de exames"
        ariaLabel="Pedidos de exames"
        id="exams-requests"
        key="exams-requests"
        isLastItem={false}
        hideIconButton
      />
      <NewListItem
        onClick={() => dismissSuggestion(reminder)}
        iconType="icon-TrashLight"
        iconColor={primary}
        iconSize="21px"
        title="Remover lembrete"
        ariaLabel="Remover lembrete"
        id="remove-suggestion"
        key="remove-suggestion"
        isLastItem
        hideIconButton
      />
    </ul>
  )

  const onClickSuggestionBanner = (reminder: Reminders) => {
    switch (reminder.type) {
      case 'SIGNUP': {
        setPreviousSelectedCategory(SpecialistCategories.GINECOLOGIST)
        history.push(AGENDAR)
        break
      }
      case 'EXAM_REQUEST': {
        history.push(PRESCRIPTIONS_AND_EXAMS, [reminder, 'isFromReminder'])
        break
      }
      case 'RIGHT_AFTER': {
        setPreviousSelectedCategory(reminder.category)
        history.push(AGENDAR)
        break
      }
      default:
        return ''
    }
    return ''
  }

  if (isDependent
    || (!isGettingScheduleSuggestion && (!reminders || reminders?.length === 0))
    || !shouldSendReminder) return null

  const scheduleSuggestionLoader = (
    <div className="schedule-suggestion__loader-container">
      <div className="schedule-suggestion__loader-card skeleton">
        <div className="schedule-suggestion__loader-img skeleton" />
        <div className="schedule-suggestion__loader-text skeleton" />
        <div className="schedule-suggestion__loader-btn-options" />
      </div>
    </div>
  )

  return (
    <>
      {isGettingScheduleSuggestion && (scheduleSuggestionLoader)}
      {!isGettingScheduleSuggestion
      && remindersOrdered
      && remindersOrdered.map((reminder, index) => (
        <div
          className={`schedule-suggestion-banner-container ${reminder.firstTime ? 'first-time' : ''}`}
          role="button"
          tabIndex={0}
          onClick={() => onClickSuggestionBanner(reminder)}
          onKeyDown={() => onClickSuggestionBanner(reminder)}
          key={`${reminder.category}-${index}`}
        >
          <div className="flex w-full p-4 justify-between items-center schedule-suggestion">
            <div className="flex items-center w-full">
              <div className="relative schedule-suggestion-img">
                <Logo />
                {reminder.type === 'SIGNUP' && (
                  <span className="block absolute schedule-suggestion-icon">
                    <FontIcon iconType="icon-CalendarLight" color="#FFFFFF" />
                  </span>
                )}
                {reminder.type === 'EXAM_REQUEST' && (
                  <span className="block absolute prescription-icon">
                    <FontIcon iconType="icon-PaperclipLight" color="#FFFFFF" />
                  </span>
                )}
              </div>
              <p className="ml-6 text-textPrimary text-fontExtraSmall">{reminder.textHome}</p>
            </div>
            <ButtonOptions
              buttonToAnchor={(
                <IconButton
                  aria-label="Mostrar mais"
                  variant="text"
                  width="44px"
                  height="44px"
                  iconType="icon-MoreLight"
                  iconSize="25px"
                  onClick={() => openOptions(index)}
                />
                )}
              visible={optionsVisible === index}
              onClose={closeOptions}
              headerText="Opções de ações para a sugestão de consulta"
              lastIdFocused="button"
            >
              <div className="suggestion-bookings-options-modal">
                {reminder.type === 'SIGNUP' ? optionsModalTypeSignUp(reminder) : optionsModalTypeExam(reminder)}
              </div>
            </ButtonOptions>
          </div>
        </div>
      ))}
    </>
  )
}

const mapStateToProps = ({
  appProfiles,
  scheduleSuggestion
}: AppState) => ({
  isDependent: appProfiles.isDependent,
  isGettingScheduleSuggestion: scheduleSuggestion.isLoading,
  reminders: scheduleSuggestion.byCategory,
  shouldSendReminder: scheduleSuggestion.shouldSendReminder,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onUpdateScheduleSuggestion: onUpdateBookingSuggestionAction,
  getScheduleSuggestion: getScheduleSuggestionAction,
  setPreviousSelectedCategory: setPreviousSelectedCategoryAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleSugestionBanner)
