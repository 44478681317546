import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Divider,
  IconButton,
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import {
  AttendanceAvailabilityType,
  CategoryToSchedule,
  CreditCardInfos,
  PaymentMethods,
  ScheduleFlowSteps,
  ScheduleInfosType,
  SchedulePaymentType,
  ScheduleResponse,
} from '../../domain/ScheduleFlow'
import { textSecondary } from '../../color'
import {
  clearAttendanceTypeAction,
  clearCategoryAction,
  clearPaymentMethodAction,
  clearScheduleErrorAction,
  clearSpecialistAction,
  clearTimeSlotAction,
  confirmScheduleAction,
  resetScheduleFlowAction,
  selectSpecialistAction,
  selectTimeSlotAction,
  setLoadingStepAction,
  setNewCardDataAction,
  setPaymentMethodIdAction,
  setScheduleStepAction
} from '../../state/scheduleFlow/actions'
import ChooseAttendanceType from './ChooseAttendanceType'
import ChooseDayAndTime from './ChooseDayAndTime'
import PaymentSummary from './PaymentSummary'
import {
  getAttendanceTypeToShow,
  isChildHealthPlanPayment,
  isCreditCard,
  isExamCategory,
  isPresential,
  isValidElement
} from '../../utils/helpers'
import { User } from '../../domain/User'
import ChooseSpecialist from './ChooseSpecialist'
import ChoosePayment from './ChoosePayment'
import ScheduleSuccess from './ScheduleSuccess'
import Logo from '../../../assets/horizontal-logo.svg'
import { cleanPromotionalCodeAction } from '../../state/payment/actions'
import SchedulePayment from '../payment/SchedulePayment'
import ScheduleReviewAndConfirm from './ScheduleReviewAndConfirm'
import { LocalDate } from '../../utils/LocalDate'
import { LocalAddress } from '../../utils/LocalAddress'
import { TheiaError } from '../../domain/errors/TheiaError'
import ScheduleErrorModal from './ScheduleErrorModal'
import { UsgStatus } from '../../domain/Prescriptions'
import { postUsgStatusAction, suppressUsgModalAction } from '../../state/prescriptions/actions'
import { AGENDAR } from '../../routes/RoutesConstants'
import SubmitDocuments from './SubmitDocuments'
import './ScheduleFlow.scss'
import { getSpecialistDetailsAction } from '../../state/specialists/actions'
import { SpecialistDetails } from '../../domain/Specialist'
import { AvailabilityTimeSlot, SlotSpecialist } from '../../domain/Availabilities'
import { resetAvailabilitiesStateAction } from '../../state/availabilities/actions'
import { getHighlightsAction } from '../../state/userHighlights/actions'
import { HealthInsurancePlanData } from '../../domain/Healthplan'
import { ActiveProfileType } from '../../domain/AppProfiles'

interface Props {
  isDependent: boolean
  activeProfile?: ActiveProfileType
  selectedCategory?: CategoryToSchedule;
  selectedAttendanceType?: AttendanceAvailabilityType;
  selectedTimeSlot?: AvailabilityTimeSlot;
  selectedPaymentMethod?: SchedulePaymentType;
  selectedSpecialist?: SlotSpecialist;
  activeScheduleStep?: ScheduleFlowSteps;
  currentUser?: User;
  hasPreviousCreditCard?: boolean;
  useOldCard: boolean;
  productId?: string;
  attendanceAddress?: LocalAddress;
  oldCardData?: CreditCardInfos;
  newCardData?: CreditCardInfos;
  paymentMethods?: PaymentMethods;
  newPaymentMethodAdded: boolean;
  paymentMethodId?: string;
  coupon?: string;
  discount?: number;
  promotionalPrice?: number;
  isScheduling: boolean;
  scheduleError?: TheiaError;
  scheduleSuccess: boolean;
  scheduleSuccessInfos?: ScheduleResponse;
  isGettingSpecialistDetails: boolean;
  specialistDetails?: SpecialistDetails;
  isFree: boolean;
  haveGuidedJourney: boolean
  professionalInfoError?: TheiaError;
  usgId?: string;
  previousSelectedSpecialistId?: string;
  previousSelectedSpecialistName?: string;
  loadingStep: boolean;
  healthInsurancePlanData: HealthInsurancePlanData | null;
  resetScheduleFlow: () => void;
  setScheduleStep: (step: ScheduleFlowSteps) => void;
  cleanPromotionalCode: () => void;
  setPaymentMethodId: (paymentMethodId: string) => void;
  selectTimeSlot: (slot: AvailabilityTimeSlot) => void;
  selectSpecialist: (specialist: SlotSpecialist) => void;
  setNewCardData: (newCardData: CreditCardInfos) => void;
  confirmSchedule: (scheduleInfos: ScheduleInfosType) => void;
  clearAttendanceType: () => void;
  clearTimeSlot: () => void;
  clearSpecialist: () => void;
  clearPaymentMethod: () => void;
  clearCategory: () => void;
  setLoadingSteps: (isLoading: boolean) => void;
  getSpecialistDetails: (specialistId: string) => void;
  clearScheduleError: () => void;
  postUsgStatus: (status: UsgStatus, usgId: string) => void;
  suppressUsgModal: () => void;
  getHighlights: () => void;
  resetAvailabilitiesState: () => void;
}

type ScheduleStep = {
  name: ScheduleFlowSteps;
  renderStep: boolean;
  actionsOnGoBack?: Array<() => void>;
}

function ScheduleFlow({
  isDependent,
  activeProfile,
  selectedCategory,
  selectedAttendanceType,
  selectedTimeSlot,
  selectedPaymentMethod,
  selectedSpecialist,
  activeScheduleStep,
  currentUser,
  hasPreviousCreditCard,
  useOldCard,
  productId,
  attendanceAddress,
  oldCardData,
  newCardData,
  paymentMethods,
  newPaymentMethodAdded,
  paymentMethodId,
  coupon,
  discount,
  promotionalPrice,
  isScheduling,
  scheduleError,
  scheduleSuccess,
  scheduleSuccessInfos,
  isGettingSpecialistDetails,
  specialistDetails,
  isFree,
  haveGuidedJourney,
  professionalInfoError,
  usgId,
  previousSelectedSpecialistId,
  previousSelectedSpecialistName,
  loadingStep,
  healthInsurancePlanData,
  resetScheduleFlow,
  setScheduleStep,
  cleanPromotionalCode,
  setPaymentMethodId,
  selectTimeSlot,
  selectSpecialist,
  setNewCardData,
  confirmSchedule,
  clearAttendanceType,
  clearTimeSlot,
  clearSpecialist,
  clearPaymentMethod,
  clearCategory,
  setLoadingSteps,
  getSpecialistDetails,
  clearScheduleError,
  postUsgStatus,
  suppressUsgModal,
  getHighlights,
  resetAvailabilitiesState,
}: Props) {
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0)
  const isExam = isExamCategory(selectedCategory?.category)
  const headerTitle = isDependent ? `Para ${activeProfile?.name}` : 'Para você'
  const atendanceTypeText = getAttendanceTypeToShow(selectedAttendanceType)
  const categoryText = isExam ? selectedSpecialist?.name : selectedCategory?.category
  const headerSubtitle = `${categoryText}${selectedAttendanceType ? ` - ${atendanceTypeText}` : ''}`
  const isMissingChildDocument = !!(isDependent && !isValidElement(activeProfile?.document))
  const isMissingUserDocument = !!(!currentUser?.document
    || !currentUser?.birthDate || !currentUser?.nationalIdentityCard)
  const isNotCovered = !!(!selectedCategory?.included && selectedCategory?.attendanceAvailability !== 'none')
  const showPaymentSummary = isNotCovered
    && activeScheduleStep !== ScheduleFlowSteps.ScheduleSuccess
  const healthInsuranceCompanyName = isChildHealthPlanPayment(selectedPaymentMethod)
    ? activeProfile?.companyName : healthInsurancePlanData?.healthInsurancePlan?.company
  const isReview = activeScheduleStep === ScheduleFlowSteps.ReviewAndConfirm
  const scheduleContainerRef = useRef<HTMLDivElement>(null)

  const scheduleSteps: ScheduleStep[] = [
    {
      name: ScheduleFlowSteps.ChooseAttendanceType,
      renderStep: !isExam,
      actionsOnGoBack: [clearCategory]
    },
    {
      name: ScheduleFlowSteps.ChooseDayAndTime,
      renderStep: !isExam,
      actionsOnGoBack: [clearSpecialist, clearAttendanceType],
    },
    {
      name: ScheduleFlowSteps.SubmitDocuments,
      renderStep: isMissingChildDocument || isMissingUserDocument,
      actionsOnGoBack: [clearTimeSlot]
    },
    {
      name: ScheduleFlowSteps.ChooseSpecialist,
      renderStep: !isExam,
    },
    {
      name: ScheduleFlowSteps.ChoosePayment,
      renderStep: isNotCovered,
      actionsOnGoBack: [clearPaymentMethod]
    },
    {
      name: ScheduleFlowSteps.AddNewCreditCard,
      renderStep: isCreditCard(selectedPaymentMethod) && useOldCard === false,
    },
    {
      name: ScheduleFlowSteps.ReviewAndConfirm,
      renderStep: true,
      actionsOnGoBack: [cleanPromotionalCode]
    },
    {
      name: ScheduleFlowSteps.ScheduleSuccess,
      renderStep: scheduleSuccess,
    },
  ]

  function handleNextStep() {
    setLoadingSteps(true)
    setActiveStep(activeStep + 1)
  }

  useEffect(() => {
    if (activeStep) {
      scheduleContainerRef?.current?.scrollTo({ top: 0 })
    }
    if (activeStep > (scheduleSteps.length - 1)) return
    if (scheduleSteps[activeStep]?.renderStep === true) {
      setScheduleStep(scheduleSteps[activeStep].name)
    } else {
      setActiveStep(activeStep + 1)
    }
  }, [activeStep])

  function goBack() {
    setLoadingSteps(true)
    scheduleSteps[activeStep]?.actionsOnGoBack?.map((action) => action())
    if (activeStep > 0 && activeStep < scheduleSteps.length - 1) {
      for (let i = activeStep - 1; i >= 0; i -= 1) {
        if (scheduleSteps[i]?.renderStep === true) {
          setActiveStep(i)
          setLoadingSteps(false)
          return
        }
      }
    }
    clearPaymentMethod()
    history.goBack()
  }

  function onConfirmSchedule() {
    if (selectedSpecialist && selectedCategory) {
      confirmSchedule({
        availabilityId: selectedSpecialist?.slotId,
        newPaymentMethodAdded,
        paymentType: selectedPaymentMethod,
        paymentMethodId,
        promotionalCode: coupon,
        childrenId: activeProfile?.id,
        isChildSchedule: isDependent,
        isFromGuidedJourney: false,
        selectedCategory: selectedCategory.category,
        specialistName: selectedSpecialist.name
      })
    }
  }

  useEffect(() => {
    if (scheduleSuccess && scheduleSuccessInfos) {
      handleNextStep()
    }
  }, [scheduleSuccess])

  useEffect(() => {
    if (!selectedCategory) {
      resetScheduleFlow()
      cleanPromotionalCode()
      history.push(AGENDAR)
    }
  }, [selectedCategory])

  return (
    <div className="new-schedule-flow-container" ref={scheduleContainerRef}>
      <div className="new-schedule-flow-header">
        <div className={`new-schedule-flow-header-content ${showPaymentSummary ? '' : 'centered-head'}`}>
          {activeScheduleStep !== ScheduleFlowSteps.ScheduleSuccess && !isExam && (
            <IconButton
              aria-label="Voltar"
              variant="text"
              width="44px"
              height="44px"
              iconType="icon-ArrowLeft2Light"
              iconColor={textSecondary}
              iconSize="24px"
              onClick={goBack}
              extraClass="mr-2"
            />
          )}
          {activeScheduleStep === ScheduleFlowSteps.ScheduleSuccess || isReview || isExam
            ? (
              <Logo alt="logo-theia" className={`logo-theia ${isReview ? 'pr-16' : ''}`} />
            ) : (
              <div className="header-text">
                <h1 className="">{headerTitle}</h1>
                <p className="">{headerSubtitle}</p>
              </div>
            )}
        </div>
        <Divider className="col-start-1 col-end-13" />
      </div>
      <div className="new-schedule-flow-body">
        <div className={`new-Schedule-flow-body-content ${showPaymentSummary ? 'with-payment-resume' : 'without-payment-resume'} `}>
          {activeScheduleStep === ScheduleFlowSteps.ChooseAttendanceType && (
            <ChooseAttendanceType onChooseAttendanceType={handleNextStep} />
          )}
          {activeScheduleStep === ScheduleFlowSteps.ChooseDayAndTime && (
            <ChooseDayAndTime
              onChooseTimeSlot={(slot: AvailabilityTimeSlot) => {
                selectTimeSlot(slot)
                handleNextStep()
              }}
              childId={isDependent ? activeProfile?.id : undefined}
              selectedAttendanceType={selectedAttendanceType}
              categoryId={selectedCategory?.categoryId}
              included={selectedCategory?.included}
              chronosId={previousSelectedSpecialistId}
              specialistName={previousSelectedSpecialistName}
              loadingStep={loadingStep}
            />
          )}
          {activeScheduleStep === ScheduleFlowSteps.SubmitDocuments && (
            <SubmitDocuments
              onUpdateDocuments={handleNextStep}
            />
          )}
          {activeScheduleStep === ScheduleFlowSteps.ChooseSpecialist && (
            <ChooseSpecialist
              categoryName={selectedCategory?.category}
              selectedTimeSlot={selectedTimeSlot}
              onChooseSpecialist={(specialist: SlotSpecialist) => {
                selectSpecialist(specialist)
                handleNextStep()
              }}
            />
          )}
          {activeScheduleStep === ScheduleFlowSteps.ChoosePayment && (
            <ChoosePayment onChoosePaymentMethod={handleNextStep} />
          )}
          {activeScheduleStep === ScheduleFlowSteps.AddNewCreditCard && (
            <SchedulePayment
              productId={productId}
              changePaymentMethod={goBack}
              confirmNewCardId={(id: string) => setPaymentMethodId(id)}
              setNewCardData={(cardData: CreditCardInfos) => {
                setNewCardData(cardData)
              }}
              onSuccessExtraAction={handleNextStep}
            >
              <h1 className="schedule-checkout-title">
                {hasPreviousCreditCard ? 'Alterar' : 'Cadastrar'} cartão de crédito
              </h1>
            </SchedulePayment>
          )}
          {isReview && (
            <ScheduleReviewAndConfirm
              isPresential={isPresential(selectedAttendanceType)}
              selectedPaymentMethod={selectedPaymentMethod}
              isCovered={!isNotCovered}
              startDate={selectedTimeSlot?.startTime}
              attendanceAddress={attendanceAddress}
              cardBrand={useOldCard ? oldCardData?.brand : newCardData?.brand}
              cardLastDigits={useOldCard ? oldCardData?.lastDigits : newCardData?.lastDigits}
              isExam={isExam}
              professionalPicture={selectedSpecialist?.pictureUrl}
              selectedCategory={selectedCategory?.category}
              professionalName={selectedSpecialist?.name}
              onClickSpecialistInfos={() => {
                if (selectedSpecialist?.chronosId) {
                  getSpecialistDetails(selectedSpecialist?.chronosId)
                }
              }}
              isGettingSpecialistDetails={isGettingSpecialistDetails}
              specialistDetails={specialistDetails}
              paymentSlipDueDate={new LocalDate(paymentMethods?.slip?.paymentSlipDueDate || '')}
              pixDueDate={new LocalDate(paymentMethods?.pix?.paymentPixDueDate) || ''}
              healthInsuranceCompanyName={healthInsuranceCompanyName || ''}
              isScheduling={isScheduling}
              onClickEditPayment={() => setActiveStep(4)}
              onConfirmSchedule={onConfirmSchedule}
              patientName={activeProfile?.name}
              professionalInfoError={professionalInfoError}
            />
          )}
          {activeScheduleStep === ScheduleFlowSteps.ScheduleSuccess && (
            <ScheduleSuccess
              onCloseSuccessScreen={() => {
                resetScheduleFlow()
                resetAvailabilitiesState()
              }}
              isPresential={isPresential(selectedAttendanceType)}
              startDate={selectedTimeSlot?.startTime}
              price={selectedCategory?.price}
              paymentType={selectedPaymentMethod}
              promotionalPrice={promotionalPrice}
              cardBrand={useOldCard ? oldCardData?.brand : newCardData?.brand}
              cardLastDigits={useOldCard ? oldCardData?.lastDigits : newCardData?.lastDigits}
              isCovered={!isNotCovered}
              professionalPicture={selectedSpecialist?.pictureUrl}
              healthInsuranceCompanyName={healthInsuranceCompanyName || ''}
              patientName={activeProfile?.name}
              isFree={isFree}
              isExam={isExam}
              paymentSlipBarCode={scheduleSuccessInfos?.paymentSlipBarCode}
              paymentSlipDigitableCode={scheduleSuccessInfos?.paymentSlipDigitableCode}
              paymentSlipPdfUrl={scheduleSuccessInfos?.paymentSlipPdfUrl}
              pixQrCodeUrl={scheduleSuccessInfos?.pixQrCodeUrl}
              pixDigitableCode={scheduleSuccessInfos?.pixDigitableCode}
              professionalName={selectedSpecialist?.name}
              selectedCategory={selectedCategory?.category}
              attendanceAddress={attendanceAddress}
              paymentSlipDueDate={new LocalDate(paymentMethods?.slip?.paymentSlipDueDate || '')}
              pixDueDate={new LocalDate(paymentMethods?.pix?.paymentPixDueDate) || ''}
              usgId={usgId}
              onScheduleUsg={(id: string) => {
                postUsgStatus('SCHEDULED', id)
                suppressUsgModal()
                getHighlights()
              }}
              haveGuidedJourney={haveGuidedJourney}
            />
          )}
        </div>
        {showPaymentSummary && (
          <PaymentSummary
            description={`Consulta ${selectedCategory?.category}`}
            isLastStep={isReview}
            price={selectedCategory?.price}
            onClick={onConfirmSchedule}
            buttonLabel="Confirmar agendamento"
            discount={discount}
            coupon={coupon}
            promotionalPrice={promotionalPrice}
            isLoading={isScheduling}
          />
        )}
      </div>
      {scheduleError && (
        <ScheduleErrorModal
          error={scheduleError}
          textCloseButton="Entendi"
          closeModal={clearScheduleError}
        />
      )}
    </div>
  )
}

const mapStateToProps = ({
  scheduleFlow,
  authentication,
  payment,
  pediatricFlow,
  prescriptions,
  specialists,
  availabilities,
  healthplan,
  appProfiles,
  guidedJourney
}: AppState) => ({
  selectedCategory: scheduleFlow.selectedCategory,
  selectedAttendanceType: scheduleFlow.selectedAttendanceType,
  selectedTimeSlot: scheduleFlow.selectedTimeSlot,
  selectedPaymentMethod: scheduleFlow.selectedPaymentMethod,
  selectedSpecialist: scheduleFlow.selectedSpecialist,
  activeScheduleStep: scheduleFlow.activeScheduleStep,
  currentUser: authentication.currentUser,
  hasPreviousCreditCard: authentication.currentUser?.plan.paymentMethodAdded,
  useOldCard: scheduleFlow.useOldCard,
  productId: availabilities.productId,
  attendanceAddress: availabilities.attendanceAddress,
  oldCardData: {
    brand: authentication.currentUser?.plan.cardBrand,
    lastDigits: authentication.currentUser?.plan.cardLastDigits
  },
  newCardData: scheduleFlow.newCardData,
  paymentMethods: scheduleFlow.paymentMethods,
  newPaymentMethodAdded: scheduleFlow.newPaymentMethodAdded,
  paymentMethodId: scheduleFlow.paymentMethodId,
  coupon: payment.couponValueFromUser,
  discount: payment.discount,
  promotionalPrice: payment.promotionalPrice,
  isScheduling: scheduleFlow.isScheduling,
  scheduleError: scheduleFlow.scheduleError,
  scheduleSuccess: scheduleFlow.scheduleSuccess,
  scheduleSuccessInfos: scheduleFlow.scheduleSuccessInfos,
  isGettingSpecialistDetails: specialists.isGettingSpecialistDetails,
  specialistDetails: specialists.specialistDetails,
  isFree: scheduleFlow.isFree,
  babiesData: pediatricFlow.childrenData,
  professionalInfoError: scheduleFlow.error,
  usgId: prescriptions.usgPrescription?.usgId,
  previousSelectedSpecialistId: scheduleFlow.previousSelectedSpecialistId,
  previousSelectedSpecialistName: scheduleFlow.previousSelectedSpecialistName,
  loadingStep: scheduleFlow.loadingStep,
  healthInsurancePlanData: healthplan.healthInsurancePlanData,
  isDependent: appProfiles.isDependent,
  activeProfile: appProfiles.activeProfile,
  haveGuidedJourney: !!(guidedJourney.guidedJourney?.list),
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  resetScheduleFlow: resetScheduleFlowAction,
  setScheduleStep: setScheduleStepAction,
  cleanPromotionalCode: cleanPromotionalCodeAction,
  setPaymentMethodId: setPaymentMethodIdAction,
  selectTimeSlot: selectTimeSlotAction,
  selectSpecialist: selectSpecialistAction,
  setNewCardData: setNewCardDataAction,
  confirmSchedule: confirmScheduleAction,
  clearPaymentMethod: clearPaymentMethodAction,
  clearAttendanceType: clearAttendanceTypeAction,
  clearTimeSlot: clearTimeSlotAction,
  clearSpecialist: clearSpecialistAction,
  clearCategory: clearCategoryAction,
  setLoadingSteps: setLoadingStepAction,
  getSpecialistDetails: getSpecialistDetailsAction,
  clearScheduleError: clearScheduleErrorAction,
  postUsgStatus: postUsgStatusAction,
  suppressUsgModal: suppressUsgModalAction,
  getHighlights: getHighlightsAction,
  resetAvailabilitiesState: resetAvailabilitiesStateAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleFlow)
