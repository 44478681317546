import {
  BrazilState,
  CityOfState,
} from '../../domain/Onboarding'
import {
  REQUEST_BRAZIL_STATES,
  SUCCESS_BRAZIL_STATES,
  FAILURE_BRAZIL_STATES,
  OnboardingTypes,
  REQUEST_CITIES_OF_STATE,
  SUCCESS_CITIES_OF_STATE,
  FAILURE_CITIES_OF_STATE,
  SUCCESS_OPEN_MODAL,
  SUCCESS_CLOSE_MODAL,
  SALES_ONBOARDING_MOMENT_ADDED,
  GET_PREGNANCY_DUE_DATE,
  SUCCESS_GET_PREGNANCY_DUE_DATE,
  FAILURE_GET_PREGNANCY_DUE_DATE,
  RESET_ONBOARDING_DPP,
} from './actions'

export interface OnboardingState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  brazilStates: Array<BrazilState>;
  citiesOfState: Array<CityOfState>;
  insurancePlanCovered: boolean | null;
  isModal: boolean;
  pregnancyDueDate: number
}

const initialOnboardingFlow: OnboardingState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  brazilStates: [],
  citiesOfState: [],
  insurancePlanCovered: null,
  isModal: false,
  pregnancyDueDate: 0
}

export function onboarding(
  state = initialOnboardingFlow,
  action: OnboardingTypes
): OnboardingState {
  switch (action.type) {
    case REQUEST_BRAZIL_STATES: {
      return {
        ...state,
        isLoading: true,
        citiesOfState: []
      }
    }
    case SUCCESS_BRAZIL_STATES: {
      return {
        ...state,
        isLoading: false,
        brazilStates: action.brazilStates
      }
    }
    case FAILURE_BRAZIL_STATES: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case REQUEST_CITIES_OF_STATE: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case SUCCESS_CITIES_OF_STATE: {
      return {
        ...state,
        isLoading: false,
        citiesOfState: action.citiesOfState
      }
    }
    case FAILURE_CITIES_OF_STATE: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case SUCCESS_OPEN_MODAL: {
      return {
        ...state,
        isModal: true,
      }
    }
    case SUCCESS_CLOSE_MODAL: {
      return {
        ...state,
        isModal: false,
        isSuccess: false,
      }
    }
    case SALES_ONBOARDING_MOMENT_ADDED: {
      localStorage.setItem('salesOnboardingMoment', action.moment)
      return {
        ...state
      }
    }
    case GET_PREGNANCY_DUE_DATE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false
      }
    }
    case SUCCESS_GET_PREGNANCY_DUE_DATE: {
      return {
        ...state,
        pregnancyDueDate: action.pregnancyDueDate,
        isLoading: false,
        isSuccess: true,
        isError: false
      }
    }
    case FAILURE_GET_PREGNANCY_DUE_DATE: {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true
      }
    }
    case RESET_ONBOARDING_DPP: {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        pregnancyDueDate: 0,
        isError: false
      }
    }
    default: return state
  }
}
