import {
  HealthInsuranceCompany,
  HealthInsurancePlanData,
  HealthplanService,
  PostUserHealthPlanType
} from '../../domain/Healthplan'
import ApiClient from './ApiClient'

export default class ApiHealthplanService implements HealthplanService {
  constructor(private apiClient: ApiClient) { }

  async getHealthInsuranceCompanies(): Promise<HealthInsuranceCompany[]> {
    const healthInsuranceCompaniesResponse = await
    this.apiClient.getHealthInsuranceCompanies()
    return healthInsuranceCompaniesResponse
  }

  async getHealthInsurancePlanData(): Promise<HealthInsurancePlanData> {
    const healthInsuranceResponse = await this.apiClient.getHealthInsurancePlanData()
    return healthInsuranceResponse.healthInsurancePlanData
  }

  async postUserHealthInsurance(
    healthPlanData: PostUserHealthPlanType
  ): Promise<HealthInsurancePlanData> {
    const response = await this.apiClient.postUserHealthInsurance(healthPlanData)
    return response.healthInsurancePlanData
  }
}
