/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins */
import { eventTrack } from '../../../eventGenerate'
import {
  HealthInsuranceCompany,
  HealthInsurancePlanData,
  SelectedHealthPlan,
} from '../../domain/Healthplan'
import { StatusType, initialStatus } from '../../domain/Status';
import { CloseModalType, SUCCESS_CLOSE_MODAL } from '../onboarding/actions';
import {
  REQUEST_HEALTH_INSURANCE_CIAS_PROFILE,
  SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE,
  FAILURE_HEALTH_INSURANCE_CIAS_PROFILE,
  SELECT_HEALTH_INSURANCE_PROFILE,
  POST_HEALTH_INSURANCE_PROFILE,
  SUCCESS_POST_HEALTH_INSURANCE_PROFILE,
  FAILURE_POST_HEALTH_INSURANCE_PROFILE,
  CLEAR_POST_HEALTH_INSURANCE_STATUS,
  GET_HEALTH_INSURANCE_PLAN,
  SUCCESS_GET_HEALTH_INSURANCE_PLAN,
  FAILURE_GET_HEALTH_INSURANCE_PLAN,
  GET_ACCEPTED_HEALTH_COMPANIES,
  FAILURE_GET_ACCEPTED_HEALTH_COMPANIES,
  SUCCESS_GET_ACCEPTED_HEALTH_COMPANIES,
  HealthPlanProfileTypes,
  CLEAR_HEALTH_INSURANCE_PLAN_STATUS,
  CLEAR_FETCH_COMPANIES_STATUS,
  ENABLE_HEALTHPLAN_MODAL,
} from './actions'

const defaultAcceptedCompanies = [
  'Abertta Saúde',
  'Amil / Amil One',
  'Bradesco Saúde',
  'Care Plus',
  'Fundação Saúde Itaú',
  'Mediservice',
  'Omint',
  'Porto Seguro Saúde',
  'Saúde Abas',
  'Vivest'
]
interface HealthplanState {
  fetchCompaniesStatus: StatusType;
  selectedHealthplan?: SelectedHealthPlan;
  byCompanyName: {
    [companyName: string]: HealthInsuranceCompany;
  };
  companies?: HealthInsuranceCompany[];
  isSuccessDeleted: boolean;
  postHealthInsurancePlanStatus: StatusType;
  healthInsurancePlanData: HealthInsurancePlanData | null;
  getHealthInsurancePlanStatus: StatusType;
  acceptedHealthCompanies?: Array<string>;
  acceptedHealthCompaniesStatus: StatusType;
  shouldEnableModal: boolean;
}

const initialHealthplanProfileFlow: HealthplanState = {
  fetchCompaniesStatus: initialStatus,
  selectedHealthplan: undefined,
  byCompanyName: {},
  companies: undefined,
  isSuccessDeleted: false,
  postHealthInsurancePlanStatus: initialStatus,
  healthInsurancePlanData: null,
  getHealthInsurancePlanStatus: initialStatus,
  acceptedHealthCompanies: undefined,
  acceptedHealthCompaniesStatus: initialStatus,
  shouldEnableModal: false,
}

export function healthplan(
  state = initialHealthplanProfileFlow,
  action: HealthPlanProfileTypes | CloseModalType
): HealthplanState {
  switch (action.type) {
    case SELECT_HEALTH_INSURANCE_PROFILE: {
      return {
        ...state,
        selectedHealthplan: action.selectedHealthplan,
      }
    }
    case REQUEST_HEALTH_INSURANCE_CIAS_PROFILE: {
      return {
        ...state,
        fetchCompaniesStatus: {
          isLoading: true,
          success: false,
          error: false
        }
      }
    }
    case SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE: {
      const allCompanies = action.companies.reduce(
        (byCompanyName: any, company) => {
          const newObj: any = byCompanyName
          if (!newObj.hasOwnProperty(company.company)) {
            newObj[company.company] = { company }
          } else {
            newObj[company.company] = {
              ...newObj[company.company],
              company
            }
          }
          return newObj
        }, {}
      )
      return {
        ...state,
        fetchCompaniesStatus: {
          error: false,
          isLoading: false,
          success: true
        },
        byCompanyName: allCompanies,
        companies: action.companies
      }
    }
    case FAILURE_HEALTH_INSURANCE_CIAS_PROFILE: {
      return {
        ...state,
        fetchCompaniesStatus: {
          error: true,
          isLoading: false,
          success: false
        }
      }
    }
    case CLEAR_FETCH_COMPANIES_STATUS: {
      return {
        ...state,
        fetchCompaniesStatus: initialStatus
      }
    }
    case POST_HEALTH_INSURANCE_PROFILE: {
      return {
        ...state,
        postHealthInsurancePlanStatus: {
          isLoading: true,
          error: false,
          success: false,
          errorMessage: undefined
        }
      }
    }
    case SUCCESS_POST_HEALTH_INSURANCE_PROFILE: {
      const isSuccessDeleted: boolean = action.healthInsurancePlanData.hasHealthInsurancePlan === false
      if (isSuccessDeleted) {
        eventTrack('Removeu Plano')
      } else if (action.alreadyHasInsurancePlan) {
        eventTrack('Adicionou Plano')
      } else {
        eventTrack('Trocou Plano')
      }
      return {
        ...state,
        postHealthInsurancePlanStatus: {
          isLoading: false,
          error: false,
          success: true
        },
        isSuccessDeleted,
        healthInsurancePlanData: action.healthInsurancePlanData
      }
    }
    case FAILURE_POST_HEALTH_INSURANCE_PROFILE: {
      return {
        ...state,
        postHealthInsurancePlanStatus: {
          isLoading: false,
          error: true,
          success: false,
        }
      }
    }
    case CLEAR_POST_HEALTH_INSURANCE_STATUS: {
      return {
        ...state,
        postHealthInsurancePlanStatus: initialStatus,
        isSuccessDeleted: false
      }
    }
    case GET_HEALTH_INSURANCE_PLAN: {
      return {
        ...state,
        getHealthInsurancePlanStatus: {
          isLoading: true,
          error: false,
          success: false,
          errorMessage: undefined
        },
        healthInsurancePlanData: null
      }
    }
    case SUCCESS_GET_HEALTH_INSURANCE_PLAN: {
      return {
        ...state,
        getHealthInsurancePlanStatus: {
          isLoading: false,
          error: false,
          success: true
        },
        healthInsurancePlanData: action.healthInsurancePlanData,
      }
    }
    case FAILURE_GET_HEALTH_INSURANCE_PLAN: {
      return {
        ...state,
        getHealthInsurancePlanStatus: {
          isLoading: false,
          error: true,
          success: false
        },
      }
    }
    case CLEAR_HEALTH_INSURANCE_PLAN_STATUS: {
      return {
        ...state,
        getHealthInsurancePlanStatus: initialStatus,
        postHealthInsurancePlanStatus: initialStatus
      }
    }
    case GET_ACCEPTED_HEALTH_COMPANIES: {
      return {
        ...state,
        acceptedHealthCompaniesStatus: {
          isLoading: true,
          success: false,
          error: false,
          errorMessage: undefined
        },
      }
    }
    case SUCCESS_GET_ACCEPTED_HEALTH_COMPANIES: {
      return {
        ...state,
        acceptedHealthCompaniesStatus: {
          isLoading: false,
          success: true,
          error: false
        },
        acceptedHealthCompanies: action.acceptedHealthCompanies
      }
    }
    case FAILURE_GET_ACCEPTED_HEALTH_COMPANIES: {
      return {
        ...state,
        acceptedHealthCompaniesStatus: {
          isLoading: false,
          success: false,
          error: true
        },
        acceptedHealthCompanies: defaultAcceptedCompanies
      }
    }
    case ENABLE_HEALTHPLAN_MODAL: {
      return {
        ...state,
        shouldEnableModal: true
      }
    }
    case SUCCESS_CLOSE_MODAL: {
      return {
        ...state,
        shouldEnableModal: false
      }
    }
    default: return state
  }
}
