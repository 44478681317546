/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { SpecialistCategories } from './Specialist'

export enum AttendanceAvailabilityType {
  PRESENTIAL = 'inPerson',
  NONE = 'none',
  BOTH = 'both',
  VIRTUAL = 'virtual'
}

export enum AttendanceAvailabilityToShow {
  PRESENTIAL = 'Presencial',
  VIRTUAL = 'Online'
}

export enum CategoryPaymentType {
  HEALTH_INSURANCE = 'HEALTH_INSURANCE',
  PAID = 'PAID',
  HEALTH_INSURANCE_CHILDREN = 'HEALTH_INSURANCE_CHILDREN'
}

export type CategoryToSchedule = {
  category: SpecialistCategories;
  description: string;
  price: number;
  attendanceAvailability: AttendanceAvailabilityType;
  blockReason?: string;
  categoryId: string;
  included: boolean;
  blockReasonTitle?: string;
  blockedBundle?: boolean;
}

export enum SourceTypes {
  JOURNEY = 'jornada',
  MENU = 'menu',
  BOOKING = 'consultas',
  SPECIALIST = 'especialista',
  SCHEDULE_SIGNUP = 'cadastro',
  USG_MODAL = 'modal ultrassom',
  BOOKING_DETAILS = 'detalhes da consulta',
  HOME = 'início',
  HEALTHPLAN_INCLUDED = 'incluso no plano',
  SUGGESTION_VIEW = 'suggestion view'
}

export type ResponseCategories = {
  paymentType: CategoryPaymentType;
  categories: CategoryToSchedule[];
}

export type PaymentMethods = {
  creditCard?: {
    cardBrand?: string;
    cardDigits?: string;
  };
  slip?: {
    paymentSlipAllowed?: boolean;
    paymentSlipDueDate?: string;
  };
  pix?: {
    paymentPixAllowed?: boolean;
    paymentPixDueDate?: string;
  };
}

export enum SchedulePaymentType {
  PAYMENT_SLIP = 'PAYMENT_SLIP',
  PIX = 'PIX',
  CREDIT_CARD = 'CREDIT_CARD',
  HEALTH_INSURANCE = 'HEALTH_INSURANCE',
  HEALTH_INSURANCE_CHILDREN = 'HEALTH_INSURANCE_CHILDREN',
  NONE = ''
}

export enum BookingTextToShow {
  SCHEDULED_CONSULTATION = 'A consulta foi agendada!',
  SCHEDULED_EXAM = 'O exame foi agendado!',
  CANCELED_CONSULTATION = 'A consulta foi cancelada',
  CANCELED_EXAM = 'O exame foi cancelado',
  DEFAULT_CONSULTATION = 'a consulta',
  DEFAULT_EXAM = 'o exame',
  YOURS_CONSULTATION = 'a consulta',
  YOURS_EXAM = 'o exame',
}

export type ScheduleInfosType = {
  availabilityId: string;
  newPaymentMethodAdded: boolean;
  paymentType?: SchedulePaymentType;
  paymentMethodId?: string;
  promotionalCode?: string;
  childrenId?: string;
  isChildSchedule: boolean;
  selectedCategory: SpecialistCategories;
  specialistName: string;
  isFromGuidedJourney: boolean;
}

export type ScheduleResponse = {
  categoryWarning?: string;
  paymentSlipDigitableCode?: string;
  paymentSlipBarCode?: string;
  paymentSlipPdfUrl?: string;
  bookingId: string;
  firstBooking: boolean;
  pixDigitableCode?: string;
  pixQrCodeUrl?: string;
}

export enum ScheduleFlowSteps {
  ChildHealthPlan = 'child-healthplan',
  ChooseMedicalSpecialty = 'choose-medical-specialty',
  ChooseAttendanceType = 'choose-attendance-type',
  ChooseDayAndTime = 'choose-day-and-time',
  SubmitDocuments = 'submit-documents',
  ChooseSpecialist = 'choose-specialist',
  ChoosePayment = 'choose-payment',
  AddNewCreditCard = 'add-new-credit-card',
  ReviewAndConfirm = 'review-and-confirm',
  ScheduleSuccess = 'schedule-success',
}

export type CreditCardInfos = {
  brand?: string;
  lastDigits?: string;
}

export interface ScheduleFlowService {
  getCategoriesToSchedule(childId?: string): Promise<ResponseCategories>;
  getPaymentMethods(startTime: number): Promise<PaymentMethods>;
  confirmSchedule(scheduleInfos: ScheduleInfosType): Promise<ScheduleResponse>;
}
