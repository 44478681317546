/* eslint-disable max-len */
import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../utils'
import {
  HealthInsuranceCompany,
  HealthInsurancePlanData,
  SelectedHealthPlan,
  HealthplanService,
  PostUserHealthPlanType
} from '../../domain/Healthplan'
import { UserProvider } from '../../domain/User'
import { urlEnvironment } from '../../utils/helpers'

export const REQUEST_HEALTH_INSURANCE_CIAS_PROFILE = 'REQUEST_HEALTH_INSURANCE_CIAS_PROFILE'
export const SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE = 'SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE'
export const FAILURE_HEALTH_INSURANCE_CIAS_PROFILE = 'FAILURE_HEALTH_INSURANCE_CIAS_PROFILE'
export const CLEAR_FETCH_COMPANIES_STATUS = 'CLEAR_FETCH_COMPANIES_STATUS'

export const SELECT_HEALTH_INSURANCE_PROFILE = 'SELECT_HEALTH_INSURANCE_PROFILE'

export const POST_HEALTH_INSURANCE_PROFILE = 'POST_HEALTH_INSURANCE_PROFILE'
export const SUCCESS_POST_HEALTH_INSURANCE_PROFILE = 'SUCCESS_POST_HEALTH_INSURANCE_PROFILE'
export const FAILURE_POST_HEALTH_INSURANCE_PROFILE = 'FAILURE_POST_HEALTH_INSURANCE_PROFILE'

export const CLEAR_POST_HEALTH_INSURANCE_STATUS = 'CLEAR_POST_HEALTH_INSURANCE_STATUS'

export const GET_HEALTH_INSURANCE_PLAN = 'GET_HEALTH_INSURANCE_PLAN'
export const SUCCESS_GET_HEALTH_INSURANCE_PLAN = 'SUCCESS_GET_HEALTH_INSURANCE_PLAN'
export const FAILURE_GET_HEALTH_INSURANCE_PLAN = 'FAILURE_GET_HEALTH_INSURANCE_PLAN'

export const GET_ACCEPTED_HEALTH_COMPANIES = 'GET_ACCEPTED_HEALTH_COMPANIES'
export const SUCCESS_GET_ACCEPTED_HEALTH_COMPANIES = 'SUCCESS_GET_ACCEPTED_HEALTH_COMPANIES'
export const FAILURE_GET_ACCEPTED_HEALTH_COMPANIES = 'FAILURE_GET_ACCEPTED_HEALTH_COMPANIES'

export const CLEAR_HEALTH_INSURANCE_PLAN_STATUS = 'CLEAR_HEALTH_INSURANCE_PLAN_STATUS'

export const ENABLE_HEALTHPLAN_MODAL = 'ENABLE_HEALTHPLAN_MODAL'

interface SelectHealthInsurancePlanActionType {
  type: typeof SELECT_HEALTH_INSURANCE_PROFILE;
  selectedHealthplan: SelectedHealthPlan;
}

interface RequestHealthInsuranceCompaniesActionType {
  type: typeof REQUEST_HEALTH_INSURANCE_CIAS_PROFILE;
}

interface SuccessHealthInsuranceCompaniesActionType {
  type: typeof SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE;
  companies: Array<HealthInsuranceCompany>;
}

interface FailureHealthInsuranceCompaniesActionType {
  type: typeof FAILURE_HEALTH_INSURANCE_CIAS_PROFILE;
}

interface ClearFetchCompaniesStatusActionType {
  type: typeof CLEAR_FETCH_COMPANIES_STATUS;
}

interface PostHealthInsuranceActionType {
  type: typeof POST_HEALTH_INSURANCE_PROFILE;
}

interface SuccessPostHealthInsuranceActionType {
  type: typeof SUCCESS_POST_HEALTH_INSURANCE_PROFILE;
  healthInsurancePlanData: HealthInsurancePlanData;
  alreadyHasInsurancePlan: boolean
}

interface FailurePostHealthInsuranceActionType {
  type: typeof FAILURE_POST_HEALTH_INSURANCE_PROFILE;
}

interface ClearPostHealthInsuranceStatusActionType {
  type: typeof CLEAR_POST_HEALTH_INSURANCE_STATUS;
}

interface GetHealthInsurancePlanActionType {
  type: typeof GET_HEALTH_INSURANCE_PLAN;
}

interface SuccessGetHealthInsurancePlanActionType {
  type: typeof SUCCESS_GET_HEALTH_INSURANCE_PLAN;
  healthInsurancePlanData: HealthInsurancePlanData;
}

interface FailureGetHealthInsurancePlanActionType {
  type: typeof FAILURE_GET_HEALTH_INSURANCE_PLAN;
}

interface GetAcceptedHealthCompaniesActionType {
  type: typeof GET_ACCEPTED_HEALTH_COMPANIES;
}

interface SuccessGetAcceptedHealthCompaniesActionType {
  type: typeof SUCCESS_GET_ACCEPTED_HEALTH_COMPANIES;
  acceptedHealthCompanies: Array<string>;
}

interface FailureGetAcceptedHealthCompaniesActionType {
  type: typeof FAILURE_GET_ACCEPTED_HEALTH_COMPANIES;
}

interface ClearHealthInsurancePlanStatusActionType {
  type: typeof CLEAR_HEALTH_INSURANCE_PLAN_STATUS;
}

interface EnableHealthPlanModalActionType {
  type: typeof ENABLE_HEALTHPLAN_MODAL;
}

export type HealthPlanProfileTypes = SelectHealthInsurancePlanActionType |
RequestHealthInsuranceCompaniesActionType |
SuccessHealthInsuranceCompaniesActionType |
FailureHealthInsuranceCompaniesActionType |
ClearFetchCompaniesStatusActionType |
PostHealthInsuranceActionType |
SuccessPostHealthInsuranceActionType |
FailurePostHealthInsuranceActionType |
ClearPostHealthInsuranceStatusActionType |
GetHealthInsurancePlanActionType |
SuccessGetHealthInsurancePlanActionType |
FailureGetHealthInsurancePlanActionType |
GetAcceptedHealthCompaniesActionType |
SuccessGetAcceptedHealthCompaniesActionType |
FailureGetAcceptedHealthCompaniesActionType |
ClearHealthInsurancePlanStatusActionType |
EnableHealthPlanModalActionType

export const onSelectHealthInsuranceAction = (selectedHealthplan: SelectedHealthPlan):
    SelectHealthInsurancePlanActionType => (
  { type: SELECT_HEALTH_INSURANCE_PROFILE, selectedHealthplan }
)

export function getHealthInsuranceCompaniesAction():
  AsyncAction<{}, { healthplanService: HealthplanService }> {
  return async (dispatch, _getState, { healthplanService }) => {
    dispatch({ type: REQUEST_HEALTH_INSURANCE_CIAS_PROFILE })
    try {
      const companies = await healthplanService.getHealthInsuranceCompanies()
      dispatch({ type: SUCCESS_HEALTH_INSURANCE_CIAS_PROFILE, companies })
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: FAILURE_HEALTH_INSURANCE_CIAS_PROFILE })
        Sentry.captureException(new Error(`Failure to fetch health insurance companies - ${error.message}`))
      }
    }
  }
}

export const clearFetchCompaniesStatusAction = ():
ClearFetchCompaniesStatusActionType => ({
  type: CLEAR_FETCH_COMPANIES_STATUS
})

export const clearPostHealthInsuranceStatusAction = ():
ClearPostHealthInsuranceStatusActionType => ({
  type: CLEAR_POST_HEALTH_INSURANCE_STATUS
})

export function getHealthInsurancePlanDataAction():
  AsyncAction<{}, { healthplanService: HealthplanService; }> {
  return async (dispatch, _getState, { healthplanService }) => {
    dispatch({ type: GET_HEALTH_INSURANCE_PLAN })
    try {
      const healthInsurancePlanData: HealthInsurancePlanData = await
      healthplanService.getHealthInsurancePlanData()
      dispatch({
        type:
        SUCCESS_GET_HEALTH_INSURANCE_PLAN,
        healthInsurancePlanData
      })
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: FAILURE_GET_HEALTH_INSURANCE_PLAN })
        Sentry.captureException(new Error(`Failure to get health plan coverage infos - ${error.message}`))
      }
    }
  }
}

export const clearHealthInsurancePlanStatusAction = ():
ClearHealthInsurancePlanStatusActionType => ({
  type: CLEAR_HEALTH_INSURANCE_PLAN_STATUS
})

export function postUserHealthInsuranceAction(
  healthPlanData: PostUserHealthPlanType,
  alreadyHasInsurancePlan: boolean
): AsyncAction<{}, { healthplanService: HealthplanService, userProvider: UserProvider }> {
  return async (dispatch, _getState, { healthplanService }) => {
    dispatch({ type: POST_HEALTH_INSURANCE_PROFILE })
    try {
      const healthInsurancePlanData = await healthplanService.postUserHealthInsurance(healthPlanData)
      dispatch({
        type: SUCCESS_POST_HEALTH_INSURANCE_PROFILE,
        healthInsurancePlanData,
        alreadyHasInsurancePlan
      })
      dispatch(getHealthInsurancePlanDataAction())
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: FAILURE_POST_HEALTH_INSURANCE_PROFILE })
        Sentry.captureException(new Error(`Failure to save user health insurance infos - ${error.message}`))
      }
    }
  }
}

export function getAcceptedHealthCompaniesAction(): AsyncAction<{}, {}> {
  return async (dispatch) => {
    dispatch({ type: GET_ACCEPTED_HEALTH_COMPANIES })
    await fetch(`${process.env.API_FRONT_URL}/app-lists?listName=acceptedHealthCompanies&environment=${urlEnvironment}`)
      .then(async (response) => {
        const data = await response.json()
        if (response.ok || response.status === 200) {
          dispatch({ type: SUCCESS_GET_ACCEPTED_HEALTH_COMPANIES, acceptedHealthCompanies: data })
        } else {
          dispatch({ type: FAILURE_GET_ACCEPTED_HEALTH_COMPANIES })
          Sentry.captureException(new Error(`Failure to fetch accepted health companies: ${response}`))
        }
      })
      .catch((error) => {
        dispatch({ type: FAILURE_GET_ACCEPTED_HEALTH_COMPANIES })
        Sentry.captureException(new Error(`Failure to fetch accepted health companies: ${error}`))
      })
  }
}

export const enableHealthPlanModalAction = ():
EnableHealthPlanModalActionType => (
  { type: ENABLE_HEALTHPLAN_MODAL }
)
