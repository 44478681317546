import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../apps/main/store'
import { Booking } from '../../domain/Booking'
import BookingListView from './BookingListView'
import EmptyView from '../common/EmptyView'
import EmptyBookings from '../../../assets/empty-my-bookings.svg'
import './MyBookingsView.scss'
import { AppDispatch } from '../../state/utils'
import { SourceTypes } from '../../domain/ScheduleFlow'
import { setScheduleSourceAction } from '../../state/scheduleFlow/actions'
import { AGENDAR } from '../../routes/RoutesConstants'

interface Props {
  isFetchingAllBookings: boolean;
  bookings: Booking[];
  bookingsByMonth: Map<string, Array<Booking>>;
  goToBookingsHistory: () => void;
  setScheduleSource: (source: SourceTypes) => void;
}

function AllBookingsView({
  isFetchingAllBookings,
  bookings,
  bookingsByMonth,
  goToBookingsHistory,
  setScheduleSource
}: Props) {
  const history = useHistory()

  const goToSpecialist = () => {
    setScheduleSource(SourceTypes.BOOKING)
    history.push(AGENDAR)
  }

  return (
    <BookingListView
      isFetchingBookings={isFetchingAllBookings}
      bookingsLength={bookings.length}
      bookingsByMonth={bookingsByMonth}
      emptyComponent={(
        <EmptyView
          title="Você ainda não possui nenhum agendamento"
          subtitle="Ao realizar um agendamento, todas as informações vão estar aqui. "
          icon={<EmptyBookings />}
          extraActionButton={goToBookingsHistory}
          extraActionText="Ver histórico"
          goToSpecialist={goToSpecialist}
        />
      )}
      showTagStatus={false}
    />
  )
}

const mapStateToProps = ({
  myBookingsFlow
}: AppState) => ({
  bookings: myBookingsFlow.allBookings,
  isFetchingAllBookings: myBookingsFlow.getBookingsStatus.isLoading,
  bookingsByMonth: myBookingsFlow.bookingsByMonth
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setScheduleSource: (source: SourceTypes) => {
    dispatch(setScheduleSourceAction(source))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllBookingsView)
