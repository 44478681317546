/* eslint-disable max-len */
/* eslint-disable jsx-a11y/autocomplete-valid */
import React, { useEffect, useState, useRef } from 'react'
import { useField } from 'formik'
import { Divider, FontIcon } from 'theia-web-ds'
import { primary, warning } from '../../color'
import './SelectOptions.scss'
import './SelectInputSearch.scss'
import './Input.scss'
import { cleanStringToCompare } from '../../utils/helpers'

interface Props {
  options?: string[];
  extraClass?: string;
  disabled?: boolean;
  label?: string;
  searchInputPlaceholder?: string;
  name: string;
  placeholder?: string;
  formSelectOnChange?: (e: React.ChangeEvent) => void;
  extraOnBlur?: () => void;
  required?: boolean;
}

export default function SelectInputSearch({
  options,
  placeholder,
  extraClass,
  disabled,
  label,
  searchInputPlaceholder,
  name,
  formSelectOnChange,
  extraOnBlur,
  required = true,
}: Props) {
  const [field, meta, helpers] = useField({ name })
  const {
    onBlur, onChange, value
  } = field
  const error = meta.touched && meta.error
  const { setValue } = helpers
  const [isOptionsVisible, setIsOptionsVisible] = useState(false)
  const [isCleanBtnVisible, setIsCleanBtnVisible] = useState(false)
  const [searchResult, setSearchResult] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState('')
  const handleChange = (e: React.ChangeEvent) => {
    if (formSelectOnChange) {
      formSelectOnChange(e)
    } else {
      onChange(e)
    }
  }
  const ref = useRef<any>()
  const changeList = (option: string) => {
    setValue(option)
    setIsOptionsVisible(false)
    setSearchTerm('')
    setSearchResult(options)
  }

  /**
   *@param {Event} e evento de input que contém o termo de pesquisa
   *@cleanString string limpa (sem acentos, minuscula, sem espaços a mais e caracteres especiais)
   *@wordsToMatch transforma a string limpa em um array de palavras (cada palavra sendo um item)
   *input "rede medial" output ['rede', 'medial']
   *@regexString cria uma parte do regex pra saber se cada palavra está presente na frase total (ex: "mamão com açúcar" -> "com" está presente? sim). o join combina os regex e faz com que seja necessário todas as palavras estarem presentes no termo, mas não necessariamente em ordem
   *output (?=.*rede)(?=.*medial)
   *@wordToFilter cria o regex final que vai ser usado pra filtrar as opções
   *output /(?=.*rede)(?=.*medial)/i
   */
  const filterOptions = (e: any) => {
    const term = e.target.value
    setSearchTerm(term)
    const cleanString = cleanStringToCompare(term)
    const wordsToMatch = cleanString.split(/\s+/)
    const regexString = wordsToMatch.map((word) => `(?=.*${word})`).join('')
    const termToFilter = new RegExp(regexString, 'i')
    const optionsBySearch = options && options.filter((item) => termToFilter.test(cleanStringToCompare(item)))
      .sort((a: string, b: string) => {
        const cleanedA = cleanStringToCompare(a)
        const cleanedB = cleanStringToCompare(b)
        if (cleanedA.startsWith(cleanString) && !cleanedB.startsWith(cleanString)) return -1
        if (cleanedB.startsWith(cleanString) && !cleanedA.startsWith(cleanString)) return 1
        return cleanedA.localeCompare(cleanedB)
      })
    if (term === '') {
      setSearchResult(options)
      setIsCleanBtnVisible(false)
    } else if (term.length >= 1) {
      setSearchResult(optionsBySearch)
      setIsCleanBtnVisible(true)
    }
  }

  const closeOptions = () => {
    setIsOptionsVisible(false)
    setSearchTerm('')
    setSearchResult(options)
  }

  const cleanInputSearch = () => {
    setSearchTerm('')
    setSearchResult(options)
    setIsCleanBtnVisible(false)
  }

  useEffect(() => {
    if (options) {
      setSearchResult(options)
    }
  }, [options])

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isOptionsVisible && ref.current && !ref.current.contains(e.target)) {
        setIsOptionsVisible(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOptionsVisible])

  return (
    <div
      className={`input-search ${extraClass}`}
    >
      {label && <label className="label-normal mb-2">{label}</label>}
      <input
        required={required}
        id={name}
        autoComplete="nope"
        className={`select__options-input-search ${error ? 'error' : ''} ${!isOptionsVisible ? 'block' : 'hidden'}`}
        type="text"
        name={name}
        placeholder={placeholder}
        onBlur={extraOnBlur || onBlur}
        value={value}
        onChange={handleChange}
        onClick={() => setIsOptionsVisible(true)}
        onFocus={() => setSearchTerm('')}
        disabled={disabled}
      />
      <ul className={`select-input-search-options ${isOptionsVisible ? 'block' : 'hidden'}`} ref={ref}>
        <li className="relative flex items-center li__input-option" key="input-list">
          <button type="button" onClick={closeOptions} className="close-options">
            <FontIcon iconType="icon-ArrowLeft2Light" color="#000000" fontSize="20px" />
          </button>
          <input
            required={required}
            id={name}
            autoComplete="nope"
            className={`input ${extraClass} ${error ? 'error' : ''}`}
            type="text"
            name={name}
            placeholder={searchInputPlaceholder}
            onChange={(e) => filterOptions(e)}
            value={searchTerm}
          />
          <button type="button" onClick={cleanInputSearch} className={`clean-input ${isCleanBtnVisible ? 'block' : 'hidden'}`}>
            <FontIcon iconType="icon-CloseLight" color="#000000" fontSize="20px" />
          </button>
        </li>
        {searchResult
          && searchResult.map((option: string, index: any) => (
            <div key={index}>
              <li
                className="select__option"
                key={option}
                value={option}
                aria-label={option}
              >
                <button type="button" onClick={() => changeList(option)} className="flex items-center justify-between w-full">
                  <span>
                    <FontIcon iconType="icon-SearchLight" color="#000000" fontSize="20px" />
                    <span className="ml-4">{option}</span>
                  </span>
                  <FontIcon iconType="icon-ArrowRight2Light" color={primary} fontSize="25px" />
                </button>
              </li>
              <Divider className="list-item-divider divider-search-list" />
            </div>
          ))}
      </ul>
      {error && (
        <div className="error-container">
          <FontIcon iconType="icon-DangerBold" fontSize="11px" color={warning} />
          <p className="error-text">
            {error}
          </p>
        </div>
      )}
    </div>
  )
}
