import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ButtonOptions, ListItemCard, RegularButton } from 'theia-web-ds'
import { useHistory } from 'react-router-dom'
import { AppDispatch } from '../../../state/utils'
import { error, textSecondary } from '../../../color'
import EditImageWithCrop from './EditImageWithCrop'
import { AppState } from '../../../apps/main/store'
import ProfilePicture, { PicturePlaceholder } from './ProfilePicture'
import { ActiveProfileType } from '../../../domain/AppProfiles'
import { onDeletePictureProfileAction, onUploadPictureProfileAction } from '../../../state/profile/actions'
import { User } from '../../../domain/User'
import { EDIT_DEPENDENT, EDIT_PROFILE } from '../../../routes/RoutesConstants'
import { StatusType } from '../../../domain/Status'
import './ProfilePictureEdit.scss'

interface Props {
  activeProfile?: ActiveProfileType
  isDependent: boolean
  currentUser?: User
  isUploadingPictureProfile: boolean
  isDeletingPictureProfile: boolean
  getUserHighlightsStatus: StatusType
  getChildrenDataStatus: StatusType
  isFetchingProfile: boolean
  showEditPhotoButtons?: boolean
  onUploadPictureProfile: (file: File) => void
  onDeletePictureProfile: (file: string | null) => void;}

function ProfilePictureEdit({
  activeProfile,
  isDependent,
  currentUser,
  isUploadingPictureProfile,
  isDeletingPictureProfile,
  getUserHighlightsStatus,
  getChildrenDataStatus,
  isFetchingProfile,
  showEditPhotoButtons,
  onUploadPictureProfile,
  onDeletePictureProfile
}: Props) {
  const history = useHistory()
  const [isCropOpen, setIsCropOpen] = useState(false)
  const [buttonDrawerIsVisible, setButtonDrawerIsVisible] = useState(false)
  const isFetching = isUploadingPictureProfile
    || isDeletingPictureProfile
    || getUserHighlightsStatus.isLoading
    || getChildrenDataStatus.isLoading
    || isFetchingProfile
  const hideButtonModal = () => {
    setIsCropOpen(!isCropOpen)
  }

  const closeButtonModal = () => {
    setIsCropOpen(false)
    setButtonDrawerIsVisible(false)
  }

  const deleteProfilePicture = () => {
    onDeletePictureProfile(activeProfile?.imageUrl || null)
    closeButtonModal()
  }

  const closeButtonDrawer = () => {
    setButtonDrawerIsVisible(false)
  }

  const openButtonDrawer = () => {
    setButtonDrawerIsVisible(true)
  }

  const onEditProfile = () => {
    if (isDependent) {
      history.push(EDIT_DEPENDENT)
    } else {
      history.push(EDIT_PROFILE)
    }
  }

  return (
    <div className="profile-container">
      <div className="profile-data">
        <ProfilePicture
          pictureUrl={activeProfile?.imageUrl || null}
          pictureSize={94}
          isLoading={isFetching}
          className="mb-4"
          extraClass="mb-4"
          picturePlaceholder={isDependent
            ? PicturePlaceholder.BABY
            : PicturePlaceholder.USER}
        />
        {isFetching ? (
          <div className="user-name-loading" />
        ) : (
          <>
            <p className="user-name-style">
              {activeProfile?.name || ''}
            </p>
            <p className="user-email">
              {isDependent ? 'Dependente' : currentUser?.email}
            </p>
          </>
        )}
      </div>
      {showEditPhotoButtons && !isDependent && (
        <>
          {activeProfile?.imageUrl === null && (
            <EditImageWithCrop
              id="photo"
              onUpload={onUploadPictureProfile}
              buttonLabel="Adicionar foto"
              hideButtonModal={hideButtonModal}
              closeButtonModal={closeButtonModal}
              iconColor={textSecondary}
              variant="subtle"
              className="mb-4"
            />
          )}
          {activeProfile?.imageUrl !== null && (
            <ButtonOptions
              buttonToAnchor={(
                <RegularButton
                  variant="subtle"
                  label="Trocar foto"
                  extraClass="button-photo"
                  onClick={openButtonDrawer}
                />
              )}
              visible={buttonDrawerIsVisible}
              onClose={closeButtonDrawer}
              headerText="Opções de ações para a edição de foto"
              isFromCrop={isCropOpen}
              extraClass="new-anchor-group"
            >
              <EditImageWithCrop
                id="photo"
                onUpload={onUploadPictureProfile}
                buttonLabel="Carregar foto"
                isFromModal
                hideButtonModal={hideButtonModal}
                closeButtonModal={closeButtonModal}
                arrowColor={textSecondary}
              />
              <ListItemCard
                title="Deletar foto atual"
                ariaLabel="Deletar foto atual"
                onClick={deleteProfilePicture}
                iconType="icon-TrashLight"
                iconColor={error}
                arrowColor={textSecondary}
                extraClassNameContainer="item-from-btn-group"
                extraClassClickableContainer="last-item-border-radius"
                isLastItem
              />
            </ButtonOptions>
          )}
        </>
      )}
      <RegularButton
        variant="subtle"
        label="Editar perfil"
        extraClass="button-photo pb-4"
        onClick={onEditProfile}
      />
    </div>
  )
}

const mapStateToProps = ({
  profile, authentication, appProfiles, pediatricFlow, userHighlights
}: AppState) => ({
  activeProfile: appProfiles.activeProfile,
  isDependent: appProfiles.isDependent,
  currentUser: authentication.currentUser,
  isUploadingPictureProfile: profile.isUploadingPictureProfile,
  isDeletingPictureProfile: profile.isDeletingPictureProfile,
  getUserHighlightsStatus: userHighlights.getUserHighlightsStatus,
  getChildrenDataStatus: pediatricFlow.getChildrenDataStatus,
  isFetchingProfile: profile.isFetchingProfile
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onUploadPictureProfile: onUploadPictureProfileAction,
  onDeletePictureProfile: onDeletePictureProfileAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePictureEdit)
