/* eslint-disable max-len */
import * as Sentry from '@sentry/browser'
import {
  AttendanceAvailabilityType,
  CategoryToSchedule,
  CreditCardInfos,
  PaymentMethods,
  ResponseCategories,
  ScheduleFlowService,
  ScheduleFlowSteps,
  ScheduleInfosType,
  SchedulePaymentType,
  ScheduleResponse,
  SourceTypes,
} from '../../domain/ScheduleFlow'
import { AppDispatch, AsyncAction } from '../utils'
import { AppState } from '../../apps/main/store'
import { eventTrack } from '../../../eventGenerate'
import { SpecialistCategories } from '../../domain/Specialist'
import { UserProvider } from '../../domain/User'
import { HttpClient } from '../../services/Http'
import { onUpdateBookingSuggestionStatusAction } from '../suggestionsFlow/actions'
import { onUpdateBookingSuggestionAction } from '../scheduleSuggestion/actions'
import { BookingService } from '../../domain/Booking'
import { TheiaError } from '../../domain/errors/TheiaError'
import { AvailabilityTimeSlot, SlotSpecialist } from '../../domain/Availabilities'
import { EventCategories } from '../../utils/EventCategories'

export const GET_CATEGORIES_TO_SCHEDULE = 'GET_CATEGORIES_TO_SCHEDULE'
export const SUCCESS_GET_CATEGORIES_TO_SCHEDULE = 'SUCCESS_GET_CATEGORIES_TO_SCHEDULE'
export const FAILURE_GET_CATEGORIES_TO_SCHEDULE = 'FAILURE_GET_CATEGORIES_TO_SCHEDULE'
export const RESET_SCHEDULE_FLOW = 'RESET_SCHEDULE_FLOW'
export const SELECT_CATEGORY_TO_SCHEDULE = 'SELECT_CATEGORY_TO_SCHEDULE'
export const SELECT_ATTENDANCE_TYPE = 'SELECT_ATTENDANCE_TYPE'
export const CLEAR_ATTENDANCE_TYPE = 'CLEAR_ATTENDANCE_TYPE'
export const SET_SCHEDULE_STEP = 'SET_SCHEDULE_STEP'
export const SELECT_TIMESLOT = 'SELECT_TIMESLOT'
export const CLEAR_TIMESLOT = 'CLEAR_TIMESLOT'
export const SELECT_SPECIALIST = 'SELECT_SPECIALIST'
export const CLEAR_SPECIALIST = 'CLEAR_SPECIALIST'
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD'
export const CLEAR_PAYMENT_METHOD = 'CLEAR_PAYMENT_METHOD'
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'
export const SUCCESS_GET_PAYMENT_METHODS = 'SUCCESS_GET_PAYMENT_METHODS'
export const FAILURE_GET_PAYMENT_METHODS = 'FAILURE_GET_PAYMENT_METHODS'
export const SET_PAYMENT_METHOD_ID = 'SET_PAYMENT_METHOD_ID'
export const SET_NEW_CARD_DATA = 'SET_NEW_CARD_DATA'
export const CONFIRM_SCHEDULE = 'CONFIRM_SCHEDULE'
export const SUCCESS_CONFIRM_SCHEDULE = 'SUCCESS_CONFIRM_SCHEDULE'
export const FAILURE_CONFIRM_SCHEDULE = 'FAILURE_CONFIRM_SCHEDULE'
export const SET_PREVIOUS_SELECTED_CATEGORY = 'SET_PREVIOUS_SELECTED_CATEGORY'
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY'
export const SET_PREVIOUS_SELECTED_SPECIALIST = 'SET_PREVIOUS_SELECTED_SPECIALIST'
export const SET_LOADING_STEP = 'SET_LOADING_STEP'
export const SET_SCHEDULE_SOURCE = 'SET_SCHEDULE_SOURCE'
export const CLEAR_SCHEDULE_ERROR = 'CLEAR_SCHEDULE_ERROR'

interface GetCategoriesToScheduleActionType {
  type: typeof GET_CATEGORIES_TO_SCHEDULE;
}

interface SuccessGetCategoriesToScheduleActionType {
  type: typeof SUCCESS_GET_CATEGORIES_TO_SCHEDULE;
  categories: ResponseCategories;
}

interface FailureGetCategoriesToScheduleActionType {
  type: typeof FAILURE_GET_CATEGORIES_TO_SCHEDULE;
  error?: TheiaError;
}

interface ResetScheduleFlowActionType {
  type: typeof RESET_SCHEDULE_FLOW;
}

interface SelectCategoryToScheduleActionType {
  type: typeof SELECT_CATEGORY_TO_SCHEDULE;
  category?: CategoryToSchedule;
}

interface SelectAttendanceTypeActionType {
  type: typeof SELECT_ATTENDANCE_TYPE;
  attendanceType?: AttendanceAvailabilityType;
}

interface ClearAttendanceTypeActionType {
  type: typeof CLEAR_ATTENDANCE_TYPE
}

interface SetScheduleStepActionType {
  type: typeof SET_SCHEDULE_STEP;
  step: ScheduleFlowSteps;
}

interface SelectTimeSlotActionType {
  type: typeof SELECT_TIMESLOT;
  selectedTimeSlot?: AvailabilityTimeSlot;
}

interface ClearTimeSlotActionType {
  type: typeof CLEAR_TIMESLOT
}

interface SelectSpecialistActionType {
  type: typeof SELECT_SPECIALIST;
  selectedSpecialist?: SlotSpecialist;
}

interface ClearSpecialistActionType {
  type: typeof CLEAR_SPECIALIST;
}

interface SelectPaymentMethodActionType {
  type: typeof SELECT_PAYMENT_METHOD;
  useOldCard: boolean;
  isFree: boolean;
  newPaymentMethodAdded: boolean;
  selectedPaymentMethod?: SchedulePaymentType;
}

interface ClearPaymentMethodActionType {
  type: typeof CLEAR_PAYMENT_METHOD
}

interface GetPaymentMethodsActionType {
  type: typeof GET_PAYMENT_METHODS;
}

interface SuccessGetPaymentMethodsActionType {
  type: typeof SUCCESS_GET_PAYMENT_METHODS;
  paymentMethods: PaymentMethods;
}

interface FailureGetPaymentMethodsActionType {
  type: typeof FAILURE_GET_PAYMENT_METHODS;
  error?: TheiaError;
}

interface SetPaymentMethodIdActionType {
  type: typeof SET_PAYMENT_METHOD_ID;
  paymentMethodId: string;
}

interface SetNewCardDataActionType {
  type: typeof SET_NEW_CARD_DATA;
  newCardData: CreditCardInfos;
}

interface ConfirmScheduleActionType {
  type: typeof CONFIRM_SCHEDULE;
}

interface SuccessConfirmScheduleActionType {
  type: typeof SUCCESS_CONFIRM_SCHEDULE;
  response: ScheduleResponse;
}

interface FailureConfirmScheduleActionType {
  type: typeof FAILURE_CONFIRM_SCHEDULE;
  error: TheiaError;
}

interface SetPreviousSelectedCategoryActionType {
  type: typeof SET_PREVIOUS_SELECTED_CATEGORY;
  category?: SpecialistCategories;
}

interface ClearCategoryActionType {
  type: typeof CLEAR_CATEGORY
}

interface SetPreviousSelectedSpecialistActionType {
  type: typeof SET_PREVIOUS_SELECTED_SPECIALIST;
  specialistId?: string;
  specialistName?: string;
}

interface SetLoadingStepActionType {
  type: typeof SET_LOADING_STEP;
  loadingStep: boolean;
}

interface SetScheduleSourceActionType {
  type: typeof SET_SCHEDULE_SOURCE;
  source?: SourceTypes;
}

interface ClearScheduleErrorActionType {
  type: typeof CLEAR_SCHEDULE_ERROR;
}

export type ScheduleTypes = GetCategoriesToScheduleActionType
| SuccessGetCategoriesToScheduleActionType
| FailureGetCategoriesToScheduleActionType
| ResetScheduleFlowActionType
| SelectCategoryToScheduleActionType
| SelectAttendanceTypeActionType
| ClearAttendanceTypeActionType
| SetScheduleStepActionType
| SelectTimeSlotActionType
| ClearTimeSlotActionType
| SelectSpecialistActionType
| ClearSpecialistActionType
| SelectPaymentMethodActionType
| ClearPaymentMethodActionType
| GetPaymentMethodsActionType
| SuccessGetPaymentMethodsActionType
| FailureGetPaymentMethodsActionType
| SetPaymentMethodIdActionType
| SetNewCardDataActionType
| ConfirmScheduleActionType
| SuccessConfirmScheduleActionType
| FailureConfirmScheduleActionType
| SetPreviousSelectedCategoryActionType
| ClearCategoryActionType
| SetPreviousSelectedSpecialistActionType
| SetLoadingStepActionType
| SetScheduleSourceActionType
| ClearScheduleErrorActionType

export function getCategoriesToScheduleAction(childId?: string):
  AsyncAction<{}, { scheduleFlowService: ScheduleFlowService }> {
  return async (dispatch, _getState, { scheduleFlowService }) => {
    dispatch({ type: GET_CATEGORIES_TO_SCHEDULE })
    try {
      const categories: ResponseCategories = await scheduleFlowService.getCategoriesToSchedule(childId)
      dispatch({ type: SUCCESS_GET_CATEGORIES_TO_SCHEDULE, categories })
    } catch (error) {
      if (error instanceof TheiaError) {
        dispatch({ type: FAILURE_GET_CATEGORIES_TO_SCHEDULE, error })
        Sentry.captureException(new Error(`Failure to get categories to schedule - ${error.message}`))
      }
    }
  }
}

export function getPaymentMethodsAction(
  startTime: number
):
  AsyncAction<{}, { scheduleFlowService: ScheduleFlowService }> {
  return async (dispatch, _getState, { scheduleFlowService }) => {
    dispatch({ type: GET_PAYMENT_METHODS })
    try {
      const paymentMethods = await scheduleFlowService.getPaymentMethods(startTime)
      dispatch({
        type: SUCCESS_GET_PAYMENT_METHODS,
        paymentMethods
      })
    } catch (error) {
      if (error instanceof TheiaError) {
        dispatch({ type: FAILURE_GET_PAYMENT_METHODS, error })
        Sentry.captureException(new Error(`Failure to get payment methods for selected availability - ${error.message}`))
      }
    }
  }
}

export function confirmScheduleAction(scheduleInfos: ScheduleInfosType):
  AsyncAction<{}, {
    scheduleFlowService: ScheduleFlowService;
    bookingService: BookingService;
    userProvider: UserProvider;
    httpClient: HttpClient;
  }> {
  return async (dispatch, _getState, { scheduleFlowService }) => {
    try {
      dispatch({ type: CONFIRM_SCHEDULE })
      const {
        authentication,
        scheduleFlow,
        appProfiles
      } = _getState() as AppState
      const response = await scheduleFlowService.confirmSchedule(scheduleInfos)
      if (response) {
        dispatch({
          type: SUCCESS_CONFIRM_SCHEDULE,
          response
        })
        eventTrack('Consulta Agendada', {
          'Id paciente': scheduleInfos.isChildSchedule ? scheduleInfos.childrenId : authentication.currentUser?.id,
          'Tipo de paciente': scheduleInfos.isChildSchedule ? 'criança' : 'mãe',
          'id mae': authentication.currentUser?.id,
          'Especialidade': scheduleInfos.selectedCategory,
          'Especialista': scheduleInfos.specialistName,
          'Momento': authentication.currentUser?.pregnancyMoment,
          'Idade gestacional': authentication.currentUser?.pregnancyMomentText,
          'category': EventCategories.NEW_SCHEDULE_FLOW,
          'source': scheduleFlow.source,
          'pagamento': scheduleInfos.paymentType,
          'convenio': appProfiles.activeProfile?.companyName || '',
        })
        const bookingSuggestion = JSON.parse(localStorage.getItem('bookingSuggestion') || '{}')
        if (bookingSuggestion.category !== undefined
          && (bookingSuggestion.category === scheduleInfos.selectedCategory)) {
          dispatch(onUpdateBookingSuggestionStatusAction(
            bookingSuggestion.id,
            'Scheduled',
            response.bookingId
          ))
        }
        const scheduleSuggestion = JSON.parse(localStorage.getItem('scheduleSuggestion') || '{}')
        if (scheduleSuggestion.type) {
          dispatch(
            onUpdateBookingSuggestionAction(scheduleSuggestion.type, scheduleSuggestion.bookingId)
          )
        }
      }
    } catch (error) {
      if (error instanceof TheiaError) {
        Sentry.captureException(new Error(`Failure creating a booking - ${error.message} - ${error.friendlyMessage} - ${error.friendlyMessageTitle} - ${error.statusCode}`))
        eventTrack('Erro no agendamento', {
          'Motivo do erro': `${error.message} - ${error.friendlyMessage} - ${error.friendlyMessageTitle} - ${error.statusCode}`
        })
      }
      dispatch({
        type: FAILURE_CONFIRM_SCHEDULE,
        error
      })
    }
  }
}

export const resetScheduleFlowAction = ()
: ResetScheduleFlowActionType => ({
  type: RESET_SCHEDULE_FLOW
})

export const selectCategoryToScheduleAction = (
  category?: CategoryToSchedule
): SelectCategoryToScheduleActionType => ({
  type: SELECT_CATEGORY_TO_SCHEDULE,
  category
})

export const selectAttendanceTypeAction = (
  attendanceType?: AttendanceAvailabilityType
): SelectAttendanceTypeActionType => ({
  type: SELECT_ATTENDANCE_TYPE,
  attendanceType
})

export const clearAttendanceTypeAction = (): ClearAttendanceTypeActionType => ({
  type: CLEAR_ATTENDANCE_TYPE,
})

export const setScheduleStepAction = (
  step: ScheduleFlowSteps
): SetScheduleStepActionType => ({
  type: SET_SCHEDULE_STEP,
  step
})

export const selectTimeSlotAction = (
  selectedTimeSlot?: AvailabilityTimeSlot
): SelectTimeSlotActionType => ({
  type: SELECT_TIMESLOT,
  selectedTimeSlot
})

export const clearTimeSlotAction = (): ClearTimeSlotActionType => ({
  type: CLEAR_TIMESLOT
})

export const selectSpecialistAction = (
  selectedSpecialist?: SlotSpecialist
): SelectSpecialistActionType => ({
  type: SELECT_SPECIALIST,
  selectedSpecialist
})

export const clearSpecialistAction = (): ClearSpecialistActionType => ({
  type: CLEAR_SPECIALIST
})

export const selectPaymentMethodAction = (
  useOldCard: boolean,
  isFree: boolean,
  newPaymentMethodAdded: boolean,
  selectedPaymentMethod?: SchedulePaymentType,
): SelectPaymentMethodActionType => ({
  type: SELECT_PAYMENT_METHOD,
  useOldCard,
  isFree,
  newPaymentMethodAdded,
  selectedPaymentMethod,
})

export const clearPaymentMethodAction = (): ClearPaymentMethodActionType => ({
  type: CLEAR_PAYMENT_METHOD
})

export const setPaymentMethodIdAction = (
  paymentMethodId: string
): SetPaymentMethodIdActionType => ({
  type: SET_PAYMENT_METHOD_ID,
  paymentMethodId
})

export const setNewCardDataAction = (
  newCardData: CreditCardInfos
): SetNewCardDataActionType => ({
  type: SET_NEW_CARD_DATA,
  newCardData
})

export const setPreviousSelectedCategoryAction = (
  category?: SpecialistCategories
): SetPreviousSelectedCategoryActionType => ({
  type: SET_PREVIOUS_SELECTED_CATEGORY,
  category
})

export const clearCategoryAction = (): ClearCategoryActionType => ({
  type: CLEAR_CATEGORY
})

export const setPreviousSelectedSpecialistAction = (
  specialistId?: string,
  specialistName?: string
): SetPreviousSelectedSpecialistActionType => ({
  type: SET_PREVIOUS_SELECTED_SPECIALIST,
  specialistId,
  specialistName
})

export const setLoadingStepAction = (
  loadingStep: boolean
): SetLoadingStepActionType => ({
  type: SET_LOADING_STEP,
  loadingStep
})

export const setScheduleSourceAction = (
  source?: SourceTypes
) => (dispatch: AppDispatch) => {
  dispatch({
    type: SET_SCHEDULE_SOURCE,
    source
  })
  eventTrack('clicou agendar', {
    categoria: EventCategories.NEW_SCHEDULE_FLOW,
    source
  })
}

export const clearScheduleErrorAction = (): ClearScheduleErrorActionType => ({
  type: CLEAR_SCHEDULE_ERROR
})
