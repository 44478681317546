import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../utils'
import {
  BrazilState,
  CityOfState,
  OnboardingService
} from '../../domain/Onboarding'

export const REQUEST_BRAZIL_STATES = 'REQUEST_BRAZIL_STATES'
export const SUCCESS_BRAZIL_STATES = 'SUCCESS_BRAZIL_STATES'
export const FAILURE_BRAZIL_STATES = 'FAILURE_BRAZIL_STATES'

export const REQUEST_CITIES_OF_STATE = 'REQUEST_CITIES_OF_STATE'
export const SUCCESS_CITIES_OF_STATE = 'SUCCESS_CITIES_OF_STATE'
export const FAILURE_CITIES_OF_STATE = 'FAILURE_CITIES_OF_STATE'

export const SUCCESS_OPEN_MODAL = 'SUCCESS_OPEN_MODAL'
export const SUCCESS_CLOSE_MODAL = 'SUCCESS_CLOSE_MODAL'

export const SALES_ONBOARDING_MOMENT_ADDED = 'SALES_ONBOARDING_MOMENT_ADDED'
export const SHOW_SCHEDULE_PROFILE_MODAL = 'SHOW_SCHEDULE_PROFILE_MODAL'

export const GET_PREGNANCY_DUE_DATE = 'GET_PREGNANCY_DUE_DATE'
export const SUCCESS_GET_PREGNANCY_DUE_DATE = 'SUCCESS_GET_PREGNANCY_DUE_DATE'
export const FAILURE_GET_PREGNANCY_DUE_DATE = 'FAILURE_GET_PREGNANCY_DUE_DATE'
export const RESET_ONBOARDING_DPP = 'RESET_ONBOARDING_DPP'

interface RequestBrazilStatesActionType {
  type: typeof REQUEST_BRAZIL_STATES;
}

interface SuccessBrazilStatesActionType {
  type: typeof SUCCESS_BRAZIL_STATES;
  brazilStates: Array<BrazilState>;
}

interface FailureBrazilStatesActionType {
  type: typeof FAILURE_BRAZIL_STATES;
}

interface RequestCitiesOfStateActionType {
  type: typeof REQUEST_CITIES_OF_STATE;
}

interface SuccessCitiesOfStateActionType {
  type: typeof SUCCESS_CITIES_OF_STATE;
  citiesOfState: Array<CityOfState>;
}

interface FailureCitiesOfStateActionType {
  type: typeof FAILURE_CITIES_OF_STATE;
}

interface OpenModalType {
  type: typeof SUCCESS_OPEN_MODAL;
}

export interface CloseModalType {
  type: typeof SUCCESS_CLOSE_MODAL;
}

export interface SalesOnboardingMomentAddedActionType {
  type: typeof SALES_ONBOARDING_MOMENT_ADDED;
  moment: string;
}

interface ShowScheduleProfileModalActionType {
  type: typeof SHOW_SCHEDULE_PROFILE_MODAL;
}

interface GetPregnancyDueDateActionType {
  type: typeof GET_PREGNANCY_DUE_DATE
}

interface SuccessGetPregnancyDueDateActionType {
  type: typeof SUCCESS_GET_PREGNANCY_DUE_DATE
  pregnancyDueDate: number
}

interface FailureGetPregnancyDueDateActionType {
  type: typeof FAILURE_GET_PREGNANCY_DUE_DATE
}

interface ResetOnBoardingPregnancyDueDateActionType {
  type: typeof RESET_ONBOARDING_DPP
}

export type OnboardingTypes =
  | RequestBrazilStatesActionType
  | SuccessBrazilStatesActionType
  | FailureBrazilStatesActionType
  | RequestCitiesOfStateActionType
  | SuccessCitiesOfStateActionType
  | FailureCitiesOfStateActionType
  | OpenModalType
  | CloseModalType
  | SalesOnboardingMomentAddedActionType
  | ShowScheduleProfileModalActionType
  | GetPregnancyDueDateActionType
  | SuccessGetPregnancyDueDateActionType
  | FailureGetPregnancyDueDateActionType
  | ResetOnBoardingPregnancyDueDateActionType

export function getBrazilStatesAction():
  AsyncAction<{}, { onboardingService: OnboardingService }> {
  return async (dispatch, _getState, { onboardingService }) => {
    dispatch({ type: REQUEST_BRAZIL_STATES })
    try {
      const brazilStates = await onboardingService.getBrazilStates()
      dispatch({ type: SUCCESS_BRAZIL_STATES, brazilStates })
    } catch (error) {
      dispatch({ type: FAILURE_BRAZIL_STATES })
      Sentry.captureException(new Error(`Failure to fetch Brazil states - ${error.message}`))
    }
  }
}

export function getCitiesOfStateAction(id: string):
  AsyncAction<{}, { onboardingService: OnboardingService }> {
  return async (dispatch, _getState, { onboardingService }) => {
    dispatch({ type: REQUEST_CITIES_OF_STATE })
    try {
      const citiesOfState = await onboardingService.getCitiesOfState(id)

      dispatch({ type: SUCCESS_CITIES_OF_STATE, citiesOfState })
    } catch (error) {
      dispatch({ type: FAILURE_CITIES_OF_STATE })
      Sentry.captureException(new Error(`Failure to fetch cities os state - ${error.message}`))
    }
  }
}

export const openModalAction = (): OpenModalType => (
  { type: SUCCESS_OPEN_MODAL }
)

export const closeModalAction = (): CloseModalType => (
  { type: SUCCESS_CLOSE_MODAL }
)

export const onAddMomentAction = (moment: string):
SalesOnboardingMomentAddedActionType => ({
  type: SALES_ONBOARDING_MOMENT_ADDED,
  moment
})

export const onShowScheduleProfileModalAction = ():
ShowScheduleProfileModalActionType => ({
  type: SHOW_SCHEDULE_PROFILE_MODAL
})

export function getOnboardingPregnancyDueDateAction(dum: number):
AsyncAction<{}, { onboardingService: OnboardingService }> {
  return async (dispatch, _getState, { onboardingService }) => {
    dispatch({ type: GET_PREGNANCY_DUE_DATE })
    try {
      const { pregnancyDueDate } = await onboardingService.getPregnancyDueDate(dum)
      dispatch({ type: SUCCESS_GET_PREGNANCY_DUE_DATE, pregnancyDueDate })
    } catch (error) {
      dispatch({ type: FAILURE_GET_PREGNANCY_DUE_DATE })
      Sentry.captureException(new Error(`Failure to calculate DPP onboarding - ${error.message}`))
    }
  }
}

export const resetOnboardingDPPAction = ():
ResetOnBoardingPregnancyDueDateActionType => ({
  type: RESET_ONBOARDING_DPP
})
