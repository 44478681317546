import React from 'react'
import { Dispatch } from 'redux'
import { RegularButton } from 'theia-web-ds'
import { connect } from 'react-redux'
import ExclamationError from '../../../assets/exclamation-error.svg'
import { suppressDefaultErrorAction } from '../../state/defaultError/actions'
import Modal from './Modal'
import HeaderModal from './HeaderModal'
import { AppState } from '../../apps/main/store'
import { TheiaError } from '../../domain/errors/TheiaError'

interface Props {
  defaultErrorVisible: boolean;
  error?: TheiaError;
  closeModal: () => void;
}

function DefaultErrorModal({ defaultErrorVisible, error, closeModal }: Props) {
  if (!defaultErrorVisible) return null

  return (
    <Modal>
      <div className="default-error-modal flex flex-col text-center" data-testid="default-error-modal">
        <ExclamationError className="self-center" />
        <HeaderModal>
          {error?.friendlyMessageTitle || 'Ops, aconteceu um problema'}
        </HeaderModal>
        <p className="text-textSecondary font-normal text-bodyMedium mb-4">
          {error?.friendlyMessage || 'Não foi possível concluir sua solicitação.'}
        </p>
        <RegularButton btnSize="small" label="Fechar" onClick={closeModal} />
      </div>
    </Modal>
  )
}

const mapStateToProps = ({
  defaultError,
}: AppState) => ({
  defaultErrorVisible: defaultError.visible,
  error: defaultError.error
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => { dispatch(suppressDefaultErrorAction()) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultErrorModal)
