import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../utils'
import {
  BabyData,
  ChildrenSummary,
  HealthInsurancePlanStatus,
  PediatricFlowService
} from '../../domain/PediatricFlow'
import { eventTrack } from '../../../eventGenerate'
import { havePlan } from '../../utils/helpers'
import { showNotificationAction } from '../notifications/actions'
import { success, warning as errorColor } from '../../color'
import { TheiaError } from '../../domain/errors/TheiaError'
import { NotificationMessage, ToastType } from '../../domain/Notifications'
import { EventCategories } from '../../utils/EventCategories'

export const UPDATE_CHILDREN = 'UPDATE_CHILDREN'
export const SUCCESS_UPDATE_CHILDREN = 'SUCCESS_UPDATE_CHILDREN'
export const FAILURE_UPDATE_CHILDREN = 'FAILURE_UPDATE_CHILDREN'
export const GET_CHILDREN_DATA = 'GET_CHILDREN_DATA'
export const SUCCESS_GET_CHILDREN_DATA = 'SUCCESS_GET_CHILDREN_DATA'
export const FAILURE_GET_CHILDREN_DATA = 'FAILURE_GET_CHILDREN_DATA'
export const GET_CHILDREN_SUMMARY = 'GET_CHILDREN_SUMMARY'
export const SUCCESS_GET_CHILDREN_SUMMARY = 'SUCCESS_GET_CHILDREN_SUMMARY'
export const FAILURE_GET_CHILDREN_SUMMARY = 'FAILURE_GET_CHILDREN_SUMMARY'
export const SUPPRESS_PEDIATRIC_WELCOME_MODAL = 'SUPPRESS_PEDIATRIC_WELCOME_MODAL'
export const CLEAR_UPDATE_CHILDREN_STATUS = 'CLEAR_UPDATE_CHILDREN_STATUS'
export const SHOW_PEDIATRIC_WELCOME_MODAL = 'SHOW_PEDIATRIC_WELCOME_MODAL'
export const UPDATE_CHILD_HEALTHPLAN = 'UPDATE_CHILD_HEALTHPLAN'
export const SUCCESS_UPDATE_CHILD_HEALTHPLAN = 'SUCCESS_UPDATE_CHILD_HEALTHPLAN'
export const FAILURE_UPDATE_CHILD_HEALTHPLAN = 'FAILURE_UPDATE_CHILD_HEALTHPLAN'

interface UpdateChildrenActionType {
  type: typeof UPDATE_CHILDREN;
}

export interface SuccessUpdateChildrenActionType {
  type: typeof SUCCESS_UPDATE_CHILDREN;
}

interface FailureUpdateChildrenActionType {
  type: typeof FAILURE_UPDATE_CHILDREN;
  error: TheiaError;
}

interface GetChildrenDataActionType {
  type: typeof GET_CHILDREN_DATA;
}

interface SuccessGetChildrenDataActionType {
  type: typeof SUCCESS_GET_CHILDREN_DATA;
  childrenData: BabyData[];
}

interface FailureGetChildrenDataActionType {
  type: typeof FAILURE_GET_CHILDREN_DATA;
  error?: TheiaError;
}

interface GetChildrenSummaryActionType {
  type: typeof GET_CHILDREN_SUMMARY;
}

interface SuccessGetChildrenSummaryActionType {
  type: typeof SUCCESS_GET_CHILDREN_SUMMARY;
  childrenSummary?: ChildrenSummary;
}

interface FailureGetChildrenSummaryActionType {
  type: typeof FAILURE_GET_CHILDREN_SUMMARY;
}

interface SuppressPediatricWelcomeModalActionType {
  type: typeof SUPPRESS_PEDIATRIC_WELCOME_MODAL;
}

interface ClearUpdateChildrenStatusActionType {
  type: typeof CLEAR_UPDATE_CHILDREN_STATUS;
}

interface ShowPediatricWelcomeModalActionType {
  type: typeof SHOW_PEDIATRIC_WELCOME_MODAL
}

interface UpdateChildHealthPlanActionType {
  type: typeof UPDATE_CHILD_HEALTHPLAN;
}

export interface SuccessUpdateChildHealthPlanActionType {
  type: typeof SUCCESS_UPDATE_CHILD_HEALTHPLAN;
  healthInsurancePlanStatus: HealthInsurancePlanStatus;
  childData: BabyData;
}

interface FailureUpdateChildHealthPlanActionType {
  type: typeof FAILURE_UPDATE_CHILD_HEALTHPLAN;
  error: TheiaError;
}

export type PediatricFlowTypes = UpdateChildrenActionType
| SuccessUpdateChildrenActionType
| FailureUpdateChildrenActionType
| GetChildrenDataActionType
| SuccessGetChildrenDataActionType
| FailureGetChildrenDataActionType
| GetChildrenSummaryActionType
| SuccessGetChildrenSummaryActionType
| FailureGetChildrenSummaryActionType
| SuppressPediatricWelcomeModalActionType
| ClearUpdateChildrenStatusActionType
| ShowPediatricWelcomeModalActionType
| UpdateChildHealthPlanActionType
| SuccessUpdateChildHealthPlanActionType
| FailureUpdateChildHealthPlanActionType

export function getChildrenDataAction():
  AsyncAction<{}, {
    pediatricFlowService: PediatricFlowService
  }> {
  return async (dispatch, _getState, { pediatricFlowService }) => {
    dispatch({ type: GET_CHILDREN_DATA })
    try {
      const childrenData = await pediatricFlowService.getChildrenData()
      dispatch({ type: SUCCESS_GET_CHILDREN_DATA, childrenData })
    } catch (error) {
      if (error instanceof TheiaError) {
        dispatch({ type: FAILURE_GET_CHILDREN_DATA, error })
        Sentry.captureException(new Error(`Failure to get children data - ${error.message} - ${error.friendlyMessage} - ${error.friendlyMessageTitle}`))
      }
    }
  }
}

export function getChildrenSummaryAction():
  AsyncAction<{}, { pediatricFlowService: PediatricFlowService }> {
  return async (dispatch, _getState, { pediatricFlowService }) => {
    dispatch({ type: GET_CHILDREN_SUMMARY })
    try {
      const childrenSummary = await pediatricFlowService.getChildrenSummary()
      dispatch({ type: SUCCESS_GET_CHILDREN_SUMMARY, childrenSummary })
    } catch (error) {
      dispatch({ type: FAILURE_GET_CHILDREN_SUMMARY })
      if (error instanceof TheiaError && error.statusCode !== '404') {
        Sentry.captureException(new Error(`Failure to get children infos - ${error.message}`))
      }
    }
  }
}

export function updateChildrenAction(babiesData: BabyData[]):
  AsyncAction<{}, { pediatricFlowService: PediatricFlowService }> {
  return async (dispatch, _getState, { pediatricFlowService }) => {
    dispatch({ type: UPDATE_CHILDREN })
    try {
      await pediatricFlowService.updateChildren(babiesData)
      dispatch({ type: SUCCESS_UPDATE_CHILDREN })
      dispatch(showNotificationAction({
        message: NotificationMessage.DATA_UPDATED_SUCCESSFULLY,
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_UPDATE_CHILDREN',
        type: ToastType.SUCCESS
      }))
      dispatch(getChildrenDataAction())
      dispatch(getChildrenSummaryAction())
    } catch (error) {
      if (error instanceof TheiaError) {
        dispatch({
          type: FAILURE_UPDATE_CHILDREN,
          error
        })
        dispatch(showNotificationAction({
          message: error.friendlyMessage
            || error.friendlyMessageTitle
            || NotificationMessage.ERROR_TRY_AGAIN,
          iconColor: errorColor,
          iconType: 'icon-DangerTriangleLight',
          timer: 5000,
          id: 'FAILURE_UPDATE_CHILDREN',
          type: ToastType.ERROR
        }))
        Sentry.captureException(new Error(`Failure to update children data - ${error.message} - ${error.friendlyMessage} - ${error.friendlyMessageTitle}`))
      }
    }
  }
}

export function updateChildHealthPlanAction(childData: BabyData):
  AsyncAction<{}, { pediatricFlowService: PediatricFlowService }> {
  return async (dispatch, _getState, { pediatricFlowService }) => {
    dispatch({ type: UPDATE_CHILD_HEALTHPLAN })
    const payload = {
      id: childData.id,
      companyId: childData.companyId,
      memberId: childData.memberId,
      planId: childData.planId,
      healthInsurancePlanStatus: childData.healthInsurancePlanStatus
    }
    try {
      await pediatricFlowService.updateChildren([payload])
      dispatch({
        type: SUCCESS_UPDATE_CHILD_HEALTHPLAN,
        healthInsurancePlanStatus: childData.healthInsurancePlanStatus,
        childData
      })
      eventTrack('Convenio criança cadastrado com sucesso', {
        category: EventCategories.PEDIATRIC_SCHEDULE,
        'Id criança': childData.id,
        Status: childData?.healthInsurancePlanStatus,
        'Nome do convenio': childData?.companyName || '',
        'Nome do plano': childData?.planName || ''
      })
      dispatch(showNotificationAction({
        message: havePlan(childData.healthInsurancePlanStatus)
          ? NotificationMessage.HEALTHPLAN_REGISTERED_SUCCESSFULLY
          : NotificationMessage.DATA_UPDATED_SUCCESSFULLY,
        iconType: 'icon-TickRoundLight',
        iconColor: success,
        timer: 5000,
        id: 'SUCCESS_UPDATE_CHILD_HEALTHPLAN',
        type: ToastType.SUCCESS
      }))
      dispatch(getChildrenDataAction())
      dispatch(getChildrenSummaryAction())
    } catch (error) {
      if (error instanceof TheiaError) {
        dispatch({
          type: FAILURE_UPDATE_CHILD_HEALTHPLAN,
          error
        })
        dispatch(showNotificationAction({
          message: error.friendlyMessage
            || error.friendlyMessageTitle
            || NotificationMessage.ERROR_TRY_AGAIN,
          iconColor: errorColor,
          iconType: 'icon-DangerTriangleLight',
          timer: 5000,
          id: 'FAILURE_UPDATE_CHILD_HEALTHPLAN',
          type: ToastType.ERROR
        }))
        Sentry.captureException(new Error(`Failure to update child health plan - ${error.message}`))
        eventTrack('Erro ao cadastrar convenio criança', {
          category: EventCategories.PEDIATRIC_SCHEDULE,
          'Id criança': childData.id,
          Status: childData?.healthInsurancePlanStatus,
          'Nome do convenio': childData?.companyName || '',
          'Nome do plano': childData?.planName || '',
          'Motivo do erro': error.friendlyMessage || error.statusCode
        })
      }
    }
  }
}

export const suppressPediatricWelcomeModalAction = (): SuppressPediatricWelcomeModalActionType => ({
  type: SUPPRESS_PEDIATRIC_WELCOME_MODAL
})

export const clearUpdateChildrenStatusAction = (): ClearUpdateChildrenStatusActionType => ({
  type: CLEAR_UPDATE_CHILDREN_STATUS
})

export const showPediatricWelcomeModalAction = (): ShowPediatricWelcomeModalActionType => ({
  type: SHOW_PEDIATRIC_WELCOME_MODAL
})
