import React, { useMemo } from 'react'

import { Reason } from '../../domain/Booking'
import { separateInGroups } from '../../utils/helpers'
import './ReasonSelection.scss'

interface ToogleProps {
  onClick: () => void;
  children: React.ReactNode;
  active: boolean;
}

function ToggleOption({ onClick, children, active }: ToogleProps) {
  return (
    <button
      type="button"
      className={`reason-selection__option-button focus:outline-none ${active ? 'reason-selection__option-button--active' : ''}`}
      onClick={onClick}
      data-testid="reason-selection__option-button"
    >
      {children}
    </button>
  )
}

interface Props {
  reasons: Array<Reason>;
  toggleReason: (reason: Reason) => void;
  otherMotive: string;
  updateOtherMotive: (otherMotive: string) => void;
}

export default function ReasonSelection({
  reasons, toggleReason, otherMotive, updateOtherMotive
}: Props) {
  const otherReason = useMemo(() => reasons.find((r) => r.name === 'Other'), [reasons])

  function onOtherMotiveChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    updateOtherMotive(event.currentTarget.value)
  }

  return (
    <div>
      <div className="flex flex-col justify-center pt-2 pb-4">
        {separateInGroups(reasons, 2).map((pair, index) => (
          <div key={index} className="flex">
            {pair.map((reason) => (
              <ToggleOption
                key={reason.id}
                onClick={() => toggleReason(reason)}
                active={reason.selected}
              >{reason.description}
              </ToggleOption>
            ))}
          </div>
        ))}
        {otherReason && otherReason.selected && (
          <>
            <p className="reason-selection__talk-about">Fale mais sobre isso:</p>
            <textarea
              value={otherMotive}
              onChange={onOtherMotiveChange}
              className="reason-selection__textarea focus:outline-none"
              data-testid="reason-selection__textarea"
            />
          </>
        )}
      </div>
    </div>
  )
}
