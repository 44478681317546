import {
  FAILURE_FETCH_SPECIALIST,
  SUCCESS_FETCH_SPECIALIST,
  FETCH_SPECIALIST,
  GET_AVAILABLE_SPECIALISTS,
  SUCCESS_GET_AVAILABLE_SPECIALISTS,
  FAILURE_GET_AVAILABLE_SPECIALISTS,
  GET_SPECIALIST_DETAILS,
  SUCCESS_GET_SPECIALIST_DETAILS,
  FAILURE_GET_SPECIALIST_DETAILS,
  SET_FILTERED_SPECIALISTS,
  SpecialistTypes,
} from './actions'
import {
  Specialist, SpecialistDetails, SpecialistFromList
} from '../../domain/Specialist'
import { TheiaError } from '../../domain/errors/TheiaError'
import { CLEAR_SPECIALIST, ScheduleTypes } from '../scheduleFlow/actions';

type SpecialistData = {
  specialist?: Specialist;
  isFetching: boolean;
}

export interface SpecialistState {
  byIdentity: {
    [identity: string]: SpecialistData;
  };
  isGettingSpecialists: boolean;
  availableSpecialists?: SpecialistFromList[];
  specialistsError?: TheiaError;
  specialistDetails?: SpecialistDetails;
  isGettingSpecialistDetails: boolean;
  specialistDetailsError?: TheiaError;
  filteredSpecialists?: SpecialistFromList[];
}

const initialState: SpecialistState = {
  byIdentity: {},
  isGettingSpecialists: false,
  availableSpecialists: undefined,
  specialistsError: undefined,
  specialistDetails: undefined,
  isGettingSpecialistDetails: false,
  specialistDetailsError: undefined,
  filteredSpecialists: undefined,
}

export function specialists(
  state: SpecialistState = initialState,
  action: SpecialistTypes | ScheduleTypes
): SpecialistState {
  switch (action.type) {
    case FETCH_SPECIALIST:
      return {
        ...state,
        byIdentity: {
          ...state.byIdentity,
          [action.identity]: { isFetching: true }
        },
      }
    case SUCCESS_FETCH_SPECIALIST:
      return {
        ...state,
        byIdentity: {
          ...state.byIdentity,
          [action.identity]: { isFetching: false, specialist: action.specialist }
        },
      }
    case FAILURE_FETCH_SPECIALIST:
      return {
        ...state,
        byIdentity: {
          ...state.byIdentity,
          [action.identity]: { isFetching: false }
        },
      }
    case GET_AVAILABLE_SPECIALISTS: {
      return {
        ...state,
        isGettingSpecialists: true,
        availableSpecialists: undefined,
        specialistsError: undefined
      }
    }
    case SUCCESS_GET_AVAILABLE_SPECIALISTS: {
      return {
        ...state,
        isGettingSpecialists: false,
        availableSpecialists: action.availableSpecialists
      }
    }
    case FAILURE_GET_AVAILABLE_SPECIALISTS: {
      return {
        ...state,
        isGettingSpecialists: false,
        specialistsError: action.error
      }
    }
    case GET_SPECIALIST_DETAILS: {
      return {
        ...state,
        isGettingSpecialistDetails: true,
        specialistDetails: undefined,
        specialistDetailsError: undefined
      }
    }
    case SUCCESS_GET_SPECIALIST_DETAILS: {
      return {
        ...state,
        isGettingSpecialistDetails: false,
        specialistDetails: action.specialistDetails
      }
    }
    case FAILURE_GET_SPECIALIST_DETAILS: {
      return {
        ...state,
        isGettingSpecialistDetails: false,
        specialistDetailsError: action.error
      }
    }
    case SET_FILTERED_SPECIALISTS: {
      return {
        ...state,
        filteredSpecialists: action.filteredSpecialists,
      }
    }
    case CLEAR_SPECIALIST: {
      return {
        ...state,
        filteredSpecialists: undefined,
      }
    }
    default:
      return state
  }
}
