import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  RegularButton,
  ListItemCard,
  Loader,
  ButtonOptions,
  Divider,
  IconButton
} from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { AppDispatch } from '../../state/utils'
import {
  ProfileForm,
} from '../../domain/Profile'
import LocationImg from '../../../assets/Location.svg'
import ProfileImg from '../../../assets/id-document.svg'
import { AppState } from '../../apps/main/store'
import {
  fetchProfileAction,
  onDeletePictureProfileAction,
  onUploadPictureProfileAction
} from '../../state/profile/actions'
import {
  clearLogoutFailedAction,
  logoutAction,
} from '../../state/authentication/main/actions'
import { User } from '../../domain/User'
import EditImageWithCrop from '../common/molecules/EditImageWithCrop'
import {
  bgBox,
  error,
  primary,
  success,
  textSecondary
} from '../../color'
import HealthPlanProfileCard from './healthplan/HealthPlanProfileCard'
import PaymentCardProfile from './payment/PaymentCardProfile'
import { eventPage, resetUser } from '../../../eventGenerate'
import UserInfo from './UserInfo'
import './Profile.scss'
import {
  EDIT_ADDRESS,
  EDIT_DEPENDENT,
  EDIT_PROFILE,
  PROFILE
} from '../../routes/RoutesConstants'
import { clearNotificationAction, showNotificationAction } from '../../state/notifications/actions'
import { ToastInfos, ToastType } from '../../domain/Notifications'
import { ActiveProfileType, EventProfileType } from '../../domain/AppProfiles'
import DependentHealthplanCard from './healthplan/DependentHealthplanCard'
import { StatusType } from '../../domain/Status'
import ProfilePictureEdit from '../common/molecules/ProfilePictureEdit'
import AppHeader from '../common/AppHeader'
import { PicturePlaceholder } from '../common/molecules/ProfilePicture'

interface Props {
  currentUser?: User;
  forceFetchProfile: boolean;
  isFetchingProfile: boolean;
  profile?: ProfileForm;
  isLogoutFailed: boolean;
  isUploadingPictureProfile: boolean;
  errorUploadingPictureProfile: boolean;
  isDeletingPictureProfile: boolean;
  errorDeletingPictureProfile: boolean;
  isFetchingUser: boolean;
  isPictureProfileUploaded: boolean;
  isProfilePictureDeleted: boolean;
  activeProfile?: ActiveProfileType
  isDependent: boolean
  getChildrenDataStatus: StatusType;
  profileType?: EventProfileType
  logout: () => void;
  fetchProfile: () => void;
  clearLogoutFailed: () => void;
  onUploadPictureProfile: (file: File) => void;
  onDeletePictureProfile: (file: string | null) => void;
  showNotification: (toastInfos: ToastInfos) => void;
  clearNotification: (id: string) => void;
}

export interface ProfileLocationProps {
  isFromFeedbackChangePlan?: boolean;
  hasPreviousInsurancePlan?: boolean;
  isFromDeleteHealthPlan?: boolean;
  isFromDeleteCardPayment?: boolean;
  isFromUpdateCardPayment?: boolean;
  alreadyHasCardAdded?: boolean;
}

interface ProfileHeaderUserInfos {
  name: string
  email: string
  pictureUrl?: string | null
  picturePlaceholder?: PicturePlaceholder
}

function Profile({
  currentUser,
  profile,
  isFetchingProfile,
  forceFetchProfile,
  isLogoutFailed,
  isUploadingPictureProfile,
  errorUploadingPictureProfile,
  isDeletingPictureProfile,
  errorDeletingPictureProfile,
  isFetchingUser,
  isPictureProfileUploaded,
  isProfilePictureDeleted,
  activeProfile,
  isDependent,
  getChildrenDataStatus,
  profileType,
  logout,
  fetchProfile,
  clearLogoutFailed,
  onUploadPictureProfile,
  onDeletePictureProfile,
  showNotification,
  clearNotification,
}: Props) {
  const history = useHistory()
  const location = useLocation().state as ProfileLocationProps
  const [buttonDrawerIsVisible, setButtonDrawerIsVisible] = useState(false)
  const [showHeaderButtons, setShowHeaderButtons] = useState(true)
  const [isCropOpen, setIsCropOpen] = useState(false)
  const [userInfos, setUserInfos] = useState<ProfileHeaderUserInfos>({ name: '', email: '' })
  const hasPreviousInsurancePlan = location?.hasPreviousInsurancePlan || false
  const isFromDeleteHealthPlan = location?.isFromDeleteHealthPlan || false
  const isFromFeedbackChangePlan = location?.isFromFeedbackChangePlan || false
  const isFromDeleteCardPayment = location?.isFromDeleteCardPayment || false
  const isFromUpdateCardPayment = location?.isFromUpdateCardPayment || false
  const alreadyHasCardAdded = location?.alreadyHasCardAdded || false

  useEffect(() => {
    eventPage('perfil', 'Perfil', { tipo_de_perfil: profileType })
    if (isFromFeedbackChangePlan) {
      showNotification({
        message: `Tudo certo! Seu plano de saúde foi ${hasPreviousInsurancePlan ? 'atualizado' : 'adicionado'} com sucesso.`,
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_UPDATE_HEALTH_PLAN',
        type: ToastType.SUCCESS,
      })
    }
    if (isFromUpdateCardPayment) {
      showNotification({
        message: `Cartão ${alreadyHasCardAdded ? 'alterado' : 'adicionado'} com sucesso.`,
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_CHANGE_CARD_PAYMENT',
        type: ToastType.SUCCESS,
      })
    }
    if (isFromDeleteCardPayment) {
      showNotification({
        message: 'Cartão excluido com sucesso!',
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_DELETE_CARD_PAYMENT',
        type: ToastType.SUCCESS,
      })
    }
    if (isFromDeleteHealthPlan) {
      showNotification({
        message: 'Plano de saúde excluído com sucesso',
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_DELETE_HEALTH_PLAN',
        type: ToastType.SUCCESS,
      })
    }
    history.replace(PROFILE)
  }, [location])

  useEffect(() => {
    if (isDeletingPictureProfile) {
      showNotification({
        message: 'Deletando foto...',
        iconColor: bgBox,
        iconType: 'icon-LoadingLight',
        timer: 5000,
        id: 'DELETING_PROFILE_PHOTO',
        type: ToastType.INFO,
      })
    }
    if (isProfilePictureDeleted) {
      clearNotification('DELETING_PROFILE_PHOTO')
      showNotification({
        message: 'Foto deletada com sucesso!',
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_UPDATE_PROFILE_PHOTO',
        type: ToastType.SUCCESS,
      })
    }
    if (errorDeletingPictureProfile) {
      clearNotification('DELETING_PROFILE_PHOTO')
      showNotification({
        message: 'Ops! Tivemos um problema para atualizar a sua foto. Tente novamente.',
        iconColor: error,
        iconType: 'icon-DangerCircleLight',
        timer: 5000,
        id: 'FAIL_UPDATING_PICTURE_PROFILE',
        type: ToastType.SUCCESS,
      })
    }
  }, [isDeletingPictureProfile, isProfilePictureDeleted, errorDeletingPictureProfile])

  useEffect(() => {
    if (isUploadingPictureProfile) {
      showNotification({
        message: 'Atualizando foto...',
        iconColor: bgBox,
        iconType: 'icon-LoadingLight',
        timer: 5000,
        id: 'UPLOADING_PROFILE_PHOTO',
        type: ToastType.INFO,
      })
    }
    if (isPictureProfileUploaded) {
      clearNotification('UPLOADING_PROFILE_PHOTO')
      showNotification({
        message: 'Foto atualizada com sucesso!',
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_UPDATE_PROFILE_PHOTO',
        type: ToastType.SUCCESS,
      })
    }
    if (errorUploadingPictureProfile) {
      clearNotification('UPLOADING_PROFILE_PHOTO')
      showNotification({
        message: 'Ops! Tivemos um problema para atualizar a sua foto. Tente novamente.',
        iconColor: error,
        iconType: 'icon-DangerCircleLight',
        timer: 5000,
        id: 'FAIL_UPDATING_PICTURE_PROFILE',
        type: ToastType.SUCCESS,
      })
    }
  }, [isUploadingPictureProfile, isPictureProfileUploaded, errorUploadingPictureProfile])

  const closeButtonDrawer = () => {
    setButtonDrawerIsVisible(false)
  }

  const openButtonDrawer = () => {
    setButtonDrawerIsVisible(true)
  }

  useEffect(() => {
    if ((!isFetchingProfile && !profile)
    || (!isFetchingProfile && forceFetchProfile)
    ) {
      fetchProfile()
    }
  }, [forceFetchProfile])

  useEffect(() => {
    if (isLogoutFailed) {
      setTimeout(() => clearLogoutFailed(), 8000)
    }
  }, [isLogoutFailed])

  useEffect(() => {
    let infos: ProfileHeaderUserInfos = { name: '', email: '' }
    if (currentUser && activeProfile) {
      if (isDependent) {
        infos = {
          name: activeProfile?.name || '',
          email: 'Dependente',
          pictureUrl: activeProfile?.imageUrl || null,
          picturePlaceholder: PicturePlaceholder.BABY
        }
      } else {
        infos = {
          name: currentUser?.name,
          email: currentUser?.email,
          pictureUrl: currentUser?.pictureUrl || null,
          picturePlaceholder: PicturePlaceholder.USER
        }
      }
    }
    setUserInfos(infos)
  }, [currentUser?.id, activeProfile?.id])

  function onLogout() {
    logout()
    resetUser()
  }

  if (!currentUser) return null

  if (isFetchingProfile || isFetchingUser || getChildrenDataStatus.isLoading) {
    return (
      <div className="flex justify-center h-screen w-full">
        <div className="flex items-center h-full">
          <Loader />
        </div>
      </div>
    )
  }

  const onEditProfile = () => {
    if (isDependent) {
      history.push(EDIT_DEPENDENT)
    } else {
      history.push(EDIT_PROFILE)
    }
  }

  const onEditAddress = () => {
    history.push(EDIT_ADDRESS)
  }

  const hideButtonModal = () => {
    setIsCropOpen(!isCropOpen)
  }

  const closeButtonModal = () => {
    setIsCropOpen(false)
    setButtonDrawerIsVisible(false)
  }

  const deleteProfilePicture = (pictureUrl?: string | null) => {
    onDeletePictureProfile(pictureUrl || null)
    closeButtonModal()
  }

  const fullAddress = `${profile?.addressName}${profile?.number !== '' ? `, ${profile?.number}` : ''}`

  return (
    <div className="profile-view">
      <AppHeader title="Perfil" />
      <div className="profile-view-grid-container">
        <div className="profile-view-item-a">
          <div className="profile-card-container-item-a mb-4">
            <UserInfo
              profileImage={userInfos?.pictureUrl}
              profileEmail={userInfos.email}
              profileName={userInfos.name}
              isLoading={isUploadingPictureProfile || isDeletingPictureProfile}
              isFetchingProfile={isFetchingProfile || getChildrenDataStatus.isLoading}
              picturePlaceholder={userInfos?.picturePlaceholder}
              rightComponent={(
                <IconButton
                  iconType={showHeaderButtons ? 'icon-ArrowDown2Light' : 'icon-ArrowUp2Light'}
                  onClick={() => setShowHeaderButtons(!showHeaderButtons)}
                  variant="form-icon"
                  iconColor={textSecondary}
                  iconSize="24px"
                />
              )}
            />
            <div className="flex mt-5">
              {userInfos.pictureUrl === null && !isDependent && showHeaderButtons && (
                <EditImageWithCrop
                  id="photo"
                  onUpload={onUploadPictureProfile}
                  buttonLabel="Adicionar foto"
                  hideButtonModal={hideButtonModal}
                  closeButtonModal={closeButtonModal}
                  variant="subtle"
                  className="mb-4"
                />
              )}
              {userInfos.pictureUrl !== null && !isDependent && showHeaderButtons && (
                <ButtonOptions
                  buttonToAnchor={(
                    <RegularButton variant="subtle" label="Trocar foto" extraClass="button-photo" onClick={openButtonDrawer} />
                )}
                  visible={buttonDrawerIsVisible}
                  onClose={closeButtonDrawer}
                  headerText="Opções de ações para a edição de fotos"
                  isFromCrop={isCropOpen}
                  extraClass="btn-group-photo-edit"
                >
                  <EditImageWithCrop
                    id="photo"
                    onUpload={onUploadPictureProfile}
                    buttonLabel="Carregar foto"
                    isFromModal
                    hideButtonModal={hideButtonModal}
                    closeButtonModal={closeButtonModal}
                    iconColor={textSecondary}
                  />
                  <ListItemCard
                    title="Deletar foto atual"
                    ariaLabel="Deletar foto atual"
                    onClick={() => deleteProfilePicture(userInfos.pictureUrl)}
                    iconType="icon-TrashLight"
                    iconColor={error}
                    arrowColor={textSecondary}
                    extraClassNameContainer="item-from-btn-group"
                    extraClassClickableContainer="last-item-border-radius"
                    isLastItem
                  />
                </ButtonOptions>
              )}
              {showHeaderButtons && (
                <RegularButton
                  variant="subtle"
                  label="Editar perfil"
                  extraClass="button-photo ml-2"
                  onClick={onEditProfile}
                />
              )}
            </div>
          </div>
          <Divider className="mb-6 mt-4 lg:hidden" />
          <h1 className="text-fontSmall text-mediumDarkGreyColor mx-4 lg:mx-0">
            Dados do plano de saúde
          </h1>
          {isDependent ? (
            <DependentHealthplanCard />
          ) : (
            <HealthPlanProfileCard
              isFetchingProfile={isFetchingProfile}
            />
          )}
          <h1 className="text-fontSmall text-mediumDarkGreyColor mx-4 lg:mx-0">
            Minha conta
          </h1>
          <ListItemCard
            extraClassNameContainer="profile-card personal-data list-item-card-no-btn"
            extraClassClickableContainer="list-item-container-no-btn"
            onClick={onEditProfile}
            picture={<span className="profile-icon"><ProfileImg /></span>}
            iconColor={primary}
            title="Dados pessoais"
            ariaLabel="edit-personal-data"
            alertMessage={currentUser.document === null && !isDependent ? 'Completar dados' : ''}
            isLastItem
            isLoading={isFetchingProfile}
            isItemFromList={false}
            key="edit-profile"
          />
          {!isDependent && (
            <>
              <ListItemCard
                extraClassNameContainer="profile-card address list-item-card-no-btn"
                extraClassClickableContainer="list-item-container-no-btn"
                onClick={onEditAddress}
                picture={<span className="profile-icon"><LocationImg /></span>}
                iconColor={primary}
                caption={profile?.addressName ? fullAddress : ''}
                title="Endereço"
                ariaLabel="edit-address"
                alertMessage={profile?.address === null ? 'Completar dados' : ''}
                isLastItem
                isLoading={isFetchingProfile}
                isItemFromList={false}
                key="edit-address"
              />
              <PaymentCardProfile />
            </>
          )}
          <div className="logout mb-10">
            <p className="logout-failed-message">{isLogoutFailed ? 'Desculpe! Algo deu errado. Tente novamente.' : ''}</p>
            <RegularButton
              iconType="icon-LogoutLight"
              onClick={onLogout}
              label="Sair"
              variant="text"
              iconSize="30px"
              width="auto"
              extraClass="logout-btn"
            />
          </div>
        </div>
        <div className="profile-view-item-b">
          <div className="flex-none mt-8 mb-5">
            <ProfilePictureEdit showEditPhotoButtons />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  profile, authentication, payment, appProfiles, pediatricFlow
}: AppState) => ({
  currentUser: authentication.currentUser,
  profile: profile.profile,
  isFetchingProfile: profile.isFetchingProfile,
  forceFetchProfile: profile.forceFetchProfile,
  isLogoutFailed: authentication.isLogoutFailed,
  planType: authentication.currentUser?.plan?.planType,
  isUploadingPictureProfile: profile.isUploadingPictureProfile,
  errorUploadingPictureProfile: profile.errorUploadingPictureProfile,
  isDeletingPictureProfile: profile.isDeletingPictureProfile,
  errorDeletingPictureProfile: profile.errorDeletingPictureProfile,
  isSuccessDeletedCardPayment: payment.isSuccessDeleted,
  isPictureProfileUploaded: profile.isPictureProfileUploaded,
  isProfilePictureDeleted: profile.isProfilePictureDeleted,
  forceUpdateCurrentUser: authentication.forceUpdateCurrentUser,
  isFetchingUser: authentication.isFetchingUser,
  activeProfile: appProfiles.activeProfile,
  isDependent: appProfiles.isDependent,
  getChildrenDataStatus: pediatricFlow.getChildrenDataStatus,
  profileType: appProfiles.profileType
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  logout: logoutAction,
  fetchProfile: fetchProfileAction,
  clearLogoutFailed: clearLogoutFailedAction,
  onUploadPictureProfile: onUploadPictureProfileAction,
  onDeletePictureProfile: onDeletePictureProfileAction,
  showNotification: showNotificationAction,
  clearNotification: clearNotificationAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
