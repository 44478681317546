import React from 'react'
import { Alert, BadgeNotification, FontIcon } from 'theia-web-ds'
import { error } from '../../../color'
import './NavItem.scss'

type MenuItemProps = {
  text: string;
  isActive: boolean;
  handleClick: () => void;
  variant?: 'sidebar' | 'bottombar' | 'tab';
  iconType?: string;
  className?: string;
  activeIconType?: string;
  hasNotification?: boolean;
  hasNotificationOnIcon?: boolean;
  disabled?: boolean;
  tabTooltipMessage?: string;
}

function NavItem({
  text,
  isActive,
  handleClick,
  variant = 'sidebar',
  iconType,
  className,
  activeIconType,
  hasNotification,
  hasNotificationOnIcon,
  disabled,
  tabTooltipMessage
}: MenuItemProps) {
  return (
    <li key={text} className={className}>
      <button
        disabled={disabled}
        role="menuitem"
        id={text}
        onClick={handleClick}
        type="button"
        aria-label={text}
        className={`menu-item ${variant} ${isActive ? 'text-primary nav-item-active' : 'text-textPrimary'} focus:outline-none items-center`}
      >
        {!hasNotification && !hasNotificationOnIcon && activeIconType && iconType && (
          <FontIcon
            iconType={isActive ? activeIconType : iconType}
            fontSize="24px"
          />
        )}
        {!hasNotification && hasNotificationOnIcon && activeIconType && iconType && (
          <div className="relative">
            <FontIcon iconType={isActive ? activeIconType : iconType} fontSize="24px" />
            <BadgeNotification
              color={error}
              width={8}
              height={8}
              borderRadius="50%"
              extraClass="badge-outline absolute right-0"
            />
          </div>
        )}
        <div className="nav-item-text">
          {text}
          {(hasNotification && !hasNotificationOnIcon) && (
            <BadgeNotification
              color={error}
              width={8}
              height={8}
              borderRadius="50%"
              extraClass="badge-outline"
            />
          )}
        </div>
        {tabTooltipMessage && (
          <Alert
            message={tabTooltipMessage}
            extraClass="nav-item-tooltip-message"
            variant="flowting"
            indicator="right-top"
            timer={10000}
          />
        )}
      </button>
    </li>
  )
}

export default NavItem
