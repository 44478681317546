import React, { useState } from 'react'
import {
  ButtonOptions,
  FontIcon,
  IconButton,
  ListItemCard,
  NewListItem
} from 'theia-web-ds'
import moment from 'moment'
import {
  greyColor,
  primary,
  textSecondary,
  warning
} from '../../../color'
import ExamsIcon from '../../../../assets/exams.png'
import ExamsDisabled from '../../../../assets/exams-disabled.png'
import { transformBytes } from '../../../utils/helpers'
import './ExamCard.scss'

interface Props {
  fileName: string
  fileSize?: number
  uploadProgress?: number
  hasError?: boolean
  ownerName?: string
  uploadDate?: number
  onCancelUpload?: (fileName: string) => void
  onClickTryAgain?: (fileName: string) => void
  onClickDeleteExam?: () => void
  onClickExamOptions?: (showOptions: boolean) => void
  onClickDownloadExam?: () => void
}

function ExamCard({
  fileName,
  fileSize,
  uploadProgress,
  hasError,
  ownerName,
  uploadDate,
  onCancelUpload,
  onClickTryAgain,
  onClickDeleteExam,
  onClickExamOptions,
  onClickDownloadExam
}: Props) {
  const [examOptionsVisible, setExamOptionsVisible] = useState(false)

  function handleClickExamOptions(showOptions: boolean) {
    if (onClickExamOptions) onClickExamOptions(showOptions)
    setExamOptionsVisible(showOptions)
  }

  function handleClickDownloadExam() {
    setExamOptionsVisible(false)
    if (onClickDownloadExam) onClickDownloadExam()
  }

  function handleClickDeleteExam() {
    if (onClickDeleteExam) onClickDeleteExam()
    setExamOptionsVisible(false)
  }

  return (
    <div className="exam-card__item">
      <NewListItem
        imageIconBadgeComponent={hasError ? (
          <FontIcon
            iconType="icon-DangerTriangleLight"
            color={warning}
            fontSize="24px"
            extraClass="pr-5 pl-4"
          />
        ) : (
          <img
            src={uploadProgress ? ExamsDisabled : ExamsIcon}
            alt="ícone-exame"
            width={40}
            height={40}
            className="mr-5"
          />
        )}
        title={fileName}
        ariaLabel="exam-card"
        caption={fileSize && fileSize !== null && fileSize !== undefined ? transformBytes(fileSize) : `Exame de ${ownerName}`}
        extraButton={uploadProgress ? (
          <div className="flex w-full items-center justify-between gap-4">
            <div style={{ backgroundColor: greyColor, height: '2px' }} className="flex-1">
              <div
                style={{
                  backgroundColor: primary,
                  width: `${uploadProgress}%`,
                  height: '2px'
                }}
              />
            </div>
            <p className="text-bodyMedium text-center text-textSecondary">
              {uploadProgress}%
            </p>
          </div>
        ) : (
          <p className="extra-caption">
            Enviado dia {moment(uploadDate).format('DD/MM/YY')}
          </p>
        )}
        id={fileName}
        extraComponent={(
          <div className="upload-exam-card__btn">
            {hasError && (
              <IconButton
                variant="form-icon"
                iconType="icon-RefreshLight"
                color={textSecondary}
                iconSize="24px"
                height="54px"
                width="54px"
                onClick={() => {
                  if (onClickTryAgain) onClickTryAgain(fileName)
                }}
                extraClass="try-again__btn pr-3"
              />
            )}
            {uploadProgress && (
              <IconButton
                variant="form-icon"
                iconType="icon-CloseLight"
                color={textSecondary}
                iconSize="24px"
                height="54px"
                width="54px"
                onClick={() => {
                  if (onCancelUpload) onCancelUpload(fileName)
                }}
                isDisabled={!!(uploadProgress && uploadProgress > 50)}
                extraClass="cancel-upload__btn pr-3"
              />
            )}
            {!hasError && !uploadProgress && (
              <ButtonOptions
                buttonToAnchor={(
                  <div className="exam-options-btn">
                    <IconButton
                      variant="text"
                      iconType="icon-MoreLight"
                      iconSize="24px"
                      onClick={() => handleClickExamOptions(true)}
                    />
                  </div>
                )}
                visible={examOptionsVisible}
                onClose={() => handleClickExamOptions(false)}
                headerText="Opções de exame"
                extraClass="new-anchor-group"
              >
                <ul>
                  <ListItemCard
                    title="Download"
                    ariaLabel="Download"
                    onClick={handleClickDownloadExam}
                    iconType="icon-DownloadLight"
                    iconColor={primary}
                    arrowColor={primary}
                    iconSize="24px"
                  />
                  <ListItemCard
                    title="Excluir"
                    ariaLabel="Excluir"
                    onClick={handleClickDeleteExam}
                    iconType="icon-TrashLight"
                    iconColor={primary}
                    arrowColor={primary}
                    iconSize="24px"
                    extraClassNameContainer="achor-exclude-btn"
                  />
                </ul>
              </ButtonOptions>
            )}
          </div>
        )}
      />
    </div>
  )
}

export default ExamCard
