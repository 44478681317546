import * as Sentry from '@sentry/browser'
import {
  AppProfilesService,
  AppProfileType,
  ProfilesTypes,
  ActiveProfileType,
  EventProfileType
} from '../../domain/AppProfiles'
import { TheiaError } from '../../domain/errors/TheiaError'
import { AsyncAction } from '../utils'
import { BabyData, HealthInsurancePlanStatus, PediatricFlowService } from '../../domain/PediatricFlow'
import { getChildrenDataAction } from '../pediatricFlow/actions'
import { AppState } from '../../apps/main/store'

export const SELECT_PROFILE = 'SELECT_PROFILE'
export const REMOVE_ACTIVE_PROFILE = 'REMOVE_ACTIVE_PROFILE'

export const GET_APP_PROFILES = 'GET_APP_PROFILES'
export const SUCCESS_GET_APP_PROFILES = 'SUCCESS_GET_APP_PROFILES'
export const FAILURE_GET_APP_PROFILES = 'FAILURE_GET_APP_PROFILES'

export const SAVE_DEPENDENT_DATA = 'SAVE_DEPENDENT_DATA'
export const SUCCESS_SAVE_DEPENDENT_DATA = 'SUCCESS_SAVE_DEPENDENT_DATA'
export const FAILURE_SAVE_DEPENDENT_DATA = 'FAILURE_SAVE_DEPENDENT_DATA'

export const PROMPT_CHOOSE_PROFILE_MODAL = 'PROMPT_CHOOSE_PROFILE_MODAL'
export const SUPPRESS_CHOOSE_PROFILE_MODAL = 'SUPPRESS_CHOOSE_PROFILE_MODAL'
export const CLEAR_SAVE_DEPENDENT_STATUS = 'CLEAR_SAVE_DEPENDENT_STATUS'

interface SelectProfileActionType {
  type: typeof SELECT_PROFILE
  activeProfile: ActiveProfileType
  isDependent: boolean
  profileType: EventProfileType
}

interface RemoveActiveProfileActionType {
  type: typeof REMOVE_ACTIVE_PROFILE
}

interface GetAppProfilesActionType {
  type: typeof GET_APP_PROFILES
}

interface SuccessGetAppProfilesActionType {
  type: typeof SUCCESS_GET_APP_PROFILES
  appProfiles: AppProfileType[]
}

interface FailureGetAppProfilesActionType {
  type: typeof FAILURE_GET_APP_PROFILES
  error: TheiaError
}

interface SaveDependentDataActionType {
  type: typeof SAVE_DEPENDENT_DATA
}

interface SuccessSaveDependentDataActionType {
  type: typeof SUCCESS_SAVE_DEPENDENT_DATA
}

interface FailureSaveDependentDataActionType {
  type: typeof FAILURE_SAVE_DEPENDENT_DATA
  error: TheiaError
}

interface PromptChooseProfileModalActionType {
  type: typeof PROMPT_CHOOSE_PROFILE_MODAL
}

interface SuppressChooseProfileModalActionType {
  type: typeof SUPPRESS_CHOOSE_PROFILE_MODAL
}

interface ClearSaveDependentStatusActionType {
  type: typeof CLEAR_SAVE_DEPENDENT_STATUS
}

export type AppProfilesTypes =
  | SelectProfileActionType
  | RemoveActiveProfileActionType
  | GetAppProfilesActionType
  | SuccessGetAppProfilesActionType
  | FailureGetAppProfilesActionType
  | SaveDependentDataActionType
  | SuccessSaveDependentDataActionType
  | FailureSaveDependentDataActionType
  | PromptChooseProfileModalActionType
  | SuppressChooseProfileModalActionType
  | ClearSaveDependentStatusActionType

export function selectProfileAction(profile: AppProfileType):
  AsyncAction<{}, {}> {
  return async (dispatch, getState) => {
    const state = getState() as AppState
    const { pediatricFlow, healthplan, authentication } = state
    const consultantHealthplan = healthplan.healthInsurancePlanData?.healthInsurancePlan
    const consultantData = authentication.currentUser
    const profileType = EventProfileType[profile.type]
    const isDependent = profile.type === ProfilesTypes.CHILD
    let selectedProfile: ActiveProfileType
    if (isDependent) {
      const childData = pediatricFlow.childrenData?.find(
        (child) => child.id === profile.id
      ) || undefined
      selectedProfile = {
        ...profile,
        ...childData,
      }
    } else {
      selectedProfile = {
        ...profile,
        companyName: consultantHealthplan?.company,
        companyId: consultantHealthplan?.companyId,
        planName: consultantHealthplan?.planName,
        planId: consultantHealthplan?.planId,
        memberId: consultantHealthplan?.memberId,
        birthDate: consultantData?.birthDate,
        document: consultantData?.document,
        email: consultantData?.email,
        healthInsurancePlanStatus: healthplan.healthInsurancePlanData?.hasHealthInsurancePlan
        && healthplan.healthInsurancePlanData.insurancePlanCovered
          ? HealthInsurancePlanStatus.HAVE_PLAN
          : HealthInsurancePlanStatus.DONT_HAVE_PLAN
      }
    }
    dispatch({
      type: SELECT_PROFILE,
      activeProfile: selectedProfile,
      isDependent,
      profileType
    })
  }
}

export function getAppProfilesAction():
  AsyncAction<{}, { appProfilesService: AppProfilesService }> {
  return async (dispatch, _getState, { appProfilesService }) => {
    dispatch({ type: GET_APP_PROFILES })
    try {
      const appProfiles: AppProfileType[] = await appProfilesService.getAppProfiles()
      dispatch({
        type: SUCCESS_GET_APP_PROFILES,
        appProfiles
      })
    } catch (error) {
      dispatch({ type: FAILURE_GET_APP_PROFILES, error })
      if (error instanceof TheiaError) {
        Sentry.captureException(new Error(`Failure to get app profiles - ${error.message}`))
      }
    }
  }
}

export function saveDependentDataAction(payload: BabyData):
  AsyncAction<{}, {
    pediatricFlowService: PediatricFlowService
    appProfilesService: AppProfilesService
  }> {
  return async (dispatch, _getState, { pediatricFlowService }) => {
    dispatch({ type: SAVE_DEPENDENT_DATA })
    try {
      const response = await pediatricFlowService.updateChildren([payload])
      dispatch({
        type: SUCCESS_SAVE_DEPENDENT_DATA,
        response
      })
      dispatch(getChildrenDataAction())
      dispatch(getAppProfilesAction())
    } catch (error) {
      dispatch({ type: FAILURE_SAVE_DEPENDENT_DATA, error })
      if (error instanceof TheiaError) {
        Sentry.captureException(new Error(`Failure to add new dependent - ${error.message}`))
      }
    }
  }
}

export const clearSaveDependentStatusAction = (): ClearSaveDependentStatusActionType => ({
  type: CLEAR_SAVE_DEPENDENT_STATUS
})

export const removeActiveProfileAction = (): RemoveActiveProfileActionType => ({
  type: REMOVE_ACTIVE_PROFILE
})

export const promptChooseProfileModalAction = (): PromptChooseProfileModalActionType => ({
  type: PROMPT_CHOOSE_PROFILE_MODAL
})

export const suppressChooseProfileModalAction = (): SuppressChooseProfileModalActionType => ({
  type: SUPPRESS_CHOOSE_PROFILE_MODAL
})
