import React from 'react'
import { SimpleCarousel } from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { WeekInfo } from '../../domain/WeekFlow'
import { isPregnant, isPreOrPosPregnancy } from '../../utils/helpers'
import { getFruitInfos } from './helpers'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { selectWeekAction } from '../../state/weekFlow/actions'
import { User } from '../../domain/User'
import './WeekCarousel.scss'

interface ItemProps {
  textItem: string
  isSelected: boolean
  isCurrentWeek: boolean
  onSelectWeek: (e: string) => void
  isFirstOrLast: boolean
}

function WeekItem({
  textItem,
  isSelected,
  isCurrentWeek,
  onSelectWeek,
  isFirstOrLast
}: ItemProps) {
  const normalWeek = isSelected ? 'Semana' : 'S'
  const caption = isFirstOrLast && isSelected ? 'Semanas' : normalWeek
  const fruitInfos = getFruitInfos(textItem)

  return (
    <button
      type="button"
      aria-label={`Semana ${textItem}. ${isCurrentWeek ? 'Você está aqui.' : ''}`}
      onClick={() => onSelectWeek(textItem)}
      className={`week-item-default
        ${isCurrentWeek ? 'week-item-purple' : 'week-item-grey'}
        ${isSelected ? 'expanded-week-item' : 'normal-week-item'}
      `}
    >
      {isCurrentWeek && isSelected && (
        <div className="current-week-container">
          <p className="current-week-caption">
            {caption}
          </p>
          <p className="current-week-text">Você está aqui</p>
        </div>
      )}
      <div className={`week-name-container ${isCurrentWeek && isSelected ? 'week-name-container-current' : 'week-name-container-not-current'}`}>
        <div className="week-name-text-container">
          {(!isCurrentWeek || !isSelected) && (
            <p className={`week-caption ${isCurrentWeek ? 'text-white' : 'text-textSecondary'}`}>{caption}</p>
          )}
          <p className={`week-name ${isCurrentWeek ? 'text-white' : 'text-textPrimary'}`}>
            {isFirstOrLast && !isSelected ? textItem.split('-')[0] : textItem}
          </p>
          {isSelected && fruitInfos && (
            <p className={`week-name-baby-size-text ${isCurrentWeek ? 'text-white' : 'text-textSecondary'}`}>
              {fruitInfos?.title}
            </p>
          )}
        </div>
        {isSelected && fruitInfos && (
          <div className="week-name-fruit-picture-container">
            <div className="week-name-size-picture">
              {fruitInfos?.picture}
            </div>
          </div>
        )}
      </div>
    </button>
  )
}

interface Props {
  weeks: string[]
  selectedWeek?: WeekInfo
  currentWeek?: WeekInfo
  currentUser?: User
  pregnancyJourney: boolean
  isDependent: boolean
  isFetchingWeekInfos: boolean
  onSelectWeek: (element: string) => void
  onScrollWeekCarousel?: () => void
}

function WeekCarousel({
  selectedWeek,
  currentWeek,
  weeks,
  currentUser,
  pregnancyJourney,
  isDependent,
  isFetchingWeekInfos,
  onSelectWeek,
  onScrollWeekCarousel
}: Props) {
  const initialSlide = selectedWeek?.weekName ? weeks.indexOf(selectedWeek?.weekName) : 0

  if (isDependent
    || !isPregnant(currentUser?.pregnancyMoment)
    || (!isFetchingWeekInfos && (
      weeks.length === 0
      || !selectedWeek
      || !pregnancyJourney
    ))) return null

  function renderWeek(element: string) {
    const isSelected = selectedWeek?.weekName === element
    return (
      <div className={`week-${element}`}>
        <WeekItem
          key={`item-${element}-${isSelected ? 'selected' : 'not-selected'}`}
          textItem={element}
          isSelected={selectedWeek?.weekName === element}
          isCurrentWeek={currentWeek?.weekName === element}
          onSelectWeek={onSelectWeek}
          isFirstOrLast={isPreOrPosPregnancy(element || '')}
        />
      </div>
    )
  }

  if (isFetchingWeekInfos) {
    return (
      <div className="week-carousel__skelleton-container">
        <div className="carousel__skelleton">
          <div className="larger-carousel-item__skelleton skeleton" />
          {[...Array(20)].map((_, index) => (
            <div className="carousel-item__skelleton skeleton" key={`card-${index}`} />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="carousel-weeks mb-4">
      <SimpleCarousel
        id="carousel-weeks"
        list={weeks}
        carouselId="weeks"
        onRenderElement={renderWeek}
        initialSlide={initialSlide}
        onScrollCarousel={onScrollWeekCarousel}
        cardSize={64}
      />
    </div>
  )
}

const mapStateToProps = ({
  weekFlow,
  authentication,
  appProfiles
}: AppState) => ({
  weeks: weekFlow.weeks,
  selectedWeek: weekFlow.selectedWeek,
  currentWeek: weekFlow.currentWeek,
  currentUser: authentication.currentUser,
  pregnancyJourney: weekFlow.pregnancyJourney,
  isDependent: appProfiles.isDependent,
  isFetchingWeekInfos: weekFlow.isFetchingWeekInfos
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  onSelectWeek: selectWeekAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeekCarousel)
