/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Divider } from 'theia-web-ds'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { fetchBookingHistoryAction, getBookingsAction } from '../../state/myBookingsFlow/actions'
import NavItem from '../common/navigation/NavItem'
import AllBookingsView from './AllBookingsView'
import BookingHistoryView from './BookingHistoryView'
import { eventPage } from '../../../eventGenerate'
import './MyBookingsView.scss'
import { ActiveProfileType, EventProfileType } from '../../domain/AppProfiles'
import AppHeader from '../common/AppHeader'

interface Props {
  fetchAllBookings: () => void;
  fetchBookingHistory: () => void;
  profileType?: EventProfileType
  isFetchingBookingHistory: boolean;
  forceUpdateHistory: boolean;
  hasPendingPrescription?: boolean;
  activeProfile?: ActiveProfileType
}

export enum TabType {
  BOOKING = 'agendamentos',
  HYSTORY = 'historico'
}

interface ParamsType {
  initialTab?: TabType;
  isFromReminder?: boolean;
}

function MyBookingsView({
  fetchAllBookings,
  fetchBookingHistory,
  profileType,
  isFetchingBookingHistory,
  forceUpdateHistory,
  hasPendingPrescription,
  activeProfile,
}: Props) {
  const params = useLocation().state as ParamsType
  const initialTab = params?.initialTab || undefined
  const [tabSelected, setTabSelected] = useState<TabType>(TabType.BOOKING)
  const [dataFetched, setDataFetched] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    eventPage('agenda', 'Agendamentos', { tipo_de_perfil: profileType })
    if (params?.isFromReminder === true) {
      setTabSelected(TabType.HYSTORY)
    }
    if (initialTab) {
      setTabSelected(initialTab)
    }
  }, [])

  const fetchBookingHistoryMemoized = useCallback(() => {
    if (!dataFetched[`${activeProfile?.id}_history`]) {
      fetchBookingHistory()
      setDataFetched((prev) => ({ ...prev, [`${activeProfile?.id}_history`]: true }))
    }
  }, [activeProfile?.id, dataFetched])

  const fetchAllBookingsMemoized = useCallback(() => {
    if (!dataFetched[`${activeProfile?.id}_booking`]) {
      fetchAllBookings()
      setDataFetched((prev) => ({ ...prev, [`${activeProfile?.id}_booking`]: true }))
    }
  }, [activeProfile?.id, dataFetched])

  useEffect(() => {
    if (tabSelected === TabType.HYSTORY) {
      fetchBookingHistoryMemoized()
    } else if (tabSelected === TabType.BOOKING) {
      fetchAllBookingsMemoized()
    }
  }, [tabSelected, fetchBookingHistoryMemoized, fetchAllBookingsMemoized])

  useEffect(() => {
    if (forceUpdateHistory && !isFetchingBookingHistory && tabSelected === TabType.HYSTORY) {
      fetchBookingHistory()
    }
  }, [forceUpdateHistory])

  const onSelectBookings = () => setTabSelected(TabType.BOOKING)
  const onSelectHistoric = () => setTabSelected(TabType.HYSTORY)

  return (
    <div className="my-bookings-view-container">
      <AppHeader title="Consultas" />
      <div className="my-bookings-tab">
        <ul className="flex flex-1 justify-center" role="menu" aria-label="Menu de agendamentos">
          <NavItem
            handleClick={onSelectBookings}
            isActive={tabSelected === TabType.BOOKING}
            text="Agendamentos"
            variant="tab"
          />
          <NavItem
            handleClick={onSelectHistoric}
            isActive={tabSelected === TabType.HYSTORY}
            text="Histórico"
            variant="tab"
            hasNotification={hasPendingPrescription}
          />
        </ul>
      </div>
      <Divider />
      <div className="my-bookings-body">
        {tabSelected === TabType.BOOKING && (
          <AllBookingsView goToBookingsHistory={onSelectHistoric} />
        )}
        {tabSelected === TabType.HYSTORY && (
          <BookingHistoryView goToAllBookings={onSelectBookings} />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({
  myBookingsFlow, appProfiles
}: AppState) => ({
  isFetchingBookingHistory: myBookingsFlow.isFetchingBookingHistory,
  hasPendingPrescription: myBookingsFlow.hasPendingNotification,
  forceUpdateHistory: myBookingsFlow.forceUpdateHistory,
  activeProfile: appProfiles.activeProfile,
  profileType: appProfiles.profileType
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  fetchBookingHistory: fetchBookingHistoryAction,
  fetchAllBookings: getBookingsAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyBookingsView)
