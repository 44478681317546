import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { AccessibleErrorModal, TextArea } from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import {
  getDesireContentAction,
  postMaternalDesireAction,
  suppressFirstMaternalDesireModalAction,
  suppressMaternalDesireErrorAction
} from '../../state/consultant/actions'
import { DesireContent, DesireType, MaternalDesireType } from '../../domain/Consultant'
import GenericSurveyModal, { StepType } from '../common/GenericSurveyModal'
import { isPregnant } from '../../utils/helpers'
import { CONSULTAS, INICIO, SAUDE } from '../../routes/RoutesConstants'
import ExclamationError from '../../../assets/exclamation-error.svg'
import './DesireModalGroup.scss'
import { HighlightDesireType } from '../../domain/UserHighlights'
import { postNotificationConfirmationAction } from '../../state/userHighlights/actions'

interface Props {
  maternalDesireModalType: HighlightDesireType;
  successGetHighlights: boolean;
  sawFirstMaternalDesireModal: boolean;
  isPostingDesire: boolean;
  failurePostDesire: boolean;
  desireContent?: DesireContent;
  isGettingDesireContent: boolean;
  suppressFirstMaternalDesireModal: () => void;
  postNotificationConfirmation: (type: HighlightDesireType) => void;
  postMaternalDesire: (body: MaternalDesireType) => void;
  suppressMaternalDesireError: () => void;
  getDesireContent: () => void;
}

function DesireModalsGroup({
  maternalDesireModalType,
  successGetHighlights,
  sawFirstMaternalDesireModal,
  isPostingDesire,
  failurePostDesire,
  desireContent,
  isGettingDesireContent,
  suppressFirstMaternalDesireModal,
  postNotificationConfirmation,
  postMaternalDesire,
  suppressMaternalDesireError,
  getDesireContent
}: Props) {
  const location = useLocation()
  const { pathname } = location
  const [desire, setDesire] = useState<DesireType>()
  const [obs, setObs] = useState<string>('')

  const screensToShowModal = [
    INICIO,
    CONSULTAS,
    SAUDE
  ]

  useEffect(() => {
    if (maternalDesireModalType.toString() === 'LAST_QUARTER'
      && desireContent === undefined
      && !isGettingDesireContent
      && screensToShowModal.includes(pathname)
    ) {
      getDesireContent()
    }
  }, [maternalDesireModalType, desireContent])

  function handleSubmitDesire() {
    if (desire) {
      const body: MaternalDesireType = {
        desire,
        obs,
        origin: 'web',
        modalType: maternalDesireModalType
      }
      postMaternalDesire(body)
    }
  }

  function handleDenyToAnswer() {
    postNotificationConfirmation(maternalDesireModalType)
    suppressFirstMaternalDesireModal()
  }

  function handleCloseModal() {
    suppressFirstMaternalDesireModal()
  }

  function handleTryAgain() {
    suppressMaternalDesireError()
    handleSubmitDesire()
  }

  function handleTryLater() {
    suppressMaternalDesireError()
    suppressFirstMaternalDesireModal()
  }

  function renderLastQuarterModal() {
    return maternalDesireModalType === 'LAST_QUARTER' && desireContent
  }

  const firstQuarterSteps: StepType[] = [
    {
      title: `${renderLastQuarterModal()
        ? 'E qual o tipo de parto você deseja para sua gestação?'
        : 'Você já pensou sobre qual tipo de parto deseja para sua gestação?'}`,
      options: [
        {
          name: 'Parto Vaginal / Normal',
          value: 'VAGINAL',
          selected: desire === 'VAGINAL',
          onClick: () => setDesire('VAGINAL')
        },
        {
          name: 'Cesárea',
          value: 'CESAREAN_CONSULTANT_DESIRE',
          selected: desire === 'CESAREAN_CONSULTANT_DESIRE',
          onClick: () => setDesire('CESAREAN_CONSULTANT_DESIRE')
        },
        {
          name: 'Ainda não sei / Estou indecisa',
          value: 'UNDECIDED',
          selected: desire === 'UNDECIDED',
          onClick: () => setDesire('UNDECIDED')
        },
      ]
    },
    {
      title: 'Deseja comentar sobre?',
      subtitle: 'Opcional',
      component: (
        <TextArea
          id="desire-comments"
          onChange={(e) => setObs(e.target.value)}
          rows={5}
        />
      )
    }
  ]

  const lastQuarterSteps: StepType[] = desireContent ? [
    {
      title: desireContent.text,
      options: [
        {
          name: 'Sim',
          value: desireContent.desire,
          selected: desire === desireContent.desire,
          onClick: () => setDesire(desireContent.desire)
        },
        {
          name: 'Não',
          value: '',
          selected: desire !== undefined && desire !== desireContent.desire,
          onClick: () => setDesire('')
        }
      ]
    },
    ...firstQuarterSteps
  ] : []

  const stepsToRender = renderLastQuarterModal()
    ? lastQuarterSteps : firstQuarterSteps

  const isToSkip = useMemo(() => {
    let skip = false
    if (renderLastQuarterModal()
      && desire === desireContent?.desire
    ) {
      skip = true
    }
    return skip
  }, [desire])

  const errorImg = (
    <div className="flex justify-center">
      <ExclamationError />
    </div>
  )

  if (maternalDesireModalType === 'DONT_SHOW'
    || (maternalDesireModalType === 'LAST_QUARTER' && !desireContent)
    || !successGetHighlights
    || !isPregnant
    || !screensToShowModal.includes(pathname)
  ) return null

  return (
    <>
      <GenericSurveyModal
        visible={!sawFirstMaternalDesireModal}
        numberOfSteps={stepsToRender.length}
        onCloseModal={handleCloseModal}
        onDenyToAnswer={handleDenyToAnswer}
        isSubmitting={isPostingDesire}
        cantProceed={desire === undefined}
        steps={stepsToRender}
        onSubmit={handleSubmitDesire}
        skipToLastStep={isToSkip}
        ariaLabelledby="Enquete - Desejo Materno de Via de Parto"
        desktopImageUrl="https://theia-all-assets.s3.amazonaws.com/desire/desire_desktop.png"
        mobileImageUrl="https://theia-all-assets.s3.amazonaws.com/desire/desire_small.png"
        closeButtonText="Prefiro não responder"
      />
      {failurePostDesire && (
        <AccessibleErrorModal
          visible={failurePostDesire}
          closeModal={suppressMaternalDesireError}
          error={({
            title: 'Ops, aconteceu um problema',
            message: 'Infelizmente não foi possível salvar sua resposta no momento.'
          })}
          firstButtonText="Tentar novamente"
          firstButtonAction={handleTryAgain}
          secondButtonText="Tentar mais tarde"
          secondButtonAction={handleTryLater}
          errorImg={errorImg}
        />
      )}
    </>
  )
}

const mapStateToProps = ({
  authentication,
  consultant,
  userHighlights
}: AppState) => ({
  maternalDesireModalType: userHighlights.maternalDesire,
  successGetHighlights: userHighlights.getUserHighlightsStatus.success,
  pregnancyMoment: authentication.currentUser?.pregnancyMoment,
  sawFirstMaternalDesireModal: consultant.sawFirstMaternalDesireModal,
  isPostingDesire: consultant.isPostingDesire,
  failurePostDesire: consultant.failurePostDesire,
  desireContent: consultant.desireContent,
  isGettingDesireContent: consultant.isGettingDesireContent
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  suppressFirstMaternalDesireModal: suppressFirstMaternalDesireModalAction,
  postNotificationConfirmation: postNotificationConfirmationAction,
  postMaternalDesire: postMaternalDesireAction,
  suppressMaternalDesireError: suppressMaternalDesireErrorAction,
  getDesireContent: getDesireContentAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesireModalsGroup)
