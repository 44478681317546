import { toast } from 'react-toastify'
import { FontIcon, IconButton } from 'theia-web-ds'
import { ToastInfos } from '../../domain/Notifications'
import { AsyncAction } from '../utils'
import { bgBox } from '../../color'

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const SUCCESS_UPDATED_NOTIFICATION = 'SUCCESS_UPDATED_NOTIFICATION'

interface ClearNotificationActionType {
  type: typeof CLEAR_NOTIFICATION;
  id: string;
}

interface ShowNotificationActionType {
  type: typeof SHOW_NOTIFICATION;
  toastInfos: ToastInfos;
}

interface HideNotificationActionType {
  type: typeof HIDE_NOTIFICATION;
  id?: string;
}

interface UpdateNotificationActionType {
  type: typeof UPDATE_NOTIFICATION;
  toastInfos: ToastInfos;
  id: string;
}

interface SuccessUpdatedNotificationActionType {
  type: typeof SUCCESS_UPDATED_NOTIFICATION;
}

export type NotificationTypes = ClearNotificationActionType
| ShowNotificationActionType
| HideNotificationActionType
| UpdateNotificationActionType
| SuccessUpdatedNotificationActionType

export const clearNotificationAction = (
  id: string
): ClearNotificationActionType => ({
  type: CLEAR_NOTIFICATION,
  id
})

export function hideNotificationAction(id?: string):
  AsyncAction<{}, {}> {
  return async (dispatch) => {
    dispatch({ type: HIDE_NOTIFICATION, id })
    toast.dismiss(id)
  }
}

export function updateNotificationAction(toastInfos: ToastInfos):
  AsyncAction<{}, {}> {
  return async (dispatch) => {
    const toastId = toastInfos.id
    dispatch({ type: UPDATE_NOTIFICATION, toastInfos })
    toast.update(toastId, {
      render: toastInfos.message,
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'toast-message',
      hideProgressBar: true,
      autoClose: toastInfos?.timer || false,
      type: toastInfos.type,
      icon: FontIcon({
        iconType: toastInfos.iconType,
        color: toastInfos.iconColor,
        fontSize: '22px',
        extraClass: 'Toastify__toast-icon',
        animation: toastInfos.isLoading ? 'spin 2s linear infinite' : ''
      }),
      toastId,
      closeButton: ({ closeToast }) => IconButton({
        variant: 'form-icon',
        iconType: 'icon-CloseLight',
        iconColor: bgBox,
        iconSize: '22px',
        extraClass: 'Toastify__close-button',
        onClick: (e: any) => {
          closeToast(e)
          dispatch(hideNotificationAction(toastInfos.id))
        }
      })
    })
  }
}

export function showNotificationAction(toastInfos: ToastInfos):
  AsyncAction<{}, {}> {
  return async (dispatch) => {
    dispatch({ type: SHOW_NOTIFICATION, toastInfos })
    const toastId = toastInfos.id
    if (toast.isActive(toastId)) {
      dispatch(updateNotificationAction(toastInfos))
      return
    }
    toast(toastInfos.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'toast-message',
      hideProgressBar: true,
      autoClose: toastInfos.timer || false,
      type: toastInfos.type,
      icon: FontIcon({
        iconType: toastInfos.iconType,
        color: toastInfos.iconColor,
        fontSize: '22px',
        extraClass: 'Toastify__toast-icon'
      }),
      toastId,
      closeButton: ({ closeToast }) => IconButton({
        variant: 'form-icon',
        iconType: 'icon-CloseLight',
        iconColor: bgBox,
        iconSize: '22px',
        extraClass: 'Toastify__close-button',
        onClick: (e: any) => {
          closeToast(e)
          dispatch(hideNotificationAction(toastInfos.id))
        }
      }),
    })
  }
}
