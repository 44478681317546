import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import MaskedInput from 'react-text-mask'
import moment from 'moment'
import { RegularButton, ErrorMessage } from 'theia-web-ds'
import { eventTrack } from '../../../eventGenerate'
import {
  dateValidationRegex, isNotFutureDate, isWithinOneYearRange, onlyDigits, isWithinGestationalMonth
} from '../../utils/helpers'

interface Props {
  locationToPush?: string;
  isToRedirect?: boolean;
  submitToBack?: (lastPeriodDate: number | string) => void;
  isSubmitting?: boolean;
  isOnboarding?: boolean;
  errorGetDPP?: boolean;
}

interface FormValues {
  date: string;
}

function LastPeriod({
  locationToPush,
  isToRedirect,
  submitToBack,
  isSubmitting,
  isOnboarding,
  errorGetDPP
}: Props) {
  const history = useHistory()
  const [periodDate, setPeriodDate] = useState<string>('')

  function onSaveDate(values: FormValues) {
    const valueToDate = (moment(values.date, 'DD/MM/YYYY').toDate())
    const dateTime = valueToDate.valueOf()
    localStorage.setItem('lastPeriod', (dateTime ? `${dateTime}` : ''))
    localStorage.setItem('pregnancyDueDate', '')
    eventTrack('Clicou Continuar DUM')
    if (isToRedirect) {
      history.push(`${locationToPush}`)
    } else if (submitToBack) {
      if (isOnboarding) {
        submitToBack(dateTime)
        return
      }
      submitToBack(values.date)
    }
  }

  const validationSchema = Yup.object().shape({
    date: Yup.string()
      .required('Campo obrigatório')
      .matches(dateValidationRegex, 'Data inválida')
      .test('date', 'Não é possível incluir uma data futura', (value) => isNotFutureDate(value))
      .test('date', 'Data fora do período aceito', (value) => isWithinOneYearRange(value))
      .test('date', 'Data fora do período gestacional', (value) => isWithinGestationalMonth(value))
  })

  return (
    <Formik
      initialValues={{ date: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSaveDate(values)}
    >{({
      handleSubmit, values, errors, handleChange, setFieldError
    }) => (
      <div className="input-group">
        <p className="text-fontSmall text-textPrimary justify-center text-center mt-5 max-w-full">
          Qual foi o primeiro dia da sua última menstruação?
        </p>
        <MaskedInput
          id="last-period-date"
          name="last-period-date"
          type="text"
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          value={periodDate}
          placeholder="DD/MM/AAAA"
          onChange={(e) => {
            setFieldError('date', '')
            setPeriodDate(e.target.value)
            if (onlyDigits(e.target.value).length === 8) {
              handleChange('date')(e.target.value)
              eventTrack('Preencheu DUM')
            }
          }}
          style={{
            padding: 10,
            width: '100%',
            border: '1px solid #EEEEEE',
            borderRadius: 5,
            marginTop: 15,
            borderColor: errors.date && '#FF7951'
          }}
        />
        {errors.date && <ErrorMessage error={errors.date} />}
        {isOnboarding && errorGetDPP && <ErrorMessage error="Não foi possível calcular a DPP. Tente novamente." />}
        <div className="flex items-center justify-center bottom-button-date-container">
          <RegularButton
            onClick={() => handleSubmit()}
            disabled={isSubmitting || !!((errors.date || !values.date))}
            label="Continuar"
            extraClass="mt-6"
            isSubmitting={isSubmitting}
            type="submit"
          />
        </div>
      </div>
    )}
    </Formik>
  )
}

export default LastPeriod
