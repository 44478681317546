import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CardPost, SimpleCarousel } from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { AppState } from '../../apps/main/store'
import { Post } from '../../domain/Content'
import { fetchAllPostsAction, fetchPostAction } from '../../state/content/actions'
import { AppDispatch } from '../../state/utils'
import { isPostPregnant, notUndefined } from '../../utils/helpers'
import { getPostsSlugByWeek, hasTagOrSlug, normalizeStringToSlug } from './helpers'
import { ChildrenSummary } from '../../domain/PediatricFlow'
import { CONTENT } from '../../routes/RoutesConstants'
import { StatusType } from '../../domain/Status'
import { WeekInfo } from '../../domain/WeekFlow'
import { User } from '../../domain/User'
import { getAllWeekOptionsAction } from '../../state/weekFlow/actions'
import { getChildrenSummaryAction } from '../../state/pediatricFlow/actions'
import './WeekContent.scss'
import { eventTrack } from '../../../eventGenerate'
import { EventCategories } from '../../utils/EventCategories'
import { EventProfileType } from '../../domain/AppProfiles'

interface Props {
  posts: Array<Post>
  childrenSummary?: ChildrenSummary
  getChildrenSummaryStatus: StatusType
  selectedWeek?: WeekInfo
  currentUser?: User
  weeks: string[]
  babyJourney: boolean
  isFetchingWeekInfos: boolean
  isDependent: boolean
  pregnancyJourney: boolean
  getAllPostsStatus: StatusType
  allPostsIsEmpty: boolean
  profileType?: EventProfileType
  getAllWeekOptions: () => void
  fetchPost: (id: string) => void
  fetchAllPosts: () => void
  getChildrenSummary: () => void
}

function WeekContent({
  posts,
  childrenSummary,
  getChildrenSummaryStatus,
  selectedWeek,
  currentUser,
  weeks,
  babyJourney,
  isFetchingWeekInfos,
  isDependent,
  pregnancyJourney,
  getAllPostsStatus,
  allPostsIsEmpty,
  profileType,
  getAllWeekOptions,
  fetchPost,
  fetchAllPosts,
  getChildrenSummary,
}: Props) {
  const history = useHistory()
  const [filteredPosts, setFilteredPosts] = useState<Post[]>([])
  const [slugName, setSlugName] = useState<string>('')
  const showPediatricContent = isPostPregnant(
    currentUser?.pregnancyMoment,
    currentUser?.pregnancyMomentText
  )

  function getPostById(post: Post, title?: string, category?: string) {
    fetchPost(post.id)
    history.push(`${CONTENT}/${post.slug}/${post.id}`, { title, category })
  }

  useEffect(() => {
    if (!getChildrenSummaryStatus.success) getChildrenSummary()
  }, [])

  useEffect(() => {
    if (allPostsIsEmpty && !getAllPostsStatus.isLoading) {
      fetchAllPosts()
    }
  }, [])

  useEffect(() => {
    if (weeks.length === 0
      && !isFetchingWeekInfos
      && (pregnancyJourney || babyJourney)) {
      getAllWeekOptions()
    }
  }, [])

  useEffect(() => {
    let newSlugName = ''
    if (selectedWeek?.weekName && !showPediatricContent) {
      newSlugName = getPostsSlugByWeek(selectedWeek?.weekName)
    }
    if (showPediatricContent && !childrenSummary?.currentMonth) {
      newSlugName = 'bebe-1-mes'
    }
    if (showPediatricContent && childrenSummary?.currentMonth) {
      newSlugName = normalizeStringToSlug(childrenSummary?.currentMonth)
    }
    setSlugName(newSlugName)
    const postsFilteredByTag = posts.filter((p) => hasTagOrSlug(p.tags, newSlugName))
    const featuredPosts = postsFilteredByTag.filter((p) => p.featured)
    let newPosts
    if (featuredPosts.length > 0) {
      newPosts = featuredPosts.concat(postsFilteredByTag.filter((p) => !p.featured))
    } else {
      newPosts = postsFilteredByTag
    }
    setFilteredPosts(newPosts)
  }, [selectedWeek?.weekName, childrenSummary, showPediatricContent])

  if (isFetchingWeekInfos || getAllPostsStatus.isLoading) {
    return (
      <div className="content-loader__skelleton-container">
        <div className="content-loader__skelleton-title skeleton" />
        <div className="content-loader__skelleton-carousel">
          <div className="content-loader__skelleton-gradient left" />
          {[...Array(5)].map((_, index) => (
            <div className="content-loader__carousel-item skeleton" key={`post-${index}`} />
          ))}
          <div className="content-loader__skelleton-gradient right" />
        </div>
      </div>
    )
  }

  if ((!isFetchingWeekInfos
    && !getAllPostsStatus.isLoading
    && (!selectedWeek?.weekName || weeks.length === 0))) {
    return null
  }

  function renderPost(post: Post) {
    const slug: string = (post.tags.filter((tag) => tag.slug === slugName) || [])[0].name
      || post.primary_tag.name
    const contentCategory = slugName === '' ? slug : slugName
    const title = post.meta_title || post.title
    return (
      <button
        onClick={() => {
          eventTrack('clicou em conteudo', {
            titulo_conteudo: title,
            categoria_conteudo: contentCategory,
            tipo_de_perfil: profileType,
            localizacao: 'home',
            category: EventCategories.CONTENT
          })
          getPostById(post, title, contentCategory)
        }}
        className="carousel-content-card"
        type="button"
      >
        <CardPost
          featureImage={post.feature_image}
          category={contentCategory}
          title={title}
          description={post.meta_description || post.excerpt}
          fixedWidth
          isLoading={getChildrenSummaryStatus.isLoading}
        />
      </button>
    )
  }

  return (
    <div className="content-week-container">
      <p className="content-week-title">
        {`Conteúdo ${isDependent ? '' : 'pra você'}`}
      </p>
      {filteredPosts.length === 0 ? (
        <p className="content-week-empty">
          Não há conteúdos para essa semana.
        </p>
      ) : (
        <div className="carousel-content-container">
          <SimpleCarousel
            id={`carousel-content-${slugName}`}
            list={filteredPosts}
            carouselId="posts"
            onRenderElement={(post: Post) => renderPost(post)}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({
  content, pediatricFlow, weekFlow, userHighlights, appProfiles
}: AppState) => {
  const allPosts = content.allPosts.map((post: any) => post)
    .filter(notUndefined)
  return {
    posts: allPosts,
    childrenSummary: pediatricFlow.childrenSummary,
    getChildrenSummaryStatus: pediatricFlow.getChildrenSummaryStatus,
    selectedWeek: weekFlow.selectedWeek,
    weeks: weekFlow.weeks,
    babyJourney: userHighlights.babyJourney,
    isFetchingWeekInfos: weekFlow.isFetchingWeekInfos,
    isDependent: appProfiles.isDependent,
    pregnancyJourney: weekFlow.pregnancyJourney,
    getAllPostsStatus: content.getAllPostsStatus,
    allPostsIsEmpty: content.allPosts.length === 0,
    profileType: appProfiles.profileType
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  fetchPost: fetchPostAction,
  getAllWeekOptions: getAllWeekOptionsAction,
  fetchAllPosts: fetchAllPostsAction,
  getChildrenSummary: getChildrenSummaryAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeekContent)
