import * as Sentry from '@sentry/browser'
import { AsyncAction } from '../utils'
import {
  ConsultantService,
  DesireContent,
  MaternalDesireType,
  PregnancyCardContent
} from '../../domain/Consultant'
import { primary } from '../../color'
import { showNotificationAction } from '../notifications/actions'
import { ToastType } from '../../domain/Notifications'
import { getHighlightsAction } from '../userHighlights/actions'
import { AppState } from '../../apps/main/store'
import { UserHighlightsService } from '../../domain/UserHighlights'
import { TheiaError } from '../../domain/errors/TheiaError'

export const POST_MATERNAL_DESIRE = 'POST_MATERNAL_DESIRE'
export const SUCCESS_POST_MATERNAL_DESIRE = 'SUCCESS_POST_MATERNAL_DESIRE'
export const FAILURE_POST_MATERNAL_DESIRE = 'FAILURE_POST_MATERNAL_DESIRE'

export const GET_DESIRE_CONTENT = 'GET_DESIRE_CONTENT'
export const SUCCESS_GET_DESIRE_CONTENT = 'SUCCESS_GET_DESIRE_CONTENT'
export const FAILURE_GET_DESIRE_CONTENT = 'FAILURE_GET_DESIRE_CONTENT'

export const GET_PREGNANCY_CARDS = 'GET_PREGNANCY_CARDS'
export const SUCCESS_GET_PREGNANCY_CARDS = 'SUCCESS_GET_PREGNANCY_CARDS'
export const FAILURE_GET_PREGNANCY_CARDS = 'FAILURE_GET_PREGNANCY_CARDS'

export const SUPPRESS_FIRST_MATERNAL_DESIRE_MODAL = 'SUPPRESS_FIRST_MATERNAL_DESIRE_MODAL'
export const SUPPRESS_MATERNAL_DESIRE_ERROR = 'SUPPRESS_MATERNAL_DESIRE_ERROR'

export const PROMPT_PREGNANCY_CARDS_DRAWER = 'PROMPT_PREGNANCY_CARDS_DRAWER'
export const SUPPRESS_PREGNANCY_CARDS_DRAWER = 'SUPPRESS_PREGNANCY_CARDS_DRAWER'

interface PostMaternalDesireActionType {
  type: typeof POST_MATERNAL_DESIRE;
}

export interface SuccessPostMaternalDesireActionType {
  type: typeof SUCCESS_POST_MATERNAL_DESIRE;
}

interface FailurePostMaternalDesireActionType {
  type: typeof FAILURE_POST_MATERNAL_DESIRE;
}

interface GetDesireContentActionType {
  type: typeof GET_DESIRE_CONTENT;
}

interface SuccessGetDesireContentActionType {
  type: typeof SUCCESS_GET_DESIRE_CONTENT;
  desireContent: DesireContent;
}

interface FailureGetDesireContentActionType {
  type: typeof FAILURE_GET_DESIRE_CONTENT;
}

interface GetPregnancyCardsActionType {
  type: typeof GET_PREGNANCY_CARDS;
}

interface SuccessGetPregnancyCardsActionType {
  type: typeof SUCCESS_GET_PREGNANCY_CARDS;
  pregnancyCards: Array<PregnancyCardContent>;
}

interface FailureGetPregnancyCardsActionType {
  type: typeof FAILURE_GET_PREGNANCY_CARDS;
  error?: TheiaError;
}

interface SuppressFirstMaternalDesireModalActionType {
  type: typeof SUPPRESS_FIRST_MATERNAL_DESIRE_MODAL;
}

interface SuppressMaternalDesireErrorActionType {
  type: typeof SUPPRESS_MATERNAL_DESIRE_ERROR;
}

interface PromptPregnancyCardsDrawerActionType {
  type: typeof PROMPT_PREGNANCY_CARDS_DRAWER;
}

interface SuppressPregnancyCardsDrawerActionType {
  type: typeof SUPPRESS_PREGNANCY_CARDS_DRAWER;
}

export type ConsultantTypes = PostMaternalDesireActionType
| SuccessPostMaternalDesireActionType
| FailurePostMaternalDesireActionType
| SuppressFirstMaternalDesireModalActionType
| SuppressMaternalDesireErrorActionType
| GetDesireContentActionType
| SuccessGetDesireContentActionType
| FailureGetDesireContentActionType
| GetPregnancyCardsActionType
| SuccessGetPregnancyCardsActionType
| FailureGetPregnancyCardsActionType
| PromptPregnancyCardsDrawerActionType
| SuppressPregnancyCardsDrawerActionType

export function postMaternalDesireAction(body: MaternalDesireType):
  AsyncAction<AppState, {
    consultantService: ConsultantService,
    userHighlightsService: UserHighlightsService
  }> {
  return async (dispatch, _getState, { consultantService }) => {
    dispatch({ type: POST_MATERNAL_DESIRE })
    try {
      await consultantService.postMaternalDesire(body)
      dispatch({ type: SUCCESS_POST_MATERNAL_DESIRE })
      dispatch(getHighlightsAction())
      dispatch(showNotificationAction({
        message: 'Agradecemos sua resposta!',
        iconColor: primary,
        iconType: 'icon-HeartLight',
        timer: 5000,
        id: 'SUCCESS_POST_MATERNAL_DESIRE',
        type: ToastType.SUCCESS
      }))
    } catch (error) {
      dispatch({ type: FAILURE_POST_MATERNAL_DESIRE })
      Sentry.captureException(new Error(`Failure on post maternal desire - ${error.message}`))
    }
  }
}

export function getDesireContentAction():
  AsyncAction<{}, { consultantService: ConsultantService }> {
  return async (dispatch, _getState, { consultantService }) => {
    dispatch({ type: GET_DESIRE_CONTENT })
    try {
      const desireContent = await consultantService.getDesireContent()
      dispatch({ type: SUCCESS_GET_DESIRE_CONTENT, desireContent })
    } catch (error) {
      dispatch({ type: FAILURE_GET_DESIRE_CONTENT })
      Sentry.captureException(new Error(`Failure to get desire content - ${error.message}`))
    }
  }
}

export function getPregnancyCardsAction():
  AsyncAction<{}, { consultantService: ConsultantService }> {
  return async (dispatch, _getState, { consultantService }) => {
    dispatch({ type: GET_PREGNANCY_CARDS })
    try {
      const pregnancyCards = await consultantService.getPregnancyCards()
      dispatch({ type: SUCCESS_GET_PREGNANCY_CARDS, pregnancyCards })
    } catch (error) {
      dispatch({ type: FAILURE_GET_PREGNANCY_CARDS, error })
      if (error instanceof TheiaError) {
        Sentry.captureException(new Error(`Failure to get pregnancy card - ${error.statusCode} - ${error.friendlyMessage} - ${error.message}`))
      }
    }
  }
}

export const suppressFirstMaternalDesireModalAction = ():
SuppressFirstMaternalDesireModalActionType => ({
  type: SUPPRESS_FIRST_MATERNAL_DESIRE_MODAL
})

export const suppressMaternalDesireErrorAction = ():
SuppressMaternalDesireErrorActionType => ({
  type: SUPPRESS_MATERNAL_DESIRE_ERROR
})

export const promptPregnancyCardsDrawerAction = ():
PromptPregnancyCardsDrawerActionType => ({
  type: PROMPT_PREGNANCY_CARDS_DRAWER
})

export const suppressPregnancyCardsDrawerAction = ():
SuppressPregnancyCardsDrawerActionType => ({
  type: SUPPRESS_PREGNANCY_CARDS_DRAWER
})
