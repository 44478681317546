/* eslint-disable no-shadow */

import { HealthInsurancePlanStatus } from './PediatricFlow'

/* eslint-disable no-unused-vars */
export enum ProfilesTypes {
  CONSULTANT = 'CONSULTANT',
  CHILD = 'CHILD'
}

export enum EventProfileType {
  CONSULTANT = 'titular',
  CHILD = 'dependente'
}

export type AppProfileType = {
  name: string
  id: string
  type: ProfilesTypes
  imageUrl?: string
  document?: string
}

export type ActiveProfileType = {
  name?: string
  id?: string
  type?: ProfilesTypes
  imageUrl?: string
  document?: string
  email?: string
  birthDate?: string
  companyId?: string | null
  companyName?: string | null
  memberId?: string | null
  planId?: string | null
  planName?: string | null
  healthInsurancePlanStatus?: HealthInsurancePlanStatus
}

export interface AppProfilesService {
  getAppProfiles: () => Promise<AppProfileType[]>
}
