import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Loader,
} from 'theia-web-ds'
import { AppState } from '../../apps/main/store'
import WeekCarousel from './WeekCarousel'
import HealthPlanModal from './HealthPlanModal'
import { User } from '../../domain/User'
import WeekContent from './WeekContent'
import ScheduleSuggestionBanner from '../suggestionsFlow/ScheduleSuggestionBanner'
import { eventPage } from '../../../eventGenerate'
import UsgModal from '../usgFlow/UsgModal'
import JourneyWithNoSuggestions from './JourneyWithNoSuggestions'
import GuidedJourneyComponent from '../guidedJourney/GuidedJourneyComponent'
import {
  isTrying
} from '../../utils/helpers'
import './Home.scss'
import EmailValidationCard from '../profile/accountValidation/EmailValidationCard'
import PhoneValidationCard from '../profile/accountValidation/PhoneValidationCard'
import AppHeader from '../common/AppHeader'
import PediatricJourneyBanner from '../pediatricFlow/PediatricJourneyBanner'
import BookingsComponent from './BookingsComponent'
import { StatusType } from '../../domain/Status'
import { EventProfileType } from '../../domain/AppProfiles'

interface Props {
  currentUser?: User;
  isFetchingUser?: boolean;
  getUserHighlightsStatus: StatusType;
  pregnancyJourney?: boolean
  babyJourney?: boolean
  profileType?: EventProfileType
}

function Home({
  currentUser,
  isFetchingUser,
  getUserHighlightsStatus,
  pregnancyJourney,
  babyJourney,
  profileType,
}: Props) {
  useEffect(() => {
    eventPage('home', 'Home', { tipo_de_perfil: profileType })
  }, [])

  function getHeaderTitle() {
    const hours = new Date().getHours()
    if (hours >= 12 && hours < 18) return 'Boa tarde!'
    if (hours >= 18 && hours < 24) return 'Boa noite!'
    return 'Bom dia!'
  }

  return (
    <>
      <div className="week-view">
        <AppHeader />
        <div className="week-view-content__scrollable-view">
          {(isFetchingUser || getUserHighlightsStatus.isLoading) ? (
            <div className="flex justify-center w-full h-screen">
              <div className="flex items-center h-full">
                <Loader />
              </div>
            </div>
          ) : (
            <>
              <div className="app-header__welcome-text">
                <h2>{getHeaderTitle()}</h2>
                {(pregnancyJourney || babyJourney) && (
                  <p>
                    Tudo que você precisa saber agora.
                  </p>
                )}
              </div>
              <ScheduleSuggestionBanner />
              <EmailValidationCard />
              <PhoneValidationCard />
              <JourneyWithNoSuggestions />
              <WeekCarousel />
              <PediatricJourneyBanner />
              <div
                className={`week-view-grid-container
                ${isTrying(currentUser?.pregnancyMoment) ? 'pt-0' : 'pt-8'}
                `}
              >
                <div className="week-view-item-a">
                  <GuidedJourneyComponent />
                  <WeekContent />
                  <div className="bookings-container-item-a">
                    <BookingsComponent />
                  </div>
                </div>
                <div className="week-view-item-b">
                  <div className="bookings-container-item-b">
                    <BookingsComponent />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <HealthPlanModal />
      <UsgModal />
    </>
  )
}

const mapStateToProps = ({
  authentication,
  prescriptions,
  userHighlights,
  weekFlow,
  appProfiles
}: AppState) => ({
  currentUser: authentication.currentUser,
  getUserHighlightsStatus: userHighlights.getUserHighlightsStatus,
  isFetchingUser: authentication.isFetchingUser,
  prescriptions: prescriptions.prescriptions,
  pregnancyJourney: weekFlow.pregnancyJourney,
  babyJourney: userHighlights.babyJourney,
  profileType: appProfiles.profileType
})

export default connect(
  mapStateToProps,
)(Home)
