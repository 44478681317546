import React from 'react'
import { RegularButton } from 'theia-web-ds'
import AppMainContainerOff from '../AppMainContainerOff'
import './SalesOnboarding.scss'
import BlockedCalendar from '../../../assets/calendar_x.svg'

function SalesOnboardingBlockedDPP() {
  function goToTheiaSite() {
    window.location.href = 'https://theia.com.br'
  }

  return (
    <AppMainContainerOff>
      <div className="sales-onboarding-blocked-container flex flex-col">
        <div className="text-center">
          <BlockedCalendar height="80" width="80" className="mx-auto" />
          <p className="text-textMedium my-4 text-textPrimary">
            Verificamos sua data provável do parto e, infelizmente,
            nossa agenda para gestantes desse período está completa.
          </p>
          <p className="mb-4 text-fontDefault text-textSecondary">
            Por isso, não poderemos atendê-la desta vez.
          </p>
          <p className="mb-5 text-fontDefault text-textSecondary">
            Agradecemos pela compreensão e desejamos
            que você encontre o cuidado ideal para este momento tão especial.
          </p>
        </div>
        <RegularButton
          onClick={goToTheiaSite}
          label="Entendi, voltar ao site"
        />
      </div>
    </AppMainContainerOff>
  )
}

export default SalesOnboardingBlockedDPP
