import { ActiveProfileType, AppProfileType, EventProfileType } from '../../domain/AppProfiles'
import { initialStatus, StatusType } from '../../domain/Status'
import { PediatricFlowTypes, SUCCESS_GET_CHILDREN_DATA } from '../pediatricFlow/actions'
import { SUCCESS_UPDATE_PROFILE_INFOS, SuccessUpdateProfileInfosActionType } from '../profile/actions'
import {
  AppProfilesTypes,
  FAILURE_GET_APP_PROFILES,
  GET_APP_PROFILES,
  PROMPT_CHOOSE_PROFILE_MODAL,
  SELECT_PROFILE,
  SUCCESS_GET_APP_PROFILES,
  SUPPRESS_CHOOSE_PROFILE_MODAL,
  SAVE_DEPENDENT_DATA,
  SUCCESS_SAVE_DEPENDENT_DATA,
  FAILURE_SAVE_DEPENDENT_DATA,
  CLEAR_SAVE_DEPENDENT_STATUS,
  REMOVE_ACTIVE_PROFILE
} from './actions'

export interface AppProfilesState {
  activeProfile?: ActiveProfileType
  appProfiles?: AppProfileType[]
  getAppProfilesStatus: StatusType
  chooseProfileModalIsVisible: boolean
  saveDependentDataStatus: StatusType
  isDependent: boolean
  profileType?: EventProfileType
}

const initialDrawer: AppProfilesState = {
  activeProfile: undefined,
  appProfiles: undefined,
  getAppProfilesStatus: initialStatus,
  chooseProfileModalIsVisible: false,
  saveDependentDataStatus: initialStatus,
  isDependent: false,
  profileType: undefined
}

export function appProfiles(
  state = initialDrawer,
  action: AppProfilesTypes
  | PediatricFlowTypes
  | SuccessUpdateProfileInfosActionType
): AppProfilesState {
  switch (action.type) {
    case SELECT_PROFILE: {
      return {
        ...state,
        activeProfile: action.activeProfile,
        isDependent: action.isDependent,
        profileType: action.profileType
      }
    }
    case REMOVE_ACTIVE_PROFILE: {
      return {
        ...state,
        activeProfile: undefined,
        isDependent: false,
        profileType: undefined
      }
    }
    case SUCCESS_GET_CHILDREN_DATA: {
      const dependent = state.isDependent && action.childrenData
        ? action.childrenData.find((child) => child.id === state.activeProfile?.id)
        : undefined
      return {
        ...state,
        activeProfile: {
          ...state.activeProfile,
          ...dependent,
          document: state.isDependent ? dependent?.cpf : state.activeProfile?.document
        },
      }
    }
    case SUCCESS_UPDATE_PROFILE_INFOS: {
      return {
        ...state,
        activeProfile: {
          ...state.activeProfile,
          birthDate: action.profileInfos.birthDate,
          document: action.profileInfos.document,
          email: action.profileInfos.email,
          name: action.profileInfos.name
        },
      }
    }
    case GET_APP_PROFILES: {
      return {
        ...state,
        getAppProfilesStatus: {
          ...initialStatus,
          isLoading: true
        },
        appProfiles: undefined
      }
    }
    case SUCCESS_GET_APP_PROFILES: {
      return {
        ...state,
        getAppProfilesStatus: {
          ...initialStatus,
          success: true
        },
        appProfiles: action.appProfiles
      }
    }
    case FAILURE_GET_APP_PROFILES: {
      return {
        ...state,
        getAppProfilesStatus: {
          ...initialStatus,
          error: true,
          errorMessage: action.error
        }
      }
    }
    case SAVE_DEPENDENT_DATA: {
      return {
        ...state,
        saveDependentDataStatus: {
          ...initialStatus,
          isLoading: true
        }
      }
    }
    case SUCCESS_SAVE_DEPENDENT_DATA: {
      return {
        ...state,
        saveDependentDataStatus: {
          ...initialStatus,
          success: true
        }
      }
    }
    case FAILURE_SAVE_DEPENDENT_DATA: {
      return {
        ...state,
        saveDependentDataStatus: {
          ...initialStatus,
          error: true,
          errorMessage: action.error
        }
      }
    }
    case PROMPT_CHOOSE_PROFILE_MODAL: {
      return {
        ...state,
        chooseProfileModalIsVisible: true
      }
    }
    case SUPPRESS_CHOOSE_PROFILE_MODAL: {
      return {
        ...state,
        chooseProfileModalIsVisible: false
      }
    }
    case CLEAR_SAVE_DEPENDENT_STATUS: {
      return {
        ...state,
        saveDependentDataStatus: initialStatus
      }
    }
    default:
      return state
  }
}
