/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/browser'
import { AvailabilitySlot, ProfessionalListType } from '../../domain/Professional';
import {
  Exam,
  Prescriptions,
  PrescriptionsService,
  UsgAvailabilityParams,
  UsgPrescription,
  UsgStatus,
} from '../../domain/Prescriptions';
import { AsyncAction } from '../utils'
import { TheiaError } from '../../domain/errors/TheiaError';
import { showNotificationAction } from '../notifications/actions';
import { NotificationMessage, ToastType } from '../../domain/Notifications';
import { success } from '../../color';
import { AppState } from '../../apps/main/store'

export const GET_PRESCRIPTIONS_INFOS = 'GET_PRESCRIPTIONS_INFOS'
export const SUCCESS_GET_PRESCRIPTIONS_INFOS = 'SUCCESS_GET_PRESCRIPTIONS_INFOS'
export const FAILURE_GET_PRESCRIPTIONS_INFOS = 'FAILURE_GET_PRESCRIPTIONS_INFOS'

export const GET_EXAMS = 'GET_EXAMS'
export const SUCCESS_GET_EXAMS = 'SUCCESS_GET_EXAMS'
export const FAILURE_GET_EXAMS = 'FAILURE_GET_EXAMS'

export const DELETE_EXAM = 'DELETE_EXAM'
export const SUCCESS_DELETE_EXAM = 'SUCCESS_DELETE_EXAM'
export const FAILURE_DELETE_EXAM = 'FAILURE_DELETE_EXAM'

export const UPLOAD_EXAM = 'UPLOAD_EXAM'
export const SUCCESS_UPLOAD_EXAM = 'SUCCESS_UPLOAD_EXAM'
export const FAILURE_UPLOAD_EXAM = 'FAILURE_UPLOAD_EXAM'

export const GET_EXAM_DOWNLOAD_URL = 'GET_EXAM_DOWNLOAD_URL'
export const SUCCESS_GET_EXAM_DOWNLOAD_URL = 'SUCCESS_GET_EXAM_DOWNLOAD_URL'
export const FAILURE_GET_EXAM_DOWNLOAD_URL = 'FAILURE_GET_EXAM_DOWNLOAD_URL'

export const GET_USG_PRESCRIPTION = 'GET_USG_PRESCRIPTION'
export const SUCCESS_GET_USG_PRESCRIPTION = 'SUCCESS_GET_USG_PRESCRIPTION'
export const FAILURE_GET_USG_PRESCRIPTION = 'FAILURE_GET_USG_PRESCRIPTION'

export const GET_USG_DATA = 'GET_USG_DATA'
export const SUCCESS_GET_USG_DATA = 'SUCCESS_GET_USG_DATA'
export const FAILURE_GET_USG_DATA = 'FAILURE_GET_USG_DATA'

export const GET_USG_AVAILABILITY = 'GET_USG_AVAILABILITY'
export const SUCCESS_GET_USG_AVAILABILITY = 'SUCCESS_GET_USG_AVAILABILITY'
export const FAILURE_GET_USG_AVAILABILITY = 'FAILURE_GET_USG_AVAILABILITY'

export const POST_USG_STATUS = 'POST_USG_STATUS'
export const SUCCESS_POST_USG_STATUS = 'SUCCESS_POST_USG_STATUS'
export const FAILURE_POST_USG_STATUS = 'FAILURE_POST_USG_STATUS'

export const SUPPRESS_USG_MODAL = 'SUPPRESS_USG_MODAL'
export const RESET_GET_URL_STATUS = 'RESET_GET_URL_STATUS'
export const RESET_DELETE_EXAM_STATUS = 'RESET_DELETE_EXAM_STATUS'

interface GetPrescriptionsInfosActionType {
  type: typeof GET_PRESCRIPTIONS_INFOS;
}

interface SuccessGetPrescriptionsInfosActionType {
  type: typeof SUCCESS_GET_PRESCRIPTIONS_INFOS;
  prescriptions: Array<Prescriptions>;
}

interface FailureGetPrescriptionsInfosActionType {
  type: typeof FAILURE_GET_PRESCRIPTIONS_INFOS;
  error?: Error;
}

interface GetExamsActionType {
  type: typeof GET_EXAMS;
  isUpdate?: boolean
}

interface SuccessGetExamsActionType {
  type: typeof SUCCESS_GET_EXAMS;
  exams: Array<Exam>;
}

interface FailureGetExamsActionType {
  type: typeof FAILURE_GET_EXAMS;
  error?: TheiaError;
}

interface DeleteExamActionType {
  type: typeof DELETE_EXAM;
}

interface SuccessDeleteExamActionType {
  type: typeof SUCCESS_DELETE_EXAM;
  examId: string;
}

interface FailureDeleteExamActionType {
  type: typeof FAILURE_DELETE_EXAM;
  error?: TheiaError;
}

interface UploadExamActionType {
  type: typeof UPLOAD_EXAM;
  file: File
}

interface SuccessUploadExamActionType {
  type: typeof SUCCESS_UPLOAD_EXAM;
  file: File;
}

interface FailureUploadExamActionType {
  type: typeof FAILURE_UPLOAD_EXAM;
  file: File
  error?: TheiaError;
}

interface GetExamDownloadUrlActionType {
  type: typeof GET_EXAM_DOWNLOAD_URL;
}

interface SuccessGetExamDownloadUrlActionType {
  type: typeof SUCCESS_GET_EXAM_DOWNLOAD_URL;
  examDownloadUrl: string;
}

interface FailureGetExamDownloadUrlActionType {
  type: typeof FAILURE_GET_EXAM_DOWNLOAD_URL;
  error?: TheiaError
}

interface GetUsgPrescriptionActionType {
  type: typeof GET_USG_PRESCRIPTION;
}

interface SuccessGetUsgPrescriptionActionType {
  type: typeof SUCCESS_GET_USG_PRESCRIPTION;
  usgPrescription: UsgPrescription;
}

interface FailureGetUsgPrescriptionActionType {
  type: typeof FAILURE_GET_USG_PRESCRIPTION;
}

interface GetUsgDataActionType {
  type: typeof GET_USG_DATA;
}

interface SuccessGetUsgDataActionType {
  type: typeof SUCCESS_GET_USG_DATA;
  usgData: ProfessionalListType;
}

interface FailureGetUsgDataActionType {
  type: typeof FAILURE_GET_USG_DATA;
}

interface GetUsgAvailabilityActionType {
  type: typeof GET_USG_AVAILABILITY;
}

interface SuccessGetUsgAvailabilityActionType {
  type: typeof SUCCESS_GET_USG_AVAILABILITY;
  usgAvailability: Array<AvailabilitySlot>;
}

interface FailureGetUsgAvailabilityActionType {
  type: typeof FAILURE_GET_USG_AVAILABILITY;
}

interface PostUsgStatusActionType {
  type: typeof POST_USG_STATUS;
}

interface SuccessPostUsgStatusActionType {
  type: typeof SUCCESS_POST_USG_STATUS;
}

interface FailurePostUsgStatusActionType {
  type: typeof FAILURE_POST_USG_STATUS;
}

interface SuppressUsgModalActionType {
  type: typeof SUPPRESS_USG_MODAL;
}

interface ResetGetUrlStatusActionType {
  type: typeof RESET_GET_URL_STATUS;
}

interface ResetDeleteExamStatusActionType {
  type: typeof RESET_DELETE_EXAM_STATUS;
}

export type PrescriptionsTypes = GetPrescriptionsInfosActionType
| SuccessGetPrescriptionsInfosActionType
| FailureGetPrescriptionsInfosActionType
| GetExamsActionType
| SuccessGetExamsActionType
| FailureGetExamsActionType
| DeleteExamActionType
| SuccessDeleteExamActionType
| FailureDeleteExamActionType
| UploadExamActionType
| SuccessUploadExamActionType
| FailureUploadExamActionType
| GetExamDownloadUrlActionType
| SuccessGetExamDownloadUrlActionType
| FailureGetExamDownloadUrlActionType
| GetUsgPrescriptionActionType
| SuccessGetUsgPrescriptionActionType
| FailureGetUsgPrescriptionActionType
| GetUsgDataActionType
| SuccessGetUsgDataActionType
| FailureGetUsgDataActionType
| GetUsgAvailabilityActionType
| SuccessGetUsgAvailabilityActionType
| FailureGetUsgAvailabilityActionType
| PostUsgStatusActionType
| SuccessPostUsgStatusActionType
| FailurePostUsgStatusActionType
| SuppressUsgModalActionType
| ResetGetUrlStatusActionType
| ResetDeleteExamStatusActionType

export function getPrescriptionsInformationAction():
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, getState, { prescriptionsService }) => {
    const state = getState() as AppState
    const { appProfiles } = state
    dispatch({ type: GET_PRESCRIPTIONS_INFOS })
    try {
      const response = await prescriptionsService.getPrescriptionsInformation()
      const prescriptions = appProfiles.isDependent ? [] : response
      dispatch({ type: SUCCESS_GET_PRESCRIPTIONS_INFOS, prescriptions })
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: FAILURE_GET_PRESCRIPTIONS_INFOS, error })
        Sentry.captureException(new Error(`Failure to fetch prescriptions - ${error.message}`))
      }
    }
  }
}

export function getExamsAction(childId?: string, isUpdate?: boolean):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: GET_EXAMS, isUpdate })
    try {
      const exams = await (childId
        ? prescriptionsService.getChildExams(childId)
        : prescriptionsService.getExams()
      )
      dispatch({ type: SUCCESS_GET_EXAMS, exams })
    } catch (error) {
      if (error instanceof TheiaError) {
        dispatch({ type: FAILURE_GET_EXAMS, error })
        Sentry.captureException(new Error(`Failure to fetch ${childId ? 'child ' : ''}exams - ${JSON.stringify(error)}`))
      }
    }
  }
}

export function deleteExamAction(examId: string):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: DELETE_EXAM })
    try {
      await prescriptionsService.deleteExam(examId)
      dispatch({ type: SUCCESS_DELETE_EXAM, examId })
      dispatch(showNotificationAction({
        message: NotificationMessage.SUCCESS_DELETE_EXAM,
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 2000,
        id: `${SUCCESS_DELETE_EXAM} ${examId}`,
        type: ToastType.SUCCESS
      }))
    } catch (error) {
      if (error instanceof TheiaError) {
        dispatch({ type: FAILURE_DELETE_EXAM, error })
        Sentry.captureException(new Error(`Failure to delete exam ${examId} - ${JSON.stringify(error)}`))
      }
    }
  }
}

export function uploadExamAction(consultantId: string, file: File, childId?: string):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: UPLOAD_EXAM, file })
    try {
      const formData = new FormData()
      formData.append('document', file)
      if (childId) formData.append('childId', childId)
      await prescriptionsService.uploadExam(
        consultantId,
        formData
      )
      dispatch({ type: SUCCESS_UPLOAD_EXAM, file })
      dispatch(showNotificationAction({
        message: NotificationMessage.SUCCESS_UPLOAD_EXAM,
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 2000,
        id: `SUCCESS_UPLOAD_EXAM_${file.name}`,
        type: ToastType.SUCCESS
      }))
    } catch (error) {
      dispatch({ type: FAILURE_UPLOAD_EXAM, error, file })
      Sentry.captureException(new Error(`Failure to upload exam - ${JSON.stringify(error)}`))
    }
  }
}

export function getExamDownloadUrlAction(examId: string):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: GET_EXAM_DOWNLOAD_URL })
    try {
      const examDownloadUrl = await prescriptionsService.getExamDownloadUrl(examId)
      dispatch({ type: SUCCESS_GET_EXAM_DOWNLOAD_URL, examDownloadUrl })
    } catch (error) {
      if (error instanceof TheiaError) {
        dispatch({ type: FAILURE_GET_EXAM_DOWNLOAD_URL, error })
        Sentry.captureException(new Error(`Failure to get exam download url ${examId} - ${JSON.stringify(error)}`))
      }
    }
  }
}

export const resetGetUrlStatusAction = (): ResetGetUrlStatusActionType => ({
  type: RESET_GET_URL_STATUS
})

export const resetDeleteExamStatusAction = (): ResetDeleteExamStatusActionType => ({
  type: RESET_DELETE_EXAM_STATUS
})

export function getUsgPrescriptionAction():
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: GET_USG_PRESCRIPTION })
    try {
      const usgPrescription = await prescriptionsService.getUsgPrescription()
      dispatch({ type: SUCCESS_GET_USG_PRESCRIPTION, usgPrescription })
    } catch (error) {
      dispatch({ type: FAILURE_GET_USG_PRESCRIPTION })
      Sentry.captureException(new Error(`Failure to fetch usg prescription - ${JSON.stringify(error)}`))
    }
  }
}

export function getUsgDataAction(chronosId: string, token?: string):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: GET_USG_DATA })
    try {
      const usgData = await prescriptionsService.getUsgData(chronosId, token)
      dispatch({ type: SUCCESS_GET_USG_DATA, usgData })
    } catch (error) {
      dispatch({ type: FAILURE_GET_USG_DATA })
      Sentry.captureException(new Error(`Failure to fetch usg data - ${JSON.stringify(error)}`))
    }
  }
}

export function getUsgAvailabilityAction(
  chronosId: string,
  params: UsgAvailabilityParams,
  token?: string
):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: GET_USG_AVAILABILITY })
    try {
      const usgAvailability = await prescriptionsService.getUsgAvailability(
        chronosId, params, token
      )
      dispatch({ type: SUCCESS_GET_USG_AVAILABILITY, usgAvailability })
    } catch (error) {
      dispatch({ type: FAILURE_GET_USG_AVAILABILITY })
      Sentry.captureException(new Error(`Failure to fetch usg availability - ${JSON.stringify(error)}`))
    }
  }
}

export function postUsgStatusAction(status: UsgStatus, usgId: string):
  AsyncAction<{}, { prescriptionsService: PrescriptionsService }> {
  return async (dispatch, _getState, { prescriptionsService }) => {
    dispatch({ type: POST_USG_STATUS })
    try {
      await prescriptionsService.postUsgStatus(status, usgId)
      dispatch({ type: SUCCESS_POST_USG_STATUS })
    } catch (error) {
      dispatch({ type: FAILURE_POST_USG_STATUS })
      Sentry.captureException(new Error(`Failure to post usg status - ${JSON.stringify(error)}`))
    }
  }
}

export const suppressUsgModalAction = (): PrescriptionsTypes => ({
  type: SUPPRESS_USG_MODAL,
})
