import React, { useEffect, useState } from 'react'
import { ContentCard, IconButton } from 'theia-web-ds'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PhoneConfirmIlustra from '../../../../assets/phone-confirm.png'
import { textSecondary } from '../../../color';
import { AppDispatch } from '../../../state/utils'
import { promptValidatePhoneModalAction } from '../../../state/profile/actions';
import { eventTrack } from '../../../../eventGenerate';
import { UserConfirmationInfos } from '../../../domain/User';
import { AppState } from '../../../apps/main/store';
import { StatusType } from '../../../domain/Status';
import { getUserConfirmationInfosAction } from '../../../state/authentication/main/actions';
import { ValidationCardSkelleton } from './ValidationCardSkelleton';
import './ValidationCard.scss'
import { EventCategories } from '../../../utils/EventCategories';

interface Props {
  userConfirmationInfos?: UserConfirmationInfos
  isDependent: boolean
  getUserConfirmationStatus: StatusType
  promptValidatePhoneModal: (isFromProfileEdit: boolean) => void
  getUserConfirmationInfos: () => void
}

function PhoneValidationCard({
  userConfirmationInfos,
  isDependent,
  getUserConfirmationStatus,
  promptValidatePhoneModal,
  getUserConfirmationInfos,
}: Props) {
  const [visible, setVisible] = useState(true)

  function handleClickClose() {
    eventTrack('fechou o card', {
      category: EventCategories.PHONE_VALIDATION,
    })
    setVisible(false)
  }

  function handleClickConfirmPhone() {
    eventTrack('clicou card na home', {
      category: EventCategories.PHONE_VALIDATION,
    })
    promptValidatePhoneModal(false)
  }

  useEffect(() => {
    if (visible && !isDependent && !userConfirmationInfos && !getUserConfirmationStatus.isLoading) {
      getUserConfirmationInfos()
    }
  }, [visible, getUserConfirmationStatus, userConfirmationInfos, isDependent])

  if (!visible || isDependent || !userConfirmationInfos?.phone) return null

  return getUserConfirmationStatus.isLoading ? (
    <ValidationCardSkelleton />
  ) : (
    <div className="home-validation-card">
      <ContentCard
        firstButtonProps={{
          label: 'Validar celular',
          onClick: handleClickConfirmPhone
        }}
        type="outlined"
        headerTitle="Valide seu celular para receber informações de suas consultas"
        ilustraBefore={{ src: PhoneConfirmIlustra }}
        buttonAfter={(
          <IconButton
            variant="text"
            onClick={handleClickClose}
            iconType="icon-CloseLight"
            iconColor={textSecondary}
            iconSize="24px"
          />
        )}
      />
    </div>
  )
}

const mapStateToProps = ({
  authentication, appProfiles
}: AppState) => ({
  userConfirmationInfos: authentication.userConfirmationInfos,
  isDependent: appProfiles.isDependent,
  getUserConfirmationStatus: authentication.getUserConfirmationStatus,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  promptValidatePhoneModal: promptValidatePhoneModalAction,
  getUserConfirmationInfos: getUserConfirmationInfosAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneValidationCard)
