import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { IconButton, Loader, RegularButton } from 'theia-web-ds'
import { useHistory } from 'react-router-dom'
import { AppDispatch } from '../../state/utils'
import { Professional } from '../../domain/Professional'
import { AppState } from '../../apps/main/store'
import { fetchProfessionalSummaryAction } from '../../state/professionals/actions'
import { eventPage, eventTrack } from '../../../eventGenerate'
import { AGENDAR } from '../../routes/RoutesConstants'
import ProfilePicture, { PicturePlaceholder } from '../common/molecules/ProfilePicture'
import { cleanPromotionalCodeAction } from '../../state/payment/actions'
import {
  setLoadingStepAction,
  setPreviousSelectedCategoryAction,
  setPreviousSelectedSpecialistAction,
  setScheduleSourceAction
} from '../../state/scheduleFlow/actions'
import { SpecialistCategories } from '../../domain/Specialist'
import { SourceTypes } from '../../domain/ScheduleFlow'
import './ProfessionalInfo.scss'
import { EventProfileType } from '../../domain/AppProfiles'

interface Props {
  isFetchingSummary?: boolean;
  professionalSummary?: Professional;
  id?: string;
  previousRoute?: string;
  profileType?: EventProfileType
  fetchProfessionalSummary: (id: string) => void;
  cleanPromotionalCode: () => void;
  setPreviousSelectedCategory: (category?: SpecialistCategories) => void;
  setLoadingStep: (loading: boolean) => void;
  setScheduleSource: (source: SourceTypes) => void;
  setPreviousSelectedSpecialist: (specialistId?: string, specialistName?: string) => void;
}

function ProfessionalInfo({
  professionalSummary,
  isFetchingSummary,
  id,
  previousRoute,
  profileType,
  fetchProfessionalSummary,
  cleanPromotionalCode,
  setPreviousSelectedCategory,
  setLoadingStep,
  setScheduleSource,
  setPreviousSelectedSpecialist,
}: Props) {
  const history = useHistory()

  useEffect(() => {
    eventPage('especialista', 'Especialista', { tipo_de_perfil: profileType })
    eventTrack('Visualizou tela especialista', {
      source: previousRoute
    })
    cleanPromotionalCode()
  }, [])

  useEffect(() => {
    if (id && !isFetchingSummary) {
      fetchProfessionalSummary(id)
    }
  }, [id])

  function goBack() {
    history.push(AGENDAR)
  }

  function handleClickSchedule() {
    eventTrack('Clicou Agendar Especialista')
    setPreviousSelectedCategory(professionalSummary?.category)
    setLoadingStep(true)
    setScheduleSource(SourceTypes.SPECIALIST)
    setPreviousSelectedSpecialist(undefined, professionalSummary?.name)
    history.push(AGENDAR)
  }

  return (
    <div className="professional-summary--view">
      <div className="professional-summary--scroll-view">
        <IconButton
          aria-label="Voltar"
          variant="subtle"
          width="44px"
          height="44px"
          iconType="icon-ArrowLeft2Light"
          onClick={goBack}
          extraClass="back-button"
        />
        {professionalSummary && !isFetchingSummary && (
          <>
            <div className="professional-summary--header">
              <div className="professional-summary--header-content">
                <div className="professional-summary--professional-info">
                  <ProfilePicture
                    pictureUrl={professionalSummary.pictureUrl}
                    className="professional-picture"
                    pictureSize={64}
                    picturePlaceholder={PicturePlaceholder.SPECIALIST}
                  />
                  <div className="flex-1">
                    <p className="professional-name">
                      {professionalSummary.name}
                    </p>
                    <p className="professional-category">
                      {professionalSummary.category}
                    </p>
                  </div>
                </div>
                <RegularButton
                  label=" Agendar"
                  variant="subtle"
                  iconType="icon-CalendarLight"
                  iconSize="24px"
                  maxWidth="163px"
                  onClick={handleClickSchedule}
                  extraClass="schedule-btn"
                />
              </div>
            </div>
            <div className="professional-summary--content">
              <div
                className="professional-summary--biography"
                dangerouslySetInnerHTML={{ __html: professionalSummary.biographyHtml }}
              />
            </div>
          </>
        )}
        {!professionalSummary && isFetchingSummary && (
          <div className="professional-summary--loader-container">
            <div className="professional-summary--loader">
              <Loader />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({
  professionals, routerListener, appProfiles
}: AppState,
{ id }: { id: string }
) => ({
  isFetchingSummary: professionals.isFetchingSummary,
  professionalSummary: professionals.professionalSummary,
  previousRoute: routerListener.previousRoute,
  profileType: appProfiles.profileType,
  id
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  fetchProfessionalSummary: fetchProfessionalSummaryAction,
  cleanPromotionalCode: cleanPromotionalCodeAction,
  setPreviousSelectedCategory: setPreviousSelectedCategoryAction,
  setLoadingStep: setLoadingStepAction,
  setScheduleSource: setScheduleSourceAction,
  setPreviousSelectedSpecialist: setPreviousSelectedSpecialistAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionalInfo)
