import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Loader } from 'theia-web-ds'
import { useHistory, useLocation } from 'react-router-dom'
import { AppDispatch } from '../../state/utils'
import { AppState } from '../../apps/main/store'
import { Post } from '../../domain/Content'
import { fetchPostAction } from '../../state/content/actions'
import AppHeader from '../common/AppHeader'
import './SinglePost.scss'
import { StatusType } from '../../domain/Status'
import { eventTrack } from '../../../eventGenerate'
import { EventCategories } from '../../utils/EventCategories'
import { EventProfileType } from '../../domain/AppProfiles'

interface Props {
  fetchPost: (id: string) => void;
  getSinglePostStatus: StatusType
  singlePost?: Array<Post>;
  id: string;
  profileType?: EventProfileType
}

interface RouteParams {
  title: string
  category: string
}

function SinglePost({
  fetchPost,
  getSinglePostStatus,
  singlePost,
  id,
  profileType,
}: Props) {
  const history = useHistory()
  const params = useLocation().state as RouteParams
  const postTitle = params?.title || ''
  const postCategory = params?.category || ''
  const [postUrl, setPostUrl] = useState('')

  useEffect(() => {
    if (!getSinglePostStatus.isLoading) {
      fetchPost(id)
    }
    return () => {
      eventTrack('saiu do conteudo', {
        titulo_conteudo: postTitle,
        categoria_conteudo: postCategory,
        tipo_de_perfil: profileType,
        category: EventCategories.CONTENT
      })
    }
  }, [])

  useEffect(() => {
    if (singlePost) {
      const singlePostUrl = singlePost.map((post) => post.url)
      setPostUrl(singlePostUrl[0])
    }
  }, [singlePost])

  if (getSinglePostStatus.isLoading) {
    return (
      <div className="single-post__loader-container">
        <div className="single-post__loader">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="single-post__view">
      <AppHeader title="Conteúdo" onGoBack={() => history.goBack()} />
      <div className="single-post__content">
        {postUrl === '' && <Loader />}
        <iframe src={postUrl} className="single-post__iframe" title="Single Post" />
      </div>
    </div>
  )
}

const mapStateToProps = ({ content, appProfiles }: AppState, { id }: { id: string }) => ({
  getSinglePostStatus: content.getSinglePostStatus,
  singlePost: content.singlePost,
  id,
  profileType: appProfiles.profileType
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchPost: (id: string) => { dispatch(fetchPostAction(id)) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SinglePost)
