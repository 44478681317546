import { BookingStateOptions } from '../../domain/Booking'

function isConfirmedBooking(status: string) {
  return status.toLowerCase() === 'scheduled'
    || status === BookingStateOptions.CONFIRMED
}

function isCompletedBooking(status: string) {
  return status.toLowerCase() === 'done'
    || status === BookingStateOptions.COMPLETED
    || status === BookingStateOptions.AUTO_COMPLETED
}

function isCancelledBooking(status: string) {
  return status === BookingStateOptions.CANCELLED
  || status === BookingStateOptions.CANCELLED_BY_COSTUMER
  || status === BookingStateOptions.CANCELLING_BY_COSTUMER
}

function isDismissedBooking(status: string) {
  return status.toLowerCase() === 'dismissed'
}

export default {
  isCancelledBooking,
  isConfirmedBooking,
  isCompletedBooking,
  isDismissedBooking
}
