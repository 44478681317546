import ApiClient from './ApiClient'
import {
  LoginPayload,
  UserCredentials,
  User,
  UserProvider,
  SignUpPayload,
  UserConfirmationInfos,
  VerifiedSignUpPayload,
  SignUpResponse
} from '../../domain/User'

export default class ApiUserProvider implements UserProvider {
  constructor(private apiClient: ApiClient) { }

  async getCurrentUser(token?: string): Promise<User> {
    const user = await this.apiClient.getCurrentUser(token)
    // TODO: Remove after backend is updated.
    user.timezone = 'America/Sao_Paulo'
    user.locale = 'pt-BR'

    return user
  }

  postRecaptchaGoogle(token: string): Promise<void> {
    return this.apiClient.postRecaptchaGoogle(token)
  }

  postLoginUnlock(token: string): Promise<void> {
    return this.apiClient.postLoginUnlock(token)
  }

  postEmailUnlock(login: string): Promise<void> {
    return this.apiClient.postEmailUnlock(login)
  }

  postLogin(payload: LoginPayload): Promise<UserCredentials> {
    return this.apiClient.postLogin(payload)
  }

  handleRefreshToken(refreshToken: string): Promise<UserCredentials> {
    return this.apiClient.handleRefreshToken(refreshToken)
  }

  handleSignUp(payload: SignUpPayload): Promise<SignUpResponse> {
    return this.apiClient.handleSignUp(payload)
  }

  sendRecoveryLink(email: string): Promise<void> {
    return this.apiClient.sendRecoveryLink(email)
  }

  resetAccountPassword(password: string, token: string): Promise<void> {
    return this.apiClient.resetAccountPassword(password, token)
  }

  logout(refreshToken: string): Promise<void> {
    return this.apiClient.logout(refreshToken)
  }

  verifySignUpData(signUpData: SignUpPayload): Promise<void> {
    return this.apiClient.verifySignUpData(signUpData)
  }

  verifiedSignUp(signUpData: VerifiedSignUpPayload): Promise<SignUpResponse> {
    return this.apiClient.verifiedSignUp(signUpData)
  }

  sendVerificationCode(email?: string, phone?: string): Promise<void> {
    return this.apiClient.sendVerificationCode(email, phone)
  }

  sendSignUpVerificationCode(email: string): Promise<void> {
    return this.apiClient.sendSignUpVerificationCode(email)
  }

  async getUserConfirmationInfos(): Promise<UserConfirmationInfos> {
    return this.apiClient.getUserConfirmationInfos()
  }
}
