import * as Sentry from '@sentry/browser';

import { WeekInfo, WeekSuggestionsResponse, WeekSuggestionsService } from '../../domain/WeekFlow'
import { AsyncAction } from '../utils'

export const SELECT_WEEK = 'SELECT_WEEK'
export const FETCH_WEEK_REQUESTED = 'FETCH_WEEK_REQUESTED'
export const SUCCESS_WEEK_FETCHED = 'SUCCESS_WEEK_FETCHED'
export const FAILURE_WEEK_FETCHED = 'FAILURE_WEEK_FETCHED'
export const CLOSE_SHOULD_ASK_FOR_PROFILE_DETAILS = 'CLOSE_SHOULD_ASK_FOR_PROFILE_DETAILS'
export const SUCCESS_WEEK_WITHOUT_ADITIONAL_INTELIGENCE_FETCHED = 'SUCCESS_WEEK_WITHOUT_ADITIONAL_INTELIGENCE_FETCHED'
export const SUCCESS_GET_ALL_WEEKS_INFOS = 'SUCCESS_GET_ALL_WEEKS_INFOS'

interface CloseShouldAskForProfileDetailActionType {
  type: typeof CLOSE_SHOULD_ASK_FOR_PROFILE_DETAILS;
}

interface SelectWeekActionType {
  type: typeof SELECT_WEEK;
  week: string;
}

interface RequestGetAllWeekOptionsActionType {
  type: typeof FETCH_WEEK_REQUESTED;
}

interface FailureGetAllWeekOptionsActionType {
  type: typeof FAILURE_WEEK_FETCHED;
}

interface SuccessGetAllWeekOptionsActionType {
  type: typeof SUCCESS_WEEK_FETCHED;
  weeks: WeekInfo[];
  shouldAskForProfileDetail: boolean;
  pregnancyJourney: boolean;
}

interface SuccessGetAllWeekWithoutAditionalInteligenceActionType {
  type: typeof SUCCESS_WEEK_WITHOUT_ADITIONAL_INTELIGENCE_FETCHED;
  weeksWithoutAditionalInteligence: WeekInfo[];
}

interface SuccessGetAllWeeksInfosActionType {
  type: typeof SUCCESS_GET_ALL_WEEKS_INFOS;
}

export type WeekFlowTypes = SelectWeekActionType |
  SuccessGetAllWeekOptionsActionType |
  RequestGetAllWeekOptionsActionType |
  FailureGetAllWeekOptionsActionType |
  CloseShouldAskForProfileDetailActionType |
  SuccessGetAllWeekWithoutAditionalInteligenceActionType |
  SuccessGetAllWeeksInfosActionType

export function getAllWeekOptionsAction(isJourney?: boolean):
  AsyncAction<{}, { weekSuggestionsService: WeekSuggestionsService }> {
  return async (dispatch, _getState, { weekSuggestionsService }) => {
    dispatch({ type: FETCH_WEEK_REQUESTED })
    try {
      const weekResponse: WeekSuggestionsResponse = (
        await weekSuggestionsService.getWeekSuggestions()
      )
      const {
        weeks,
        shouldAskForProfileDetail,
        pregnancyJourney
      } = weekResponse
      dispatch({
        type: SUCCESS_WEEK_FETCHED,
        weeks,
        shouldAskForProfileDetail,
        pregnancyJourney
      })
      if (isJourney) {
        const weekResponseWithoutAditionalInteligence: WeekSuggestionsResponse = (
          await weekSuggestionsService.getWeekSuggestionsToJourney()
        )
        dispatch({
          type: SUCCESS_WEEK_WITHOUT_ADITIONAL_INTELIGENCE_FETCHED,
          weeksWithoutAditionalInteligence: weekResponseWithoutAditionalInteligence.weeks,
        })
      }
      dispatch({ type: SUCCESS_GET_ALL_WEEKS_INFOS })
    } catch (error) {
      dispatch({ type: FAILURE_WEEK_FETCHED })
      Sentry.captureException(new Error(`Failure to fetch week suggestions - ${error.message}`))
    }
  }
}

export const selectWeekAction = (week: string):
SelectWeekActionType => ({
  type: SELECT_WEEK,
  week,
})

export const closeShouldAskForProfileDetailAction = ():
CloseShouldAskForProfileDetailActionType => ({
  type: CLOSE_SHOULD_ASK_FOR_PROFILE_DETAILS,
})
