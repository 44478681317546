/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Alert } from 'theia-web-ds'
import Logo from '../../../../assets/horizontal-logo.svg'
import NavItem from './NavItem'
import './Navigation.scss'
import { AppDispatch } from '../../../state/utils'
import { getFlutterClient } from '../../../utils/helpers'
import { AppState } from '../../../apps/main/store'
import { AppointedSpecialists } from '../../../domain/User'
import { eventTrack } from '../../../../eventGenerate'
import {
  AGENDAR,
  CHANGE_HEALTH_PLAN,
  CHAT,
  CONSULTAS,
  CONTENT,
  EDIT_ADDRESS,
  EDIT_DEPENDENT,
  EDIT_DEPENDENT_HEALTHPLAN,
  EDIT_PAYMENT,
  EDIT_PROFILE,
  INICIO,
  JORNADA_EXPLICADA,
  PRESCRIPTIONS_AND_EXAMS,
  PROFILE,
  SAUDE
} from '../../../routes/RoutesConstants'
import { SourceTypes } from '../../../domain/ScheduleFlow'
import { setScheduleSourceAction } from '../../../state/scheduleFlow/actions'
import { onSendHighlightAction } from '../../../state/userHighlights/actions'
import { promptPregnancyCardsDrawerAction } from '../../../state/consultant/actions'

type MenuProps = {
  route: string;
  className?: string;
  firstPregnancyCard: boolean;
  hasPregnancyCard: boolean;
  hasNewPrescription: boolean;
  sawExamsList: boolean;
  hasSpecialist?: Array<AppointedSpecialists> | [];
  insurancePlanCovered?: boolean | null;
  isDependent: boolean
  sawExamsUpload: boolean
  setScheduleSource: (source: SourceTypes) => void;
  promptPregnancyCardsDrawer: () => void;
  onSendHightlight: (hightlight: string) => void;
}

const SideBar = React.memo(({
  route,
  className,
  firstPregnancyCard,
  hasPregnancyCard,
  hasNewPrescription,
  sawExamsList,
  hasSpecialist,
  insurancePlanCovered,
  isDependent,
  sawExamsUpload,
  setScheduleSource,
  promptPregnancyCardsDrawer,
  onSendHightlight,
}: MenuProps) => {
  const history = useHistory()

  const isActiveMenuItem = (pages: Array<string>) => useMemo(() => pages
    .some((p) => window.location.pathname.includes(p)), [route])

  const initialIsActiveHome = isActiveMenuItem([INICIO, JORNADA_EXPLICADA])
  const initialIsActiveMyBookingsView = isActiveMenuItem([CONSULTAS])
  const initialIsActiveSchedule = isActiveMenuItem([AGENDAR])
  const initialIsActiveContent = isActiveMenuItem([CONTENT, `${CONTENT}/:title/:id`])
  const initialIsActiveChat = isActiveMenuItem([CHAT])
  const initialIsActiveProfile = isActiveMenuItem([
    PROFILE,
    EDIT_PROFILE,
    EDIT_ADDRESS,
    EDIT_PAYMENT,
    EDIT_DEPENDENT,
    EDIT_DEPENDENT_HEALTHPLAN,
    CHANGE_HEALTH_PLAN
  ])
  const initialIsActiveHealth = isActiveMenuItem([SAUDE, PRESCRIPTIONS_AND_EXAMS])

  const handleClick = (newRoute: string) => {
    localStorage.removeItem('bookingSuggestion')
    localStorage.removeItem('scheduleSuggestion')
    history.push(newRoute)
  }

  const handleClickHealth = () => {
    eventTrack('Clicou Saúde')
    handleClick(SAUDE)
  }

  const handleClickPregnancyCard = () => {
    eventTrack('Clicou Cartão da Gestante')
    promptPregnancyCardsDrawer()
    if (firstPregnancyCard) onSendHightlight('firstPregnancyCard')
  }

  const handleClickChat = () => {
    eventTrack('Clicou Chat')
    handleClick(CHAT)
  }

  const handleClickHome = () => {
    eventTrack('Clicou Início')
    handleClick(INICIO)
  }

  const handleClickMyBookingsView = () => {
    eventTrack('Clicou Consultas')
    handleClick(CONSULTAS)
  }

  const handleClickSchedule = () => {
    eventTrack('Clicou Agendar')
    setScheduleSource(SourceTypes.MENU)
    handleClick(AGENDAR)
  }

  const handleClickContent = () => {
    handleClick(CONTENT)
  }

  const handleClickProfile = () => {
    eventTrack('Clicou Perfil')
    handleClick(PROFILE)
  }
  if (getFlutterClient()) return null

  return (
    <div className={`sidebar-content ${className}`}>
      <div className="menu__logo">
        <Logo className="logo" alt="" />
      </div>
      <ul role="menu" aria-label="Menu">
        <NavItem
          handleClick={handleClickHome}
          isActive={initialIsActiveHome}
          text="Início"
          activeIconType="icon-WeekBold"
          iconType="icon-WeekLight"
          variant="sidebar"
        />
        <NavItem
          handleClick={handleClickSchedule}
          isActive={initialIsActiveSchedule}
          text="Agendar"
          activeIconType="icon-CalendarBold"
          iconType="icon-CalendarLight"
          variant="sidebar"
        />
        {((hasSpecialist !== undefined && hasSpecialist.length > 0)
            || (insurancePlanCovered !== null && insurancePlanCovered))
            && (
            <NavItem
              handleClick={handleClickChat}
              isActive={initialIsActiveChat}
              text="Chat"
              activeIconType="icon-TheiaLight"
              iconType="icon-TheiaLight"
              variant="sidebar"
            />
            )}
        <NavItem
          handleClick={handleClickMyBookingsView}
          isActive={initialIsActiveMyBookingsView}
          text="Consultas"
          activeIconType="icon-PhonendoscopeBold"
          iconType="icon-PhonendoscopeLight"
          variant="sidebar"
        />
        {hasPregnancyCard && !isDependent ? (
          <div className="relative">
            <NavItem
              handleClick={handleClickPregnancyCard}
              isActive={false}
              text="Cartão da gestante"
              iconType="icon-IdLight"
              activeIconType="icon-IdBold"
              variant="sidebar"
              hasNotificationOnIcon={firstPregnancyCard}
            />
            {firstPregnancyCard ? (
              <Alert
                message="Novidade! Agora você tem acesso ao seu Cartão da Gestante online."
                extraClass="pregnancy-card-popup"
                variant="flowting"
                indicator="right-bottom"
                timer={5000}
              />
            ) : null}
          </div>
        ) : null}
        <div className="relative">
          <NavItem
            handleClick={handleClickHealth}
            isActive={initialIsActiveHealth}
            text="Saúde"
            activeIconType="icon-MedicalRecordBold"
            iconType="icon-MedicalRecordLight"
            variant="sidebar"
            hasNotificationOnIcon={hasNewPrescription || !sawExamsList}
          />
          {!sawExamsUpload ? (
            <Alert
              message="Novidade! Agora você pode enviar os seus exames no menu saúde."
              extraClass="upload-exams-popup"
              variant="flowting"
              indicator="left-bottom"
              timer={5000}
            />
          ) : null}
        </div>
        <NavItem
          handleClick={handleClickContent}
          isActive={initialIsActiveContent}
          text="Conteúdo"
          activeIconType="icon-PaperBold"
          iconType="icon-PaperLight"
          variant="sidebar"
        />
        <NavItem
          handleClick={handleClickProfile}
          isActive={initialIsActiveProfile}
          text="Perfil"
          activeIconType="icon-ProfileBold"
          iconType="icon-ProfileLight"
          variant="sidebar"
        />
      </ul>
    </div>
  )
})

const mapStateToProps = ({
  authentication, userHighlights, healthplan, appProfiles
}: AppState) => ({
  firstPregnancyCard: userHighlights.firstPregnancyCard,
  hasPregnancyCard: userHighlights.hasPregnancyCard,
  hasNewPrescription: userHighlights.highlightHealthMenuItem,
  hasSpecialist: authentication.currentUser?.appointedSpecialists,
  insurancePlanCovered: healthplan.healthInsurancePlanData?.insurancePlanCovered,
  sawExamsList: userHighlights.sawExamsList,
  sawExamsUpload: userHighlights.sawExamsUpload,
  isDependent: appProfiles.isDependent,
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  setScheduleSource: setScheduleSourceAction,
  promptPregnancyCardsDrawer: promptPregnancyCardsDrawerAction,
  onSendHightlight: onSendHighlightAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar)
