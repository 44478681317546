import { initialStatus, StatusType } from '../../domain/Status'
import { UserHighlights } from '../../domain/UserHighlights'
import {
  FAILURE_GET_HIGHLIGHTS, FAILURE_POST_NOTIFICATION_CONFIRMATION,
  FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS,
  GET_HIGHLIGHTS,
  POST_NOTIFICATION_CONFIRMATION,
  POST_PRESCRIPTIONS_HIGHLIGHTS,
  SET_SAW_EXAMS_LIST,
  SET_SAW_EXAMS_UPLOAD,
  SUCCESS_GET_HIGHLIGHTS,
  SUCCESS_POST_NOTIFICATION_CONFIRMATION,
  SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS,
  RESET_HIGHLIGHTS_STATUS,
  UserHighlightsType
} from './actions'

export interface UserHighlightsState extends UserHighlights {
  getUserHighlightsStatus: StatusType;
  postUserHighlightsStatus: StatusType;
  sawExamsList: boolean;
  sawExamsUpload: boolean;
}

const initialUserHighlightsState: UserHighlightsState = {
  maternalDesire: 'DONT_SHOW',
  firstTimePrescriptionsFeature: false,
  hasUSGRequest: false,
  highlightChatMenuItem: false,
  highlightHealthMenuItem: false,
  highlightPlusMenu: false,
  babyJourney: false,
  needToCreateBaby: false,
  getUserHighlightsStatus: initialStatus,
  postUserHighlightsStatus: initialStatus,
  firstPregnancyCard: false,
  hasPregnancyCard: false,
  sawExamsList: !!(localStorage.getItem('sawExamsList')),
  sawExamsUpload: !!(localStorage.getItem('sawExamsUpload')),
}

export function userHighlights(
  state: UserHighlightsState = initialUserHighlightsState,
  action: UserHighlightsType
): UserHighlightsState {
  switch (action.type) {
    case GET_HIGHLIGHTS: {
      return {
        ...state,
        getUserHighlightsStatus: {
          ...initialStatus,
          isLoading: true
        }
      }
    }
    case SUCCESS_GET_HIGHLIGHTS: {
      return {
        ...state,
        ...action.highlights,
        maternalDesire: action.highlights.maternalDesire,
        needToCreateBaby: action.highlights.needToCreateBaby,
        getUserHighlightsStatus: {
          ...initialStatus,
          success: true
        }
      }
    }
    case FAILURE_GET_HIGHLIGHTS: {
      return {
        ...state,
        getUserHighlightsStatus: {
          ...initialStatus,
          error: true
        }
      }
    }
    case RESET_HIGHLIGHTS_STATUS: {
      return {
        ...state,
        getUserHighlightsStatus: initialStatus
      }
    }
    case POST_PRESCRIPTIONS_HIGHLIGHTS: {
      return {
        ...state,
        postUserHighlightsStatus: {
          ...initialStatus,
          isLoading: true
        }
      }
    }
    case SUCCESS_POST_PRESCRIPTIONS_HIGHLIGHTS: {
      return {
        ...state,
        postUserHighlightsStatus: {
          ...initialStatus,
          success: true
        }
      }
    }
    case FAILURE_POST_PRESCRIPTIONS_HIGHLIGHTS: {
      return {
        ...state,
        postUserHighlightsStatus: {
          ...initialStatus,
          error: true
        }
      }
    }
    case POST_NOTIFICATION_CONFIRMATION: {
      return {
        ...state,
      }
    }
    case SUCCESS_POST_NOTIFICATION_CONFIRMATION: {
      return {
        ...state,
      }
    }
    case FAILURE_POST_NOTIFICATION_CONFIRMATION: {
      return {
        ...state,
      }
    }
    case SET_SAW_EXAMS_LIST: {
      localStorage.setItem('sawExamsList', 'true')
      return {
        ...state,
        sawExamsList: true
      }
    }
    case SET_SAW_EXAMS_UPLOAD: {
      localStorage.setItem('sawExamsUpload', 'true')
      return {
        ...state,
        sawExamsUpload: true
      }
    }
    default: return state
  }
}
