/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { RegularButton, Loader } from 'theia-web-ds'
import { success } from '../../../color'
import HealthPlanAccepted from '../../../../assets/Yes-Plancard.svg'
import { AppState } from '../../../apps/main/store'
import {
  HealthInsurancePlanData
} from '../../../domain/Healthplan'
import { HealthPlanFaqAndBenefits } from './HealthplanFaqAndBenefits'
import { eventPage, eventTrack } from '../../../../eventGenerate'
import { AppDispatch } from '../../../state/utils'
import { clearHealthInsurancePlanStatusAction, getHealthInsurancePlanDataAction } from '../../../state/healthplanProfile/actions'
import IconWhatsapp from '../../../../assets/icon-whatsapp.png'
import { Booking } from '../../../domain/Booking'
import { AGENDAR, INICIO } from '../../../routes/RoutesConstants'
import { SourceTypes } from '../../../domain/ScheduleFlow'
import { setScheduleSourceAction } from '../../../state/scheduleFlow/actions'
import BackButton from '../../common/buttons/BackButton'
import { StatusType } from '../../../domain/Status'
import { ToastInfos, ToastType } from '../../../domain/Notifications'
import { showNotificationAction } from '../../../state/notifications/actions'
import { EventProfileType } from '../../../domain/AppProfiles'

interface Props {
  postHealthInsurancePlanStatus: StatusType;
  getHealthInsurancePlanStatus: StatusType;
  healthInsurancePlanData: HealthInsurancePlanData | null;
  bookings: Array<Booking>;
  profileType?: EventProfileType
  getHealthInsurancePlan: () => void;
  clearHealthInsurancePlanStatus: () => void;
  setScheduleSource: (source: SourceTypes) => void;
  showNotification: (toastInfos: ToastInfos) => void;
}

interface LocationProps {
  isFromFeedbackChangePlan: boolean;
  hasPreviousInsurancePlan: boolean;
}

function HealthPlanIncluded({
  postHealthInsurancePlanStatus,
  getHealthInsurancePlanStatus,
  healthInsurancePlanData,
  bookings,
  profileType,
  getHealthInsurancePlan,
  clearHealthInsurancePlanStatus,
  setScheduleSource,
  showNotification
}: Props) {
  const history = useHistory()
  const location = useLocation().state as LocationProps
  const isFromFeedbackChangePlan = location?.isFromFeedbackChangePlan || false
  const hasPreviousInsurancePlan = location?.hasPreviousInsurancePlan || false
  const bookingsToShow: Booking[] = bookings.length > 2 ? [bookings[0], bookings[1]] : bookings
  const companyName = healthInsurancePlanData?.healthInsurancePlan?.company
  const planName = healthInsurancePlanData?.healthInsurancePlan?.planName
  const isCovered = !!(healthInsurancePlanData?.insurancePlanCovered)
  const benefitsSubtitle = healthInsurancePlanData?.benefitsSubtitle
  const includedCategories = healthInsurancePlanData?.benefits?.map(
    (item) => item.categoryName
  ).filter((categoryName) => categoryName !== null).length || 0

  useEffect(() => {
    if (!isFromFeedbackChangePlan || (!healthInsurancePlanData && !getHealthInsurancePlanStatus.isLoading)) {
      getHealthInsurancePlan()
    }
    return () => clearHealthInsurancePlanStatus()
  }, [])

  useEffect(() => {
    if (isFromFeedbackChangePlan) {
      showNotification({
        message: `Tudo certo! Seu plano de saúde foi ${hasPreviousInsurancePlan ? 'atualizado' : 'adicionado'} com sucesso.`,
        iconColor: success,
        iconType: 'icon-TickRoundLight',
        timer: 5000,
        id: 'SUCCESS_UPDATE_HEALTH_PLAN',
        type: ToastType.SUCCESS,
      })
    }
  }, [location])

  useEffect(() => {
    eventPage('incluso no plano', 'incluso no plano', { tipo_de_perfil: profileType })
  }, [])

  const goToSchedule = () => {
    eventTrack('Clicou Agendar consulta')
    setScheduleSource(SourceTypes.HEALTHPLAN_INCLUDED)
    history.push(AGENDAR)
  }

  function goToPage(link: string) {
    eventTrack('Clicou Falar com a gente')
    window.open(link, '_blank')
  }

  function goToLocationUsingHistoryPush(link: string, flutterKey?: string) {
    if (flutterKey === 'LIST_ITEM_WITH_CATEGORY') {
      eventTrack('Clicou Agendar Agora')
    } else {
      eventTrack('Clicou Agendar Depois')
    }
    history.push(link)
  }

  if (healthInsurancePlanData && (postHealthInsurancePlanStatus.success || getHealthInsurancePlanStatus.success)
  ) {
    return (
      <div className="flex flex-1 relative">
        <div className="mx-auto w-full">
          <div className="sticky flex items-center bg-bgCanvas top-0 z-10 h-20">
            <BackButton text="" to="/profile" extraClass="back-button-svg" />
          </div>
          <div className="lg:max-w-xl mx-auto w-full">
            <div className="flex p-4 health-plan-welcome-card">
              <div><HealthPlanAccepted /></div>
              <div className="pl-4">
                <p className="text-fontDefault text-textPrimary font-medium">
                  Plano {companyName} {planName}
                </p>
                {!isCovered || !benefitsSubtitle ? (
                  <p className="text-fontDefault text-textSecondary">
                    {includedCategories <= 1 && (`Você pode agendar consultas com ${includedCategories} especialidade sem custo adicional.`)}
                    {includedCategories > 1 && (`Você pode agendar consultas com ${includedCategories} especialidades sem custo adicional.`)}
                  </p>
                ) : (
                  <p className="text-fontDefault text-textSecondary"> {benefitsSubtitle} </p>
                )}
              </div>
            </div>
            <div className="px-4">
              <RegularButton
                label="Agendar agora"
                extraClass="mt-6 mb-8 mx-auto button-healthplan-feedback"
                onClick={goToSchedule}
              />
            </div>
            <p className="text-fontDefault font-medium mb-4 text-left mx-4">
              Está incluso no seu plano
            </p>
            <HealthPlanFaqAndBenefits
              benefits={healthInsurancePlanData.benefits}
              iconColor={success}
              iconType="icon-TickRoundLight"
              screen="health-plan-included"
            />
          </div>
          {bookingsToShow.length === 0 && (
            <div className="mx-4">
              <img src={IconWhatsapp} alt="Whatsapp" className="mb-4 mt-6 mx-auto rounded-full" />
              <p className="text-textMedium text-center font-normal justify-center mt-4 mb-2">
                Ainda em dúvida?
              </p>
              <p className="text-fontDefault text-textSecondary text-center">
                Nosso time está pronto para te atender <span role="img" aria-label="carinha mandando um abraço">🤗</span>
              </p>
              <RegularButton
                onClick={() => goToPage('https://atendimento.theia.com.br/whatsapp')}
                variant="subtle"
                label="Falar com a gente"
                extraClass="my-6 button-healthplan-feedback mx-auto"
              />
              <RegularButton
                onClick={() => goToLocationUsingHistoryPush(INICIO, 'GO_TO_HOME')}
                variant="text"
                label="Agendar depois"
                extraClass="mb-6 button-healthplan-feedback mx-auto"
              />
            </div>
          )}
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-1 relative pt-20">
      <div className="mx-auto w-full flex items-center justify-center">
        <Loader />
      </div>
    </div>
  )
}

const mapStateToProps = ({
  healthplan, myBookingsFlow, appProfiles
}: AppState) => ({
  postHealthInsurancePlanStatus: healthplan.postHealthInsurancePlanStatus,
  getHealthInsurancePlanStatus: healthplan.getHealthInsurancePlanStatus,
  healthInsurancePlanData: healthplan.healthInsurancePlanData,
  bookings: myBookingsFlow.allBookings,
  profileType: appProfiles.profileType
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  getHealthInsurancePlan: getHealthInsurancePlanDataAction,
  clearHealthInsurancePlanStatus: clearHealthInsurancePlanStatusAction,
  setScheduleSource: setScheduleSourceAction,
  showNotification: showNotificationAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthPlanIncluded)
