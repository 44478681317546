import React, { useEffect, useState } from 'react'
import { RegularButton, AccessibleModal } from 'theia-web-ds'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AcceptHealthPlan from '../../../assets/PlanCard-Yes-2.svg'
import { AppDispatch } from '../../state/utils'
import { setForceUpdateCurrentUserAction } from '../../state/authentication/main/actions'
import { openModalAction, closeModalAction } from '../../state/onboarding/actions'
import { AppState } from '../../apps/main/store'
import { eventTrack } from '../../../eventGenerate'
import { CHANGE_HEALTH_PLAN } from '../../routes/RoutesConstants'
import './HealthPlanModal.scss'
import { getAcceptedHealthCompaniesAction, postUserHealthInsuranceAction } from '../../state/healthplanProfile/actions'
import { HealthInsurancePlanData, PostUserHealthPlanType } from '../../domain/Healthplan'

interface Props {
  forceUpdateCurrentUser: () => void;
  onPostHealthInsurance: (
    healthPlanData: PostUserHealthPlanType,
    alreadyHasInsurancePlan: boolean
  ) => void;
  openModal: () => void;
  isModal?: boolean;
  closeModal: () => void;
  isUserFetched?: boolean;
  shouldEnableModal?: boolean;
  acceptedHealthCompanies?: Array<string>;
  healthInsurancePlanData: HealthInsurancePlanData | null;
  isDependent: boolean
  getAcceptedHealthCompanies: () => void;
}

type ModalType = 'GENERIC' | 'LEGACYHEALTHPLAN'

function HealthPlanModal({
  forceUpdateCurrentUser,
  onPostHealthInsurance,
  openModal,
  isModal,
  closeModal,
  isUserFetched,
  shouldEnableModal,
  acceptedHealthCompanies,
  healthInsurancePlanData,
  isDependent,
  getAcceptedHealthCompanies,
}: Props) {
  const history = useHistory()
  const alreadyHasInsurancePlan = healthInsurancePlanData?.hasHealthInsurancePlan
  const healthInsuranceCompany = healthInsurancePlanData?.healthInsurancePlan?.company
  const healthInsurancePlanName = healthInsurancePlanData?.healthInsurancePlan?.planName
  const shouldOpenLegacyHealthPlanModal = !!(healthInsuranceCompany
    && acceptedHealthCompanies
    && acceptedHealthCompanies.includes(healthInsuranceCompany))
    && healthInsurancePlanName === ''
  const [page, setModalType] = useState<ModalType>(
    shouldOpenLegacyHealthPlanModal ? 'LEGACYHEALTHPLAN' : 'GENERIC'
  )

  useEffect(() => {
    if (!acceptedHealthCompanies) {
      getAcceptedHealthCompanies()
    }
  }, [])

  useEffect(() => {
    if (alreadyHasInsurancePlan === null) {
      setModalType('GENERIC')
    }
  }, [])

  useEffect(() => {
    if (acceptedHealthCompanies) {
      if (shouldEnableModal && isUserFetched && !isDependent && (
        alreadyHasInsurancePlan === null || shouldOpenLegacyHealthPlanModal
      )) {
        openModal()
        if (page === 'LEGACYHEALTHPLAN') {
          eventTrack(`Abriu Modal ${healthInsuranceCompany} Legado`)
        } else {
          eventTrack('Abriu Modal Onboarding')
        }
      }
    }
  }, [acceptedHealthCompanies])

  const noHealthPlan = () => {
    onPostHealthInsurance({
      hasHealthInsurancePlan: false,
      healthInsurancePlan: {
        company: null,
        companyId: null,
        memberId: null,
        planId: null,
        planName: null,
      }
    }, !!(alreadyHasInsurancePlan))
    forceUpdateCurrentUser()
    if (page === 'LEGACYHEALTHPLAN') {
      eventTrack(`Sem plano ${healthInsuranceCompany} Legado`)
    } else {
      eventTrack('Sem plano de saúde Modal Onboarding')
    }
    closeModal()
  }

  const addLegacyHealthPlan = () => {
    history.push(CHANGE_HEALTH_PLAN, { isStillLegacyPlan: true })
    eventTrack(`Adicionou plano ${healthInsuranceCompany} Legado`)
    closeModal()
  }

  const changeHealthPlan = () => {
    eventTrack(`Trocou plano ${healthInsuranceCompany} Legado`)
    history.push(CHANGE_HEALTH_PLAN, { isStillLegacyPlan: false })
    closeModal()
  }

  const addHealthPlan = () => {
    eventTrack('Adicionou Plano Modal Onboarding')
    history.push(CHANGE_HEALTH_PLAN)
    closeModal()
  }

  const onCloseModal = () => {
    if (page === 'LEGACYHEALTHPLAN') {
      eventTrack(`Fechou Modal ${healthInsuranceCompany} Legado`)
    } else {
      eventTrack('Fechou Modal Onboarding')
    }
    closeModal()
  }

  const modalLegacyHealthPlan = (
    <div className="flex flex-col items-center h-full">
      <AcceptHealthPlan className="health-plan-svg mb-2" />
      <h2 className="text-fontSmall text-center my-2">
        Boa notícia! Agora aceitamos o plano de saúde da {healthInsuranceCompany}
      </h2>
      <p className="text-fontDefault text-center my-2">Adicione seu plano e confira quais profissionais estão inclusos. </p>
      <div className="flex flex-col w-full h-full mx-2 justify-end">
        <RegularButton
          variant="primary"
          label="Adicionar Plano"
          extraClass="my-2"
          onClick={addLegacyHealthPlan}
        />
        <RegularButton
          variant="subtle"
          label="Troquei de plano"
          extraClass="my-2"
          onClick={changeHealthPlan}
        />
        <RegularButton
          variant="text"
          label="Não tenho mais plano de saúde"
          extraClass="my-2"
          onClick={noHealthPlan}
        />
      </div>
    </div>
  )

  const modalGeneric = (
    <div className="flex flex-col items-center h-full">
      <AcceptHealthPlan className="health-plan-svg mb-2" />
      <h2 className="text-fontSmall text-center my-2">Boa notícia! Agora aceitamos planos de saúde na Theia</h2>
      <p className="text-fontDefault text-center my-2">Adicione seu plano e confira quais profissionais estão inclusos.</p>
      <div className="flex flex-col w-full h-full mx-2 justify-end">
        <RegularButton
          variant="primary"
          label="Adicionar plano"
          extraClass="my-2"
          onClick={addHealthPlan}
        />
        <RegularButton
          variant="text"
          label="Não tenho plano de saúde"
          extraClass="my-2"
          onClick={noHealthPlan}
        />
      </div>
    </div>
  )

  return (
    isModal ? (
      <AccessibleModal
        headerText=""
        onClose={onCloseModal}
        visible={isModal}
        extraModalContainerClass="health-plan"
        variant="centerBottom"
      >
        {page === 'LEGACYHEALTHPLAN' && modalLegacyHealthPlan}
        {page === 'GENERIC' && modalGeneric}
      </AccessibleModal>
    ) : null
  )
}
const mapStateToProps = ({
  onboarding,
  authentication,
  healthplan,
  appProfiles
}: AppState) => ({
  isModal: onboarding?.isModal,
  isUserFetched: authentication.isUserFetched,
  shouldEnableModal: healthplan.shouldEnableModal,
  acceptedHealthCompanies: healthplan.acceptedHealthCompanies,
  healthInsurancePlanData: healthplan.healthInsurancePlanData,
  isDependent: appProfiles.isDependent
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  forceUpdateCurrentUser: setForceUpdateCurrentUserAction,
  onPostHealthInsurance: postUserHealthInsuranceAction,
  openModal: openModalAction,
  closeModal: closeModalAction,
  getAcceptedHealthCompanies: getAcceptedHealthCompaniesAction
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthPlanModal)
