import React from 'react'
import './ValidationCardSkelleton.scss'

export function ValidationCardSkelleton() {
  return (
    <div className="validation-card-skelleton__container">
      <div className="validation-card-skelleton__card">
        <div className="validation-card-skelleton__top-content">
          <div className="validation-card-skelleton__image skeleton" />
          <div className="validation-card-skelleton__title skeleton" />
          <div className="validation-card-skelleton__close-btn skeleton" />
        </div>
        <div className="validation-card-skelleton__subtitle skeleton" />
        <div className="validation-card-skelleton__divider skeleton" />
        <div className="validation-card-skelleton__action-btn skeleton" />
      </div>
    </div>
  )
}
