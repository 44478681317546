/* eslint-disable indent */
import {
  User,
  UserLoginStatus,
  UnauthenticatedModalError,
  UserConfirmationInfos,
  SignUpPayload,
  TOKEN_ACCESS,
  TOKEN_REFRESH
} from '../../../domain/User'
import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  AuthenticationActionTypes,
  ACCESS_ONBOARDING,
  CLICK_ONBOARDING,
  ARROW_CLICKED,
  FREE_MVP_CONTRACT,
  SUPPRESS_RECOVERY_MODAL,
  PROMPT_RECOVERY_MODAL,
  CLOSE_COOKIE_INFO,
  PROMPT_PRIVACY_MODAL,
  SUPPRESS_PRIVACY_MODAL,
  LOGOUT_FAILED,
  CLEAR_LOGOUT_FAILED,
  FETCHING_CURRENT_USER,
  REQUEST_LOGOUT,
  SUPPRESS_TERMS_MODAL,
  PROMPT_TERMS_MODAL,
  SUPPRESS_COOKIES_MODAL,
  PROMPT_COOKIES_MODAL,
  POST_LOGIN_UNLOCK,
  SUCCESS_POST_LOGIN_UNLOCK,
  FAILURE_POST_LOGIN_UNLOCK,
  POST_EMAIL_UNLOCK,
  SUCCESS_POST_EMAIL_UNLOCK,
  FAILURE_POST_EMAIL_UNLOCK,
  SET_FORCE_UPDATE_CURRENT_USER,
  CLEAR_FORCE_UPDATE_CURRENT_USER,
  PROMPT_HEALTH_DATA_MODAL,
  SUPPRESS_HEALTH_DATA_MODAL,
  POST_LOGIN,
  SUCCESS_POST_LOGIN,
  FAILURE_POST_LOGIN,
  REFRESH_TOKEN,
  SUCCESS_REFRESH_TOKEN,
  FAILURE_REFRESH_TOKEN,
  AUTHENTICATING,
  PROMPT_UNAUTHENTICATED_MODAL,
  SUPPRESS_UNAUTHENTICATED_MODAL,
  HANDLE_SIGNUP,
  FAILURE_HANDLE_SIGNUP,
  SUCCESS_HANDLE_SIGNUP,
  SEND_RECOVERY_LINK,
  SUCCESS_SEND_RECOVERY_LINK,
  FAILURE_SEND_RECOVERY_LINK,
  RESET_ACCOUNT_PASSWORD,
  SUCCESS_RESET_ACCOUNT_PASSWORD,
  FAILURE_RESET_ACCOUNT_PASSWORD,
  VERIFY_SIGNUP_DATA,
  SUCCESS_VERIFY_SIGNUP_DATA,
  FAILURE_VERIFY_SIGNUP_DATA,
  CLEAR_VERIFY_DATA_STATUS,
  VERIFIED_SIGNUP,
  SUCCESS_VERIFIED_SIGNUP,
  FAILURE_VERIFIED_SIGNUP,
  CLEAR_SIGN_UP_ERRORS,
  GET_USER_CONFIRMATION_INFOS,
  SUCCESS_GET_USER_CONFIRMATION_INFOS,
  FAILURE_GET_USER_CONFIRMATION_INFOS,
  SEND_VERIFICATION_CODE,
  SUCCESS_SEND_VERIFICATION_CODE,
  FAILURE_SEND_VERIFICATION_CODE,
  CLEAR_VERIFICATION_CODE_STATUS
} from './actions'
import {
  SUCCESS_UPDATE_PROFILE,
  SuccessUpdateProfileActionType,
  SUCCESS_UPLOAD_PICTURE_PROFILE,
  SuccessUploadPictureProfileType,
  SUCCESS_DELETE_PICTURE_PROFILE,
  SuccessDeletePictureProfileType,
  SUCCESS_UPDATE_PROFILE_INFOS,
  SuccessUpdateProfileInfosActionType
} from '../../profile/actions'
import { StatusType, PostStatusType, initialStatus } from '../../../domain/Status'

export interface AuthenticationState {
  authenticated: boolean;
  isAuthenticating: boolean;
  currentUser?: User;
  clickOnboardingItem: any;
  accessOnboardingItem: number;
  arrowClicked: string;
  forceUpdateCurrentUser: boolean;
  // New Onboarding Flow
  freeMvpContract: boolean;
  showRecoveryModal: boolean;
  showCookieInfo: boolean;
  showPrivacyModal: boolean;
  isLogoutFailed: boolean;
  isFetchingUser: boolean;
  isLogoutLoading: boolean;
  showTermsModal: boolean;
  showHealthDataModal: boolean;
  showCookiesModal: boolean;
  isUserFetched: boolean;
  isSuccess: boolean;
  isFailure: boolean;
  isUnlockingLogin: boolean;
  unlockErrorMessage?: string;
  resendLink?: boolean;
  isSuccessEmail: boolean;
  isFailureEmail: boolean;
  redirect?: boolean;
  isLoading: boolean;
  postLoginStatus: UserLoginStatus;
  postRefreshTokenStatus: PostStatusType;
  currentRefreshToken: string;
  unauthenticatedModalError?: UnauthenticatedModalError;
  signUpStatus: UserLoginStatus;
  consultantId: string;
  postPasswordRecoveryStatus: PostStatusType;
  resetAccountPasswordStatus: StatusType
  verifySignUpDataStatus: StatusType;
  signUpUserData?: SignUpPayload;
  verifiedSignUpStatus: StatusType;
  getUserConfirmationStatus: StatusType;
  userConfirmationInfos?: UserConfirmationInfos;
  sendVerificationCodeStatus: StatusType;
}

const initialAuthenticationState: AuthenticationState = {
  authenticated: false,
  isAuthenticating: false,
  currentUser: undefined,
  clickOnboardingItem: null,
  accessOnboardingItem: 0,
  arrowClicked: '',
  forceUpdateCurrentUser: false,
  showRecoveryModal: false,
  // New Onboarding Flow
  freeMvpContract: false,
  showCookieInfo: localStorage.getItem('CookiesInfoWasShown') !== 'true',
  showPrivacyModal: false,
  isLogoutFailed: false,
  isFetchingUser: false,
  isLogoutLoading: false,
  showTermsModal: false,
  showHealthDataModal: false,
  showCookiesModal: false,
  isUserFetched: false,
  isSuccess: false,
  isFailure: false,
  isUnlockingLogin: true,
  unlockErrorMessage: undefined,
  resendLink: false,
  isSuccessEmail: false,
  isFailureEmail: false,
  redirect: false,
  isLoading: false,
  postLoginStatus: {
    isPost: false,
    isPosting: false,
    errorPost: false,
    errorData: undefined
  },
  postRefreshTokenStatus: {
    isPost: false,
    isPosting: false,
    errorPost: false
  },
  currentRefreshToken: '',
  unauthenticatedModalError: undefined,
  signUpStatus: {
    isPost: false,
    isPosting: false,
    errorPost: false,
    errorData: undefined
  },
  consultantId: '',
  postPasswordRecoveryStatus: {
    isPost: false,
    isPosting: false,
    errorPost: false
  },
  resetAccountPasswordStatus: initialStatus,
  verifySignUpDataStatus: {
    isLoading: false,
    success: false,
    error: false,
    errorMessage: undefined
  },
  signUpUserData: undefined,
  verifiedSignUpStatus: {
    isLoading: false,
    success: false,
    error: false,
    errorMessage: undefined
  },
  sendVerificationCodeStatus: {
    isLoading: false,
    success: false,
    error: false,
  },
  getUserConfirmationStatus: {
    isLoading: false,
    success: false,
    error: false
  },
  userConfirmationInfos: undefined,
}

export function authentication(
  state = initialAuthenticationState,
  action: (
    AuthenticationActionTypes
  | SuccessUpdateProfileActionType
  | SuccessUploadPictureProfileType
  | SuccessDeletePictureProfileType
  | SuccessUpdateProfileInfosActionType
  )
): AuthenticationState {
  switch (action.type) {
    case FETCHING_CURRENT_USER:
      return {
        ...state,
        isFetchingUser: true,
        isUserFetched: false
      }
    case AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        isAuthenticating: false,
        isFetchingUser: false,
        currentUser: action.currentUser,
        isUserFetched: true
      }
    case AUTHENTICATING: {
      return {
        ...state,
        isAuthenticating: true
      }
    }
    case UNAUTHENTICATED:
      localStorage.removeItem(TOKEN_ACCESS)
      localStorage.removeItem(TOKEN_REFRESH)
      return {
        ...state,
        currentUser: undefined,
        authenticated: false,
        isAuthenticating: false,
        isLogoutLoading: false,
        isUserFetched: false
      }
    case REQUEST_LOGOUT:
      return {
        ...state,
        isLogoutLoading: true,
      }
    case ACCESS_ONBOARDING:
      return {
        ...state,
        accessOnboardingItem: action.accessOnboardingItem,
      }
    case CLICK_ONBOARDING:
      return {
        ...state,
        clickOnboardingItem: action.clickOnboardingItem,
      }
    case ARROW_CLICKED:
      return {
        ...state,
        clickOnboardingItem: action.clickOnboardingItem,
        arrowClicked: action.arrowClicked
      }
    case SUCCESS_UPDATE_PROFILE: {
      if (!state.currentUser) return state
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          name: action.profile.name,
          phone: action.profile.phone,
          email: action.profile.email,
          birthDate: action.profile.birthDate,
          document: action.profile.document,
          nationalIdentityCard: action.profile.nationalIdentityCard
        }
      }
    }
    case SUCCESS_UPDATE_PROFILE_INFOS: {
      // TODO: Remover o controle de dados do perfil do auth e passar para profile
      if (!state.currentUser) return state
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          name: action.profileInfos.name,
          phone: action.profileInfos.phone,
          email: action.profileInfos.email,
          birthDate: action.profileInfos.birthDate,
          document: action.profileInfos.document,
          nationalIdentityCard: action.profileInfos.nationalIdentityCard
        }
      }
    }
    case SUCCESS_UPLOAD_PICTURE_PROFILE: {
      if (!state.currentUser) return state
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          pictureUrl: action.pictureUrl,
        }
      }
    }
    case SUCCESS_DELETE_PICTURE_PROFILE: {
      if (!state.currentUser) return state
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          pictureUrl: null,
        }
      }
    }
    case PROMPT_RECOVERY_MODAL: {
      return {
        ...state,
        showRecoveryModal: true,
      }
    }
    case SUPPRESS_RECOVERY_MODAL: {
      return {
        ...state,
        showRecoveryModal: false,
      }
    }
    case CLOSE_COOKIE_INFO:
      return {
        ...state,
        showCookieInfo: false,
      }
    case PROMPT_PRIVACY_MODAL:
      return {
        ...state,
        showPrivacyModal: true,
      }
    case SUPPRESS_PRIVACY_MODAL: {
      return {
        ...state,
        showPrivacyModal: false,
      }
    }
    case PROMPT_TERMS_MODAL:
      return {
        ...state,
        showTermsModal: true,
      }
    case SUPPRESS_TERMS_MODAL: {
      return {
        ...state,
        showTermsModal: false,
      }
    }
    case PROMPT_HEALTH_DATA_MODAL: {
      return {
        ...state,
        showHealthDataModal: true
      }
    }
    case SUPPRESS_HEALTH_DATA_MODAL: {
      return {
        ...state,
        showHealthDataModal: false
      }
    }
    case PROMPT_COOKIES_MODAL:
      return {
        ...state,
        showCookiesModal: true,
      }
    case SUPPRESS_COOKIES_MODAL: {
      return {
        ...state,
        showCookiesModal: false,
      }
    }
    case FREE_MVP_CONTRACT: {
      return {
        ...state,
        freeMvpContract: true,
      }
    }
    case LOGOUT_FAILED: {
      return {
        ...state,
        isLogoutFailed: true,
        isLogoutLoading: false,
      }
    }
    case CLEAR_LOGOUT_FAILED: {
      return {
        ...state,
        isLogoutFailed: false
      }
    }
    case POST_LOGIN_UNLOCK: {
      return {
        ...state,
        isSuccess: false,
        isFailure: false,
        isUnlockingLogin: true,
        unlockErrorMessage: undefined,
        isFailureEmail: false
      }
    }
    case SUCCESS_POST_LOGIN_UNLOCK: {
      return {
        ...state,
        isSuccess: true,
        isFailure: false,
        isUnlockingLogin: false
      }
    }
    case FAILURE_POST_LOGIN_UNLOCK: {
      return {
        ...state,
        isSuccess: false,
        isFailure: true,
        isUnlockingLogin: false,
        unlockErrorMessage: action.unlockErrorMessage,
        resendLink: action.resendLink,
        redirect: action.redirect
      }
    }
    case POST_EMAIL_UNLOCK: {
      return {
        ...state,
        isFailureEmail: false,
        unlockErrorMessage: undefined,
        isLoading: true
      }
    }
    case SUCCESS_POST_EMAIL_UNLOCK: {
      return {
        ...state,
        isSuccessEmail: true,
        isFailureEmail: false,
        isFailure: false,
        isLoading: false
      }
    }
    case FAILURE_POST_EMAIL_UNLOCK: {
      return {
        ...state,
        isSuccessEmail: false,
        isFailureEmail: true,
        unlockErrorMessage: action.unlockErrorMessage,
        isLoading: false
      }
    }
    case SET_FORCE_UPDATE_CURRENT_USER: {
      return {
        ...state,
        forceUpdateCurrentUser: true
      }
    }
    case CLEAR_FORCE_UPDATE_CURRENT_USER: {
      return {
        ...state,
        forceUpdateCurrentUser: false,
      }
    }
    case POST_LOGIN: {
      return {
        ...state,
        postLoginStatus: {
          isPosting: true,
          isPost: false,
          errorData: undefined,
          errorPost: false
        }
      }
    }
    case SUCCESS_POST_LOGIN: {
      return {
        ...state,
        postLoginStatus: {
          isPosting: false,
          isPost: true,
          errorData: undefined,
          errorPost: false
        }
      }
    }
    case FAILURE_POST_LOGIN: {
      return {
        ...state,
        postLoginStatus: {
          isPosting: false,
          isPost: false,
          errorData: action.error,
          errorPost: true
        }
      }
    }
    case REFRESH_TOKEN: {
      return {
        ...state,
        postRefreshTokenStatus: {
          isPosting: true,
          isPost: false,
          errorPost: false
        }
      }
    }
    case SUCCESS_REFRESH_TOKEN: {
      return {
        ...state,
        postRefreshTokenStatus: {
          isPosting: false,
          isPost: true,
          errorPost: false
        },
        currentRefreshToken: action.currentRefreshToken
      }
    }
    case FAILURE_REFRESH_TOKEN: {
      return {
        ...state,
        postRefreshTokenStatus: {
          isPosting: false,
          isPost: false,
          errorPost: true
        },
        currentRefreshToken: ''
      }
    }
    case PROMPT_UNAUTHENTICATED_MODAL: {
      return {
        ...state,
        unauthenticatedModalError: action.errorType
      }
    }
    case SUPPRESS_UNAUTHENTICATED_MODAL: {
      return {
        ...state,
        unauthenticatedModalError: undefined,
        postRefreshTokenStatus: {
          isPosting: false,
          isPost: false,
          errorPost: false
        },
        currentRefreshToken: ''
      }
    }
    case HANDLE_SIGNUP: {
      return {
        ...state,
        signUpStatus: {
          isPosting: true,
          isPost: false,
          errorPost: false,
          errorData: undefined
        },
        consultantId: ''
      }
    }
    case SUCCESS_HANDLE_SIGNUP: {
      return {
        ...state,
        signUpStatus: {
          isPosting: false,
          isPost: true,
          errorPost: false,
          errorData: undefined
        },
        consultantId: action.consultantId
      }
    }
    case FAILURE_HANDLE_SIGNUP: {
      return {
        ...state,
        signUpStatus: {
          isPosting: false,
          isPost: false,
          errorPost: true,
          errorData: undefined
        },
        consultantId: ''
      }
    }
    case SEND_RECOVERY_LINK: {
      return {
        ...state,
        postPasswordRecoveryStatus: {
          isPost: false,
          isPosting: true,
          errorPost: false
        }
      }
    }
    case SUCCESS_SEND_RECOVERY_LINK: {
      return {
        ...state,
        postPasswordRecoveryStatus: {
          isPost: true,
          isPosting: false,
          errorPost: false
        }
      }
    }
    case FAILURE_SEND_RECOVERY_LINK: {
      return {
        ...state,
        postPasswordRecoveryStatus: {
          isPost: false,
          isPosting: false,
          errorPost: true
        }
      }
    }
    case RESET_ACCOUNT_PASSWORD: {
      return {
        ...state,
        resetAccountPasswordStatus: {
          ...initialStatus,
          isLoading: true,
        }
      }
    }
    case SUCCESS_RESET_ACCOUNT_PASSWORD: {
      return {
        ...state,
        resetAccountPasswordStatus: {
          ...initialStatus,
          success: true,
        }
      }
    }
    case FAILURE_RESET_ACCOUNT_PASSWORD: {
      return {
        ...state,
        resetAccountPasswordStatus: {
          ...initialStatus,
          error: true,
          errorMessage: action.error
        }
      }
    }
    case VERIFY_SIGNUP_DATA: {
      return {
        ...state,
        verifySignUpDataStatus: {
          isLoading: true,
          success: false,
          error: false,
          errorMessage: undefined
        },
        signUpUserData: undefined
      }
    }
    case SUCCESS_VERIFY_SIGNUP_DATA: {
      return {
        ...state,
        verifySignUpDataStatus: {
          isLoading: false,
          success: true,
          error: false
        },
        signUpUserData: action.signUpUserData
      }
    }
    case FAILURE_VERIFY_SIGNUP_DATA: {
      return {
        ...state,
        verifySignUpDataStatus: {
          isLoading: false,
          success: false,
          error: true,
          errorMessage: action.error
        }
      }
    }
    case CLEAR_VERIFY_DATA_STATUS: {
      return {
        ...state,
        verifySignUpDataStatus: {
          isLoading: false,
          success: false,
          error: false,
          errorMessage: undefined
        }
      }
    }
    case VERIFIED_SIGNUP: {
      return {
        ...state,
        verifiedSignUpStatus: {
          isLoading: true,
          success: false,
          error: false,
          errorMessage: undefined
        }
      }
    }
    case SUCCESS_VERIFIED_SIGNUP: {
      return {
        ...state,
        verifiedSignUpStatus: {
          isLoading: false,
          success: true,
          error: false
        }
      }
    }
    case FAILURE_VERIFIED_SIGNUP: {
      return {
        ...state,
        verifiedSignUpStatus: {
          isLoading: false,
          success: false,
          error: true,
          errorMessage: action.error
        }
      }
    }
    case CLEAR_SIGN_UP_ERRORS: {
      return {
        ...state,
        verifiedSignUpStatus: {
          isLoading: false,
          success: false,
          error: false,
          errorMessage: undefined
        }
      }
    }
    case SEND_VERIFICATION_CODE: {
      return {
        ...state,
        sendVerificationCodeStatus: {
          isLoading: true,
          success: false,
          error: false,
          errorMessage: undefined
        }
      }
    }
    case SUCCESS_SEND_VERIFICATION_CODE: {
      return {
        ...state,
        sendVerificationCodeStatus: {
          isLoading: false,
          success: true,
          error: false
        }
      }
    }
    case FAILURE_SEND_VERIFICATION_CODE: {
      return {
        ...state,
        sendVerificationCodeStatus: {
          isLoading: false,
          success: false,
          error: true,
          errorMessage: action.error
        }
      }
    }
    case GET_USER_CONFIRMATION_INFOS: {
      return {
        ...state,
        getUserConfirmationStatus: {
          isLoading: true,
          success: false,
          error: false
        },
        userConfirmationInfos: undefined
      }
    }
    case SUCCESS_GET_USER_CONFIRMATION_INFOS: {
      return {
        ...state,
        getUserConfirmationStatus: {
          isLoading: false,
          success: true,
          error: false
        },
        userConfirmationInfos: action.userConfirmationInfos
      }
    }
    case FAILURE_GET_USER_CONFIRMATION_INFOS: {
      return {
        ...state,
        getUserConfirmationStatus: {
          isLoading: false,
          success: false,
          error: true
        },
        userConfirmationInfos: undefined
      }
    }
    case CLEAR_VERIFICATION_CODE_STATUS: {
      return {
        ...state,
        sendVerificationCodeStatus: {
          isLoading: false,
          error: false,
          errorMessage: undefined,
          success: false
        }
      }
    }
    default: return state
  }
}
