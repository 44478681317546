import React from 'react'
import { ListItemCard } from 'theia-web-ds'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PediatricJourneyIcon from '../../../assets/pediatric-journey.png'
import { isPostPregnant, isPregnant, isStillBornPostPregnant } from '../../utils/helpers'
import { eventTrack } from '../../../eventGenerate'
import { AppState } from '../../apps/main/store'
import { AppDispatch } from '../../state/utils'
import { User } from '../../domain/User'
import { ADD_NEW_DEPENDENT } from '../../routes/RoutesConstants'
import { showPediatricWelcomeModalAction } from '../../state/pediatricFlow/actions'
import { BabyData } from '../../domain/PediatricFlow'
import { EventCategories } from '../../utils/EventCategories'

interface Props {
  currentUser?: User
  needToCreateBaby: boolean
  childrenData?: BabyData[]
  isDependent: boolean
  showPediatricWelcomeModal: () => void
}

function PediatricJourneyBanner({
  currentUser,
  needToCreateBaby,
  childrenData,
  isDependent,
  showPediatricWelcomeModal,
}: Props) {
  const history = useHistory()
  const hasChild = childrenData && childrenData.length > 0

  function getPediatricCardTitle() {
    if (isPregnant(currentUser?.pregnancyMoment) && needToCreateBaby) {
      return 'Seu bebê nasceu?'
    }
    if (isPostPregnant(currentUser?.pregnancyMoment) && needToCreateBaby) {
      return 'Jornada Pediátrica'
    }
    return 'Precisa de pediatria?'
  }

  function getPediatricCardSubtitle() {
    if (isPregnant(currentUser?.pregnancyMoment) && needToCreateBaby) {
      return 'Inicie a jornada pediátrica'
    }
    if (isPostPregnant(currentUser?.pregnancyMoment) && needToCreateBaby) {
      return 'Cadastre seu bebê para iniciar'
    }
    return 'Cadastre sua(s) criança(s)'
  }

  function handlePediatricFlow() {
    if (isPregnant(currentUser?.pregnancyMoment) && needToCreateBaby) {
      eventTrack('clicou seu bebe nasceu', { category: EventCategories.PEDIATRIC_REGISTER })
      return showPediatricWelcomeModal()
    }
    eventTrack('Clicou cadastrar criança no card da home', { category: EventCategories.PEDIATRIC_REGISTER })
    return history.push(ADD_NEW_DEPENDENT)
  }

  if (isDependent
    || !needToCreateBaby
    || hasChild
    || isStillBornPostPregnant(
      currentUser?.pregnancyMoment,
      currentUser?.pregnancyMomentText
    )
  ) return null

  return (
    <div className="p-4">
      <ListItemCard
        extraClassNameContainer="pediatric-journey-banner"
        title={getPediatricCardTitle()}
        picture={(
          <img
            src={PediatricJourneyIcon}
            width={40}
            height={40}
            className="mr-3 ml-6"
            alt=""
          />
        )}
        isLastItem
        caption={getPediatricCardSubtitle()}
        ariaLabel="Banner Jornada Pediátrica"
        onClick={handlePediatricFlow}
        titleSize="text-fontDefault"
        arrowColor="rgba(0, 0, 0, 0.6)"
        extraTitleClass="mb-2"
      />
    </div>
  )
}

const mapStateToProps = ({
  userHighlights, authentication, pediatricFlow, appProfiles
}: AppState) => ({
  currentUser: authentication.currentUser,
  needToCreateBaby: userHighlights.needToCreateBaby,
  childrenData: pediatricFlow.childrenData,
  isDependent: appProfiles.isDependent
})

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  showPediatricWelcomeModal: showPediatricWelcomeModalAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PediatricJourneyBanner)
